export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC"
}

export type SortParams = {
  sortBy: string;
  sortDirection: SortDirection;
};

export function sortByProperty<T>(
  array: T[],
  sortBy: string,
  sortDirection: SortDirection
): T[] {
  const sorted = array.sort((a, b) => {
    return getObjectAccessor(sortBy, a) < getObjectAccessor(sortBy, b) ? 1 : -1;
  });

  return sortDirection === SortDirection.DESC ? sorted.reverse() : sorted;
}

function getObjectAccessor<T>(path: string, obj: T): T {
  return path.split(".").reduce((o: any, i: any) => o && o[i], obj);
}
