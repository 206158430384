import { IRawData } from "./model";

export const rawData = [
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "6,5",
      "soc_ref": "5,9",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "5,4",
      "soc_ref": "5,9",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "6,4",
      "soc_ref": "6,5",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,3",
      "soc_ref": "7,5",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "6,3",
      "soc_ref": "7,3",
      "Forskel": "-1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "8,5",
      "soc_ref": "8,0",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "7,5",
      "soc_ref": "6,7",
      "Forskel": "0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "6,6",
      "soc_ref": "7,0",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "7,4",
      "soc_ref": "7,0",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "8,5",
      "soc_ref": "6,5",
      "Forskel": "2*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "6,3",
      "soc_ref": "6,4",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "7,3",
      "soc_ref": "6,6",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,5",
      "soc_ref": "7,6",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "6,9",
      "soc_ref": "7,6",
      "Forskel": "-0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,7",
      "soc_ref": "7,6",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "6,2",
      "soc_ref": "6,5",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "9,5",
      "soc_ref": "7,9",
      "Forskel": "1,6*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "7,1",
      "soc_ref": "7,4",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "8,3",
      "soc_ref": "6,8",
      "Forskel": "1,5*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "5,6",
      "soc_ref": "6,3",
      "Forskel": "-0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "6,1",
      "soc_ref": "6,4",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "8,3",
      "soc_ref": "7,1",
      "Forskel": "1,2*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "5,9",
      "soc_ref": "6,6",
      "Forskel": "-0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "6,1",
      "soc_ref": "6,6",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,9",
      "Soc_ref1": "7,1",
      "Textbox50": "0,8*",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,2",
      "Soc_ref1": "6,8",
      "Textbox50": "-0,6",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,9",
      "Soc_ref1": "6,8",
      "Textbox50": "0,1",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "5,8",
      "Socioøkonomisk_reference2": "6,0",
      "Forskel2": "-0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "8,2",
      "Socioøkonomisk_reference2": "7,6",
      "Forskel2": "0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "5,9",
      "Socioøkonomisk_reference2": "5,2",
      "Forskel2": "0,7",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "5,3",
      "Socioøkonomisk_reference2": "5,9",
      "Forskel2": "-0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "5,3",
      "Socioøkonomisk_reference2": "6,7",
      "Forskel2": "-1,4*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,1",
      "Socioøkonomisk_reference2": "7,0",
      "Forskel2": "0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,2",
      "Socioøkonomisk_reference2": "5,9",
      "Forskel2": "1,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "8,2",
      "Socioøkonomisk_reference2": "6,5",
      "Forskel2": "1,7*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "5,7",
      "soc_ref": "5,5",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "5,5",
      "soc_ref": "5,5",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "7,0",
      "soc_ref": "6,8",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "9,4",
      "soc_ref": "8,6",
      "Forskel": "0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,5",
      "soc_ref": "8,0",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "6,5",
      "soc_ref": "7,0",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "5,4",
      "soc_ref": "5,6",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "6,3",
      "soc_ref": "6,1",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "7,8",
      "soc_ref": "7,2",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "6,6",
      "soc_ref": "6,1",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "7,0",
      "soc_ref": "6,1",
      "Forskel": "0,9",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "8,8",
      "soc_ref": "7,5",
      "Forskel": "1,3*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "6,6",
      "soc_ref": "6,7",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,8",
      "soc_ref": "8,4",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "6,4",
      "soc_ref": "6,7",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "6,9",
      "soc_ref": "6,8",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "6,8",
      "soc_ref": "6,7",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "7,1",
      "soc_ref": "7,1",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "4,7",
      "soc_ref": "5,5",
      "Forskel": "-0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "7,2",
      "soc_ref": "6,4",
      "Forskel": "0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "6,9",
      "soc_ref": "6,6",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "4,9",
      "soc_ref": "5,4",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "7,3",
      "soc_ref": "6,9",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "7,3",
      "soc_ref": "7,0",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,3",
      "Soc_ref1": "6,3",
      "Textbox50": "0,0",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,2",
      "Soc_ref1": "6,8",
      "Textbox50": "0,4",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,2",
      "Soc_ref1": "7,0",
      "Textbox50": "0,2",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "5,7",
      "Socioøkonomisk_reference2": "5,4",
      "Forskel2": "0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,7",
      "Socioøkonomisk_reference2": "7,0",
      "Forskel2": "0,7",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "6,8",
      "Socioøkonomisk_reference2": "6,3",
      "Forskel2": "0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "5,8",
      "Socioøkonomisk_reference2": "6,4",
      "Forskel2": "-0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2015/2016",
      "Karakter2": "9,6",
      "Socioøkonomisk_reference2": "8,1",
      "Forskel2": "1,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,8",
      "Socioøkonomisk_reference2": "7,6",
      "Forskel2": "0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "5,7",
      "soc_ref": "6,0",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "7,7",
      "soc_ref": "7,1",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "6,6",
      "soc_ref": "6,5",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "9,5",
      "soc_ref": "9,0",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "7,5",
      "soc_ref": "7,8",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "8,3",
      "soc_ref": "7,9",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "8,1",
      "soc_ref": "7,4",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "9,1",
      "soc_ref": "7,6",
      "Forskel": "1,5*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "7,6",
      "soc_ref": "6,9",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "6,9",
      "soc_ref": "6,7",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "7,2",
      "soc_ref": "6,7",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "7,0",
      "soc_ref": "6,4",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,6",
      "soc_ref": "7,7",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,0",
      "soc_ref": "7,9",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "8,3",
      "soc_ref": "8,0",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,3",
      "soc_ref": "6,9",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,3",
      "soc_ref": "8,0",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "9,2",
      "soc_ref": "8,0",
      "Forskel": "1,2*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "6,9",
      "soc_ref": "7,1",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "7,2",
      "soc_ref": "6,9",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "6,1",
      "soc_ref": "6,3",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,1",
      "soc_ref": "7,1",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "8,0",
      "soc_ref": "7,4",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "7,1",
      "soc_ref": "6,9",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,5",
      "Soc_ref1": "7,4",
      "Textbox50": "0,1",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "8,0",
      "Soc_ref1": "7,5",
      "Textbox50": "0,5",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,3",
      "Soc_ref1": "7,0",
      "Textbox50": "0,3",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "6,9",
      "Socioøkonomisk_reference2": "6,9",
      "Forskel2": "0,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,4",
      "Socioøkonomisk_reference2": "7,4",
      "Forskel2": "1,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,9",
      "Socioøkonomisk_reference2": "7,8",
      "Forskel2": "0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "8,3",
      "Socioøkonomisk_reference2": "7,8",
      "Forskel2": "0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "9,5",
      "Socioøkonomisk_reference2": "8,3",
      "Forskel2": "1,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,7",
      "Socioøkonomisk_reference2": "7,0",
      "Forskel2": "-0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "8,0",
      "Socioøkonomisk_reference2": "7,6",
      "Forskel2": "0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "6,4",
      "Socioøkonomisk_reference2": "6,1",
      "Forskel2": "0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "4,6",
      "soc_ref": "5,0",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "5,4",
      "soc_ref": "5,8",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "5,8",
      "soc_ref": "6,1",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,3",
      "soc_ref": "7,5",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "7,2",
      "soc_ref": "7,6",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,7",
      "soc_ref": "7,3",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "6,1",
      "soc_ref": "6,1",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "6,6",
      "soc_ref": "6,6",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "6,2",
      "soc_ref": "6,4",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "4,6",
      "soc_ref": "5,7",
      "Forskel": "-1,1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "6,6",
      "soc_ref": "6,3",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "5,4",
      "soc_ref": "5,9",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,7",
      "soc_ref": "7,8",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "6,7",
      "soc_ref": "7,4",
      "Forskel": "-0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,5",
      "soc_ref": "7,5",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "6,1",
      "soc_ref": "6,1",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "4,8",
      "soc_ref": "6,5",
      "Forskel": "-1,7*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "6,8",
      "soc_ref": "7,1",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "5,6",
      "soc_ref": "6,2",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "5,9",
      "soc_ref": "5,9",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "5,7",
      "soc_ref": "5,9",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "5,7",
      "soc_ref": "6,2",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "6,2",
      "soc_ref": "6,4",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "5,2",
      "soc_ref": "6,1",
      "Forskel": "-0,9*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "5,8",
      "Soc_ref1": "6,3",
      "Textbox50": "-0,5",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,4",
      "Soc_ref1": "6,6",
      "Textbox50": "-0,2",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,2",
      "Soc_ref1": "6,4",
      "Textbox50": "-0,2",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,0",
      "Socioøkonomisk_reference2": "6,3",
      "Forskel2": "0,7",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "5,1",
      "Socioøkonomisk_reference2": "5,5",
      "Forskel2": "-0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,2",
      "Socioøkonomisk_reference2": "7,0",
      "Forskel2": "0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,0",
      "Socioøkonomisk_reference2": "6,7",
      "Forskel2": "0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "4,3",
      "Socioøkonomisk_reference2": "4,6",
      "Forskel2": "-0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "5,4",
      "Socioøkonomisk_reference2": "5,7",
      "Forskel2": "-0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,7",
      "Socioøkonomisk_reference2": "7,3",
      "Forskel2": "0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2017/2018",
      "Karakter2": "5,5",
      "Socioøkonomisk_reference2": "6,6",
      "Forskel2": "-1,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "9,3",
      "Socioøkonomisk_reference2": "8,2",
      "Forskel2": "1,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "6,6",
      "Socioøkonomisk_reference2": "6,8",
      "Forskel2": "-0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,8",
      "Socioøkonomisk_reference2": "6,8",
      "Forskel2": "0,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "6,7",
      "Socioøkonomisk_reference2": "7,3",
      "Forskel2": "-0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "5,4",
      "Socioøkonomisk_reference2": "5,4",
      "Forskel2": "0,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "6,8",
      "Socioøkonomisk_reference2": "7,0",
      "Forskel2": "-0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "7,0",
      "soc_ref": "6,9",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "6,3",
      "soc_ref": "6,6",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "7,7",
      "soc_ref": "7,1",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,5",
      "soc_ref": "8,3",
      "Forskel": "-0,8*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "9,6",
      "soc_ref": "8,6",
      "Forskel": "1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,5",
      "soc_ref": "7,8",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "8,1",
      "soc_ref": "7,6",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "6,9",
      "soc_ref": "7,1",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "7,6",
      "soc_ref": "7,1",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "6,3",
      "soc_ref": "6,8",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "8,9",
      "soc_ref": "6,9",
      "Forskel": "2*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "7,2",
      "soc_ref": "6,6",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,9",
      "soc_ref": "8,3",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "7,6",
      "soc_ref": "7,9",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "8,3",
      "soc_ref": "8,1",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "6,5",
      "soc_ref": "6,9",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,2",
      "soc_ref": "7,9",
      "Forskel": "-0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "6,2",
      "soc_ref": "7,3",
      "Forskel": "-1,1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "9,0",
      "soc_ref": "8,1",
      "Forskel": "0,9*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "8,3",
      "soc_ref": "7,6",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "7,4",
      "soc_ref": "7,1",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "8,9",
      "soc_ref": "8,1",
      "Forskel": "0,8*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "8,4",
      "soc_ref": "7,9",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "7,9",
      "soc_ref": "7,5",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,7",
      "Soc_ref1": "7,7",
      "Textbox50": "0,0",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,8",
      "Soc_ref1": "7,6",
      "Textbox50": "0,2",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,5",
      "Soc_ref1": "7,3",
      "Textbox50": "0,2",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,0",
      "Socioøkonomisk_reference2": "7,0",
      "Forskel2": "0,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,3",
      "Socioøkonomisk_reference2": "6,8",
      "Forskel2": "-0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "8,0",
      "Socioøkonomisk_reference2": "8,1",
      "Forskel2": "-0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "6,8",
      "Socioøkonomisk_reference2": "7,3",
      "Forskel2": "-0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "9,3",
      "Socioøkonomisk_reference2": "8,2",
      "Forskel2": "1,1*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "6,7",
      "Socioøkonomisk_reference2": "6,6",
      "Forskel2": "0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "8,2",
      "Socioøkonomisk_reference2": "8,1",
      "Forskel2": "0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,0",
      "Socioøkonomisk_reference2": "8,0",
      "Forskel2": "0,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2015/2016",
      "Karakter2": "5,8",
      "Socioøkonomisk_reference2": "7,2",
      "Forskel2": "-1,4*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "8,0",
      "Socioøkonomisk_reference2": "8,1",
      "Forskel2": "-0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,9",
      "Socioøkonomisk_reference2": "7,7",
      "Forskel2": "0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "9,7",
      "Socioøkonomisk_reference2": "9,1",
      "Forskel2": "0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,4",
      "Socioøkonomisk_reference2": "7,0",
      "Forskel2": "-0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "6,9",
      "Socioøkonomisk_reference2": "7,1",
      "Forskel2": "-0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "6,7",
      "soc_ref": "6,1",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "5,2",
      "soc_ref": "5,9",
      "Forskel": "-0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "7,2",
      "soc_ref": "6,6",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,5",
      "soc_ref": "7,6",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "7,5",
      "soc_ref": "7,9",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,0",
      "soc_ref": "7,1",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "7,3",
      "soc_ref": "6,6",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "6,7",
      "soc_ref": "6,9",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "7,4",
      "soc_ref": "6,5",
      "Forskel": "0,9*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "6,9",
      "soc_ref": "6,3",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "4,1",
      "soc_ref": "6,1",
      "Forskel": "-2*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "7,1",
      "soc_ref": "6,1",
      "Forskel": "1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,0",
      "soc_ref": "7,2",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "6,3",
      "soc_ref": "7,0",
      "Forskel": "-0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "8,2",
      "soc_ref": "7,7",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,0",
      "soc_ref": "6,6",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,2",
      "soc_ref": "7,1",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "6,8",
      "soc_ref": "7,2",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "6,6",
      "soc_ref": "6,7",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "4,4",
      "soc_ref": "6,0",
      "Forskel": "-1,6*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "6,8",
      "soc_ref": "6,4",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,3",
      "soc_ref": "6,7",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "5,7",
      "soc_ref": "6,3",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "7,1",
      "soc_ref": "6,8",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,1",
      "Soc_ref1": "6,9",
      "Textbox50": "0,2",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "5,8",
      "Soc_ref1": "6,6",
      "Textbox50": "-0,8*",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,2",
      "Soc_ref1": "6,7",
      "Textbox50": "0,5",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "5,1",
      "Socioøkonomisk_reference2": "5,3",
      "Forskel2": "-0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,7",
      "Socioøkonomisk_reference2": "6,9",
      "Forskel2": "0,8",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "6,6",
      "Socioøkonomisk_reference2": "6,4",
      "Forskel2": "0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "4,5",
      "Socioøkonomisk_reference2": "5,1",
      "Forskel2": "-0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,1",
      "Socioøkonomisk_reference2": "7,5",
      "Forskel2": "-0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,3",
      "Socioøkonomisk_reference2": "6,9",
      "Forskel2": "0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,1",
      "Socioøkonomisk_reference2": "7,7",
      "Forskel2": "-0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,7",
      "Socioøkonomisk_reference2": "6,7",
      "Forskel2": "0,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,3",
      "Socioøkonomisk_reference2": "7,8",
      "Forskel2": "0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "6,1",
      "Socioøkonomisk_reference2": "6,1",
      "Forskel2": "0,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,6",
      "Socioøkonomisk_reference2": "7,2",
      "Forskel2": "0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "6,0",
      "soc_ref": "6,4",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "7,0",
      "soc_ref": "7,0",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "7,5",
      "soc_ref": "7,4",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,1",
      "soc_ref": "8,2",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "9,5",
      "soc_ref": "8,7",
      "Forskel": "0,8*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "8,9",
      "soc_ref": "8,6",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "7,3",
      "soc_ref": "7,2",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "8,0",
      "soc_ref": "7,8",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "8,4",
      "soc_ref": "7,9",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "5,4",
      "soc_ref": "6,5",
      "Forskel": "-1,1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "6,1",
      "soc_ref": "6,8",
      "Forskel": "-0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "7,0",
      "soc_ref": "7,1",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,5",
      "soc_ref": "8,5",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,6",
      "soc_ref": "8,5",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "8,3",
      "soc_ref": "8,3",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "6,8",
      "soc_ref": "7,3",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,6",
      "soc_ref": "7,7",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,2",
      "soc_ref": "8,0",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "6,7",
      "soc_ref": "7,6",
      "Forskel": "-0,9*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "8,0",
      "soc_ref": "7,6",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "7,6",
      "soc_ref": "7,5",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,5",
      "soc_ref": "7,6",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "8,0",
      "soc_ref": "7,8",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "8,0",
      "soc_ref": "7,8",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,1",
      "Soc_ref1": "7,4",
      "Textbox50": "-0,3",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,9",
      "Soc_ref1": "7,7",
      "Textbox50": "0,2",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,8",
      "Soc_ref1": "7,7",
      "Textbox50": "0,1",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,5",
      "Socioøkonomisk_reference2": "7,5",
      "Forskel2": "0,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,3",
      "Socioøkonomisk_reference2": "7,8",
      "Forskel2": "-0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,1",
      "Socioøkonomisk_reference2": "8,4",
      "Forskel2": "-0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,6",
      "Socioøkonomisk_reference2": "8,0",
      "Forskel2": "-0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "6,3",
      "Socioøkonomisk_reference2": "6,4",
      "Forskel2": "-0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "5,3",
      "Socioøkonomisk_reference2": "5,8",
      "Forskel2": "-0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "6,8",
      "Socioøkonomisk_reference2": "7,3",
      "Forskel2": "-0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,3",
      "Socioøkonomisk_reference2": "6,9",
      "Forskel2": "0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,6",
      "Socioøkonomisk_reference2": "8,4",
      "Forskel2": "0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "5,8",
      "Socioøkonomisk_reference2": "7,2",
      "Forskel2": "-1,4*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2017/2018",
      "Karakter2": "9,5",
      "Socioøkonomisk_reference2": "8,4",
      "Forskel2": "1,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2016/2017",
      "Karakter2": "9,7",
      "Socioøkonomisk_reference2": "8,8",
      "Forskel2": "0,9",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2015/2016",
      "Karakter2": "8,5",
      "Socioøkonomisk_reference2": "8,6",
      "Forskel2": "-0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "6,7",
      "Socioøkonomisk_reference2": "7,7",
      "Forskel2": "-1,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "8,4",
      "Socioøkonomisk_reference2": "8,3",
      "Forskel2": "0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "6,9",
      "Socioøkonomisk_reference2": "7,5",
      "Forskel2": "-0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "8,9",
      "Socioøkonomisk_reference2": "8,4",
      "Forskel2": "0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,6",
      "Socioøkonomisk_reference2": "8,5",
      "Forskel2": "0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "6,8",
      "Socioøkonomisk_reference2": "7,0",
      "Forskel2": "-0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "10,3",
      "Socioøkonomisk_reference2": "9,0",
      "Forskel2": "1,3*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "7,1",
      "soc_ref": "6,9",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "6,7",
      "soc_ref": "7,1",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "7,5",
      "soc_ref": "7,7",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,9",
      "soc_ref": "8,7",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "9,1",
      "soc_ref": "8,8",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "9,8",
      "soc_ref": "9,1",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "7,5",
      "soc_ref": "7,5",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "8,1",
      "soc_ref": "8,0",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "8,0",
      "soc_ref": "8,0",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "7,2",
      "soc_ref": "6,9",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "5,9",
      "soc_ref": "7,0",
      "Forskel": "-1,1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "6,2",
      "soc_ref": "7,1",
      "Forskel": "-0,9*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,7",
      "soc_ref": "8,7",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,6",
      "soc_ref": "8,7",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "9,5",
      "soc_ref": "8,9",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "9,1",
      "soc_ref": "7,9",
      "Forskel": "1,2*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,0",
      "soc_ref": "8,1",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "9,3",
      "soc_ref": "8,3",
      "Forskel": "1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,2",
      "soc_ref": "7,9",
      "Forskel": "-0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "7,5",
      "soc_ref": "7,7",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "8,6",
      "soc_ref": "7,9",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,7",
      "soc_ref": "7,9",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "8,2",
      "soc_ref": "8,0",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "8,6",
      "soc_ref": "8,1",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,8",
      "Soc_ref1": "7,8",
      "Textbox50": "0,0",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,9",
      "Soc_ref1": "7,9",
      "Textbox50": "0,0",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "8,4",
      "Soc_ref1": "8,1",
      "Textbox50": "0,3",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "4,4",
      "Socioøkonomisk_reference2": "6,2",
      "Forskel2": "-1,8*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,6",
      "Socioøkonomisk_reference2": "7,6",
      "Forskel2": "0,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,1",
      "Socioøkonomisk_reference2": "7,0",
      "Forskel2": "-0,9",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "9,0",
      "Socioøkonomisk_reference2": "8,6",
      "Forskel2": "0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,2",
      "Socioøkonomisk_reference2": "7,0",
      "Forskel2": "0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "6,2",
      "Socioøkonomisk_reference2": "7,1",
      "Forskel2": "-0,9",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,0",
      "Socioøkonomisk_reference2": "7,1",
      "Forskel2": "-0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,4",
      "Socioøkonomisk_reference2": "8,6",
      "Forskel2": "-0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,0",
      "Socioøkonomisk_reference2": "8,0",
      "Forskel2": "-1,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,4",
      "Socioøkonomisk_reference2": "8,2",
      "Forskel2": "-0,8",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,7",
      "Socioøkonomisk_reference2": "8,1",
      "Forskel2": "-0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "9,2",
      "Socioøkonomisk_reference2": "8,8",
      "Forskel2": "0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "9,3",
      "Socioøkonomisk_reference2": "8,8",
      "Forskel2": "0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "9,0",
      "Socioøkonomisk_reference2": "8,5",
      "Forskel2": "0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "10,1",
      "Socioøkonomisk_reference2": "9,0",
      "Forskel2": "1,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "9,6",
      "Socioøkonomisk_reference2": "9,2",
      "Forskel2": "0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "6,5",
      "Socioøkonomisk_reference2": "6,9",
      "Forskel2": "-0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "6,2",
      "Socioøkonomisk_reference2": "6,7",
      "Forskel2": "-0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,7",
      "Socioøkonomisk_reference2": "6,9",
      "Forskel2": "-0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "6,1",
      "soc_ref": "5,4",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "5,1",
      "soc_ref": "5,6",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "6,7",
      "soc_ref": "6,4",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,0",
      "soc_ref": "7,3",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "6,9",
      "soc_ref": "7,5",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,6",
      "soc_ref": "7,6",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "7,1",
      "soc_ref": "6,2",
      "Forskel": "0,9*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "6,6",
      "soc_ref": "6,7",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "7,6",
      "soc_ref": "6,9",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "7,7",
      "soc_ref": "6,0",
      "Forskel": "1,7*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "7,1",
      "soc_ref": "6,4",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "6,6",
      "soc_ref": "6,3",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,7",
      "soc_ref": "7,6",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "7,4",
      "soc_ref": "7,7",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "6,7",
      "soc_ref": "7,2",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "5,2",
      "soc_ref": "6,2",
      "Forskel": "-1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "5,9",
      "soc_ref": "6,6",
      "Forskel": "-0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "6,4",
      "soc_ref": "7,0",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,1",
      "soc_ref": "6,1",
      "Forskel": "1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "7,0",
      "soc_ref": "6,2",
      "Forskel": "0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "6,5",
      "soc_ref": "6,3",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "6,6",
      "soc_ref": "6,2",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "7,0",
      "soc_ref": "6,8",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "6,7",
      "soc_ref": "6,7",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,9",
      "Soc_ref1": "6,5",
      "Textbox50": "0,4",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,7",
      "Soc_ref1": "6,8",
      "Textbox50": "-0,1",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,7",
      "Soc_ref1": "6,7",
      "Textbox50": "0,0",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,6",
      "Socioøkonomisk_reference2": "7,1",
      "Forskel2": "0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "5,5",
      "Socioøkonomisk_reference2": "6,5",
      "Forskel2": "-1,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "4,0",
      "Socioøkonomisk_reference2": "4,2",
      "Forskel2": "-0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "4,2",
      "Socioøkonomisk_reference2": "5,2",
      "Forskel2": "-1,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "4,9",
      "Socioøkonomisk_reference2": "5,5",
      "Forskel2": "-0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "5,3",
      "Socioøkonomisk_reference2": "6,4",
      "Forskel2": "-1,1*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "5,2",
      "Socioøkonomisk_reference2": "6,4",
      "Forskel2": "-1,2*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,7",
      "Socioøkonomisk_reference2": "7,1",
      "Forskel2": "-0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "5,2",
      "Socioøkonomisk_reference2": "6,1",
      "Forskel2": "-0,9",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,6",
      "Socioøkonomisk_reference2": "7,3",
      "Forskel2": "0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "6,1",
      "soc_ref": "5,9",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "5,4",
      "soc_ref": "5,7",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "6,4",
      "soc_ref": "6,3",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,1",
      "soc_ref": "7,7",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,4",
      "soc_ref": "8,2",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "8,2",
      "soc_ref": "7,8",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "7,8",
      "soc_ref": "6,7",
      "Forskel": "1,1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "6,1",
      "soc_ref": "6,4",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "7,2",
      "soc_ref": "6,6",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "6,0",
      "soc_ref": "5,9",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "5,8",
      "soc_ref": "6,1",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "4,9",
      "soc_ref": "5,6",
      "Forskel": "-0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,1",
      "soc_ref": "7,2",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "5,9",
      "soc_ref": "6,5",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "6,7",
      "soc_ref": "6,9",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "6,6",
      "soc_ref": "6,4",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "9,2",
      "soc_ref": "7,7",
      "Forskel": "1,5*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "7,6",
      "soc_ref": "7,5",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "5,9",
      "soc_ref": "6,4",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "6,2",
      "soc_ref": "6,1",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "5,7",
      "soc_ref": "6,0",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,1",
      "soc_ref": "6,8",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "6,9",
      "soc_ref": "6,6",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "6,7",
      "soc_ref": "6,5",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,2",
      "Soc_ref1": "6,8",
      "Textbox50": "0,4",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,5",
      "Soc_ref1": "6,6",
      "Textbox50": "-0,1",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,5",
      "Soc_ref1": "6,5",
      "Textbox50": "0,0",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "5,3",
      "Socioøkonomisk_reference2": "5,5",
      "Forskel2": "-0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,0",
      "Socioøkonomisk_reference2": "7,0",
      "Forskel2": "0,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2017/2018",
      "Karakter2": "8,6",
      "Socioøkonomisk_reference2": "7,6",
      "Forskel2": "1,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,0",
      "Socioøkonomisk_reference2": "7,3",
      "Forskel2": "-0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "6,0",
      "Socioøkonomisk_reference2": "6,0",
      "Forskel2": "0,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "6,2",
      "soc_ref": "6,5",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "7,0",
      "soc_ref": "7,0",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "7,7",
      "soc_ref": "7,3",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,8",
      "soc_ref": "8,0",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,0",
      "soc_ref": "8,3",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "8,3",
      "soc_ref": "8,1",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "7,3",
      "soc_ref": "7,1",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "7,8",
      "soc_ref": "7,7",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "8,0",
      "soc_ref": "7,6",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "6,5",
      "soc_ref": "6,5",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "7,3",
      "soc_ref": "7,0",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "7,2",
      "soc_ref": "6,8",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,7",
      "soc_ref": "8,2",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,9",
      "soc_ref": "8,5",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "8,1",
      "soc_ref": "8,2",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "6,9",
      "soc_ref": "7,1",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "6,8",
      "soc_ref": "7,5",
      "Forskel": "-0,7*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "7,7",
      "soc_ref": "7,8",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "6,9",
      "soc_ref": "7,5",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "7,3",
      "soc_ref": "7,4",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "7,0",
      "soc_ref": "7,4",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,7",
      "soc_ref": "7,5",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "8,4",
      "soc_ref": "7,8",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "8,3",
      "soc_ref": "7,8",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,1",
      "Soc_ref1": "7,3",
      "Textbox50": "-0,2",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,8",
      "Soc_ref1": "7,7",
      "Textbox50": "0,1",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,7",
      "Soc_ref1": "7,5",
      "Textbox50": "0,2",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,2",
      "Socioøkonomisk_reference2": "6,9",
      "Forskel2": "0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "8,9",
      "Socioøkonomisk_reference2": "8,1",
      "Forskel2": "0,8",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "5,8",
      "Socioøkonomisk_reference2": "6,1",
      "Forskel2": "-0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,0",
      "Socioøkonomisk_reference2": "7,5",
      "Forskel2": "0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,4",
      "Socioøkonomisk_reference2": "7,6",
      "Forskel2": "-0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "6,4",
      "Socioøkonomisk_reference2": "7,7",
      "Forskel2": "-1,3*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,0",
      "Socioøkonomisk_reference2": "7,2",
      "Forskel2": "-1,2*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,4",
      "Socioøkonomisk_reference2": "7,7",
      "Forskel2": "-0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,0",
      "Socioøkonomisk_reference2": "8,4",
      "Forskel2": "-0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2015/2016",
      "Karakter2": "9,0",
      "Socioøkonomisk_reference2": "8,3",
      "Forskel2": "0,7",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "6,1",
      "Socioøkonomisk_reference2": "6,9",
      "Forskel2": "-0,8",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,7",
      "Socioøkonomisk_reference2": "7,7",
      "Forskel2": "0,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "9,1",
      "Socioøkonomisk_reference2": "8,8",
      "Forskel2": "0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "6,3",
      "Socioøkonomisk_reference2": "7,4",
      "Forskel2": "-1,1*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,9",
      "Socioøkonomisk_reference2": "7,5",
      "Forskel2": "0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "9,1",
      "Socioøkonomisk_reference2": "8,4",
      "Forskel2": "0,7",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "5,5",
      "Socioøkonomisk_reference2": "6,2",
      "Forskel2": "-0,7",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "4,2",
      "Socioøkonomisk_reference2": "5,2",
      "Forskel2": "-1,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,6",
      "Socioøkonomisk_reference2": "7,7",
      "Forskel2": "-0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "5,8",
      "soc_ref": "6,1",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "5,4",
      "soc_ref": "5,8",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "6,4",
      "soc_ref": "6,3",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,3",
      "soc_ref": "8,1",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "7,7",
      "soc_ref": "7,5",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "8,9",
      "soc_ref": "8,1",
      "Forskel": "0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "6,9",
      "soc_ref": "6,9",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "7,4",
      "soc_ref": "6,6",
      "Forskel": "0,8*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "7,3",
      "soc_ref": "6,7",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "5,5",
      "soc_ref": "6,3",
      "Forskel": "-0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "3,9",
      "soc_ref": "5,6",
      "Forskel": "-1,7*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "5,2",
      "soc_ref": "6,0",
      "Forskel": "-0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "6,5",
      "soc_ref": "7,3",
      "Forskel": "-0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "6,7",
      "soc_ref": "7,2",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "8,1",
      "soc_ref": "7,7",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,5",
      "soc_ref": "6,9",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,2",
      "soc_ref": "7,6",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "5,0",
      "soc_ref": "6,3",
      "Forskel": "-1,3*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "8,1",
      "soc_ref": "7,4",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "7,2",
      "soc_ref": "6,4",
      "Forskel": "0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "7,6",
      "soc_ref": "6,6",
      "Forskel": "1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "8,7",
      "soc_ref": "7,6",
      "Forskel": "1,1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "7,3",
      "soc_ref": "6,8",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "6,7",
      "soc_ref": "6,6",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,3",
      "Soc_ref1": "7,2",
      "Textbox50": "0,1",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,3",
      "Soc_ref1": "6,5",
      "Textbox50": "-0,2",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,2",
      "Soc_ref1": "6,8",
      "Textbox50": "0,4",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "5,6",
      "Socioøkonomisk_reference2": "6,0",
      "Forskel2": "-0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,9",
      "Socioøkonomisk_reference2": "7,0",
      "Forskel2": "0,9",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,5",
      "Socioøkonomisk_reference2": "6,7",
      "Forskel2": "0,8",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "5,0",
      "Socioøkonomisk_reference2": "6,3",
      "Forskel2": "-1,3*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,1",
      "Socioøkonomisk_reference2": "7,5",
      "Forskel2": "-0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,0",
      "Socioøkonomisk_reference2": "7,1",
      "Forskel2": "-1,1*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "5,9",
      "Socioøkonomisk_reference2": "7,0",
      "Forskel2": "-1,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,7",
      "Socioøkonomisk_reference2": "7,4",
      "Forskel2": "0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "8,3",
      "Socioøkonomisk_reference2": "6,8",
      "Forskel2": "1,5*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,7",
      "Socioøkonomisk_reference2": "6,8",
      "Forskel2": "-0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "4,5",
      "soc_ref": "4,8",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "5,4",
      "soc_ref": "5,8",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "5,4",
      "soc_ref": "5,7",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "5,4",
      "soc_ref": "6,5",
      "Forskel": "-1,1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "6,3",
      "soc_ref": "7,3",
      "Forskel": "-1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "6,1",
      "soc_ref": "6,5",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "5,3",
      "soc_ref": "5,7",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "4,8",
      "soc_ref": "6,2",
      "Forskel": "-1,4*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "5,4",
      "soc_ref": "5,9",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "4,1",
      "soc_ref": "5,3",
      "Forskel": "-1,2*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "7,2",
      "soc_ref": "6,4",
      "Forskel": "0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "5,8",
      "soc_ref": "5,7",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,0",
      "soc_ref": "7,7",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "7,3",
      "soc_ref": "7,6",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "8,5",
      "soc_ref": "8,0",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "5,3",
      "soc_ref": "5,9",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,1",
      "soc_ref": "6,8",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "6,7",
      "soc_ref": "7,0",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "5,3",
      "soc_ref": "5,9",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "4,8",
      "soc_ref": "5,5",
      "Forskel": "-0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "5,9",
      "soc_ref": "6,0",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "5,0",
      "soc_ref": "5,8",
      "Forskel": "-0,8*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "5,1",
      "soc_ref": "5,9",
      "Forskel": "-0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "5,5",
      "soc_ref": "6,1",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "5,6",
      "Soc_ref1": "6,0",
      "Textbox50": "-0,4",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "5,9",
      "Soc_ref1": "6,4",
      "Textbox50": "-0,5",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "5,9",
      "Soc_ref1": "6,1",
      "Textbox50": "-0,2",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "5,7",
      "Socioøkonomisk_reference2": "5,1",
      "Forskel2": "0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,3",
      "Socioøkonomisk_reference2": "6,5",
      "Forskel2": "0,8",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "4,9",
      "Socioøkonomisk_reference2": "6,1",
      "Forskel2": "-1,2*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,3",
      "Socioøkonomisk_reference2": "6,8",
      "Forskel2": "0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "3,6",
      "Socioøkonomisk_reference2": "4,3",
      "Forskel2": "-0,7",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,5",
      "Socioøkonomisk_reference2": "7,4",
      "Forskel2": "0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,8",
      "Socioøkonomisk_reference2": "7,5",
      "Forskel2": "0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,1",
      "Socioøkonomisk_reference2": "7,7",
      "Forskel2": "-0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "4,9",
      "Socioøkonomisk_reference2": "5,5",
      "Forskel2": "-0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "6,9",
      "soc_ref": "6,5",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "6,0",
      "soc_ref": "6,3",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "7,3",
      "soc_ref": "7,2",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,4",
      "soc_ref": "7,7",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "9,4",
      "soc_ref": "8,7",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,8",
      "soc_ref": "7,9",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "7,1",
      "soc_ref": "6,9",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "6,9",
      "soc_ref": "7,1",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "7,2",
      "soc_ref": "7,4",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "5,8",
      "soc_ref": "6,3",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "7,2",
      "soc_ref": "6,8",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "8,2",
      "soc_ref": "7,0",
      "Forskel": "1,2*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "6,7",
      "soc_ref": "7,3",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,0",
      "soc_ref": "8,0",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,6",
      "soc_ref": "7,9",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "5,7",
      "soc_ref": "6,6",
      "Forskel": "-0,9",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,7",
      "soc_ref": "7,9",
      "Forskel": "0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,9",
      "soc_ref": "8,0",
      "Forskel": "0,9",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,2",
      "soc_ref": "7,2",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "5,8",
      "soc_ref": "6,4",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "8,3",
      "soc_ref": "7,7",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,3",
      "soc_ref": "7,2",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "6,9",
      "soc_ref": "6,9",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "8,1",
      "soc_ref": "7,8",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,1",
      "Soc_ref1": "7,1",
      "Textbox50": "0,0",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,4",
      "Soc_ref1": "7,3",
      "Textbox50": "0,1",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,5",
      "Soc_ref1": "7,5",
      "Textbox50": "0,0",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,7",
      "Socioøkonomisk_reference2": "7,2",
      "Forskel2": "-0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,1",
      "Socioøkonomisk_reference2": "7,1",
      "Forskel2": "0,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "6,1",
      "Socioøkonomisk_reference2": "5,9",
      "Forskel2": "0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "5,2",
      "Socioøkonomisk_reference2": "5,7",
      "Forskel2": "-0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,3",
      "Socioøkonomisk_reference2": "7,2",
      "Forskel2": "0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,7",
      "Socioøkonomisk_reference2": "7,6",
      "Forskel2": "0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,6",
      "Socioøkonomisk_reference2": "7,8",
      "Forskel2": "-0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "5,9",
      "Socioøkonomisk_reference2": "6,9",
      "Forskel2": "-1,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "6,9",
      "Socioøkonomisk_reference2": "7,6",
      "Forskel2": "-0,7",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,7",
      "Socioøkonomisk_reference2": "7,6",
      "Forskel2": "-0,9",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "6,3",
      "soc_ref": "6,3",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "7,2",
      "soc_ref": "7,2",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "7,3",
      "soc_ref": "7,3",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,9",
      "soc_ref": "8,2",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,4",
      "soc_ref": "8,4",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "9,2",
      "soc_ref": "8,6",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "6,8",
      "soc_ref": "6,9",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "8,4",
      "soc_ref": "7,9",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "8,0",
      "soc_ref": "7,8",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "8,1",
      "soc_ref": "6,6",
      "Forskel": "1,5*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "7,8",
      "soc_ref": "7,1",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "6,4",
      "soc_ref": "6,9",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,9",
      "soc_ref": "8,4",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,9",
      "soc_ref": "8,6",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "9,6",
      "soc_ref": "8,8",
      "Forskel": "0,8*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,4",
      "soc_ref": "7,4",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,1",
      "soc_ref": "7,5",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,4",
      "soc_ref": "8,0",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "8,8",
      "soc_ref": "7,6",
      "Forskel": "1,2*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "8,5",
      "soc_ref": "7,8",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "7,5",
      "soc_ref": "7,6",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,4",
      "soc_ref": "7,2",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "8,9",
      "soc_ref": "8,3",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "8,4",
      "soc_ref": "7,9",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,9",
      "Soc_ref1": "7,4",
      "Textbox50": "0,5",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "8,3",
      "Soc_ref1": "7,9",
      "Textbox50": "0,4",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "8,0",
      "Soc_ref1": "7,8",
      "Textbox50": "0,2",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,5",
      "Socioøkonomisk_reference2": "7,7",
      "Forskel2": "-0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,8",
      "Socioøkonomisk_reference2": "7,9",
      "Forskel2": "-0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "6,8",
      "Socioøkonomisk_reference2": "6,3",
      "Forskel2": "0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "5,9",
      "Socioøkonomisk_reference2": "6,0",
      "Forskel2": "-0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "6,8",
      "Socioøkonomisk_reference2": "7,3",
      "Forskel2": "-0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,4",
      "Socioøkonomisk_reference2": "8,1",
      "Forskel2": "-0,7",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "9,9",
      "Socioøkonomisk_reference2": "9,0",
      "Forskel2": "0,9",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,0",
      "Socioøkonomisk_reference2": "8,1",
      "Forskel2": "-0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2015/2016",
      "Karakter2": "10,3",
      "Socioøkonomisk_reference2": "8,7",
      "Forskel2": "1,6*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "8,5",
      "Socioøkonomisk_reference2": "8,3",
      "Forskel2": "0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "8,1",
      "Socioøkonomisk_reference2": "7,8",
      "Forskel2": "0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,9",
      "Socioøkonomisk_reference2": "7,3",
      "Forskel2": "0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,5",
      "Socioøkonomisk_reference2": "8,2",
      "Forskel2": "0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,2",
      "Socioøkonomisk_reference2": "8,0",
      "Forskel2": "-0,8",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "5,3",
      "soc_ref": "5,9",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "7,1",
      "soc_ref": "6,8",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "7,2",
      "soc_ref": "7,1",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "6,9",
      "soc_ref": "7,5",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "9,3",
      "soc_ref": "8,6",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "8,1",
      "soc_ref": "8,2",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "5,6",
      "soc_ref": "6,6",
      "Forskel": "-1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "7,9",
      "soc_ref": "7,3",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "8,6",
      "soc_ref": "7,9",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "5,9",
      "soc_ref": "6,4",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "8,0",
      "soc_ref": "6,9",
      "Forskel": "1,1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "6,7",
      "soc_ref": "6,9",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,4",
      "soc_ref": "7,8",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,1",
      "soc_ref": "7,9",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "9,0",
      "soc_ref": "8,7",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "5,9",
      "soc_ref": "6,6",
      "Forskel": "-0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,2",
      "soc_ref": "8,0",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,5",
      "soc_ref": "7,8",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,8",
      "soc_ref": "7,7",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "6,7",
      "soc_ref": "6,7",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "7,7",
      "soc_ref": "7,3",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,7",
      "soc_ref": "7,7",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "6,4",
      "soc_ref": "6,7",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "7,5",
      "soc_ref": "7,4",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,8",
      "Soc_ref1": "7,2",
      "Textbox50": "-0,4",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,7",
      "Soc_ref1": "7,3",
      "Textbox50": "0,4",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,6",
      "Soc_ref1": "7,5",
      "Textbox50": "0,1",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "4,4",
      "Socioøkonomisk_reference2": "5,4",
      "Forskel2": "-1*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "6,8",
      "Socioøkonomisk_reference2": "6,7",
      "Forskel2": "0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,4",
      "Socioøkonomisk_reference2": "7,2",
      "Forskel2": "-0,8",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "6,9",
      "Socioøkonomisk_reference2": "7,7",
      "Forskel2": "-0,8",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "6,9",
      "Socioøkonomisk_reference2": "7,4",
      "Forskel2": "-0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,3",
      "Socioøkonomisk_reference2": "7,2",
      "Forskel2": "-0,9",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "5,6",
      "soc_ref": "5,4",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "5,3",
      "soc_ref": "5,4",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,6",
      "soc_ref": "7,9",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "6,8",
      "soc_ref": "6,9",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "6,2",
      "soc_ref": "5,9",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "6,4",
      "soc_ref": "6,0",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "6,2",
      "soc_ref": "5,7",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "5,5",
      "soc_ref": "5,5",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,0",
      "soc_ref": "7,7",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "6,6",
      "soc_ref": "6,7",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,0",
      "soc_ref": "6,3",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "7,6",
      "soc_ref": "7,0",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "5,8",
      "soc_ref": "5,0",
      "Forskel": "0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "5,0",
      "soc_ref": "5,1",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "5,8",
      "soc_ref": "5,6",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "6,3",
      "soc_ref": "5,9",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,6",
      "Soc_ref1": "6,1",
      "Textbox50": "0,5",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,1",
      "Soc_ref1": "6,0",
      "Textbox50": "0,1",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "5,6",
      "Socioøkonomisk_reference2": "4,8",
      "Forskel2": "0,8",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "4,3",
      "Socioøkonomisk_reference2": "4,5",
      "Forskel2": "-0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "6,4",
      "Socioøkonomisk_reference2": "6,6",
      "Forskel2": "-0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "8,6",
      "Socioøkonomisk_reference2": "7,5",
      "Forskel2": "1,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "4,9",
      "soc_ref": "4,6",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "6,4",
      "soc_ref": "6,0",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "6,1",
      "soc_ref": "6,0",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "6,8",
      "soc_ref": "6,9",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "7,3",
      "soc_ref": "7,6",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,0",
      "soc_ref": "7,3",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "5,6",
      "soc_ref": "5,4",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "5,7",
      "soc_ref": "6,3",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "5,3",
      "soc_ref": "5,9",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "4,8",
      "soc_ref": "5,2",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "5,4",
      "soc_ref": "6,0",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "5,6",
      "soc_ref": "5,8",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,4",
      "soc_ref": "7,9",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,1",
      "soc_ref": "7,9",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,5",
      "soc_ref": "7,5",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "5,9",
      "soc_ref": "6,2",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,0",
      "soc_ref": "6,7",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "6,0",
      "soc_ref": "6,6",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "5,8",
      "soc_ref": "5,5",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "5,3",
      "soc_ref": "5,3",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "5,9",
      "soc_ref": "5,8",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "5,7",
      "soc_ref": "5,6",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "5,4",
      "soc_ref": "5,7",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "6,3",
      "soc_ref": "6,2",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "5,9",
      "Soc_ref1": "5,8",
      "Textbox50": "0,1",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,2",
      "Soc_ref1": "6,4",
      "Textbox50": "-0,2",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,2",
      "Soc_ref1": "6,3",
      "Textbox50": "-0,1",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "5,8",
      "Socioøkonomisk_reference2": "5,9",
      "Forskel2": "-0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,0",
      "Socioøkonomisk_reference2": "6,2",
      "Forskel2": "-0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "5,2",
      "Socioøkonomisk_reference2": "4,6",
      "Forskel2": "0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "5,7",
      "Socioøkonomisk_reference2": "5,4",
      "Forskel2": "0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,2",
      "Socioøkonomisk_reference2": "7,1",
      "Forskel2": "0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,6",
      "Socioøkonomisk_reference2": "8,1",
      "Forskel2": "0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "6,1",
      "Socioøkonomisk_reference2": "6,5",
      "Forskel2": "-0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "6,7",
      "Socioøkonomisk_reference2": "7,2",
      "Forskel2": "-0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "3,4",
      "soc_ref": "4,2",
      "Forskel": "-0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "5,4",
      "soc_ref": "5,6",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "6,2",
      "soc_ref": "6,3",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "6,9",
      "soc_ref": "7,0",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "6,3",
      "soc_ref": "6,7",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,2",
      "soc_ref": "7,3",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "4,8",
      "soc_ref": "5,4",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "5,8",
      "soc_ref": "6,0",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "7,0",
      "soc_ref": "6,6",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "4,4",
      "soc_ref": "5,3",
      "Forskel": "-0,9",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "6,1",
      "soc_ref": "5,8",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "7,0",
      "soc_ref": "6,3",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "6,9",
      "soc_ref": "7,0",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "7,9",
      "soc_ref": "7,6",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,2",
      "soc_ref": "7,3",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "6,3",
      "soc_ref": "6,4",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "4,8",
      "soc_ref": "5,9",
      "Forskel": "-1,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "6,9",
      "soc_ref": "6,8",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "5,8",
      "soc_ref": "5,9",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "5,9",
      "soc_ref": "5,7",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "5,9",
      "soc_ref": "6,2",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "5,7",
      "soc_ref": "5,9",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "6,1",
      "soc_ref": "6,1",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "6,6",
      "soc_ref": "6,6",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "5,3",
      "Soc_ref1": "5,7",
      "Textbox50": "-0,4",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,2",
      "Soc_ref1": "6,2",
      "Textbox50": "0,0",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,7",
      "Soc_ref1": "6,7",
      "Textbox50": "0,0",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2017/2018",
      "Karakter2": "5,3",
      "Socioøkonomisk_reference2": "6,5",
      "Forskel2": "-1,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2016/2017",
      "Karakter2": "6,7",
      "Socioøkonomisk_reference2": "6,7",
      "Forskel2": "0,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,4",
      "Socioøkonomisk_reference2": "7,2",
      "Forskel2": "0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "6,6",
      "Socioøkonomisk_reference2": "6,2",
      "Forskel2": "0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,9",
      "Socioøkonomisk_reference2": "7,5",
      "Forskel2": "0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "4,1",
      "Socioøkonomisk_reference2": "4,8",
      "Forskel2": "-0,7",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "5,2",
      "Socioøkonomisk_reference2": "6,0",
      "Forskel2": "-0,8",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "7,5",
      "soc_ref": "6,7",
      "Forskel": "0,8*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "7,2",
      "soc_ref": "6,8",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "7,7",
      "soc_ref": "7,1",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,6",
      "soc_ref": "8,3",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,2",
      "soc_ref": "8,1",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "8,7",
      "soc_ref": "8,4",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "7,6",
      "soc_ref": "7,1",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "7,8",
      "soc_ref": "7,3",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "8,5",
      "soc_ref": "7,5",
      "Forskel": "1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "7,7",
      "soc_ref": "6,7",
      "Forskel": "1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "7,5",
      "soc_ref": "6,7",
      "Forskel": "0,8*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "6,5",
      "soc_ref": "6,7",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,9",
      "soc_ref": "8,1",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,5",
      "soc_ref": "8,1",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,7",
      "soc_ref": "7,9",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "8,5",
      "soc_ref": "7,5",
      "Forskel": "1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,6",
      "soc_ref": "7,7",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,5",
      "soc_ref": "7,7",
      "Forskel": "0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,7",
      "soc_ref": "7,5",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "7,1",
      "soc_ref": "7,1",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "7,1",
      "soc_ref": "7,0",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,5",
      "soc_ref": "7,4",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "8,7",
      "soc_ref": "7,7",
      "Forskel": "1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "7,8",
      "soc_ref": "7,4",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,7",
      "Soc_ref1": "7,4",
      "Textbox50": "0,3",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,9",
      "Soc_ref1": "7,4",
      "Textbox50": "0,5",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,8",
      "Soc_ref1": "7,5",
      "Textbox50": "0,3",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "6,5",
      "Socioøkonomisk_reference2": "6,5",
      "Forskel2": "0,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,3",
      "Socioøkonomisk_reference2": "7,4",
      "Forskel2": "0,9",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,4",
      "Socioøkonomisk_reference2": "7,1",
      "Forskel2": "0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "4,9",
      "Socioøkonomisk_reference2": "5,5",
      "Forskel2": "-0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "5,9",
      "Socioøkonomisk_reference2": "6,2",
      "Forskel2": "-0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "8,3",
      "Socioøkonomisk_reference2": "8,2",
      "Forskel2": "0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,5",
      "Socioøkonomisk_reference2": "7,8",
      "Forskel2": "-0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,7",
      "Socioøkonomisk_reference2": "8,1",
      "Forskel2": "-0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,2",
      "Socioøkonomisk_reference2": "8,2",
      "Forskel2": "0,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2015/2016",
      "Karakter2": "9,4",
      "Socioøkonomisk_reference2": "8,6",
      "Forskel2": "0,8",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "8,6",
      "Socioøkonomisk_reference2": "8,2",
      "Forskel2": "0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "6,4",
      "Socioøkonomisk_reference2": "7,2",
      "Forskel2": "-0,8",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "8,1",
      "Socioøkonomisk_reference2": "8,0",
      "Forskel2": "0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,5",
      "Socioøkonomisk_reference2": "7,4",
      "Forskel2": "0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,3",
      "Socioøkonomisk_reference2": "7,7",
      "Forskel2": "-0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,1",
      "Socioøkonomisk_reference2": "6,1",
      "Forskel2": "0,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,6",
      "Socioøkonomisk_reference2": "7,6",
      "Forskel2": "1,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "6,6",
      "soc_ref": "5,8",
      "Forskel": "0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "8,4",
      "soc_ref": "7,4",
      "Forskel": "1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "6,6",
      "soc_ref": "6,4",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "9,2",
      "soc_ref": "8,4",
      "Forskel": "0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "9,2",
      "soc_ref": "8,6",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "8,1",
      "soc_ref": "7,8",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "8,1",
      "soc_ref": "6,8",
      "Forskel": "1,3*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "8,5",
      "soc_ref": "7,5",
      "Forskel": "1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "7,1",
      "soc_ref": "6,7",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "6,7",
      "soc_ref": "6,1",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "8,5",
      "soc_ref": "6,9",
      "Forskel": "1,6*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "4,8",
      "soc_ref": "5,9",
      "Forskel": "-1,1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,2",
      "soc_ref": "7,4",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "9,1",
      "soc_ref": "8,5",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,5",
      "soc_ref": "7,6",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,6",
      "soc_ref": "6,8",
      "Forskel": "0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "9,7",
      "soc_ref": "7,9",
      "Forskel": "1,8*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "9,5",
      "soc_ref": "8,1",
      "Forskel": "1,4*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,6",
      "soc_ref": "6,6",
      "Forskel": "1,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "7,0",
      "soc_ref": "6,5",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "6,2",
      "soc_ref": "6,1",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,3",
      "soc_ref": "6,6",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "7,5",
      "soc_ref": "7,0",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "7,4",
      "soc_ref": "6,7",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,8",
      "Soc_ref1": "7,0",
      "Textbox50": "0,8*",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "8,4",
      "Soc_ref1": "7,5",
      "Textbox50": "0,9*",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,8",
      "Soc_ref1": "6,7",
      "Textbox50": "0,1",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "5,1",
      "Socioøkonomisk_reference2": "5,0",
      "Forskel2": "0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "5,2",
      "Socioøkonomisk_reference2": "5,8",
      "Forskel2": "-0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,7",
      "Socioøkonomisk_reference2": "6,6",
      "Forskel2": "1,1*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "9,0",
      "Socioøkonomisk_reference2": "7,9",
      "Forskel2": "1,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,5",
      "Socioøkonomisk_reference2": "7,2",
      "Forskel2": "0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,7",
      "Socioøkonomisk_reference2": "6,5",
      "Forskel2": "0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "8,2",
      "Socioøkonomisk_reference2": "7,9",
      "Forskel2": "0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "5,7",
      "Socioøkonomisk_reference2": "6,0",
      "Forskel2": "-0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "6,1",
      "soc_ref": "5,6",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "5,1",
      "soc_ref": "5,2",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "6,3",
      "soc_ref": "6,4",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,6",
      "soc_ref": "7,7",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "5,6",
      "soc_ref": "6,3",
      "Forskel": "-0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "6,7",
      "soc_ref": "7,3",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "6,9",
      "soc_ref": "6,3",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "5,6",
      "soc_ref": "5,8",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "6,2",
      "soc_ref": "6,7",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "4,2",
      "soc_ref": "5,5",
      "Forskel": "-1,3*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "5,5",
      "soc_ref": "5,4",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "6,7",
      "soc_ref": "6,4",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "6,6",
      "soc_ref": "7,0",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "5,9",
      "soc_ref": "6,4",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,4",
      "soc_ref": "7,7",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "6,2",
      "soc_ref": "6,6",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "5,2",
      "soc_ref": "6,3",
      "Forskel": "-1,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "4,5",
      "soc_ref": "5,8",
      "Forskel": "-1,3*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "4,6",
      "soc_ref": "5,4",
      "Forskel": "-0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "3,8",
      "soc_ref": "4,6",
      "Forskel": "-0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "5,9",
      "soc_ref": "6,2",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "5,2",
      "soc_ref": "5,5",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "5,3",
      "soc_ref": "5,5",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "6,8",
      "soc_ref": "6,8",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "5,8",
      "Soc_ref1": "6,1",
      "Textbox50": "-0,3",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "5,2",
      "Soc_ref1": "5,7",
      "Textbox50": "-0,5",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,5",
      "Soc_ref1": "6,7",
      "Textbox50": "-0,2",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,8",
      "Socioøkonomisk_reference2": "6,4",
      "Forskel2": "0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "5,9",
      "Socioøkonomisk_reference2": "6,8",
      "Forskel2": "-0,9",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2017/2018",
      "Karakter2": "4,6",
      "Socioøkonomisk_reference2": "6,3",
      "Forskel2": "-1,7*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2016/2017",
      "Karakter2": "5,4",
      "Socioøkonomisk_reference2": "6,4",
      "Forskel2": "-1,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2015/2016",
      "Karakter2": "8,4",
      "Socioøkonomisk_reference2": "7,8",
      "Forskel2": "0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "6,9",
      "Socioøkonomisk_reference2": "7,4",
      "Forskel2": "-0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "4,6",
      "Socioøkonomisk_reference2": "6,1",
      "Forskel2": "-1,5*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,5",
      "Socioøkonomisk_reference2": "7,9",
      "Forskel2": "-0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "6,5",
      "soc_ref": "6,4",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "6,1",
      "soc_ref": "6,7",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "7,7",
      "soc_ref": "7,0",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "6,6",
      "soc_ref": "7,5",
      "Forskel": "-0,9*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,8",
      "soc_ref": "8,6",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,7",
      "soc_ref": "7,9",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "7,4",
      "soc_ref": "7,2",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "6,8",
      "soc_ref": "7,4",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "7,4",
      "soc_ref": "7,1",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "6,9",
      "soc_ref": "6,5",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "8,3",
      "soc_ref": "7,3",
      "Forskel": "1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "6,8",
      "soc_ref": "6,6",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,8",
      "soc_ref": "8,6",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "7,3",
      "soc_ref": "7,8",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,4",
      "soc_ref": "7,6",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,6",
      "soc_ref": "6,9",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,1",
      "soc_ref": "7,5",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,1",
      "soc_ref": "7,9",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,3",
      "soc_ref": "7,3",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "7,5",
      "soc_ref": "7,2",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "7,8",
      "soc_ref": "6,6",
      "Forskel": "1,2*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,1",
      "soc_ref": "7,1",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "7,3",
      "soc_ref": "7,3",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "7,7",
      "soc_ref": "7,0",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,3",
      "Soc_ref1": "7,3",
      "Textbox50": "0,0",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,5",
      "Soc_ref1": "7,5",
      "Textbox50": "0,0",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,5",
      "Soc_ref1": "7,1",
      "Textbox50": "0,4",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "6,8",
      "Socioøkonomisk_reference2": "6,5",
      "Forskel2": "0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,9",
      "Socioøkonomisk_reference2": "6,7",
      "Forskel2": "0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,3",
      "Socioøkonomisk_reference2": "7,6",
      "Forskel2": "-0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "6,4",
      "Socioøkonomisk_reference2": "6,6",
      "Forskel2": "-0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,3",
      "Socioøkonomisk_reference2": "7,5",
      "Forskel2": "-0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2017/2018",
      "Karakter2": "6,8",
      "Socioøkonomisk_reference2": "7,6",
      "Forskel2": "-0,8",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,5",
      "Socioøkonomisk_reference2": "8,2",
      "Forskel2": "-0,7",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,1",
      "Socioøkonomisk_reference2": "6,4",
      "Forskel2": "-0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,7",
      "Socioøkonomisk_reference2": "6,8",
      "Forskel2": "-0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,4",
      "Socioøkonomisk_reference2": "6,9",
      "Forskel2": "0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "5,3",
      "soc_ref": "5,8",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "5,7",
      "soc_ref": "5,9",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "6,3",
      "soc_ref": "6,5",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,6",
      "soc_ref": "7,7",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,3",
      "soc_ref": "7,7",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,7",
      "soc_ref": "7,7",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "6,9",
      "soc_ref": "6,8",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "6,6",
      "soc_ref": "6,6",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "6,9",
      "soc_ref": "6,8",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "8,9",
      "soc_ref": "6,8",
      "Forskel": "2,1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "5,8",
      "soc_ref": "5,9",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "6,0",
      "soc_ref": "6,2",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,6",
      "soc_ref": "7,8",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,9",
      "soc_ref": "8,1",
      "Forskel": "0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,1",
      "soc_ref": "7,6",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "5,5",
      "soc_ref": "6,3",
      "Forskel": "-0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,6",
      "soc_ref": "7,4",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,1",
      "soc_ref": "7,1",
      "Forskel": "1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "5,9",
      "soc_ref": "6,8",
      "Forskel": "-0,9*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "5,0",
      "soc_ref": "5,8",
      "Forskel": "-0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "5,1",
      "soc_ref": "6,2",
      "Forskel": "-1,1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "5,8",
      "soc_ref": "6,6",
      "Forskel": "-0,8*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "5,9",
      "soc_ref": "6,4",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "5,9",
      "soc_ref": "6,6",
      "Forskel": "-0,7*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,9",
      "Soc_ref1": "6,9",
      "Textbox50": "0,0",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,7",
      "Soc_ref1": "6,6",
      "Textbox50": "0,1",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,3",
      "Soc_ref1": "6,7",
      "Textbox50": "-0,4",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "3,2",
      "Socioøkonomisk_reference2": "4,5",
      "Forskel2": "-1,3*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "4,9",
      "Socioøkonomisk_reference2": "6,1",
      "Forskel2": "-1,2*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "4,6",
      "Socioøkonomisk_reference2": "5,0",
      "Forskel2": "-0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,7",
      "Socioøkonomisk_reference2": "7,4",
      "Forskel2": "0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,9",
      "Socioøkonomisk_reference2": "7,3",
      "Forskel2": "0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "5,8",
      "Socioøkonomisk_reference2": "6,8",
      "Forskel2": "-1,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2015/2016",
      "Karakter2": "5,4",
      "Socioøkonomisk_reference2": "7,0",
      "Forskel2": "-1,6*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "6,9",
      "Socioøkonomisk_reference2": "7,4",
      "Forskel2": "-0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,4",
      "Socioøkonomisk_reference2": "7,2",
      "Forskel2": "-0,8",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "3,3",
      "Socioøkonomisk_reference2": "6,1",
      "Forskel2": "-2,8*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "4,4",
      "Socioøkonomisk_reference2": "6,2",
      "Forskel2": "-1,8*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,8",
      "Socioøkonomisk_reference2": "7,8",
      "Forskel2": "0,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "5,7",
      "Socioøkonomisk_reference2": "6,9",
      "Forskel2": "-1,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "6,4",
      "Socioøkonomisk_reference2": "6,1",
      "Forskel2": "0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "7,3",
      "soc_ref": "7,0",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "7,8",
      "soc_ref": "7,4",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "7,7",
      "soc_ref": "7,8",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,9",
      "soc_ref": "8,7",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "9,4",
      "soc_ref": "8,8",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "8,2",
      "soc_ref": "8,7",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "8,1",
      "soc_ref": "7,7",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "9,3",
      "soc_ref": "8,2",
      "Forskel": "1,1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "8,3",
      "soc_ref": "8,2",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "7,3",
      "soc_ref": "7,0",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "7,0",
      "soc_ref": "7,0",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "8,0",
      "soc_ref": "7,4",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "9,1",
      "soc_ref": "9,0",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "9,2",
      "soc_ref": "8,9",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "8,9",
      "soc_ref": "8,9",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "6,6",
      "soc_ref": "7,4",
      "Forskel": "-0,8*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,6",
      "soc_ref": "8,1",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "7,8",
      "soc_ref": "8,2",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "8,7",
      "soc_ref": "8,1",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "8,0",
      "soc_ref": "7,8",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "7,3",
      "soc_ref": "7,7",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "8,1",
      "soc_ref": "8,2",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "8,2",
      "soc_ref": "8,2",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "8,1",
      "soc_ref": "8,1",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "8,2",
      "Soc_ref1": "8,0",
      "Textbox50": "0,2",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "8,3",
      "Soc_ref1": "8,0",
      "Textbox50": "0,3",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,9",
      "Soc_ref1": "8,0",
      "Textbox50": "-0,1",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,5",
      "Socioøkonomisk_reference2": "8,0",
      "Forskel2": "0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "8,6",
      "Socioøkonomisk_reference2": "8,7",
      "Forskel2": "-0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "9,8",
      "Socioøkonomisk_reference2": "8,9",
      "Forskel2": "0,9",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "8,5",
      "Socioøkonomisk_reference2": "8,6",
      "Forskel2": "-0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,0",
      "Socioøkonomisk_reference2": "6,8",
      "Forskel2": "0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,9",
      "Socioøkonomisk_reference2": "6,8",
      "Forskel2": "1,1*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,8",
      "Socioøkonomisk_reference2": "8,0",
      "Forskel2": "0,8",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "9,6",
      "Socioøkonomisk_reference2": "8,6",
      "Forskel2": "1,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,7",
      "Socioøkonomisk_reference2": "8,1",
      "Forskel2": "-0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2015/2016",
      "Karakter2": "8,3",
      "Socioøkonomisk_reference2": "8,6",
      "Forskel2": "-0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "9,0",
      "Socioøkonomisk_reference2": "8,7",
      "Forskel2": "0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "8,9",
      "Socioøkonomisk_reference2": "8,6",
      "Forskel2": "0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "8,4",
      "Socioøkonomisk_reference2": "8,5",
      "Forskel2": "-0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "9,1",
      "Socioøkonomisk_reference2": "8,8",
      "Forskel2": "0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "9,8",
      "Socioøkonomisk_reference2": "9,2",
      "Forskel2": "0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "8,5",
      "Socioøkonomisk_reference2": "8,8",
      "Forskel2": "-0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "4,7",
      "soc_ref": "5,3",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "5,9",
      "soc_ref": "6,0",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "6,8",
      "soc_ref": "6,6",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,9",
      "soc_ref": "7,8",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "7,7",
      "soc_ref": "7,5",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,7",
      "soc_ref": "7,6",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "5,8",
      "soc_ref": "6,2",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "6,8",
      "soc_ref": "6,7",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "6,1",
      "soc_ref": "6,6",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "5,2",
      "soc_ref": "6,0",
      "Forskel": "-0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "7,0",
      "soc_ref": "6,2",
      "Forskel": "0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "4,0",
      "soc_ref": "5,8",
      "Forskel": "-1,8*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "5,3",
      "soc_ref": "6,4",
      "Forskel": "-1,1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "6,5",
      "soc_ref": "7,1",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "6,2",
      "soc_ref": "7,0",
      "Forskel": "-0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,2",
      "soc_ref": "6,8",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,2",
      "soc_ref": "7,1",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "5,9",
      "soc_ref": "6,7",
      "Forskel": "-0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,4",
      "soc_ref": "6,7",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "7,1",
      "soc_ref": "6,5",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "5,6",
      "soc_ref": "6,4",
      "Forskel": "-0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,0",
      "soc_ref": "6,6",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "7,0",
      "soc_ref": "6,8",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "6,6",
      "soc_ref": "6,8",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,3",
      "Soc_ref1": "6,5",
      "Textbox50": "-0,2",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,7",
      "Soc_ref1": "6,7",
      "Textbox50": "0,0",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,3",
      "Soc_ref1": "6,7",
      "Textbox50": "-0,4",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "4,1",
      "Socioøkonomisk_reference2": "5,6",
      "Forskel2": "-1,5*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,5",
      "Socioøkonomisk_reference2": "6,9",
      "Forskel2": "-0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,1",
      "Socioøkonomisk_reference2": "6,1",
      "Forskel2": "1,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,7",
      "Socioøkonomisk_reference2": "6,4",
      "Forskel2": "0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "8,8",
      "Socioøkonomisk_reference2": "8,0",
      "Forskel2": "0,8",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,8",
      "Socioøkonomisk_reference2": "7,5",
      "Forskel2": "0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2016/2017",
      "Karakter2": "5,4",
      "Socioøkonomisk_reference2": "7,0",
      "Forskel2": "-1,6*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,6",
      "Socioøkonomisk_reference2": "7,5",
      "Forskel2": "0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "5,0",
      "Socioøkonomisk_reference2": "6,1",
      "Forskel2": "-1,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,1",
      "Socioøkonomisk_reference2": "6,7",
      "Forskel2": "0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,1",
      "Socioøkonomisk_reference2": "7,2",
      "Forskel2": "-0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,4",
      "Socioøkonomisk_reference2": "7,4",
      "Forskel2": "-1,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "7,7",
      "soc_ref": "7,1",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "6,3",
      "soc_ref": "6,4",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "7,5",
      "soc_ref": "7,0",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "9,1",
      "soc_ref": "8,7",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,7",
      "soc_ref": "8,0",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,8",
      "soc_ref": "7,9",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "8,1",
      "soc_ref": "7,5",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "7,4",
      "soc_ref": "6,9",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "8,8",
      "soc_ref": "7,3",
      "Forskel": "1,5*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "8,8",
      "soc_ref": "7,0",
      "Forskel": "1,8*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "5,6",
      "soc_ref": "6,1",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "8,7",
      "soc_ref": "6,9",
      "Forskel": "1,8*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "9,4",
      "soc_ref": "8,9",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,2",
      "soc_ref": "8,0",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "8,5",
      "soc_ref": "8,1",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "6,1",
      "soc_ref": "6,5",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,6",
      "soc_ref": "7,9",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,2",
      "soc_ref": "7,5",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "8,3",
      "soc_ref": "7,8",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "7,8",
      "soc_ref": "7,0",
      "Forskel": "0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "7,8",
      "soc_ref": "7,0",
      "Forskel": "0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "8,4",
      "soc_ref": "7,8",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "8,2",
      "soc_ref": "7,5",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "7,5",
      "soc_ref": "7,1",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "8,5",
      "Soc_ref1": "7,9",
      "Textbox50": "0,6",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,6",
      "Soc_ref1": "7,2",
      "Textbox50": "0,4",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "8,0",
      "Soc_ref1": "7,4",
      "Textbox50": "0,6*",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,4",
      "Socioøkonomisk_reference2": "7,1",
      "Forskel2": "0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,3",
      "Socioøkonomisk_reference2": "6,3",
      "Forskel2": "0,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "8,7",
      "Socioøkonomisk_reference2": "8,4",
      "Forskel2": "0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,7",
      "Socioøkonomisk_reference2": "7,1",
      "Forskel2": "0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,1",
      "Socioøkonomisk_reference2": "7,2",
      "Forskel2": "0,9",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "6,8",
      "Socioøkonomisk_reference2": "7,1",
      "Forskel2": "-0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2017/2018",
      "Karakter2": "9,2",
      "Socioøkonomisk_reference2": "8,4",
      "Forskel2": "0,8",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,0",
      "Socioøkonomisk_reference2": "7,5",
      "Forskel2": "-0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "9,0",
      "Socioøkonomisk_reference2": "8,0",
      "Forskel2": "1,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "9,7",
      "Socioøkonomisk_reference2": "8,5",
      "Forskel2": "1,2*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,8",
      "Socioøkonomisk_reference2": "7,2",
      "Forskel2": "0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "6,0",
      "soc_ref": "6,0",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "6,0",
      "soc_ref": "6,6",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "7,2",
      "soc_ref": "6,7",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,6",
      "soc_ref": "7,8",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,6",
      "soc_ref": "8,2",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,7",
      "soc_ref": "7,7",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "7,6",
      "soc_ref": "7,0",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "7,3",
      "soc_ref": "7,6",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "7,2",
      "soc_ref": "6,9",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "7,9",
      "soc_ref": "6,6",
      "Forskel": "1,3*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "6,7",
      "soc_ref": "6,7",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "7,3",
      "soc_ref": "6,4",
      "Forskel": "0,9*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,5",
      "soc_ref": "7,9",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "7,8",
      "soc_ref": "8,1",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,4",
      "soc_ref": "7,8",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "5,7",
      "soc_ref": "6,7",
      "Forskel": "-1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "6,7",
      "soc_ref": "7,3",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "6,7",
      "soc_ref": "7,5",
      "Forskel": "-0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,3",
      "soc_ref": "7,1",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "5,0",
      "soc_ref": "7,0",
      "Forskel": "-2*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "6,7",
      "soc_ref": "6,8",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,3",
      "soc_ref": "7,2",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "7,0",
      "soc_ref": "7,6",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "7,2",
      "soc_ref": "7,2",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,2",
      "Soc_ref1": "7,1",
      "Textbox50": "0,1",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,9",
      "Soc_ref1": "7,4",
      "Textbox50": "-0,5",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,1",
      "Soc_ref1": "7,1",
      "Textbox50": "0,0",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "5,8",
      "Socioøkonomisk_reference2": "6,2",
      "Forskel2": "-0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "5,6",
      "Socioøkonomisk_reference2": "7,1",
      "Forskel2": "-1,5*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,3",
      "Socioøkonomisk_reference2": "7,0",
      "Forskel2": "0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,1",
      "Socioøkonomisk_reference2": "6,8",
      "Forskel2": "0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "3,6",
      "Socioøkonomisk_reference2": "4,1",
      "Forskel2": "-0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,5",
      "Socioøkonomisk_reference2": "6,6",
      "Forskel2": "-0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,4",
      "Socioøkonomisk_reference2": "7,6",
      "Forskel2": "-0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,2",
      "Socioøkonomisk_reference2": "8,5",
      "Forskel2": "-0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,8",
      "Socioøkonomisk_reference2": "7,7",
      "Forskel2": "-0,9",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2017/2018",
      "Karakter2": "8,9",
      "Socioøkonomisk_reference2": "8,0",
      "Forskel2": "0,9",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,2",
      "Socioøkonomisk_reference2": "7,2",
      "Forskel2": "0,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,2",
      "Socioøkonomisk_reference2": "6,8",
      "Forskel2": "0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "5,5",
      "Socioøkonomisk_reference2": "6,4",
      "Forskel2": "-0,9",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "9,6",
      "Socioøkonomisk_reference2": "8,4",
      "Forskel2": "1,2*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,6",
      "Socioøkonomisk_reference2": "8,0",
      "Forskel2": "-0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,9",
      "Socioøkonomisk_reference2": "8,2",
      "Forskel2": "-0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "8,2",
      "Socioøkonomisk_reference2": "7,9",
      "Forskel2": "0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,6",
      "Socioøkonomisk_reference2": "6,7",
      "Forskel2": "-0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "5,9",
      "soc_ref": "5,8",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "7,4",
      "soc_ref": "7,2",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,8",
      "soc_ref": "8,3",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "9,5",
      "soc_ref": "9,0",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "6,9",
      "soc_ref": "6,5",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "7,6",
      "soc_ref": "7,6",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "5,9",
      "soc_ref": "5,9",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "7,1",
      "soc_ref": "7,0",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "9,1",
      "soc_ref": "8,6",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "9,6",
      "soc_ref": "9,0",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "6,9",
      "soc_ref": "6,9",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,9",
      "soc_ref": "8,3",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,7",
      "soc_ref": "7,2",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "7,9",
      "soc_ref": "7,5",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,9",
      "soc_ref": "7,5",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "7,9",
      "soc_ref": "7,7",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,4",
      "Soc_ref1": "7,1",
      "Textbox50": "0,3",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "8,2",
      "Soc_ref1": "7,9",
      "Textbox50": "0,3",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "5,9",
      "Socioøkonomisk_reference2": "5,8",
      "Forskel2": "0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,2",
      "Socioøkonomisk_reference2": "7,5",
      "Forskel2": "0,7",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "8,5",
      "Socioøkonomisk_reference2": "7,8",
      "Forskel2": "0,7",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,4",
      "Socioøkonomisk_reference2": "6,6",
      "Forskel2": "0,8",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "5,6",
      "soc_ref": "5,9",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "7,5",
      "soc_ref": "6,9",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "5,9",
      "soc_ref": "6,3",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,8",
      "soc_ref": "8,3",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,7",
      "soc_ref": "8,2",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,7",
      "soc_ref": "7,6",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "6,7",
      "soc_ref": "6,7",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "8,4",
      "soc_ref": "7,4",
      "Forskel": "1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "7,4",
      "soc_ref": "6,9",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "6,1",
      "soc_ref": "6,3",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "7,5",
      "soc_ref": "6,6",
      "Forskel": "0,9*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "6,6",
      "soc_ref": "6,3",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "6,4",
      "soc_ref": "7,2",
      "Forskel": "-0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "7,9",
      "soc_ref": "7,9",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,1",
      "soc_ref": "7,3",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "4,7",
      "soc_ref": "6,0",
      "Forskel": "-1,3*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "6,2",
      "soc_ref": "7,1",
      "Forskel": "-0,9",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "7,1",
      "soc_ref": "7,3",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,1",
      "soc_ref": "7,1",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "8,1",
      "soc_ref": "7,2",
      "Forskel": "0,9",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "6,9",
      "soc_ref": "6,7",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "6,4",
      "soc_ref": "6,8",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "7,4",
      "soc_ref": "7,2",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "6,4",
      "soc_ref": "6,8",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,7",
      "Soc_ref1": "7,0",
      "Textbox50": "-0,3",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,8",
      "Soc_ref1": "7,3",
      "Textbox50": "0,5",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,6",
      "Soc_ref1": "6,7",
      "Textbox50": "-0,1",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,9",
      "Socioøkonomisk_reference2": "7,4",
      "Forskel2": "0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "6,1",
      "Socioøkonomisk_reference2": "6,6",
      "Forskel2": "-0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "5,1",
      "Socioøkonomisk_reference2": "5,4",
      "Forskel2": "-0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "5,9",
      "Socioøkonomisk_reference2": "6,2",
      "Forskel2": "-0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2017/2018",
      "Karakter2": "9,9",
      "Socioøkonomisk_reference2": "8,4",
      "Forskel2": "1,5*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,8",
      "Socioøkonomisk_reference2": "8,0",
      "Forskel2": "0,8",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,4",
      "Socioøkonomisk_reference2": "8,2",
      "Forskel2": "0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,9",
      "Socioøkonomisk_reference2": "7,2",
      "Forskel2": "-0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "6,0",
      "Socioøkonomisk_reference2": "6,2",
      "Forskel2": "-0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,1",
      "Socioøkonomisk_reference2": "7,1",
      "Forskel2": "0,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,8",
      "Socioøkonomisk_reference2": "7,1",
      "Forskel2": "-0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "2,3",
      "soc_ref": "2,9",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "4,3",
      "soc_ref": "4,6",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "5,6",
      "soc_ref": "5,3",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "6,1",
      "soc_ref": "6,2",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "6,0",
      "soc_ref": "6,5",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "6,9",
      "soc_ref": "6,9",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "3,3",
      "soc_ref": "4,2",
      "Forskel": "-0,9",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "5,2",
      "soc_ref": "5,6",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "6,5",
      "soc_ref": "5,9",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "3,5",
      "soc_ref": "4,4",
      "Forskel": "-0,9",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "4,1",
      "soc_ref": "5,1",
      "Forskel": "-1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "5,5",
      "soc_ref": "5,4",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "5,9",
      "soc_ref": "6,3",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "5,3",
      "soc_ref": "6,1",
      "Forskel": "-0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "6,7",
      "soc_ref": "6,7",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "4,4",
      "soc_ref": "5,1",
      "Forskel": "-0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "4,5",
      "soc_ref": "5,3",
      "Forskel": "-0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "5,6",
      "soc_ref": "6,0",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "4,4",
      "soc_ref": "4,8",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "3,7",
      "soc_ref": "4,2",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "5,5",
      "soc_ref": "5,2",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "4,5",
      "soc_ref": "4,9",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "4,4",
      "soc_ref": "4,8",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "5,4",
      "soc_ref": "5,4",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "4,3",
      "Soc_ref1": "4,8",
      "Textbox50": "-0,5",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "4,8",
      "Soc_ref1": "5,3",
      "Textbox50": "-0,5",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "5,8",
      "Soc_ref1": "5,7",
      "Textbox50": "0,1",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "4,3",
      "Socioøkonomisk_reference2": "5,4",
      "Forskel2": "-1,1*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "3,8",
      "Socioøkonomisk_reference2": "4,0",
      "Forskel2": "-0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "4,5",
      "Socioøkonomisk_reference2": "4,4",
      "Forskel2": "0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "5,2",
      "Socioøkonomisk_reference2": "5,8",
      "Forskel2": "-0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2017/2018",
      "Karakter2": "6,6",
      "Socioøkonomisk_reference2": "6,2",
      "Forskel2": "0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "6,1",
      "Socioøkonomisk_reference2": "6,4",
      "Forskel2": "-0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "6,8",
      "soc_ref": "6,0",
      "Forskel": "0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "7,4",
      "soc_ref": "7,1",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "6,9",
      "soc_ref": "6,6",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,4",
      "soc_ref": "7,4",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "7,3",
      "soc_ref": "7,9",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "6,9",
      "soc_ref": "7,2",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "7,8",
      "soc_ref": "6,5",
      "Forskel": "1,3*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "8,7",
      "soc_ref": "7,8",
      "Forskel": "0,9",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "7,7",
      "soc_ref": "6,9",
      "Forskel": "0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "7,1",
      "soc_ref": "6,1",
      "Forskel": "1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "7,6",
      "soc_ref": "7,1",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "7,0",
      "soc_ref": "6,3",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,5",
      "soc_ref": "7,9",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "7,9",
      "soc_ref": "8,0",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,8",
      "soc_ref": "7,6",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "6,3",
      "soc_ref": "6,6",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "7,6",
      "soc_ref": "7,7",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "6,8",
      "soc_ref": "6,5",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "8,1",
      "soc_ref": "7,4",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "6,4",
      "soc_ref": "6,5",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "6,5",
      "soc_ref": "6,5",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "7,8",
      "soc_ref": "7,4",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "6,3",
      "soc_ref": "6,6",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,2",
      "Soc_ref1": "6,8",
      "Textbox50": "0,4",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,9",
      "Soc_ref1": "7,7",
      "Textbox50": "0,2",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,9",
      "Soc_ref1": "6,8",
      "Textbox50": "0,1",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,0",
      "Socioøkonomisk_reference2": "7,0",
      "Forskel2": "0,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "6,8",
      "Socioøkonomisk_reference2": "7,0",
      "Forskel2": "-0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "8,0",
      "Socioøkonomisk_reference2": "7,0",
      "Forskel2": "1,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "5,2",
      "Socioøkonomisk_reference2": "7,1",
      "Forskel2": "-1,9*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,9",
      "Socioøkonomisk_reference2": "7,0",
      "Forskel2": "-0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "5,6",
      "soc_ref": "5,6",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "7,9",
      "soc_ref": "7,3",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "7,5",
      "soc_ref": "7,3",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,4",
      "soc_ref": "7,7",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,3",
      "soc_ref": "8,3",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "9,8",
      "soc_ref": "8,6",
      "Forskel": "1,2*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "6,4",
      "soc_ref": "6,6",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "8,7",
      "soc_ref": "7,9",
      "Forskel": "0,8*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "7,6",
      "soc_ref": "7,6",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "6,4",
      "soc_ref": "6,1",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "8,7",
      "soc_ref": "7,1",
      "Forskel": "1,6*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "6,9",
      "soc_ref": "6,7",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,9",
      "soc_ref": "8,0",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,5",
      "soc_ref": "8,5",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "8,3",
      "soc_ref": "8,3",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,9",
      "soc_ref": "7,2",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,0",
      "soc_ref": "7,3",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,1",
      "soc_ref": "7,9",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "6,4",
      "soc_ref": "6,7",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "7,7",
      "soc_ref": "7,4",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "7,7",
      "soc_ref": "7,2",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "5,8",
      "soc_ref": "6,6",
      "Forskel": "-0,8*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "8,1",
      "soc_ref": "7,8",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "8,5",
      "soc_ref": "7,7",
      "Forskel": "0,8*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,6",
      "Soc_ref1": "6,8",
      "Textbox50": "-0,2",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "8,2",
      "Soc_ref1": "7,8",
      "Textbox50": "0,4",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "8,0",
      "Soc_ref1": "7,6",
      "Textbox50": "0,4",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,1",
      "Socioøkonomisk_reference2": "7,4",
      "Forskel2": "0,7",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,2",
      "Socioøkonomisk_reference2": "7,1",
      "Forskel2": "0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "8,4",
      "Socioøkonomisk_reference2": "7,8",
      "Forskel2": "0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,2",
      "Socioøkonomisk_reference2": "8,0",
      "Forskel2": "0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "4,0",
      "Socioøkonomisk_reference2": "4,9",
      "Forskel2": "-0,9",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,1",
      "Socioøkonomisk_reference2": "6,9",
      "Forskel2": "0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,1",
      "Socioøkonomisk_reference2": "7,6",
      "Forskel2": "-0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "9,2",
      "Socioøkonomisk_reference2": "8,5",
      "Forskel2": "0,7",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2017/2018",
      "Karakter2": "5,8",
      "Socioøkonomisk_reference2": "6,6",
      "Forskel2": "-0,8",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,1",
      "Socioøkonomisk_reference2": "8,4",
      "Forskel2": "-0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "8,9",
      "Socioøkonomisk_reference2": "8,3",
      "Forskel2": "0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "8,9",
      "Socioøkonomisk_reference2": "8,0",
      "Forskel2": "0,9",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "9,9",
      "Socioøkonomisk_reference2": "8,8",
      "Forskel2": "1,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "6,4",
      "soc_ref": "6,5",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "8,0",
      "soc_ref": "7,4",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "7,4",
      "soc_ref": "7,0",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,2",
      "soc_ref": "8,4",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "9,7",
      "soc_ref": "8,8",
      "Forskel": "0,9*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "9,4",
      "soc_ref": "8,4",
      "Forskel": "1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "7,8",
      "soc_ref": "7,4",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "8,8",
      "soc_ref": "7,9",
      "Forskel": "0,9*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "6,7",
      "soc_ref": "6,8",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "6,7",
      "soc_ref": "6,8",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "8,3",
      "soc_ref": "7,0",
      "Forskel": "1,3*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "7,3",
      "soc_ref": "6,5",
      "Forskel": "0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "11,0",
      "soc_ref": "9,6",
      "Forskel": "1,4*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "11,5",
      "soc_ref": "9,5",
      "Forskel": "2*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "10,9",
      "soc_ref": "9,5",
      "Forskel": "1,4*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "8,0",
      "soc_ref": "7,2",
      "Forskel": "0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,5",
      "soc_ref": "8,1",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "9,9",
      "soc_ref": "8,3",
      "Forskel": "1,6*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,6",
      "soc_ref": "7,6",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "8,3",
      "soc_ref": "7,6",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "7,9",
      "soc_ref": "7,3",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,7",
      "soc_ref": "7,5",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "8,4",
      "soc_ref": "7,9",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "7,8",
      "soc_ref": "7,5",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,9",
      "Soc_ref1": "7,6",
      "Textbox50": "0,3",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "9,1",
      "Soc_ref1": "8,0",
      "Textbox50": "1,1*",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "8,2",
      "Soc_ref1": "7,5",
      "Textbox50": "0,7*",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,6",
      "Socioøkonomisk_reference2": "7,5",
      "Forskel2": "0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "8,6",
      "Socioøkonomisk_reference2": "7,6",
      "Forskel2": "1,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,7",
      "Socioøkonomisk_reference2": "8,3",
      "Forskel2": "0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "5,5",
      "Socioøkonomisk_reference2": "5,8",
      "Forskel2": "-0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,4",
      "Socioøkonomisk_reference2": "7,2",
      "Forskel2": "0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,7",
      "Socioøkonomisk_reference2": "6,8",
      "Forskel2": "-0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "8,1",
      "Socioøkonomisk_reference2": "8,2",
      "Forskel2": "-0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "9,1",
      "Socioøkonomisk_reference2": "8,4",
      "Forskel2": "0,7",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "10,6",
      "Socioøkonomisk_reference2": "9,3",
      "Forskel2": "1,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "9,5",
      "Socioøkonomisk_reference2": "8,4",
      "Forskel2": "1,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "9,0",
      "Socioøkonomisk_reference2": "8,4",
      "Forskel2": "0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "6,8",
      "soc_ref": "6,3",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "7,1",
      "soc_ref": "6,9",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "7,3",
      "soc_ref": "7,1",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,8",
      "soc_ref": "7,7",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "9,3",
      "soc_ref": "8,5",
      "Forskel": "0,8",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "8,0",
      "soc_ref": "8,1",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "8,5",
      "soc_ref": "7,2",
      "Forskel": "1,3*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "8,2",
      "soc_ref": "7,6",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "7,1",
      "soc_ref": "7,2",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "8,3",
      "soc_ref": "6,7",
      "Forskel": "1,6*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "6,9",
      "soc_ref": "6,6",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "5,8",
      "soc_ref": "6,5",
      "Forskel": "-0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,4",
      "soc_ref": "8,1",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,5",
      "soc_ref": "8,2",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,8",
      "soc_ref": "7,9",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,4",
      "soc_ref": "7,3",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,2",
      "soc_ref": "7,2",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,2",
      "soc_ref": "7,7",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "9,4",
      "soc_ref": "7,8",
      "Forskel": "1,6*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "7,4",
      "soc_ref": "7,1",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "7,2",
      "soc_ref": "7,2",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "9,3",
      "soc_ref": "8,0",
      "Forskel": "1,3*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "8,5",
      "soc_ref": "7,8",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "7,2",
      "soc_ref": "7,5",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "8,2",
      "Soc_ref1": "7,5",
      "Textbox50": "0,7*",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "8,0",
      "Soc_ref1": "7,5",
      "Textbox50": "0,5",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,2",
      "Soc_ref1": "7,3",
      "Textbox50": "-0,1",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "5,4",
      "Socioøkonomisk_reference2": "6,3",
      "Forskel2": "-0,9",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "8,0",
      "Socioøkonomisk_reference2": "6,6",
      "Forskel2": "1,4*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,2",
      "Socioøkonomisk_reference2": "7,0",
      "Forskel2": "0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,8",
      "Socioøkonomisk_reference2": "7,8",
      "Forskel2": "0,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,4",
      "Socioøkonomisk_reference2": "7,3",
      "Forskel2": "-0,9",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2016/2017",
      "Karakter2": "10,3",
      "Socioøkonomisk_reference2": "8,9",
      "Forskel2": "1,4*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,9",
      "Socioøkonomisk_reference2": "8,2",
      "Forskel2": "-0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,3",
      "Socioøkonomisk_reference2": "7,8",
      "Forskel2": "0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,4",
      "Socioøkonomisk_reference2": "6,0",
      "Forskel2": "1,4*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,1",
      "Socioøkonomisk_reference2": "7,5",
      "Forskel2": "0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "6,4",
      "soc_ref": "6,4",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "6,8",
      "soc_ref": "7,1",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "7,7",
      "soc_ref": "7,4",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,2",
      "soc_ref": "8,1",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,0",
      "soc_ref": "8,4",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "8,6",
      "soc_ref": "8,4",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "7,4",
      "soc_ref": "7,2",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "8,3",
      "soc_ref": "8,0",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "7,6",
      "soc_ref": "7,7",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "7,8",
      "soc_ref": "6,7",
      "Forskel": "1,1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "8,2",
      "soc_ref": "7,2",
      "Forskel": "1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "7,9",
      "soc_ref": "7,0",
      "Forskel": "0,9*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,4",
      "soc_ref": "8,5",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "9,0",
      "soc_ref": "8,9",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "9,3",
      "soc_ref": "8,7",
      "Forskel": "0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "5,9",
      "soc_ref": "6,8",
      "Forskel": "-0,9*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "6,9",
      "soc_ref": "7,5",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "7,4",
      "soc_ref": "8,0",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,4",
      "soc_ref": "7,5",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "5,5",
      "soc_ref": "7,3",
      "Forskel": "-1,8*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "7,7",
      "soc_ref": "7,3",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,3",
      "soc_ref": "7,4",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "7,2",
      "soc_ref": "7,8",
      "Forskel": "-0,6",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "7,4",
      "soc_ref": "7,6",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,5",
      "Soc_ref1": "7,4",
      "Textbox50": "0,1",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,6",
      "Soc_ref1": "7,9",
      "Textbox50": "-0,3",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,8",
      "Soc_ref1": "7,6",
      "Textbox50": "0,2",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "6,6",
      "Socioøkonomisk_reference2": "6,5",
      "Forskel2": "0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,9",
      "Socioøkonomisk_reference2": "7,7",
      "Forskel2": "0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,9",
      "Socioøkonomisk_reference2": "7,0",
      "Forskel2": "-0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "8,3",
      "Socioøkonomisk_reference2": "8,1",
      "Forskel2": "0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,3",
      "Socioøkonomisk_reference2": "7,6",
      "Forskel2": "-0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "5,4",
      "Socioøkonomisk_reference2": "6,0",
      "Forskel2": "-0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "8,0",
      "Socioøkonomisk_reference2": "7,7",
      "Forskel2": "0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "7,3",
      "Socioøkonomisk_reference2": "7,9",
      "Forskel2": "-0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,5",
      "Socioøkonomisk_reference2": "8,7",
      "Forskel2": "-0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "8,5",
      "Socioøkonomisk_reference2": "8,4",
      "Forskel2": "0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2016/2017",
      "Karakter2": "9,8",
      "Socioøkonomisk_reference2": "8,7",
      "Forskel2": "1,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "9,2",
      "Socioøkonomisk_reference2": "8,6",
      "Forskel2": "0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,1",
      "Socioøkonomisk_reference2": "7,9",
      "Forskel2": "-0,8",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "6,6",
      "Socioøkonomisk_reference2": "7,3",
      "Forskel2": "-0,7",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,8",
      "Socioøkonomisk_reference2": "7,3",
      "Forskel2": "0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "6,1",
      "Socioøkonomisk_reference2": "6,5",
      "Forskel2": "-0,4",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,7",
      "Socioøkonomisk_reference2": "6,4",
      "Forskel2": "0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "7,0",
      "soc_ref": "6,6",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "6,2",
      "soc_ref": "6,1",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "6,0",
      "soc_ref": "6,1",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,6",
      "soc_ref": "8,5",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "7,3",
      "soc_ref": "7,3",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,6",
      "soc_ref": "7,4",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "7,4",
      "soc_ref": "7,1",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "5,9",
      "soc_ref": "6,3",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "6,2",
      "soc_ref": "6,3",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "7,1",
      "soc_ref": "6,7",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "8,7",
      "soc_ref": "6,6",
      "Forskel": "2,1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "6,9",
      "soc_ref": "6,2",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "9,1",
      "soc_ref": "8,7",
      "Forskel": "0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "6,9",
      "soc_ref": "7,2",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "9,1",
      "soc_ref": "8,4",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "4,6",
      "soc_ref": "5,5",
      "Forskel": "-0,9",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,9",
      "soc_ref": "8,2",
      "Forskel": "0,7",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "5,4",
      "soc_ref": "6,3",
      "Forskel": "-0,9",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "8,5",
      "soc_ref": "7,6",
      "Forskel": "0,9",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "5,6",
      "soc_ref": "6,0",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "5,8",
      "soc_ref": "5,9",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "7,6",
      "soc_ref": "7,3",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "6,5",
      "soc_ref": "6,4",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "5,9",
      "soc_ref": "6,2",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "8,0",
      "Soc_ref1": "7,6",
      "Textbox50": "0,4",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,6",
      "Soc_ref1": "6,6",
      "Textbox50": "0,0",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,5",
      "Soc_ref1": "6,4",
      "Textbox50": "0,1",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "6,0",
      "Socioøkonomisk_reference2": "5,8",
      "Forskel2": "0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,5",
      "Socioøkonomisk_reference2": "7,3",
      "Forskel2": "0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,3",
      "Socioøkonomisk_reference2": "7,8",
      "Forskel2": "0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "5,7",
      "Socioøkonomisk_reference2": "6,5",
      "Forskel2": "-0,8",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "5,8",
      "Socioøkonomisk_reference2": "6,6",
      "Forskel2": "-0,8",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "9,1",
      "Socioøkonomisk_reference2": "8,3",
      "Forskel2": "0,8",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "8,1",
      "Socioøkonomisk_reference2": "7,5",
      "Forskel2": "0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2017/2018",
      "karakter": "6,0",
      "soc_ref": "5,9",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2016/2017",
      "karakter": "5,9",
      "soc_ref": "6,4",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2015/2016",
      "karakter": "6,9",
      "soc_ref": "6,7",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "8,1",
      "soc_ref": "7,9",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "7,4",
      "soc_ref": "7,8",
      "Forskel": "-0,4",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "7,1",
      "soc_ref": "7,6",
      "Forskel": "-0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2017/2018",
      "karakter": "6,9",
      "soc_ref": "6,6",
      "Forskel": "0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2016/2017",
      "karakter": "6,1",
      "soc_ref": "7,0",
      "Forskel": "-0,9*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2015/2016",
      "karakter": "7,5",
      "soc_ref": "7,0",
      "Forskel": "0,5",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2017/2018",
      "karakter": "7,1",
      "soc_ref": "6,4",
      "Forskel": "0,7*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2016/2017",
      "karakter": "6,7",
      "soc_ref": "6,5",
      "Forskel": "0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2015/2016",
      "karakter": "7,2",
      "soc_ref": "6,5",
      "Forskel": "0,7*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "7,1",
      "soc_ref": "7,4",
      "Forskel": "-0,3",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "8,1",
      "soc_ref": "8,0",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "8,0",
      "soc_ref": "7,9",
      "Forskel": "0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "5,9",
      "soc_ref": "6,8",
      "Forskel": "-0,9*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2017/2018",
      "karakter": "5,9",
      "soc_ref": "7,1",
      "Forskel": "-1,2*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2016/2017",
      "karakter": "6,4",
      "soc_ref": "7,3",
      "Forskel": "-0,9*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Fællesprøve i fysik/kemi, biologi og geografi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2015/2016",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "6,0",
      "soc_ref": "6,8",
      "Forskel": "-0,8*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "5,8",
      "soc_ref": "6,8",
      "Forskel": "-1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "6,9",
      "soc_ref": "6,9",
      "Forskel": "0,0",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2017/2018",
      "karakter": "6,6",
      "soc_ref": "6,8",
      "Forskel": "-0,2",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2016/2017",
      "karakter": "6,0",
      "soc_ref": "7,0",
      "Forskel": "-1*",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2015/2016",
      "karakter": "7,0",
      "soc_ref": "7,1",
      "Forskel": "-0,1",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,7",
      "Soc_ref1": "6,8",
      "Textbox50": "-0,1",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "6,5",
      "Soc_ref1": "7,1",
      "Textbox50": "-0,6*",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "Gennemsnit",
      "Karakter3": "7,0",
      "Soc_ref1": "7,0",
      "Textbox50": "0,0",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "",
      "fagdisciplin1": "",
      "Skoleår2": "",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "4,4",
      "Socioøkonomisk_reference2": "5,3",
      "Forskel2": "-0,9",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "5,7",
      "Socioøkonomisk_reference2": "6,7",
      "Forskel2": "-1,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Biologi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "5,9",
      "Socioøkonomisk_reference2": "6,5",
      "Forskel2": "-0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,8",
      "Socioøkonomisk_reference2": "7,8",
      "Forskel2": "0,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Engelsk",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "4,5",
      "Socioøkonomisk_reference2": "5,2",
      "Forskel2": "-0,7",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Fysik/kemi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "4,8",
      "Socioøkonomisk_reference2": "5,8",
      "Forskel2": "-1,0",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Geografi",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,1",
      "Socioøkonomisk_reference2": "6,3",
      "Forskel2": "-0,2",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "5,1",
      "Socioøkonomisk_reference2": "6,7",
      "Forskel2": "-1,6*",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "6,8",
      "Socioøkonomisk_reference2": "7,6",
      "Forskel2": "-0,8",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Historie",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,8",
      "Socioøkonomisk_reference2": "7,4",
      "Forskel2": "-0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2016/2017",
      "Karakter2": "7,1",
      "Socioøkonomisk_reference2": "7,7",
      "Forskel2": "-0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Idræt",
      "fagdisciplin1": "Fagspecifik",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,7",
      "Socioøkonomisk_reference2": "7,8",
      "Forskel2": "-0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "8,8",
      "Socioøkonomisk_reference2": "8,0",
      "Forskel2": "0,8",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "6,5",
      "Socioøkonomisk_reference2": "7,0",
      "Forskel2": "-0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Kristendomskundskab",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,5",
      "Socioøkonomisk_reference2": "7,8",
      "Forskel2": "-0,3",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "6,4",
      "Socioøkonomisk_reference2": "6,9",
      "Forskel2": "-0,5",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Matematik",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,4",
      "Socioøkonomisk_reference2": "7,0",
      "Forskel2": "-0,6",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "9,2",
      "Socioøkonomisk_reference2": "8,3",
      "Forskel2": "0,9",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Samfundsfag",
      "fagdisciplin1": "Mundtlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "6,4",
      "Socioøkonomisk_reference2": "7,1",
      "Forskel2": "-0,7",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2017/2018",
      "Karakter2": "",
      "Socioøkonomisk_reference2": "",
      "Forskel2": "",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2016/2017",
      "Karakter2": "6,1",
      "Socioøkonomisk_reference2": "7,0",
      "Forskel2": "-0,9",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    },
    {
      "Textbox20": "Karaktergennemsnit og socioøkonomisk reference",
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Fag21": "",
      "Karakter3": "",
      "Soc_ref1": "",
      "Textbox50": "",
      "Textbox13": "2015/2016, 2016/2017, 2017/2018",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      "Textbox24": "Bundne prøvefag",
      "Textbox10": "Opnået karaktergennemsnit i prøvefag til udtræk og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag1": "Tysk (tilbudsfag)",
      "fagdisciplin1": "Skriftlig",
      "Skoleår2": "2015/2016",
      "Karakter2": "7,8",
      "Socioøkonomisk_reference2": "7,9",
      "Forskel2": "-0,1",
      "Textbox21": "2015/2016, 2016/2017, 2017/2018",
      "Textbox27": "Aalborg",
      "Textbox29": "Folkeskoler",
      "Textbox37": "Prøvefag til udtræk"
    }
  ] as IRawData[]