import * as React from "react";
import {
  ISchoolPerformance,
  calculateSchoolPerformances,
  IYearlySchoolPerformance,
  yearlyAverageGradeAndSocioEconomicIndexForSchool,
} from "../shared/calculators/performance";
// import { skoledata } from "../data/folkeskole.2009.2018";
import { skoledata } from "../data/folkeskole.2009.2019";

interface IGradesContext {
  schoolPerformances: ISchoolPerformance[];
  yearlySchoolPerformance: IYearlySchoolPerformance[] | null;
  coursePack: string[];
  setCoursePack: React.Dispatch<string[]>;
  setSchoolYears: React.Dispatch<string[]>;
  setSelectedSchool: React.Dispatch<string>;
}

const GradesContext = React.createContext<IGradesContext | null>(null);

export const GradesContextProvider: React.FC = ({ children }) => {
  const [schoolPerformances, setSchoolPerformances] = React.useState<
    ISchoolPerformance[]
  >([]);
  const [coursePack, setCoursePack] = React.useState<string[]>([]);
  const [schoolYears, setSchoolYears] = React.useState<string[]>([]);
  const [selectedSchool, setSelectedSchool] = React.useState<string>("");
  const [yearlySchoolPerformance, setYearlySchoolPerformance] = React.useState<
    IYearlySchoolPerformance[] | null
  >(null);

  React.useEffect(() => {
    const performance = calculateSchoolPerformances(
      skoledata,
      schoolYears,
      coursePack
    );
    setSchoolPerformances(performance);
  }, [coursePack, schoolYears]);

  React.useEffect(() => {
    const school = skoledata.find((s) => s.name === selectedSchool);
    if (school) {
      const p = yearlyAverageGradeAndSocioEconomicIndexForSchool(
        school,
        schoolYears,
        coursePack
      );
      setYearlySchoolPerformance(p);
    }
  }, [selectedSchool, schoolYears, coursePack]);

  return (
    <GradesContext.Provider
      value={{
        schoolPerformances,
        yearlySchoolPerformance,
        coursePack,
        setCoursePack,
        setSchoolYears,
        setSelectedSchool,
      }}
    >
      {children}
    </GradesContext.Provider>
  );
};

export const useGradesContext = () => {
  const context = React.useContext(GradesContext);

  if (!context) {
    throw new Error("useGrades must be used within GradesContextProvider");
  }

  const {
    schoolPerformances,
    yearlySchoolPerformance,
    setCoursePack,
    setSchoolYears,
    setSelectedSchool,
  } = context;

  return {
    schoolPerformances,
    yearlySchoolPerformance,
    setCoursePack,
    setSchoolYears,
    setSelectedSchool,
  };
};
