import { IRawData } from "./model";

export const rawData = [
    {   
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "6,2",
      "soc_ref": "6,1",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "7,6",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "6,0",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,5",
      "soc_ref": "7,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "8,0",
      "soc_ref": "7,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "5,9",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "6,6",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "7,2",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "5,7",
      "soc_ref": "6,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "6,3",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "8,0",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "6,1",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,6",
      "soc_ref": "8,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,6",
      "soc_ref": "7,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,3",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,4",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "6,3",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "3,9",
      "soc_ref": "5,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "7,1",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "7,3",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "5,2",
      "soc_ref": "5,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "6,7",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "7,4",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "6,0",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Byplanvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "4,5",
      "soc_ref": "4,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "5,8",
      "soc_ref": "5,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "4,4",
      "soc_ref": "4,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,0",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "6,2",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,4",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "5,8",
      "soc_ref": "6,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "4,1",
      "soc_ref": "4,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "5,2",
      "soc_ref": "5,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "6,5",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "4,8",
      "soc_ref": "5,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "6,3",
      "soc_ref": "5,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,4",
      "soc_ref": "8,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,2",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,4",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,8",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "4,8",
      "soc_ref": "5,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,6",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "5,9",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "3,5",
      "soc_ref": "4,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "4,7",
      "soc_ref": "4,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "7,1",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "5,5",
      "soc_ref": "5,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "5,8",
      "soc_ref": "5,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Farstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "6,3",
      "soc_ref": "6,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "7,5",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "7,0",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,7",
      "soc_ref": "8,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "9,1",
      "soc_ref": "8,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "8,2",
      "soc_ref": "7,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "6,8",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "6,7",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "8,1",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "7,5",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "6,5",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "6,6",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "6,9",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "8,8",
      "soc_ref": "8,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "8,7",
      "soc_ref": "7,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,0",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "5,3",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "5,3",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "7,8",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "5,9",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "6,6",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "7,2",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "7,7",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "7,5",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Ferslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "6,4",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "5,5",
      "soc_ref": "5,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "7,1",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,5",
      "soc_ref": "7,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,7",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,9",
      "soc_ref": "7,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "6,8",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "5,6",
      "soc_ref": "5,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "6,9",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "6,5",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "5,1",
      "soc_ref": "5,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "7,6",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,2",
      "soc_ref": "7,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,9",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "8,4",
      "soc_ref": "7,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "6,9",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "5,2",
      "soc_ref": "5,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,7",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "6,3",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "5,7",
      "soc_ref": "5,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "6,5",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "5,8",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "5,3",
      "soc_ref": "5,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "6,7",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Filstedvejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "6,6",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "6,9",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "7,0",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,5",
      "soc_ref": "8,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,8",
      "soc_ref": "7,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,6",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "7,3",
      "soc_ref": "7,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "7,6",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "7,4",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "6,5",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "8,7",
      "soc_ref": "7,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "6,2",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,9",
      "soc_ref": "8,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "8,2",
      "soc_ref": "8,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,5",
      "soc_ref": "7,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,5",
      "soc_ref": "7,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "8,0",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "5,7",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "8,4",
      "soc_ref": "7,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "7,8",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "7,0",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "8,8",
      "soc_ref": "8,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "8,8",
      "soc_ref": "7,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "7,9",
      "soc_ref": "7,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Frejlev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "6,3",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "6,5",
      "soc_ref": "6,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "6,6",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,3",
      "soc_ref": "8,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,4",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "8,0",
      "soc_ref": "7,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "7,3",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "5,2",
      "soc_ref": "5,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "6,4",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "6,2",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "6,0",
      "soc_ref": "5,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "6,4",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,4",
      "soc_ref": "7,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,5",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,9",
      "soc_ref": "7,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,4",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "6,5",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,2",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "6,7",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "5,5",
      "soc_ref": "5,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "4,8",
      "soc_ref": "5,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "7,3",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "5,8",
      "soc_ref": "6,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "6,2",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gandrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "7,7",
      "soc_ref": "7,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "7,5",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "8,1",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,4",
      "soc_ref": "8,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,9",
      "soc_ref": "8,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "8,2",
      "soc_ref": "8,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "8,9",
      "soc_ref": "8,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "8,3",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "8,2",
      "soc_ref": "7,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "6,8",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "7,4",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "7,0",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "9,1",
      "soc_ref": "8,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "8,3",
      "soc_ref": "8,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,9",
      "soc_ref": "8,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,4",
      "soc_ref": "7,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "6,4",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,1",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "8,8",
      "soc_ref": "8,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "7,3",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "7,4",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "8,4",
      "soc_ref": "8,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "8,2",
      "soc_ref": "7,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "8,2",
      "soc_ref": "8,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gistrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "7,3",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "6,4",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "7,4",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,5",
      "soc_ref": "8,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,5",
      "soc_ref": "8,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "8,9",
      "soc_ref": "8,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "7,2",
      "soc_ref": "7,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "6,3",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "7,5",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "7,6",
      "soc_ref": "7,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "7,5",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "7,2",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,4",
      "soc_ref": "8,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "8,6",
      "soc_ref": "8,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "8,1",
      "soc_ref": "8,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,2",
      "soc_ref": "7,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,6",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,3",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "7,4",
      "soc_ref": "7,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "9,0",
      "soc_ref": "7,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "6,7",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "8,1",
      "soc_ref": "7,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "7,9",
      "soc_ref": "7,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "8,4",
      "soc_ref": "7,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Hasseris Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "6,9",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "6,1",
      "soc_ref": "5,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "6,5",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,1",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,1",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,7",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "7,2",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "6,5",
      "soc_ref": "5,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "7,3",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "7,5",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "6,3",
      "soc_ref": "6,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "7,5",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,8",
      "soc_ref": "8,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,3",
      "soc_ref": "7,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "8,5",
      "soc_ref": "7,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "6,5",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "6,6",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,0",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "6,8",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "6,1",
      "soc_ref": "5,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "7,6",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "7,0",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "7,3",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "7,6",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gl Lindholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "6,7",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "6,5",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "5,6",
      "soc_ref": "5,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,1",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,9",
      "soc_ref": "7,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "9,4",
      "soc_ref": "7,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "8,0",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "6,6",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "5,8",
      "soc_ref": "5,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "6,2",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "7,3",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "7,8",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "9,0",
      "soc_ref": "8,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,0",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,5",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "6,0",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "6,0",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,3",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "8,6",
      "soc_ref": "7,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "6,8",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "5,4",
      "soc_ref": "5,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "9,7",
      "soc_ref": "8,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "7,6",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "7,1",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gudumholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "6,5",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "7,5",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "7,5",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,8",
      "soc_ref": "8,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "8,0",
      "soc_ref": "8,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "8,4",
      "soc_ref": "8,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "7,7",
      "soc_ref": "7,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "8,1",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "7,3",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "7,0",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "7,5",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "7,9",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,6",
      "soc_ref": "7,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "8,4",
      "soc_ref": "8,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "8,0",
      "soc_ref": "7,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,4",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,8",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,1",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "7,2",
      "soc_ref": "7,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "7,8",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "7,4",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "8,0",
      "soc_ref": "7,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "8,2",
      "soc_ref": "7,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "8,6",
      "soc_ref": "7,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Gug Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "5,9",
      "soc_ref": "5,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "6,5",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "5,3",
      "soc_ref": "6,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,5",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,0",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,4",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "6,8",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "7,2",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "5,2",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "5,5",
      "soc_ref": "5,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "6,9",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "5,0",
      "soc_ref": "5,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "9,9",
      "soc_ref": "8,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,0",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,3",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "5,8",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "4,4",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,0",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "6,9",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "6,9",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "5,8",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "7,3",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "7,8",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "7,0",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Hals Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "4,8",
      "soc_ref": "5,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "7,1",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "5,7",
      "soc_ref": "5,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "4,4",
      "soc_ref": "5,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,6",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "8,0",
      "soc_ref": "7,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "5,6",
      "soc_ref": "5,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "6,2",
      "soc_ref": "5,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "5,2",
      "soc_ref": "5,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "4,7",
      "soc_ref": "5,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "5,8",
      "soc_ref": "6,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "8,1",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "6,3",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,8",
      "soc_ref": "7,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,3",
      "soc_ref": "7,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "5,4",
      "soc_ref": "5,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "6,7",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,3",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "4,7",
      "soc_ref": "5,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "5,4",
      "soc_ref": "5,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "5,9",
      "soc_ref": "5,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "4,3",
      "soc_ref": "5,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "5,4",
      "soc_ref": "5,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "6,7",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Herningvej Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "6,9",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "5,9",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "7,1",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,5",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,9",
      "soc_ref": "7,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,3",
      "soc_ref": "7,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "7,8",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "5,5",
      "soc_ref": "5,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "6,3",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "5,9",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "8,0",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "6,5",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "6,9",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "8,1",
      "soc_ref": "7,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,3",
      "soc_ref": "7,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,9",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "6,6",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,8",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "7,8",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "5,3",
      "soc_ref": "5,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "7,1",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "6,8",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "5,2",
      "soc_ref": "6,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "7,6",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Højvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "7,1",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "6,0",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "6,2",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,0",
      "soc_ref": "8,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "6,8",
      "soc_ref": "7,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,3",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "7,4",
      "soc_ref": "7,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "5,8",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "6,6",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "6,2",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "6,4",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "6,6",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,6",
      "soc_ref": "7,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "8,1",
      "soc_ref": "7,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,6",
      "soc_ref": "7,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "6,9",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "6,4",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "5,8",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "7,0",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "7,3",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "5,8",
      "soc_ref": "6,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "7,3",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "7,1",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "6,7",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Klarup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "5,6",
      "soc_ref": "5,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "6,7",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "6,0",
      "soc_ref": "5,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "5,9",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "8,0",
      "soc_ref": "8,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "5,6",
      "soc_ref": "5,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "7,6",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "6,0",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "5,3",
      "soc_ref": "5,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "6,5",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "7,3",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "6,0",
      "soc_ref": "5,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,5",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,4",
      "soc_ref": "7,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "5,6",
      "soc_ref": "5,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "6,6",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "6,5",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "5,6",
      "soc_ref": "5,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "6,8",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "5,7",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "5,3",
      "soc_ref": "5,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "6,6",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "6,0",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "5,3",
      "soc_ref": "5,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Kongerslev Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "4,0",
      "soc_ref": "4,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "7,2",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "5,4",
      "soc_ref": "5,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,6",
      "soc_ref": "7,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "6,7",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,3",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "5,8",
      "soc_ref": "5,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "4,8",
      "soc_ref": "4,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "5,3",
      "soc_ref": "5,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "5,7",
      "soc_ref": "5,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "6,4",
      "soc_ref": "5,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "5,9",
      "soc_ref": "5,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,6",
      "soc_ref": "7,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,4",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,7",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,3",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,8",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "5,4",
      "soc_ref": "5,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "7,4",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "4,7",
      "soc_ref": "4,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "4,7",
      "soc_ref": "4,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "5,9",
      "soc_ref": "5,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "6,0",
      "soc_ref": "5,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "6,2",
      "soc_ref": "5,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Løvvangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "4,0",
      "soc_ref": "4,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "5,1",
      "soc_ref": "5,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "5,0",
      "soc_ref": "5,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "6,1",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "6,4",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,9",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "5,6",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "3,6",
      "soc_ref": "4,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "5,6",
      "soc_ref": "5,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "5,6",
      "soc_ref": "5,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "7,1",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "6,4",
      "soc_ref": "5,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,4",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,5",
      "soc_ref": "7,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,6",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,1",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "5,4",
      "soc_ref": "5,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "5,7",
      "soc_ref": "5,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "5,3",
      "soc_ref": "5,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "5,3",
      "soc_ref": "5,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "5,6",
      "soc_ref": "5,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "4,5",
      "soc_ref": "5,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "5,2",
      "soc_ref": "5,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "5,6",
      "soc_ref": "5,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mellervangskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "5,6",
      "soc_ref": "5,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "5,9",
      "soc_ref": "5,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "5,0",
      "soc_ref": "5,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,2",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "8,0",
      "soc_ref": "7,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "8,6",
      "soc_ref": "7,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "7,9",
      "soc_ref": "7,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "5,2",
      "soc_ref": "5,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "5,7",
      "soc_ref": "5,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "7,1",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "6,5",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "6,1",
      "soc_ref": "6,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,4",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "9,0",
      "soc_ref": "8,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,2",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,5",
      "soc_ref": "7,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "6,5",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,3",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "6,5",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "6,7",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "6,0",
      "soc_ref": "5,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "6,0",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "6,4",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "6,9",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Mou Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "6,9",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "7,3",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "7,0",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,9",
      "soc_ref": "8,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "8,3",
      "soc_ref": "8,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,2",
      "soc_ref": "7,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "8,4",
      "soc_ref": "7,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "6,6",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "7,8",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "7,9",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "6,0",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "8,6",
      "soc_ref": "7,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,1",
      "soc_ref": "7,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,4",
      "soc_ref": "7,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,4",
      "soc_ref": "7,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,8",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "5,5",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,5",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "7,3",
      "soc_ref": "7,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "7,2",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "7,6",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "8,2",
      "soc_ref": "7,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "8,1",
      "soc_ref": "7,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "8,7",
      "soc_ref": "8,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nibe Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "8,9",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "6,2",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "5,7",
      "soc_ref": "5,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,5",
      "soc_ref": "7,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "8,5",
      "soc_ref": "8,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "5,4",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "8,4",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "5,7",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "5,4",
      "soc_ref": "5,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "7,1",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "6,8",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "6,1",
      "soc_ref": "6,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,1",
      "soc_ref": "7,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "6,5",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,3",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,5",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,8",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,7",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "8,4",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "6,1",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "5,5",
      "soc_ref": "5,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "8,9",
      "soc_ref": "7,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "7,5",
      "soc_ref": "7,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "6,8",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Nr Uttrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "5,6",
      "soc_ref": "5,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "4,6",
      "soc_ref": "5,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "4,1",
      "soc_ref": "5,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,4",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,0",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,4",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "5,9",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "4,0",
      "soc_ref": "5,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "3,2",
      "soc_ref": "4,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "6,8",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "3,5",
      "soc_ref": "5,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "3,8",
      "soc_ref": "5,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,5",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,2",
      "soc_ref": "7,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,7",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "6,0",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "6,5",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "5,0",
      "soc_ref": "5,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "5,6",
      "soc_ref": "5,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "5,3",
      "soc_ref": "5,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "3,6",
      "soc_ref": "4,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "6,0",
      "soc_ref": "6,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "6,3",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "4,4",
      "soc_ref": "5,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Seminarieskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "5,8",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "6,6",
      "soc_ref": "6,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "6,4",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,5",
      "soc_ref": "7,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,8",
      "soc_ref": "7,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,7",
      "soc_ref": "7,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "6,4",
      "soc_ref": "7,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "5,0",
      "soc_ref": "5,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "6,7",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "6,6",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "6,6",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "6,1",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,6",
      "soc_ref": "8,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,2",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,7",
      "soc_ref": "7,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,2",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "6,4",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,4",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "6,3",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "5,9",
      "soc_ref": "5,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "5,8",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "7,3",
      "soc_ref": "7,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "5,4",
      "soc_ref": "5,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "6,4",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Skansevejens Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "5,8",
      "soc_ref": "5,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "5,6",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "6,0",
      "soc_ref": "6,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,4",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,7",
      "soc_ref": "7,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,6",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "7,6",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "5,7",
      "soc_ref": "6,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "5,8",
      "soc_ref": "5,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "5,9",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "6,8",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "6,1",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,6",
      "soc_ref": "7,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,7",
      "soc_ref": "7,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,7",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "6,6",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "6,9",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "5,1",
      "soc_ref": "5,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "6,0",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "5,7",
      "soc_ref": "6,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "5,2",
      "soc_ref": "5,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "6,8",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "6,0",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "5,8",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sofiendalskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "7,3",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "7,3",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "7,7",
      "soc_ref": "7,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "10,4",
      "soc_ref": "9,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "9,2",
      "soc_ref": "8,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "8,9",
      "soc_ref": "8,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "9,6",
      "soc_ref": "8,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "8,5",
      "soc_ref": "7,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "8,6",
      "soc_ref": "7,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "8,4",
      "soc_ref": "7,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "7,6",
      "soc_ref": "7,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "7,5",
      "soc_ref": "7,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "9,8",
      "soc_ref": "9,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "9,0",
      "soc_ref": "9,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "9,0",
      "soc_ref": "8,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,9",
      "soc_ref": "7,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,8",
      "soc_ref": "7,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,8",
      "soc_ref": "7,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "9,0",
      "soc_ref": "8,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "7,5",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "6,4",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "8,8",
      "soc_ref": "8,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "8,3",
      "soc_ref": "8,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "8,5",
      "soc_ref": "8,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Stolpedalsskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "5,7",
      "soc_ref": "5,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "6,2",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "5,8",
      "soc_ref": "5,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,7",
      "soc_ref": "7,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,8",
      "soc_ref": "7,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,8",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "6,3",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "6,6",
      "soc_ref": "6,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "6,4",
      "soc_ref": "5,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "6,9",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "6,5",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "6,0",
      "soc_ref": "5,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,9",
      "soc_ref": "7,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "6,0",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,9",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "6,7",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "6,4",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,6",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "7,9",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "5,7",
      "soc_ref": "5,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "5,2",
      "soc_ref": "5,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "8,4",
      "soc_ref": "7,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "6,8",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "6,4",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sulsted Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "6,3",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "6,7",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "6,8",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,3",
      "soc_ref": "7,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "8,3",
      "soc_ref": "7,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,8",
      "soc_ref": "7,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "7,1",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "8,0",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "7,6",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "6,5",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "7,6",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "6,6",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,1",
      "soc_ref": "7,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "8,4",
      "soc_ref": "7,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "8,4",
      "soc_ref": "8,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,4",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "6,4",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,0",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "6,5",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "7,3",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "6,8",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "7,5",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "7,6",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "8,3",
      "soc_ref": "7,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Svenstrup Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "6,5",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "7,2",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "6,6",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,0",
      "soc_ref": "7,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "8,2",
      "soc_ref": "7,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,4",
      "soc_ref": "7,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "7,5",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "7,0",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "5,4",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "6,8",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "7,2",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "5,7",
      "soc_ref": "6,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,1",
      "soc_ref": "7,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,7",
      "soc_ref": "7,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "8,1",
      "soc_ref": "7,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "6,0",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "8,1",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,0",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "7,2",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "6,7",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "6,2",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "7,4",
      "soc_ref": "7,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "7,1",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "7,3",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderbroskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "6,1",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "5,2",
      "soc_ref": "5,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "5,2",
      "soc_ref": "5,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,9",
      "soc_ref": "7,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,2",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,2",
      "soc_ref": "7,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "6,7",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "5,5",
      "soc_ref": "5,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "5,1",
      "soc_ref": "5,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "6,4",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "6,9",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "5,6",
      "soc_ref": "6,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,6",
      "soc_ref": "8,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "8,9",
      "soc_ref": "8,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,5",
      "soc_ref": "7,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "4,9",
      "soc_ref": "5,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "5,1",
      "soc_ref": "5,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "5,4",
      "soc_ref": "5,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "8,1",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "5,8",
      "soc_ref": "5,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "3,2",
      "soc_ref": "4,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "6,7",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "4,8",
      "soc_ref": "5,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "4,8",
      "soc_ref": "5,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Sønderholm Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "6,0",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "5,9",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "6,9",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "9,1",
      "soc_ref": "8,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,6",
      "soc_ref": "7,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,6",
      "soc_ref": "7,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "7,3",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "5,3",
      "soc_ref": "6,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "6,0",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "6,9",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "6,3",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "6,3",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,9",
      "soc_ref": "7,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,7",
      "soc_ref": "7,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "8,8",
      "soc_ref": "8,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "5,6",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "5,2",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "4,3",
      "soc_ref": "5,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "6,9",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "6,2",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "6,3",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "6,5",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "6,8",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "7,7",
      "soc_ref": "7,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tofthøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "4,3",
      "soc_ref": "4,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "4,3",
      "soc_ref": "4,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "5,4",
      "soc_ref": "5,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "6,0",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "6,9",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "5,9",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "5,4",
      "soc_ref": "5,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "4,1",
      "soc_ref": "4,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "3,7",
      "soc_ref": "4,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "3,2",
      "soc_ref": "4,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "4,0",
      "soc_ref": "4,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "4,9",
      "soc_ref": "4,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "6,1",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "5,6",
      "soc_ref": "6,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,3",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "6,4",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "8,0",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "4,7",
      "soc_ref": "4,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "5,7",
      "soc_ref": "5,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "3,2",
      "soc_ref": "4,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "4,7",
      "soc_ref": "4,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "5,4",
      "soc_ref": "5,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "4,5",
      "soc_ref": "5,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "4,8",
      "soc_ref": "5,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Tornhøjskolen, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "6,8",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "6,2",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "7,2",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,6",
      "soc_ref": "7,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "8,3",
      "soc_ref": "8,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "8,8",
      "soc_ref": "8,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "8,9",
      "soc_ref": "7,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "6,9",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "8,2",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "8,3",
      "soc_ref": "7,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "6,0",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "6,8",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,4",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,2",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,9",
      "soc_ref": "7,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "6,2",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "6,0",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,0",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "7,2",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "5,2",
      "soc_ref": "5,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "6,8",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "6,6",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "5,1",
      "soc_ref": "6,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "6,4",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vadum Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "7,6",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "6,6",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "6,4",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,3",
      "soc_ref": "8,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "8,1",
      "soc_ref": "8,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,9",
      "soc_ref": "7,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "8,5",
      "soc_ref": "7,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "4,9",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "5,9",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "8,4",
      "soc_ref": "7,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "5,8",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "6,6",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "9,3",
      "soc_ref": "8,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,6",
      "soc_ref": "7,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "8,0",
      "soc_ref": "8,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,1",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,0",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,7",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "7,8",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "5,7",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "5,6",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "7,3",
      "soc_ref": "7,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "7,0",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "7,1",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vejgaard Østre Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "6,9",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "7,2",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "7,8",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "9,8",
      "soc_ref": "9,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "10,3",
      "soc_ref": "8,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "8,1",
      "soc_ref": "7,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "8,3",
      "soc_ref": "8,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "6,5",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "7,0",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "7,4",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "7,2",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "5,9",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "10,0",
      "soc_ref": "9,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "10,8",
      "soc_ref": "9,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "9,9",
      "soc_ref": "8,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,3",
      "soc_ref": "7,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "8,9",
      "soc_ref": "7,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,8",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "8,5",
      "soc_ref": "7,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "6,3",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "6,5",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "8,6",
      "soc_ref": "8,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "7,0",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "6,9",
      "soc_ref": "7,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vestbjerg Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "6,1",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "5,5",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "6,8",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,0",
      "soc_ref": "8,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "8,2",
      "soc_ref": "7,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,3",
      "soc_ref": "7,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "7,4",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "5,5",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "5,8",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "6,7",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "5,1",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "5,0",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,3",
      "soc_ref": "8,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,3",
      "soc_ref": "7,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,6",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,8",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,5",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,8",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "7,9",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "6,8",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "6,9",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "7,9",
      "soc_ref": "7,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "7,6",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "8,0",
      "soc_ref": "7,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Hassing Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "6,6",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "6,7",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "6,6",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,6",
      "soc_ref": "8,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "9,5",
      "soc_ref": "8,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,2",
      "soc_ref": "7,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "8,3",
      "soc_ref": "7,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "5,8",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "6,5",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "6,4",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "7,1",
      "soc_ref": "7,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "7,2",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,7",
      "soc_ref": "8,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "6,9",
      "soc_ref": "7,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "8,0",
      "soc_ref": "8,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,1",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "6,9",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,5",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "6,9",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "5,6",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "6,6",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "8,0",
      "soc_ref": "7,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "6,7",
      "soc_ref": "7,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "7,8",
      "soc_ref": "7,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vester Mariendal Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "6,1",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "7,2",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "6,2",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "6,7",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,1",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,2",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "7,0",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "6,5",
      "soc_ref": "5,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "6,1",
      "soc_ref": "6,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "6,3",
      "soc_ref": "6,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "6,5",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "6,6",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,5",
      "soc_ref": "8,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "7,9",
      "soc_ref": "7,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,9",
      "soc_ref": "7,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "5,9",
      "soc_ref": "6,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "6,4",
      "soc_ref": "6,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,0",
      "soc_ref": "6,4",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "7,1",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "6,4",
      "soc_ref": "5,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "5,5",
      "soc_ref": "5,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "7,9",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "7,4",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "6,8",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vesterkærets Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2014/2015",
      "karakter": "6,8",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2013/2014",
      "karakter": "6,8",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Læsning",
      "skoleaar": "2012/2013",
      "karakter": "6,5",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "7,9",
      "soc_ref": "8,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "8,2",
      "soc_ref": "8,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "7,3",
      "soc_ref": "7,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2014/2015",
      "karakter": "7,2",
      "soc_ref": "7,5",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2013/2014",
      "karakter": "7,0",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Retskrivning",
      "skoleaar": "2012/2013",
      "karakter": "6,8",
      "soc_ref": "6,9",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2014/2015",
      "karakter": "6,0",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2013/2014",
      "karakter": "6,2",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Dansk",
      "fagdisciplin": "Skriftlig",
      "skoleaar": "2012/2013",
      "karakter": "6,2",
      "soc_ref": "6,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "8,2",
      "soc_ref": "8,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "8,2",
      "soc_ref": "8,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Engelsk",
      "fagdisciplin": "Mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "8,1",
      "soc_ref": "8,1",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2014/2015",
      "karakter": "5,7",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2013/2014",
      "karakter": "6,7",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Fysik/kemi",
      "fagdisciplin": "Praktisk/mundtlig",
      "skoleaar": "2012/2013",
      "karakter": "6,0",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "6,4",
      "soc_ref": "7,2",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "6,7",
      "soc_ref": "6,7",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik med hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "6,3",
      "soc_ref": "6,8",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2014/2015",
      "karakter": "6,1",
      "soc_ref": "7,3",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2013/2014",
      "karakter": "6,6",
      "soc_ref": "7,0",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "Matematik",
      "fagdisciplin": "Matematik uden hjælpemidler",
      "skoleaar": "2012/2013",
      "karakter": "6,8",
      "soc_ref": "7,6",
      
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      
      
      
      
      
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
      
      
      
      
      
      
      
      
      
      
      
      
    },
    {
      
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
    },
    {
      "Textbox9": "Opnået karaktergennemsnit i Bundne prøvefag og socioøkonomiske referencer, 9. klasse, Vodskov Skole, Aalborg",
      "fag": "",
      "fagdisciplin": "",
      "skoleaar": "",
      "karakter": "",
      "soc_ref": "",
      "Forskel": "",
      "Textbox15": "Aalborg",
      "Textbox19": "Folkeskoler",
    }
  ] as IRawData[]