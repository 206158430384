import * as React from "react";
import injectSheet, { StyledComponentProps } from "react-jss";
import Button from "../../shared/components/Button";

import { recipients } from "../../data/raw.parent.20200407";
import { recipients as recipients13 } from "../../data/raw.parent.20200413";
import { guardians } from "../../data/raw.parents.all";
import {
  IParent,
  IRecipient,
  IGuardian,
  IChild,
} from "../../data/model-parents";
import {
  sortByProperty,
  SortDirection,
} from "../../shared/calculators/sorting";

// https://www.aula.dk/api/v7/?method=search.findRecipients&text=Edward&query=Edward&id=1319504&limit=100&fromModule=messages&docTypes[]=Profile

const ParentsImport: React.FC<StyledComponentProps> = ({ classes = {} }) => {
  const [parents, setParents] = React.useState<IParent[]>([]);

  const fetchAddresses = () => {
    const url = "https://www.krak.dk/farhiya+abdi+farah/personer";

    fetch(url)
      .then((response) => response.json())
      .then((data) => console.log("Data", data));

    // request(url, function (error, response, html) {
    //   if (!error) {
    //     // var $ = cheerio.load(html, { xmlMode: true });
    //     // const scriptData = $("script").get()[4].children[0].data;
    //     // const json = JSON.parse(scriptData);
    //     // console.log("-----------------");
    //     // const krakAddress = json[0].address[0];
    //     // console.log("json", krakAddress);
    //     // var address = {
    //     //   street: krakAddress.streetAddress,
    //     //   zip: krakAddress.postalCode,
    //     //   city: krakAddress.addressLocality,
    //     //   region: krakAddress.addressRegion,
    //     // };
    //     // console.log("Address", address);
    //   }

    //   // fs.writeFile("html.txt", html, function (err) {
    //   //   console.log(
    //   //     "HTML File successfully written! - Check your project directory for the output.json file"
    //   //   );
    //   // });

    //   // fs.writeFile("output.json", JSON.stringify(address, null, 4), function (
    //   //   err
    //   // ) {
    //   //   console.log(
    //   //     "File successfully written! - Check your project directory for the output.json file"
    //   //   );
    //   // });

    //   // const response = "Address resolved:<br /> "; // + json.stringify();
    //   // res.send("Address resolved");
    // });

    console.log("fetchAddresses complete");
  };

  const formClasses = (guardians: IGuardian[]) => {
    const children: IChild[] = [];

    guardians.map((g) => {
      const childrenOfGuardian = g.metadata.split(", ");
      if (childrenOfGuardian) {
        childrenOfGuardian.map((child) => {
          const childInfo = child.split(" ");
          children.push({ name: childInfo[0], class: childInfo[1] });
        });
      }
    });

    const sorted = sortByProperty(children, "class", SortDirection.DESC);
    console.log("formClasses", sorted);
  };

  const convert = (data: IRecipient[]) => {
    console.log("Convert", parents);
    const localParents = parents ? parents : [];

    data.map((r) => {
      // const text9 = r.Textbox9.split(",");
      // const schoolName = text9[text9.length - 2];

      let parent = localParents.find((s) => s.name === r.displayName);

      if (!parent) {
        parent = {
          name: r.displayName,
          metadata: r.metadata,
          address: "",
          education: "",
          nonDanish: false,
          children: [],
          portalRole: "",
          profileId: 0,
          id: 0,
        };

        localParents.push(parent);
      }

      return null;
    });

    setParents([...localParents]);
    console.log("Convert Done", localParents);
  };

  return (
    <div className={classes.root}>
      <div className={classes.column}>
        <h3>Data import</h3>
        <p>
          <Button text="Form Classes" onClick={() => formClasses(guardians)} />
          <Button text="Fetch addresses" onClick={() => fetchAddresses()} />
          {/* <Button
            text="Konverter forældre 13 april"
            onClick={() => convert(recipients13)}
          /> */}
          {/* <Button
            text="Konverter 2015-2018"
            onClick={() => convert(rawFolkeAalborg20152018)}
          /> */}
        </p>

        <textarea
          value={JSON.stringify(parents)}
          className={classes.textarea}
        />
      </div>
      <div className={classes.column}>
        <h3>Forældre ({guardians.length}):</h3>
        <ul>
          {guardians.map((s, index) => {
            return (
              <li key={index}>
                <div>
                  {index + 1}) {s.fullName} | {s.metadata}
                </div>
                <div>
                  <ul>
                    {/* {s.courses.map((c, index) => {
                      return (
                        <li key={index}>
                          {c.name}
                          <ul>
                            {c.disciplines.map((d, index) => {
                              return (
                                <li key={index}>
                                  {d.name}
                                  <ul>
                                    {d.grades.map((g, index) => {
                                      return (
                                        <li key={index}>
                                          {g.year} | K: {g.grade} | S:{" "}
                                          {g.socioEconomicIndex} | F:{" "}
                                          {(g.grade * 10 -
                                            g.socioEconomicIndex * 10) /
                                            10}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </li>
                              );
                            })}
                          </ul>
                        </li>
                      );
                    })} */}
                  </ul>
                </div>
              </li>
            );
          })}
        </ul>
      </div>

      <div id="log"></div>
    </div>
  );
};

const styles = {
  root: {
    display: "flex",
    padding: 20,
  },
  column: {
    flex: 1,
  },
  textarea: {
    width: 500,
    height: 400,
  },
};

export default injectSheet(styles)(ParentsImport);
