import React from "react";
import styled from "styled-components";
import { IYearlySchoolPerformance } from "../../shared/calculators/performance";
import { useGradesContext } from "../../contexts/GradesContext";
import LineChart from "../../shared/charts/LineChart";

interface ISchoolPerformance {
  schoolPerformance: IYearlySchoolPerformance[] | null;
}

const SchoolPerformance: React.FC<ISchoolPerformance> = () => {
  const { yearlySchoolPerformance } = useGradesContext();
  const lineChartData = yearlySchoolPerformance
    ? yearlySchoolPerformance.map((p, index) => {
        return {
          x: index,
          y: p.grade,
          si: p.socioEconomicIndex,
          label: p.year
        };
      })
    : [];

  return (
    <Root>
      SchoolPerformance
      <LineChart data={lineChartData} yAxis={{ min: 4, max: 10 }} />
      <ul>
        {yearlySchoolPerformance &&
          yearlySchoolPerformance.map((year, index) => {
            return (
              <li key={index}>
                [{year.year}]: {year.grade} - ({year.effect})
              </li>
            );
          })}
      </ul>
    </Root>
  );
};

export default SchoolPerformance;

const Root = styled.div`
  width: 80%;
  padding: 40px;
  // border: 1px solid red;
`;
