import * as React from "react";
import injectSheet, { StyledComponentProps } from "react-jss";
import {
  sortByProperty,
  SortDirection,
  SortParams
} from "../../shared/calculators/sorting";
import { ISchoolPerformance } from "../../shared/calculators/performance";

export interface ILeaderBoardProps extends StyledComponentProps {
  performance: ISchoolPerformance[];
  onSchoolSelected?: (schoolName: string) => void;
}

const LeaderBoard: React.FC<ILeaderBoardProps> = ({
  performance,
  onSchoolSelected,
  classes = {}
}) => {
  const [sortParams, setSortParams] = React.useState<SortParams>({
    sortBy: "grade",
    sortDirection: SortDirection.ASC
  });
  const [data, setData] = React.useState<ISchoolPerformance[]>([]);

  React.useEffect(() => {
    const sorted = sortByProperty(
      performance,
      sortParams.sortBy,
      sortParams.sortDirection
    );
    setData([...sorted]);
    // console.log("data", data);
  }, [sortParams, performance]);

  const onSort = (value: any) => {
    if (sortParams.sortBy === value) {
      sortParams.sortDirection =
        sortParams.sortDirection === SortDirection.DESC
          ? SortDirection.ASC
          : SortDirection.DESC;
    } else {
      sortParams.sortBy = value;
    }

    setSortParams(sortParams);
    const sorted = sortByProperty(
      performance,
      sortParams.sortBy,
      sortParams.sortDirection
    );
    setData([...sorted]);
  };

  const numberOrDash = (value: number) => {
    return Number(value.toFixed(1)) ? value.toFixed(1) : "-";
  };

  return (
    <div className={classes.table}>
      <div className={classes.tableHeader}>
        <div></div>
        <div onClick={() => onSort("name")}>Skole</div>
        <div onClick={() => onSort("grade")}>Karakter</div>
        <div onClick={() => onSort("socioEconomicIndex")}>
          <span className="full-text">Socioøkonomisk index</span>
          <span className="short-text" title="Socioøkonomisk index">
            SI
          </span>
        </div>
        <div onClick={() => onSort("effect")}>
          <span className="full-text">Undervisningseffekt</span>
          <span className="short-text" title="Undervisningseffekt">
            UE
          </span>
        </div>
      </div>
      {data &&
        data.map((s, index) => {
          return (
            <div
              key={index}
              className={classes.row}
              onClick={() => onSchoolSelected && onSchoolSelected(s.name)}
            >
              <div>{index + 1}</div>
              <div>{s.name}</div>
              <div>{numberOrDash(s.grade)}</div>
              <div>{numberOrDash(s.socioEconomicIndex)}</div>
              <div>{numberOrDash(s.effect)}</div>
            </div>
          );
        })}
    </div>
  );
};

const styles = {
  table: {
    display: "table",
    width: "80%",
    padding: 40,
    maxWidth: 1024
  },
  row: {
    display: "table-row",
    "& > div": {
      borderBottom: "1px solid #e6e6e6",
      "&:first-child": {
        paddingLeft: 20,
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
        width: "10%"
      },
      "&:nth-child(2)": {
        width: "35%"
      },
      "&:nth-child(3)": {
        width: "15%",
        textAlign: "center"
      },
      "&:nth-child(4)": {
        width: "20%",
        textAlign: "center"
      },
      "&:last-child": {
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        width: "20%",
        textAlign: "center"
      }
    },

    "&:hover": {
      fontWeight: "bold",
      backgroundColor: "white",
      boxShadow: "1px 1px 30px 0px hsla(202, 26%, 78%, 1)",
      borderRadius: 30,
      cursor: "pointer",

      "& > div": {
        borderBottom: 0
      }
    },
    "& div": {
      display: "table-cell",
      padding: "12px 6px"
    }
  },
  tableHeader: {
    display: "table-header-group",
    "& div": {
      display: "table-cell",
      padding: "12px 6px",
      fontWeight: "bold",
      textTransform: "uppercase",
      fontSize: 13,
      color: "#282c34",
      cursor: "pointer",
      "&:nth-child(n+3)": {
        textAlign: "center"
      }
    }
  }
};

export default injectSheet(styles)(LeaderBoard);
