import * as React from "react";
import LeaderBoard from "./LeaderBoard";
import FilterBar from "./FilterBar";
import injectSheet, { StyledComponentProps } from "react-jss";
import classNames from "classnames";
import { useGradesContext } from "../../contexts/GradesContext";
import SchoolPerformance from "./SchoolPerformance";

const Home: React.FC<StyledComponentProps> = ({ classes = {} }) => {
  const {
    schoolPerformances,
    setSelectedSchool,
    yearlySchoolPerformance
  } = useGradesContext();

  return (
    <div>
      <div
        className={classNames({
          [classes.center as string]: true,
          [classes.background as string]: true
        })}
      >
        <FilterBar />
      </div>
      <div className={classes.center}>
        <SchoolPerformance schoolPerformance={yearlySchoolPerformance} />
        <LeaderBoard
          performance={schoolPerformances}
          onSchoolSelected={name => setSelectedSchool(name)}
        />
      </div>
    </div>
  );
};

const styles = {
  center: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  background: {
    backgroundColor: "#282c34"
  }
};

export default injectSheet(styles)(Home);
