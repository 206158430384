import * as React from "react";
import injectSheet, { StyledComponentProps } from "react-jss";
import Button from "../../shared/components/Button";
import { rawData as rawFolkeAalborg20182019 } from "../../data/raw.csv.2018.2019";
import { rawData as rawFolkeAalborg20152018 } from "../../data/raw.csv.2015.2018";
import { rawData as rawFolkeAalborg20122015 } from "../../data/raw.csv.2012.2015";
import { rawData as rawFolkeAalborg20092012 } from "../../data/raw.csv.2009.2012";
import { ISchool, IRawData } from "../../data/model";

const DataImport: React.FC<StyledComponentProps> = ({ classes = {} }) => {
  const [schools, setSchools] = React.useState<ISchool[]>([]);

  const convert = (data: IRawData[]) => {
    console.log("Convert", schools);
    const localSchools = schools ? schools : [];

    data.map((r) => {
      const text9 = r.Textbox9.split(",");
      const schoolName = text9[text9.length - 2];

      let school = localSchools.find((s) => s.name === schoolName);

      if (!school) {
        school = {
          name: schoolName,
          municipality: r.Textbox15,
          type: r.Textbox19,
          courses: [],
        };

        localSchools.push(school);
      }

      let course = school.courses.find((c) => c.name === r.fag);
      if (!course && r.fag !== "") {
        course = {
          name: r.fag,
          disciplines: [],
        };
        school.courses.push(course);
      }

      if (course) {
        let discipline = course.disciplines.find(
          (d) => d.name === r.fagdisciplin
        );
        if (!discipline && r.fagdisciplin !== "") {
          discipline = {
            name: r.fagdisciplin,
            grades: [],
          };
          course.disciplines.push(discipline);
        }

        if (discipline) {
          let grade = discipline.grades.find((g) => g.year === r.skoleaar);
          if (!grade && r.skoleaar !== "" && r.karakter !== "") {
            grade = {
              year: r.skoleaar,
              grade: Number(r.karakter.replace(/,/, ".")),
              socioEconomicIndex: Number(r.soc_ref.replace(/,/, ".")),
            };
            discipline.grades.push(grade);
          }
        }
      }

      return null;
    });

    setSchools([...localSchools]);
    console.log("Convert Done", localSchools);
  };

  return (
    <div className={classes.root}>
      <div className={classes.column}>
        <h3>Data import</h3>
        <p>
          <Button
            text="Konverter 2009-2012"
            onClick={() => convert(rawFolkeAalborg20092012)}
          />
          <Button
            text="Konverter 2012-2015"
            onClick={() => convert(rawFolkeAalborg20122015)}
          />
          <Button
            text="Konverter 2015-2018"
            onClick={() => convert(rawFolkeAalborg20152018)}
          />
          <Button
            text="Konverter 2018-2019"
            onClick={() => convert(rawFolkeAalborg20182019)}
          />
        </p>

        <textarea
          value={JSON.stringify(schools)}
          className={classes.textarea}
        />
      </div>
      <div className={classes.column}>
        <h3>Skoler ({schools.length}):</h3>
        <ul>
          {schools.map((s, index) => {
            return (
              <li key={index}>
                <div>
                  {index + 1}) {s.name} | {s.municipality}
                </div>
                <div>
                  <ul>
                    {s.courses.map((c, index) => {
                      return (
                        <li key={index}>
                          {c.name}
                          <ul>
                            {c.disciplines.map((d, index) => {
                              return (
                                <li key={index}>
                                  {d.name}
                                  <ul>
                                    {d.grades.map((g, index) => {
                                      return (
                                        <li key={index}>
                                          {g.year} | K: {g.grade} | S:{" "}
                                          {g.socioEconomicIndex} | F:{" "}
                                          {(g.grade * 10 -
                                            g.socioEconomicIndex * 10) /
                                            10}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </li>
                              );
                            })}
                          </ul>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </li>
            );
          })}
        </ul>
      </div>

      <div id="log"></div>
    </div>
  );
};

const styles = {
  root: {
    display: "flex",
    padding: 20,
  },
  column: {
    flex: 1,
  },
  textarea: {
    width: 500,
    height: 400,
  },
};

export default injectSheet(styles)(DataImport);
