import React from "react";
import styled from "styled-components";

const LineChartDefaultProps: ILineChartOptions = {
  color: "#2196F3",
  pointRadius: 5,
  svgHeight: 300,
  svgWidth: 900,
  xLabelSize: 20,
  yLabelSize: 80
};

const DefaultAxis: IAxis = {
  x: { min: 0, max: 0 },
  y: { min: 0, max: 0 }
};

export interface ILineChartData {
  x: number;
  y: number;
  si: number;
  label: string;
}

interface IPoint {
  x: number;
  y: number;
}

export interface ILineChartOptions {
  color: string;
  pointRadius: number;
  svgHeight: number;
  svgWidth: number;
  xLabelSize: number;
  yLabelSize: number;
}

interface IMinMax {
  min: number;
  max: number;
}

interface IAxis {
  x: IMinMax;
  y: IMinMax;
}

export interface ILineChart {
  data: ILineChartData[];
  options?: ILineChartOptions;
  yAxis?: IMinMax;
  onHover?: () => void;
}

const LineChart: React.FC<ILineChart> = ({
  data,
  options = LineChartDefaultProps,
  yAxis
}) => {
  const [hoverLoc, setHoverLoc] = React.useState(0);
  const [activePoint, setActivePoint] = React.useState<IPoint | null>(null);
  const [axis, setAxis] = React.useState<IAxis>(DefaultAxis);

  const getAxis = (
    data: ILineChartData[],
    yAxis: IMinMax | undefined
  ): IAxis => {
    if (data === undefined || data.length === 0) {
      return DefaultAxis;
    }

    const axis: IAxis = {
      x: { min: data[0].x, max: data[data.length - 1].x },
      y: { min: data[0].y, max: data[0].y }
    };

    if (yAxis) {
      return { ...axis, y: yAxis };
    }

    data.reduce((axis, cur) => {
      axis.y.min = cur.y < axis.y.min ? cur.y : axis.y.min;
      axis.y.max = cur.y > axis.y.max ? cur.y : axis.y.max;
      return axis;
    }, axis);

    return axis;
  };

  React.useEffect(() => {
    setAxis(getAxis(data, yAxis));
    // console.log("useEffect => setAxis", axis);
  }, [data, yAxis]);

  const makeLabels = () => {
    const padding = 5;
    return (
      <g className="linechart_label">
        {/* Y AXIS LABELS */}
        <text
          transform={`translate(${options.yLabelSize / 2}, 20)`}
          textAnchor="middle"
        >
          {axis.y.max}
        </text>
        <text
          transform={`translate(${options.yLabelSize / 2}, ${options.svgHeight -
            options.xLabelSize -
            padding})`}
          textAnchor="middle"
        >
          {axis.y.min}
        </text>
        {/* X AXIS LABELS */}
        <text
          transform={`translate(${options.yLabelSize}, ${options.svgHeight})`}
          textAnchor="start"
        >
          {data[0].label}
        </text>
        <text
          transform={`translate(${options.svgWidth}, ${options.svgHeight})`}
          textAnchor="end"
        >
          {data[data.length - 1].label}
        </text>
      </g>
    );
  };

  const makeAxis = () => {
    const x = axis.x;
    const y = axis.y;

    const svgXMin = getSvgX(x.min);
    const svgXMax = getSvgX(x.max);

    return (
      <LineChartAxis>
        <line
          x1={svgXMin - options.yLabelSize}
          y1={getSvgY(y.min)}
          x2={svgXMax}
          y2={getSvgY(y.min)}
          strokeDasharray="5"
        />
        <line
          x1={svgXMin - options.yLabelSize}
          y1={getSvgY(y.max)}
          x2={svgXMax}
          y2={getSvgY(y.max)}
          strokeDasharray="5"
        />
      </LineChartAxis>
    );
  };

  const makePath = () => {
    let pathD = "M " + getSvgX(data[0].x) + " " + getSvgY(data[0].y) + " ";

    pathD += data
      .map(point => {
        return "L " + getSvgX(point.x) + " " + getSvgY(point.y) + " ";
      })
      .join("");

    return <LinePath d={pathD} />;
  };

  const makeArea = () => {
    let path = "M " + getSvgX(data[0].x) + " " + getSvgY(data[0].si) + " ";

    path += data
      .map(point => "L " + getSvgX(point.x) + " " + getSvgY(point.si) + " ")
      .join("");

    const x = axis.x;
    const y = axis.y;
    path +=
      "L " +
      getSvgX(x.max) +
      " " +
      getSvgY(y.min) +
      " " +
      "L " +
      getSvgX(x.min) +
      " " +
      getSvgY(y.min) +
      " ";

    return (
      <>
        <linearGradient id="gradient-vertical" x2="0" y2="1">
          <GradientVerticalStop1 offset="0%" />
          <GradientVerticalStop2 offset="100%" />
        </linearGradient>
        <LineChartArea d={path} />
      </>
    );
  };

  const createLine = () => {
    if (!hoverLoc) {
      return null;
    }

    return (
      <HoverLine
        x1={hoverLoc!}
        y1={-8}
        x2={hoverLoc!}
        y2={options.svgHeight - options.xLabelSize}
      />
    );
  };

  const createActivePoint = () => {
    return (
      activePoint && (
        <ActivePoint
          r={options.pointRadius}
          cx={activePoint.x}
          cy={activePoint.y}
        />
      )
    );
  };

  const getCoords = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    const svgLocation = document
      .getElementsByClassName("linechart")[0]
      .getBoundingClientRect();
    const adjustment = (svgLocation.width - options.svgWidth) / 2; // takes padding into consideration
    const relativeLoc = e.clientX - svgLocation.left - adjustment;

    // const svgData = data.map((point, i) => {
    //   return {
    //     svgX: getSvgX(point.x),
    //     svgY: getSvgY(point.y),
    //     d: point.label,
    //     p: point.y
    //   };
    // });

    // let closestPoint = {};
    // for (let i = 0, c = 500; i < svgData.length; i++) {
    //   if (Math.abs(svgData[i].svgX - hoverLoc) <= c) {
    //     c = Math.abs(svgData[i].svgX - hoverLoc);
    //     closestPoint = svgData[i];
    //   }
    // }

    const svgData = data.map((point, i) => {
      return {
        x: getSvgX(point.x),
        y: getSvgY(point.y),
        label: point.label
        // p: point.y
      };
    });

    let closestPoint = {};
    for (let i = 0, c = 500; i < svgData.length; i++) {
      if (Math.abs(svgData[i].x - hoverLoc) <= c) {
        c = Math.abs(svgData[i].y - hoverLoc);
        closestPoint = svgData[i];
      }
    }

    // console.log("closestPoint", closestPoint);

    if (relativeLoc - options.yLabelSize < 0) {
      stopHover();
    } else {
      setHoverLoc(relativeLoc);
      setActivePoint(closestPoint as IPoint);
    }
  };

  const stopHover = () => {
    setHoverLoc(0);
    setActivePoint(null);
  };

  const onLeave = () => {
    setHoverLoc(0);
    setActivePoint(null);
  };

  const getSvgX = (x: number) => {
    const xMax = axis.x.max;
    const svgX =
      xMax > 0
        ? options.yLabelSize +
          (x / xMax) * (options.svgWidth - options.yLabelSize)
        : options.yLabelSize + (options.svgWidth - options.yLabelSize);

    return svgX;
  };

  const getSvgY = (y: number) => {
    const gY = axis.y;

    const svgY =
      gY.max - gY.min > 0
        ? ((options.svgHeight - options.xLabelSize) * gY.max -
            (options.svgHeight - options.xLabelSize) * y) /
          (gY.max - gY.min)
        : (options.svgHeight - options.xLabelSize) * gY.max -
          (options.svgHeight - options.xLabelSize) * y;

    return svgY;
  };

  return (
    <Root>
      {console.log("<--- LineChart Render --->", data)}
      {data && data.length > 0 && (
        <svg
          // width={options.svgWidth}
          // height={options.svgHeight}
          width="100%"
          height="100%"
          viewBox={`0 0 ${options.svgWidth} ${options.svgHeight}`}
          className={"linechart"}
          onMouseLeave={() => onLeave()}
          onMouseMove={e => getCoords(e)}
        >
          <g>
            {makeAxis()}
            {makeArea()}
            {makePath()}
            {makeLabels()}
            {hoverLoc && createLine()}
            {hoverLoc && createActivePoint()}
            {/* 
       
        {this.state.hoverLoc ? this.createLine() : null}
        {this.state.hoverLoc ? this.makeActivePoint() : null} */}
          </g>
        </svg>
      )}
    </Root>
  );
};

const PathColors = ["#d4af37", "blue"];

const Root = styled.div`
  border: 1px solid blue;
`;

const LinePath = styled.path`
  stroke-width: 5;
  fill: none;
  stroke: ${PathColors[0]};
`;

const LineChartAxis = styled.g`
  stroke: #bdc3c7;
`;

const LineChartArea = styled.path`
  padding: 8px;
  // fill: #64b5f6;
  fill: url(#gradient-vertical) #447799;
  stroke: none;
  opacity: 0.4;
`;

const GradientVerticalStop1 = styled.stop`
  stop-color: #4289b3;
`;

const GradientVerticalStop2 = styled.stop`
  stop-color: #4289b326;
`;

const HoverLine = styled.line`
  stroke: #7d95b6;
  stroke-width: 1;
`;

const ActivePoint = styled.circle`
  color: "#2196F3";
  fill: #fff;
  stroke-width: 2;
`;

// .linechart {
//     padding: 8px;
//   }
//   .linechart_path {
//     stroke-width: 3;
//     fill: none;
//   }

//   .linechart_axis {
//     stroke: #bdc3c7;
//   }

//   .linechart_area {
//     padding: 8px;
//     fill: #64B5F6;
//     stroke: none;
//     opacity: .4;
//   }

//   .linechart_label {
//    fill: #64B5F6;
//    font-weight: 700;
//   }

export default LineChart;
