import React from "react";
import injectSheet, { StyledComponentProps } from "react-jss";
import YearSelector from "../../shared/components/YearSelector";
import CourseSelector from "../../shared/components/CourseSelector";

const FilterBar: React.FC<StyledComponentProps> = ({ classes = {} }) => {
  return (
    <div className={classes.root}>
      <div>
        <YearSelector />
      </div>
      <div>
        <CourseSelector />
      </div>
    </div>
  );
};

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#282c34"
  }
};

export default injectSheet(styles)(FilterBar);
