import React from "react";
import classNames from "classnames";
import injectSheet, { StyledComponentProps } from "react-jss";
import { useGradesContext } from "../../contexts/GradesContext";

export interface IOption {
  text: string;
  value: string[];
}

const CourseSelector: React.FC<StyledComponentProps> = ({ classes = {} }) => {
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
  const { setCoursePack } = useGradesContext();

  const options: IOption[] = [
    {
      text: "Alle",
      value: []
    },
    {
      text: "Dansk/Matematik",
      value: ["dansk", "matematik"]
    }
  ];

  const onSelected = (index: number) => {
    setSelectedIndex(index);
    setCoursePack(options[index].value);
  };

  return (
    <div className={classes.root}>
      <div className={classes.label}>Fag</div>
      <div className={classes.track}>
        {options.map((o, index) => {
          return (
            <div
              key={index}
              className={classNames({
                [classes.selected as string]: index === selectedIndex
              })}
              onClick={() => onSelected(index)}
            >
              {o.text}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    padding: 20
  },
  label: {
    color: "#d3d6de",
    fontSize: 14,
    fontWeight: 600,
    marginRight: 15,
    textTransform: "uppercase"
  },
  track: {
    display: "flex",
    backgroundColor: "#d3d6de",
    borderRadius: 20,
    padding: 2,
    color: "#282c34",
    "& > div": {
      flex: 1,
      padding: "6px 12px",
      fontSize: 13,
      cursor: "pointer",
      transition: "0.1s",
      borderRight: "1px solid #aeb3c2",
      textAlign: "center",
      "&:hover": {
        transform: "scale(1.15)",
        color: "black",
        backgroundColor: "white"
      },
      "&:last-child": {
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        borderRight: 0
      },
      "&:first-child": {
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20
      }
    }
  },
  selected: {
    backgroundColor: "#4289b3",
    color: "white",
    boxShadow: "1px 1px 10px 0px #1e93d9",
    borderRadius: 20
  }
};

export default injectSheet(styles)(CourseSelector);
