// import { IYearlySchoolPerformance } from "./performance";
import { ISchool, IGrade } from "../../data/model";

export interface ISchoolPerformance {
  name: string;
  grade: number;
  socioEconomicIndex: number;
  effect: number;
}

export interface IYearlySchoolPerformance {
  year: string;
  grade: number;
  socioEconomicIndex: number;
  effect: number;
}

export const calculateSchoolPerformances = (
  schools: ISchool[],
  years: string[],
  coursePack: string[]
) => {
  const result = schools.map((s) => {
    const p = averageGradeAndSocioEconomicIndexForSchool(s, years, coursePack);
    return {
      name: s.name,
      grade: p.grade,
      socioEconomicIndex: p.socioEconomicIndex,
      effect: p.effect,
    };
  });

  return result;
};

export const averageGradeAndSocioEconomicIndexForSchool = (
  school: ISchool,
  years: string[],
  coursePack: string[]
) => {
  const courses =
    coursePack.length === 0
      ? school.courses
      : school.courses.filter((c) => coursePack.includes(c.name.toLowerCase()));

  const grades = courses
    .map((course) => {
      return course.disciplines.map((discipline) => {
        return discipline.grades.filter((grade) => {
          return years.includes(grade.year);
        });
      });
    })
    .flat(3);

  return averageGradeAndSocioEconomicIndex(grades);
};

export const yearlyAverageGradeAndSocioEconomicIndexForSchool = (
  school: ISchool,
  years: string[],
  coursePack: string[]
): IYearlySchoolPerformance[] => {
  const courses =
    coursePack.length === 0
      ? school.courses
      : school.courses.filter((c) => coursePack.includes(c.name.toLowerCase()));

  const grades = courses
    .map((course) => {
      return course.disciplines.map((discipline) => {
        return discipline.grades.filter((grade) => {
          return years.includes(grade.year);
        });
      });
    })
    .flat(3);

  const yearlyPerformance = years.map((year) => {
    const gradesForYear = grades.filter((g: IGrade) => g.year === year);
    const thisYear = averageGradeAndSocioEconomicIndex(gradesForYear);
    return { year, ...thisYear };
  });

  // console.log("yearlyPerformance", yearlyPerformance);
  return yearlyPerformance;
};

export const averageGradeAndSocioEconomicIndex = (grades: IGrade[]) => {
  let total = {
    grade: 0,
    socioEconomicReference: 0,
  };
  total = grades.reduce((a, c) => {
    a.grade += c.grade * 10;
    a.socioEconomicReference += c.socioEconomicIndex * 10;
    return a;
  }, total);

  const averageGrade = Number((total.grade / (10 * grades.length)).toFixed(1));
  const averageSocioEconomicReference = Number(
    (total.socioEconomicReference / (10 * grades.length)).toFixed(1)
  );

  const average = {
    grade: averageGrade,
    socioEconomicIndex: averageSocioEconomicReference,
    effect: Number((averageGrade - averageSocioEconomicReference).toFixed(1)),
  };

  return average;
};
