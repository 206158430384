import React from "react";
import classNames from "classnames";
import injectSheet, { StyledComponentProps } from "react-jss";
import { useGradesContext } from "../../contexts/GradesContext";

export const schoolYears = [
  "2009/2010",
  "2010/2011",
  "2011/2012",
  "2012/2013",
  "2013/2014",
  "2014/2015",
  "2015/2016",
  "2016/2017",
  "2017/2018",
  "2018/2019",
];

interface ISelectedRange {
  start: number;
  end: number;
}

const YearSelector: React.FC<StyledComponentProps> = ({ classes = {} }) => {
  const { setSchoolYears } = useGradesContext();

  const [range, setRange] = React.useState<ISelectedRange>({
    start: schoolYears.length - 3,
    end: schoolYears.length - 1,
  });

  React.useEffect(() => {
    setSchoolYears(schoolYears.slice(range.start, range.end + 1));
  }, [range, setSchoolYears]);

  const yearClicked = (index: number) => {
    if (range.start === range.end) {
      if (index > range.start) {
        setRange({ ...range, end: index });
      }
      if (index < range.start) {
        setRange({ ...range, start: index });
      }
    } else {
      setRange({ start: index, end: index });
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.label}>Skoleår</div>
      <div className={classes.track}>
        {schoolYears.map((year, index) => {
          const selected = index >= range.start && index <= range.end;
          return (
            <div
              key={index}
              className={classNames({
                [classes.selected as string]: selected,
                [classes.first as string]: index === range.start,
                [classes.last as string]: index === range.end,
              })}
              onClick={() => yearClicked(index)}
            >
              {year}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    padding: 20,
  },
  label: {
    color: "#d3d6de",
    fontSize: 14,
    fontWeight: 600,
    marginRight: 15,
    textTransform: "uppercase",
  },
  track: {
    display: "flex",
    backgroundColor: "#d3d6de",
    borderRadius: 20,
    padding: "2px 10px",
    color: "#282c34",
    "& > div": {
      flex: 1,
      padding: "6px",
      fontSize: 13,
      cursor: "pointer",
      transition: "0.1s",
      borderRight: "1px solid #aeb3c2",
      width: 60,
      textAlign: "center",
      "&:hover": {
        transform: "scale(1.15)",
        color: "black",
        backgroundColor: "white",
      },
      "&:last-child": {
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        borderRight: 0,
      },
      "&:first-child": {
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
      },
    },
  },
  selected: {
    backgroundColor: "#4289b3",
    color: "white",
    fontWeight: 600,
    boxShadow: "1px 1px 10px 0px #1e93d9",
  },
  first: {
    borderTopLeftRadius: 20,
    borderBottomLeftRadius: 20,
  },
  last: {
    borderTopRightRadius: 20,
    borderBottomRightRadius: 20,
  },
};

export default injectSheet(styles)(YearSelector);
