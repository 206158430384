import { ISchool } from "./model";

export const skoledata = [
  {
    name: " Byplanvejens Skole",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 6.2, socioEconomicIndex: 5.9 },
              { year: "2017/2018", grade: 6.5, socioEconomicIndex: 5.9 },
              { year: "2016/2017", grade: 5.4, socioEconomicIndex: 5.9 },
              { year: "2015/2016", grade: 6.4, socioEconomicIndex: 6.5 },
              { year: "2014/2015", grade: 6.2, socioEconomicIndex: 6.1 },
              { year: "2013/2014", grade: 7.6, socioEconomicIndex: 6.8 },
              { year: "2012/2013", grade: 6, socioEconomicIndex: 6.1 },
              { year: "2011/2012", grade: 7.8, socioEconomicIndex: 6.9 },
              { year: "2010/2011", grade: 6.4, socioEconomicIndex: 6 },
              { year: "2009/2010", grade: 5.6, socioEconomicIndex: 5.5 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 7.1, socioEconomicIndex: 7.6 },
              { year: "2017/2018", grade: 7.3, socioEconomicIndex: 7.5 },
              { year: "2016/2017", grade: 6.3, socioEconomicIndex: 7.3 },
              { year: "2015/2016", grade: 8.5, socioEconomicIndex: 8 },
              { year: "2014/2015", grade: 7.5, socioEconomicIndex: 7.5 },
              { year: "2013/2014", grade: 8, socioEconomicIndex: 7.8 },
              { year: "2012/2013", grade: 5.9, socioEconomicIndex: 6.7 },
              { year: "2011/2012", grade: 6.1, socioEconomicIndex: 6.7 },
              { year: "2010/2011", grade: 7.5, socioEconomicIndex: 7.3 },
              { year: "2009/2010", grade: 5.7, socioEconomicIndex: 6.3 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 7.3, socioEconomicIndex: 6.8 },
              { year: "2017/2018", grade: 7.5, socioEconomicIndex: 6.7 },
              { year: "2016/2017", grade: 6.6, socioEconomicIndex: 7 },
              { year: "2015/2016", grade: 7.4, socioEconomicIndex: 7 },
              { year: "2014/2015", grade: 6.6, socioEconomicIndex: 6.9 },
              { year: "2013/2014", grade: 7.2, socioEconomicIndex: 6.3 },
              { year: "2012/2013", grade: 5.7, socioEconomicIndex: 6 },
              { year: "2011/2012", grade: 6.9, socioEconomicIndex: 6.4 },
              { year: "2010/2011", grade: 6.1, socioEconomicIndex: 6.1 },
              { year: "2009/2010", grade: 6.3, socioEconomicIndex: 5.8 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 7, socioEconomicIndex: 6.6 },
              { year: "2017/2018", grade: 8.5, socioEconomicIndex: 6.5 },
              { year: "2016/2017", grade: 6.3, socioEconomicIndex: 6.4 },
              { year: "2015/2016", grade: 7.3, socioEconomicIndex: 6.6 },
              { year: "2014/2015", grade: 6.3, socioEconomicIndex: 6.3 },
              { year: "2013/2014", grade: 8, socioEconomicIndex: 7.1 },
              { year: "2012/2013", grade: 6.1, socioEconomicIndex: 6.2 },
              { year: "2011/2012", grade: 6.3, socioEconomicIndex: 6.2 },
              { year: "2010/2011", grade: 6.9, socioEconomicIndex: 6.4 },
              { year: "2009/2010", grade: 6.7, socioEconomicIndex: 6 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 6.2, socioEconomicIndex: 5.5 },
              { year: "2010/2011", grade: 6.6, socioEconomicIndex: 5.7 },
              { year: "2009/2010", grade: 5.7, socioEconomicIndex: 5.4 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.2, socioEconomicIndex: 8 },
              { year: "2017/2018", grade: 7.5, socioEconomicIndex: 7.6 },
              { year: "2016/2017", grade: 6.9, socioEconomicIndex: 7.6 },
              { year: "2015/2016", grade: 7.7, socioEconomicIndex: 7.6 },
              { year: "2014/2015", grade: 8.6, socioEconomicIndex: 8 },
              { year: "2013/2014", grade: 7.6, socioEconomicIndex: 7.6 },
              { year: "2012/2013", grade: 6.3, socioEconomicIndex: 6.8 },
              { year: "2011/2012", grade: 7.6, socioEconomicIndex: 7.4 },
              { year: "2010/2011", grade: 7.5, socioEconomicIndex: 7.3 },
              { year: "2009/2010", grade: 5.6, socioEconomicIndex: 6.1 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 7.4, socioEconomicIndex: 7.3 },
              { year: "2017/2018", grade: 9.5, socioEconomicIndex: 7.9 },
              { year: "2016/2017", grade: 7.1, socioEconomicIndex: 7.4 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 6.8, socioEconomicIndex: 6.7 },
              { year: "2017/2018", grade: 8.3, socioEconomicIndex: 6.8 },
              { year: "2016/2017", grade: 5.6, socioEconomicIndex: 6.3 },
              { year: "2015/2016", grade: 6.1, socioEconomicIndex: 6.4 },
              { year: "2014/2015", grade: 7.1, socioEconomicIndex: 6.7 },
              { year: "2013/2014", grade: 7.3, socioEconomicIndex: 6.3 },
              { year: "2012/2013", grade: 5.2, socioEconomicIndex: 5.5 },
              { year: "2011/2012", grade: 6.3, socioEconomicIndex: 6 },
              { year: "2010/2011", grade: 6.6, socioEconomicIndex: 6.1 },
              { year: "2009/2010", grade: 6.4, socioEconomicIndex: 6.1 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 6.1, socioEconomicIndex: 6.3 },
              { year: "2017/2018", grade: 8.3, socioEconomicIndex: 7.1 },
              { year: "2016/2017", grade: 5.9, socioEconomicIndex: 6.6 },
              { year: "2015/2016", grade: 6.1, socioEconomicIndex: 6.6 },
              { year: "2014/2015", grade: 6.7, socioEconomicIndex: 6.8 },
              { year: "2013/2014", grade: 7.4, socioEconomicIndex: 6.7 },
              { year: "2012/2013", grade: 6, socioEconomicIndex: 6.3 },
              { year: "2011/2012", grade: 7.9, socioEconomicIndex: 7.1 },
              { year: "2010/2011", grade: 7.2, socioEconomicIndex: 6.8 },
              { year: "2009/2010", grade: 7, socioEconomicIndex: 6.9 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 6.2, socioEconomicIndex: 6.5 },
              { year: "2014/2015", grade: 7.4, socioEconomicIndex: 6.8 },
              { year: "2013/2014", grade: 6.3, socioEconomicIndex: 6.4 },
              { year: "2012/2013", grade: 3.9, socioEconomicIndex: 5.4 },
              { year: "2011/2012", grade: 7.5, socioEconomicIndex: 6.7 },
              { year: "2010/2011", grade: 7, socioEconomicIndex: 6.5 },
              { year: "2009/2010", grade: 6.6, socioEconomicIndex: 5.9 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Farstrup Skole",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 5.3, socioEconomicIndex: 5.3 },
              { year: "2017/2018", grade: 5.7, socioEconomicIndex: 5.5 },
              { year: "2016/2017", grade: 5.5, socioEconomicIndex: 5.5 },
              { year: "2015/2016", grade: 7, socioEconomicIndex: 6.8 },
              { year: "2014/2015", grade: 4.5, socioEconomicIndex: 4.8 },
              { year: "2013/2014", grade: 5.8, socioEconomicIndex: 5.6 },
              { year: "2012/2013", grade: 4.4, socioEconomicIndex: 4.7 },
              { year: "2011/2012", grade: 5.7, socioEconomicIndex: 5.9 },
              { year: "2010/2011", grade: 5.7, socioEconomicIndex: 5.8 },
              { year: "2009/2010", grade: 5.2, socioEconomicIndex: 5.5 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 6.1, socioEconomicIndex: 6.8 },
              { year: "2017/2018", grade: 9.4, socioEconomicIndex: 8.6 },
              { year: "2016/2017", grade: 8.5, socioEconomicIndex: 8 },
              { year: "2015/2016", grade: 6.5, socioEconomicIndex: 7 },
              { year: "2014/2015", grade: 7, socioEconomicIndex: 7 },
              { year: "2013/2014", grade: 6.2, socioEconomicIndex: 6.3 },
              { year: "2012/2013", grade: 7.4, socioEconomicIndex: 7.1 },
              { year: "2011/2012", grade: 4.2, socioEconomicIndex: 5.1 },
              { year: "2010/2011", grade: 6, socioEconomicIndex: 6.5 },
              { year: "2009/2010", grade: 6.8, socioEconomicIndex: 6.9 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 6, socioEconomicIndex: 5.9 },
              { year: "2017/2018", grade: 5.4, socioEconomicIndex: 5.6 },
              { year: "2016/2017", grade: 6.3, socioEconomicIndex: 6.1 },
              { year: "2015/2016", grade: 7.8, socioEconomicIndex: 7.2 },
              { year: "2014/2015", grade: 5.8, socioEconomicIndex: 6 },
              { year: "2013/2014", grade: 4.1, socioEconomicIndex: 4.6 },
              { year: "2012/2013", grade: 5.2, socioEconomicIndex: 5.2 },
              { year: "2011/2012", grade: 5.3, socioEconomicIndex: 5.7 },
              { year: "2010/2011", grade: 5.9, socioEconomicIndex: 6 },
              { year: "2009/2010", grade: 4.7, socioEconomicIndex: 5.4 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 5.2, socioEconomicIndex: 5.7 },
              { year: "2017/2018", grade: 6.6, socioEconomicIndex: 6.1 },
              { year: "2016/2017", grade: 7, socioEconomicIndex: 6.1 },
              { year: "2015/2016", grade: 8.8, socioEconomicIndex: 7.5 },
              { year: "2014/2015", grade: 6.5, socioEconomicIndex: 6.2 },
              { year: "2013/2014", grade: 4.8, socioEconomicIndex: 5.2 },
              { year: "2012/2013", grade: 6.3, socioEconomicIndex: 5.9 },
              { year: "2011/2012", grade: 4.5, socioEconomicIndex: 5.2 },
              { year: "2010/2011", grade: 5.1, socioEconomicIndex: 5.7 },
              { year: "2009/2010", grade: 6.4, socioEconomicIndex: 6.2 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 4.8, socioEconomicIndex: 5.3 },
              { year: "2010/2011", grade: 5.5, socioEconomicIndex: 5.5 },
              { year: "2009/2010", grade: 6.7, socioEconomicIndex: 5.7 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 6.9, socioEconomicIndex: 7.1 },
              { year: "2017/2018", grade: 6.6, socioEconomicIndex: 6.7 },
              { year: "2016/2017", grade: 8.8, socioEconomicIndex: 8.4 },
              { year: "2015/2016", grade: 6.4, socioEconomicIndex: 6.7 },
              { year: "2014/2015", grade: 8.4, socioEconomicIndex: 8.2 },
              { year: "2013/2014", grade: 7.2, socioEconomicIndex: 7 },
              { year: "2012/2013", grade: 6.4, socioEconomicIndex: 6.5 },
              { year: "2011/2012", grade: 6.7, socioEconomicIndex: 6.8 },
              { year: "2010/2011", grade: 6.1, socioEconomicIndex: 6.5 },
              { year: "2009/2010", grade: 6.8, socioEconomicIndex: 6.8 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 4.7, socioEconomicIndex: 6.1 },
              { year: "2017/2018", grade: 6.8, socioEconomicIndex: 6.7 },
              { year: "2016/2017", grade: 7.1, socioEconomicIndex: 7.1 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 6.1, socioEconomicIndex: 6.4 },
              { year: "2017/2018", grade: 4.7, socioEconomicIndex: 5.5 },
              { year: "2016/2017", grade: 7.2, socioEconomicIndex: 6.4 },
              { year: "2015/2016", grade: 6.9, socioEconomicIndex: 6.6 },
              { year: "2014/2015", grade: 5.9, socioEconomicIndex: 6.2 },
              { year: "2013/2014", grade: 3.5, socioEconomicIndex: 4.4 },
              { year: "2012/2013", grade: 4.7, socioEconomicIndex: 4.9 },
              { year: "2011/2012", grade: 4.9, socioEconomicIndex: 5.4 },
              { year: "2010/2011", grade: 6.5, socioEconomicIndex: 6 },
              { year: "2009/2010", grade: 5.3, socioEconomicIndex: 6 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 6.1, socioEconomicIndex: 6.3 },
              { year: "2017/2018", grade: 4.9, socioEconomicIndex: 5.4 },
              { year: "2016/2017", grade: 7.3, socioEconomicIndex: 6.9 },
              { year: "2015/2016", grade: 7.3, socioEconomicIndex: 7 },
              { year: "2014/2015", grade: 7.1, socioEconomicIndex: 7 },
              { year: "2013/2014", grade: 5.5, socioEconomicIndex: 5.6 },
              { year: "2012/2013", grade: 5.8, socioEconomicIndex: 5.9 },
              { year: "2011/2012", grade: 5.5, socioEconomicIndex: 5.9 },
              { year: "2010/2011", grade: 7.7, socioEconomicIndex: 6.9 },
              { year: "2009/2010", grade: 6.7, socioEconomicIndex: 7 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 6.9, socioEconomicIndex: 6.8 },
              { year: "2014/2015", grade: 7.8, socioEconomicIndex: 7.2 },
              { year: "2013/2014", grade: 4.8, socioEconomicIndex: 5.2 },
              { year: "2012/2013", grade: 6.6, socioEconomicIndex: 6.1 },
              { year: "2011/2012", grade: 4.8, socioEconomicIndex: 5.2 },
              { year: "2010/2011", grade: 4.5, socioEconomicIndex: 5.1 },
              { year: "2009/2010", grade: 3.5, socioEconomicIndex: 4.5 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Ferslev Skole",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 6.4, socioEconomicIndex: 6.2 },
              { year: "2017/2018", grade: 5.7, socioEconomicIndex: 6 },
              { year: "2016/2017", grade: 7.7, socioEconomicIndex: 7.1 },
              { year: "2015/2016", grade: 6.6, socioEconomicIndex: 6.5 },
              { year: "2014/2015", grade: 6.3, socioEconomicIndex: 6 },
              { year: "2013/2014", grade: 7.5, socioEconomicIndex: 6.8 },
              { year: "2012/2013", grade: 7, socioEconomicIndex: 6.7 },
              { year: "2011/2012", grade: 6.9, socioEconomicIndex: 6.8 },
              { year: "2010/2011", grade: 6.7, socioEconomicIndex: 6.2 },
              { year: "2009/2010", grade: 6.6, socioEconomicIndex: 6.3 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.5, socioEconomicIndex: 8.1 },
              { year: "2017/2018", grade: 9.5, socioEconomicIndex: 9 },
              { year: "2016/2017", grade: 7.5, socioEconomicIndex: 7.8 },
              { year: "2015/2016", grade: 8.3, socioEconomicIndex: 7.9 },
              { year: "2014/2015", grade: 8.7, socioEconomicIndex: 8 },
              { year: "2013/2014", grade: 9.1, socioEconomicIndex: 8.2 },
              { year: "2012/2013", grade: 8.2, socioEconomicIndex: 7.9 },
              { year: "2011/2012", grade: 7.6, socioEconomicIndex: 7.6 },
              { year: "2010/2011", grade: 8.1, socioEconomicIndex: 7.5 },
              { year: "2009/2010", grade: 6.7, socioEconomicIndex: 6.8 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 7.6, socioEconomicIndex: 6.9 },
              { year: "2017/2018", grade: 8.1, socioEconomicIndex: 7.4 },
              { year: "2016/2017", grade: 9.1, socioEconomicIndex: 7.6 },
              { year: "2015/2016", grade: 7.6, socioEconomicIndex: 6.9 },
              { year: "2014/2015", grade: 6.8, socioEconomicIndex: 6.5 },
              { year: "2013/2014", grade: 6.7, socioEconomicIndex: 6.2 },
              { year: "2012/2013", grade: 8.1, socioEconomicIndex: 7 },
              { year: "2011/2012", grade: 8, socioEconomicIndex: 7 },
              { year: "2010/2011", grade: 7.5, socioEconomicIndex: 6.5 },
              { year: "2009/2010", grade: 5.6, socioEconomicIndex: 5.8 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 6.5, socioEconomicIndex: 6.4 },
              { year: "2017/2018", grade: 6.9, socioEconomicIndex: 6.7 },
              { year: "2016/2017", grade: 7.2, socioEconomicIndex: 6.7 },
              { year: "2015/2016", grade: 7, socioEconomicIndex: 6.4 },
              { year: "2014/2015", grade: 7.5, socioEconomicIndex: 6.6 },
              { year: "2013/2014", grade: 6.5, socioEconomicIndex: 6.5 },
              { year: "2012/2013", grade: 6.6, socioEconomicIndex: 6.5 },
              { year: "2011/2012", grade: 5.7, socioEconomicIndex: 6.2 },
              { year: "2010/2011", grade: 6.4, socioEconomicIndex: 6.3 },
              { year: "2009/2010", grade: 5.9, socioEconomicIndex: 5.8 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 5.3, socioEconomicIndex: 5.8 },
              { year: "2010/2011", grade: 6, socioEconomicIndex: 5.6 },
              { year: "2009/2010", grade: 5.4, socioEconomicIndex: 5.3 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 6.8, socioEconomicIndex: 7.2 },
              { year: "2017/2018", grade: 7.6, socioEconomicIndex: 7.7 },
              { year: "2016/2017", grade: 8, socioEconomicIndex: 7.9 },
              { year: "2015/2016", grade: 8.3, socioEconomicIndex: 8 },
              { year: "2014/2015", grade: 6.9, socioEconomicIndex: 7 },
              { year: "2013/2014", grade: 8.8, socioEconomicIndex: 8.3 },
              { year: "2012/2013", grade: 8.7, socioEconomicIndex: 7.9 },
              { year: "2011/2012", grade: 7.1, socioEconomicIndex: 7.2 },
              { year: "2010/2011", grade: 6.8, socioEconomicIndex: 6.8 },
              { year: "2009/2010", grade: 6.9, socioEconomicIndex: 6.9 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 8, socioEconomicIndex: 7.6 },
              { year: "2017/2018", grade: 8.3, socioEconomicIndex: 8 },
              { year: "2016/2017", grade: 9.2, socioEconomicIndex: 8 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 7.9, socioEconomicIndex: 7.3 },
              { year: "2017/2018", grade: 6.9, socioEconomicIndex: 7.1 },
              { year: "2016/2017", grade: 7.2, socioEconomicIndex: 6.9 },
              { year: "2015/2016", grade: 6.1, socioEconomicIndex: 6.3 },
              { year: "2014/2015", grade: 7.8, socioEconomicIndex: 6.9 },
              { year: "2013/2014", grade: 5.9, socioEconomicIndex: 6.3 },
              { year: "2012/2013", grade: 6.6, socioEconomicIndex: 6.4 },
              { year: "2011/2012", grade: 6.4, socioEconomicIndex: 6.4 },
              { year: "2010/2011", grade: 5.1, socioEconomicIndex: 5.7 },
              { year: "2009/2010", grade: 6.1, socioEconomicIndex: 6.9 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 7.2, socioEconomicIndex: 7 },
              { year: "2017/2018", grade: 7.1, socioEconomicIndex: 7.1 },
              { year: "2016/2017", grade: 8, socioEconomicIndex: 7.4 },
              { year: "2015/2016", grade: 7.1, socioEconomicIndex: 6.9 },
              { year: "2014/2015", grade: 7.2, socioEconomicIndex: 6.9 },
              { year: "2013/2014", grade: 7.7, socioEconomicIndex: 7.2 },
              { year: "2012/2013", grade: 7.5, socioEconomicIndex: 7.2 },
              { year: "2011/2012", grade: 6.8, socioEconomicIndex: 7 },
              { year: "2010/2011", grade: 6.3, socioEconomicIndex: 6.4 },
              { year: "2009/2010", grade: 7.2, socioEconomicIndex: 7.7 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 7.3, socioEconomicIndex: 6.9 },
              { year: "2014/2015", grade: 7, socioEconomicIndex: 6.6 },
              { year: "2013/2014", grade: 5.3, socioEconomicIndex: 6.2 },
              { year: "2012/2013", grade: 5.3, socioEconomicIndex: 6.1 },
              { year: "2011/2012", grade: 5.6, socioEconomicIndex: 6.1 },
              { year: "2010/2011", grade: 4.2, socioEconomicIndex: 5.3 },
              { year: "2009/2010", grade: 4.7, socioEconomicIndex: 5.4 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Filstedvejens Skole",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 4.9, socioEconomicIndex: 5.3 },
              { year: "2017/2018", grade: 4.6, socioEconomicIndex: 5 },
              { year: "2016/2017", grade: 5.4, socioEconomicIndex: 5.8 },
              { year: "2015/2016", grade: 5.8, socioEconomicIndex: 6.1 },
              { year: "2014/2015", grade: 6.4, socioEconomicIndex: 6.2 },
              { year: "2013/2014", grade: 5.5, socioEconomicIndex: 5.7 },
              { year: "2012/2013", grade: 7.1, socioEconomicIndex: 6.7 },
              { year: "2011/2012", grade: 6.7, socioEconomicIndex: 6.3 },
              { year: "2010/2011", grade: 6, socioEconomicIndex: 5.9 },
              { year: "2009/2010", grade: 6.6, socioEconomicIndex: 6.1 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 6.6, socioEconomicIndex: 7.3 },
              { year: "2017/2018", grade: 7.3, socioEconomicIndex: 7.5 },
              { year: "2016/2017", grade: 7.2, socioEconomicIndex: 7.6 },
              { year: "2015/2016", grade: 7.7, socioEconomicIndex: 7.3 },
              { year: "2014/2015", grade: 8.5, socioEconomicIndex: 7.9 },
              { year: "2013/2014", grade: 7.7, socioEconomicIndex: 7.4 },
              { year: "2012/2013", grade: 7.9, socioEconomicIndex: 7.6 },
              { year: "2011/2012", grade: 7.6, socioEconomicIndex: 7.4 },
              { year: "2010/2011", grade: 5.7, socioEconomicIndex: 6.8 },
              { year: "2009/2010", grade: 8.6, socioEconomicIndex: 7.4 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 5.4, socioEconomicIndex: 6.2 },
              { year: "2017/2018", grade: 6.1, socioEconomicIndex: 6.1 },
              { year: "2016/2017", grade: 6.6, socioEconomicIndex: 6.6 },
              { year: "2015/2016", grade: 6.2, socioEconomicIndex: 6.4 },
              { year: "2014/2015", grade: 6.8, socioEconomicIndex: 6.9 },
              { year: "2013/2014", grade: 5.6, socioEconomicIndex: 5.6 },
              { year: "2012/2013", grade: 6.9, socioEconomicIndex: 6.6 },
              { year: "2011/2012", grade: 6.5, socioEconomicIndex: 6.2 },
              { year: "2010/2011", grade: 5.7, socioEconomicIndex: 6 },
              { year: "2009/2010", grade: 6.5, socioEconomicIndex: 5.9 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 6, socioEconomicIndex: 6.1 },
              { year: "2017/2018", grade: 4.6, socioEconomicIndex: 5.7 },
              { year: "2016/2017", grade: 6.6, socioEconomicIndex: 6.3 },
              { year: "2015/2016", grade: 5.4, socioEconomicIndex: 5.9 },
              { year: "2014/2015", grade: 6.5, socioEconomicIndex: 6.4 },
              { year: "2013/2014", grade: 5.1, socioEconomicIndex: 5.9 },
              { year: "2012/2013", grade: 7.6, socioEconomicIndex: 6.6 },
              { year: "2011/2012", grade: 5.5, socioEconomicIndex: 6 },
              { year: "2010/2011", grade: 5.6, socioEconomicIndex: 6 },
              { year: "2009/2010", grade: 5.6, socioEconomicIndex: 5.6 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 5.2, socioEconomicIndex: 5.7 },
              { year: "2010/2011", grade: 4.9, socioEconomicIndex: 5.4 },
              { year: "2009/2010", grade: 5.5, socioEconomicIndex: 5.3 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 7.2, socioEconomicIndex: 7.6 },
              { year: "2017/2018", grade: 7.7, socioEconomicIndex: 7.8 },
              { year: "2016/2017", grade: 6.7, socioEconomicIndex: 7.4 },
              { year: "2015/2016", grade: 7.5, socioEconomicIndex: 7.5 },
              { year: "2014/2015", grade: 8.2, socioEconomicIndex: 7.8 },
              { year: "2013/2014", grade: 7.9, socioEconomicIndex: 7.4 },
              { year: "2012/2013", grade: 8.4, socioEconomicIndex: 7.9 },
              { year: "2011/2012", grade: 5.7, socioEconomicIndex: 6.7 },
              { year: "2010/2011", grade: 6.3, socioEconomicIndex: 6.9 },
              { year: "2009/2010", grade: 6.2, socioEconomicIndex: 6.5 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 5.1, socioEconomicIndex: 6.5 },
              { year: "2017/2018", grade: 4.8, socioEconomicIndex: 6.5 },
              { year: "2016/2017", grade: 6.8, socioEconomicIndex: 7.1 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 6.1, socioEconomicIndex: 6.5 },
              { year: "2017/2018", grade: 5.6, socioEconomicIndex: 6.2 },
              { year: "2016/2017", grade: 5.9, socioEconomicIndex: 5.9 },
              { year: "2015/2016", grade: 5.7, socioEconomicIndex: 5.9 },
              { year: "2014/2015", grade: 6.3, socioEconomicIndex: 6.5 },
              { year: "2013/2014", grade: 5.7, socioEconomicIndex: 5.6 },
              { year: "2012/2013", grade: 6.5, socioEconomicIndex: 6.4 },
              { year: "2011/2012", grade: 6.5, socioEconomicIndex: 6 },
              { year: "2010/2011", grade: 6.5, socioEconomicIndex: 5.9 },
              { year: "2009/2010", grade: 7, socioEconomicIndex: 6.6 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 6, socioEconomicIndex: 6.4 },
              { year: "2017/2018", grade: 5.7, socioEconomicIndex: 6.2 },
              { year: "2016/2017", grade: 6.2, socioEconomicIndex: 6.4 },
              { year: "2015/2016", grade: 5.2, socioEconomicIndex: 6.1 },
              { year: "2014/2015", grade: 5.8, socioEconomicIndex: 6.6 },
              { year: "2013/2014", grade: 5.3, socioEconomicIndex: 5.9 },
              { year: "2012/2013", grade: 6.7, socioEconomicIndex: 7.2 },
              { year: "2011/2012", grade: 7.3, socioEconomicIndex: 6.8 },
              { year: "2010/2011", grade: 6.3, socioEconomicIndex: 6.5 },
              { year: "2009/2010", grade: 7.7, socioEconomicIndex: 7.5 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 6.1, socioEconomicIndex: 6.1 },
              { year: "2014/2015", grade: 6.9, socioEconomicIndex: 6.6 },
              { year: "2013/2014", grade: 5.2, socioEconomicIndex: 5.8 },
              { year: "2012/2013", grade: 6.7, socioEconomicIndex: 6.4 },
              { year: "2011/2012", grade: 7.2, socioEconomicIndex: 6.1 },
              { year: "2010/2011", grade: 5.9, socioEconomicIndex: 6 },
              { year: "2009/2010", grade: 6.5, socioEconomicIndex: 5.9 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Frejlev Skole",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 6.1, socioEconomicIndex: 6.5 },
              { year: "2017/2018", grade: 7, socioEconomicIndex: 6.9 },
              { year: "2016/2017", grade: 6.3, socioEconomicIndex: 6.6 },
              { year: "2015/2016", grade: 7.7, socioEconomicIndex: 7.1 },
              { year: "2014/2015", grade: 6.6, socioEconomicIndex: 6.9 },
              { year: "2013/2014", grade: 6.9, socioEconomicIndex: 6.7 },
              { year: "2012/2013", grade: 7, socioEconomicIndex: 6.8 },
              { year: "2011/2012", grade: 6.5, socioEconomicIndex: 6.4 },
              { year: "2010/2011", grade: 6.5, socioEconomicIndex: 6.5 },
              { year: "2009/2010", grade: 6.8, socioEconomicIndex: 6.6 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.4, socioEconomicIndex: 8.4 },
              { year: "2017/2018", grade: 7.5, socioEconomicIndex: 8.3 },
              { year: "2016/2017", grade: 9.6, socioEconomicIndex: 8.6 },
              { year: "2015/2016", grade: 7.5, socioEconomicIndex: 7.8 },
              { year: "2014/2015", grade: 8.5, socioEconomicIndex: 8.4 },
              { year: "2013/2014", grade: 7.8, socioEconomicIndex: 7.9 },
              { year: "2012/2013", grade: 6.6, socioEconomicIndex: 7.4 },
              { year: "2011/2012", grade: 6.8, socioEconomicIndex: 7.2 },
              { year: "2010/2011", grade: 8.4, socioEconomicIndex: 7.8 },
              { year: "2009/2010", grade: 6.5, socioEconomicIndex: 7.2 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 7.3, socioEconomicIndex: 7.4 },
              { year: "2017/2018", grade: 8.1, socioEconomicIndex: 7.6 },
              { year: "2016/2017", grade: 6.9, socioEconomicIndex: 7.1 },
              { year: "2015/2016", grade: 7.6, socioEconomicIndex: 7.1 },
              { year: "2014/2015", grade: 7.3, socioEconomicIndex: 7.7 },
              { year: "2013/2014", grade: 7.6, socioEconomicIndex: 6.7 },
              { year: "2012/2013", grade: 7.4, socioEconomicIndex: 7 },
              { year: "2011/2012", grade: 6.7, socioEconomicIndex: 6.3 },
              { year: "2010/2011", grade: 6.7, socioEconomicIndex: 6.6 },
              { year: "2009/2010", grade: 7.1, socioEconomicIndex: 6.6 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 7.6, socioEconomicIndex: 7 },
              { year: "2017/2018", grade: 6.3, socioEconomicIndex: 6.8 },
              { year: "2016/2017", grade: 8.9, socioEconomicIndex: 6.9 },
              { year: "2015/2016", grade: 7.2, socioEconomicIndex: 6.6 },
              { year: "2014/2015", grade: 6.5, socioEconomicIndex: 6.8 },
              { year: "2013/2014", grade: 8.7, socioEconomicIndex: 7.3 },
              { year: "2012/2013", grade: 6.2, socioEconomicIndex: 6.5 },
              { year: "2011/2012", grade: 5.4, socioEconomicIndex: 6 },
              { year: "2010/2011", grade: 6.6, socioEconomicIndex: 6.6 },
              { year: "2009/2010", grade: 6.4, socioEconomicIndex: 6.4 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 4.1, socioEconomicIndex: 5.4 },
              { year: "2010/2011", grade: 6.3, socioEconomicIndex: 5.9 },
              { year: "2009/2010", grade: 5.9, socioEconomicIndex: 5.7 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.1, socioEconomicIndex: 8.4 },
              { year: "2017/2018", grade: 7.9, socioEconomicIndex: 8.3 },
              { year: "2016/2017", grade: 7.6, socioEconomicIndex: 7.9 },
              { year: "2015/2016", grade: 8.3, socioEconomicIndex: 8.1 },
              { year: "2014/2015", grade: 7.9, socioEconomicIndex: 8.2 },
              { year: "2013/2014", grade: 8.2, socioEconomicIndex: 8.1 },
              { year: "2012/2013", grade: 7.5, socioEconomicIndex: 7.7 },
              { year: "2011/2012", grade: 7.6, socioEconomicIndex: 7.3 },
              { year: "2010/2011", grade: 7.5, socioEconomicIndex: 7.5 },
              { year: "2009/2010", grade: 7.9, socioEconomicIndex: 7.7 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 6.9, socioEconomicIndex: 7.8 },
              { year: "2017/2018", grade: 7.2, socioEconomicIndex: 7.9 },
              { year: "2016/2017", grade: 6.2, socioEconomicIndex: 7.3 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 8.5, socioEconomicIndex: 8.1 },
              { year: "2017/2018", grade: 9, socioEconomicIndex: 8.1 },
              { year: "2016/2017", grade: 8.3, socioEconomicIndex: 7.6 },
              { year: "2015/2016", grade: 7.4, socioEconomicIndex: 7.1 },
              { year: "2014/2015", grade: 8.4, socioEconomicIndex: 7.8 },
              { year: "2013/2014", grade: 7.8, socioEconomicIndex: 7 },
              { year: "2012/2013", grade: 7, socioEconomicIndex: 6.8 },
              { year: "2011/2012", grade: 7.4, socioEconomicIndex: 6.5 },
              { year: "2010/2011", grade: 7.8, socioEconomicIndex: 6.6 },
              { year: "2009/2010", grade: 7.9, socioEconomicIndex: 7.4 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 8.2, socioEconomicIndex: 8 },
              { year: "2017/2018", grade: 8.9, socioEconomicIndex: 8.1 },
              { year: "2016/2017", grade: 8.4, socioEconomicIndex: 7.9 },
              { year: "2015/2016", grade: 7.9, socioEconomicIndex: 7.5 },
              { year: "2014/2015", grade: 8.8, socioEconomicIndex: 8.1 },
              { year: "2013/2014", grade: 8.8, socioEconomicIndex: 7.7 },
              { year: "2012/2013", grade: 7.9, socioEconomicIndex: 7.7 },
              { year: "2011/2012", grade: 7.5, socioEconomicIndex: 7.2 },
              { year: "2010/2011", grade: 7.4, socioEconomicIndex: 7.2 },
              { year: "2009/2010", grade: 8.5, socioEconomicIndex: 8 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 6.5, socioEconomicIndex: 6.9 },
              { year: "2014/2015", grade: 7.5, socioEconomicIndex: 7.3 },
              { year: "2013/2014", grade: 8, socioEconomicIndex: 7.1 },
              { year: "2012/2013", grade: 5.7, socioEconomicIndex: 6.4 },
              { year: "2011/2012", grade: 6.6, socioEconomicIndex: 6.3 },
              { year: "2010/2011", grade: 7.8, socioEconomicIndex: 6.7 },
              { year: "2009/2010", grade: 7.3, socioEconomicIndex: 6.5 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Gandrup Skole",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 6.8, socioEconomicIndex: 6.2 },
              { year: "2017/2018", grade: 6.7, socioEconomicIndex: 6.1 },
              { year: "2016/2017", grade: 5.2, socioEconomicIndex: 5.9 },
              { year: "2015/2016", grade: 7.2, socioEconomicIndex: 6.6 },
              { year: "2014/2015", grade: 6.3, socioEconomicIndex: 6.2 },
              { year: "2013/2014", grade: 6.5, socioEconomicIndex: 6 },
              { year: "2012/2013", grade: 6.6, socioEconomicIndex: 6.4 },
              { year: "2011/2012", grade: 5.1, socioEconomicIndex: 5.8 },
              { year: "2010/2011", grade: 4.8, socioEconomicIndex: 5.3 },
              { year: "2009/2010", grade: 5.8, socioEconomicIndex: 5.9 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 7.2, socioEconomicIndex: 7.3 },
              { year: "2017/2018", grade: 7.5, socioEconomicIndex: 7.6 },
              { year: "2016/2017", grade: 7.5, socioEconomicIndex: 7.9 },
              { year: "2015/2016", grade: 7, socioEconomicIndex: 7.1 },
              { year: "2014/2015", grade: 8.3, socioEconomicIndex: 8 },
              { year: "2013/2014", grade: 7.4, socioEconomicIndex: 7.2 },
              { year: "2012/2013", grade: 8, socioEconomicIndex: 7.5 },
              { year: "2011/2012", grade: 6.5, socioEconomicIndex: 6.8 },
              { year: "2010/2011", grade: 6.8, socioEconomicIndex: 6.8 },
              { year: "2009/2010", grade: 6.1, socioEconomicIndex: 6.5 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 7.1, socioEconomicIndex: 6.5 },
              { year: "2017/2018", grade: 7.3, socioEconomicIndex: 6.6 },
              { year: "2016/2017", grade: 6.7, socioEconomicIndex: 6.9 },
              { year: "2015/2016", grade: 7.4, socioEconomicIndex: 6.5 },
              { year: "2014/2015", grade: 7.3, socioEconomicIndex: 6.8 },
              { year: "2013/2014", grade: 5.2, socioEconomicIndex: 5.3 },
              { year: "2012/2013", grade: 6.4, socioEconomicIndex: 6.2 },
              { year: "2011/2012", grade: 4.5, socioEconomicIndex: 5.8 },
              { year: "2010/2011", grade: 5, socioEconomicIndex: 5.6 },
              { year: "2009/2010", grade: 5.9, socioEconomicIndex: 6 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 5.8, socioEconomicIndex: 6 },
              { year: "2017/2018", grade: 6.9, socioEconomicIndex: 6.3 },
              { year: "2016/2017", grade: 4.1, socioEconomicIndex: 6.1 },
              { year: "2015/2016", grade: 7.1, socioEconomicIndex: 6.1 },
              { year: "2014/2015", grade: 6.2, socioEconomicIndex: 6.3 },
              { year: "2013/2014", grade: 6, socioEconomicIndex: 5.9 },
              { year: "2012/2013", grade: 6.4, socioEconomicIndex: 6.2 },
              { year: "2011/2012", grade: 4.6, socioEconomicIndex: 5.6 },
              { year: "2010/2011", grade: 5.1, socioEconomicIndex: 5.6 },
              { year: "2009/2010", grade: 5.3, socioEconomicIndex: 5.6 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 5.2, socioEconomicIndex: 5.5 },
              { year: "2010/2011", grade: 4.8, socioEconomicIndex: 5.3 },
              { year: "2009/2010", grade: 4.5, socioEconomicIndex: 5.3 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.6, socioEconomicIndex: 8 },
              { year: "2017/2018", grade: 7, socioEconomicIndex: 7.2 },
              { year: "2016/2017", grade: 6.3, socioEconomicIndex: 7 },
              { year: "2015/2016", grade: 8.2, socioEconomicIndex: 7.7 },
              { year: "2014/2015", grade: 8.4, socioEconomicIndex: 7.8 },
              { year: "2013/2014", grade: 7.5, socioEconomicIndex: 7.2 },
              { year: "2012/2013", grade: 7.9, socioEconomicIndex: 7.7 },
              { year: "2011/2012", grade: 6.3, socioEconomicIndex: 6.6 },
              { year: "2010/2011", grade: 5.7, socioEconomicIndex: 6.2 },
              { year: "2009/2010", grade: 6.4, socioEconomicIndex: 6.6 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.2, socioEconomicIndex: 7.4 },
              { year: "2017/2018", grade: 7.2, socioEconomicIndex: 7.1 },
              { year: "2016/2017", grade: 6.8, socioEconomicIndex: 7.2 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 6.2, socioEconomicIndex: 6.7 },
              { year: "2017/2018", grade: 6.6, socioEconomicIndex: 6.7 },
              { year: "2016/2017", grade: 4.4, socioEconomicIndex: 6 },
              { year: "2015/2016", grade: 6.8, socioEconomicIndex: 6.4 },
              { year: "2014/2015", grade: 6.7, socioEconomicIndex: 6.6 },
              { year: "2013/2014", grade: 5.5, socioEconomicIndex: 5.6 },
              { year: "2012/2013", grade: 4.8, socioEconomicIndex: 5.5 },
              { year: "2011/2012", grade: 5.5, socioEconomicIndex: 5.9 },
              { year: "2010/2011", grade: 5.7, socioEconomicIndex: 5.5 },
              { year: "2009/2010", grade: 6.4, socioEconomicIndex: 6.6 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 7.1, socioEconomicIndex: 6.9 },
              { year: "2017/2018", grade: 7.3, socioEconomicIndex: 6.7 },
              { year: "2016/2017", grade: 5.7, socioEconomicIndex: 6.3 },
              { year: "2015/2016", grade: 7.1, socioEconomicIndex: 6.8 },
              { year: "2014/2015", grade: 7.3, socioEconomicIndex: 6.9 },
              { year: "2013/2014", grade: 5.8, socioEconomicIndex: 6 },
              { year: "2012/2013", grade: 6.2, socioEconomicIndex: 6.6 },
              { year: "2011/2012", grade: 6.3, socioEconomicIndex: 6.7 },
              { year: "2010/2011", grade: 5.4, socioEconomicIndex: 5.9 },
              { year: "2009/2010", grade: 8.6, socioEconomicIndex: 7.8 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 7, socioEconomicIndex: 6.6 },
              { year: "2014/2015", grade: 7.4, socioEconomicIndex: 6.8 },
              { year: "2013/2014", grade: 6.5, socioEconomicIndex: 6.2 },
              { year: "2012/2013", grade: 7.2, socioEconomicIndex: 6.6 },
              { year: "2011/2012", grade: 7.7, socioEconomicIndex: 6.6 },
              { year: "2010/2011", grade: 5.8, socioEconomicIndex: 5.6 },
              { year: "2009/2010", grade: 6.5, socioEconomicIndex: 5.9 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Gistrup Skole",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 7.2, socioEconomicIndex: 6.9 },
              { year: "2017/2018", grade: 6, socioEconomicIndex: 6.4 },
              { year: "2016/2017", grade: 7, socioEconomicIndex: 7 },
              { year: "2015/2016", grade: 7.5, socioEconomicIndex: 7.4 },
              { year: "2014/2015", grade: 7.7, socioEconomicIndex: 7.3 },
              { year: "2013/2014", grade: 7.5, socioEconomicIndex: 7.2 },
              { year: "2012/2013", grade: 8.1, socioEconomicIndex: 7.4 },
              { year: "2011/2012", grade: 7.9, socioEconomicIndex: 7.4 },
              { year: "2010/2011", grade: 6.7, socioEconomicIndex: 6.5 },
              { year: "2009/2010", grade: 6.2, socioEconomicIndex: 6.5 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 9.6, socioEconomicIndex: 8.8 },
              { year: "2017/2018", grade: 8.1, socioEconomicIndex: 8.2 },
              { year: "2016/2017", grade: 9.5, socioEconomicIndex: 8.7 },
              { year: "2015/2016", grade: 8.9, socioEconomicIndex: 8.6 },
              { year: "2014/2015", grade: 8.4, socioEconomicIndex: 8.5 },
              { year: "2013/2014", grade: 7.9, socioEconomicIndex: 8.2 },
              { year: "2012/2013", grade: 8.2, socioEconomicIndex: 8 },
              { year: "2011/2012", grade: 8.2, socioEconomicIndex: 8.1 },
              { year: "2010/2011", grade: 7.6, socioEconomicIndex: 7.6 },
              { year: "2009/2010", grade: 7, socioEconomicIndex: 7.5 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 7.6, socioEconomicIndex: 7.5 },
              { year: "2017/2018", grade: 7.3, socioEconomicIndex: 7.2 },
              { year: "2016/2017", grade: 8, socioEconomicIndex: 7.8 },
              { year: "2015/2016", grade: 8.4, socioEconomicIndex: 7.9 },
              { year: "2014/2015", grade: 8.9, socioEconomicIndex: 8.2 },
              { year: "2013/2014", grade: 8.3, socioEconomicIndex: 7.2 },
              { year: "2012/2013", grade: 8.2, socioEconomicIndex: 7.3 },
              { year: "2011/2012", grade: 8.3, socioEconomicIndex: 7.5 },
              { year: "2010/2011", grade: 7.2, socioEconomicIndex: 6.6 },
              { year: "2009/2010", grade: 7.2, socioEconomicIndex: 6.7 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 8.5, socioEconomicIndex: 7.1 },
              { year: "2017/2018", grade: 5.4, socioEconomicIndex: 6.5 },
              { year: "2016/2017", grade: 6.1, socioEconomicIndex: 6.8 },
              { year: "2015/2016", grade: 7, socioEconomicIndex: 7.1 },
              { year: "2014/2015", grade: 6.8, socioEconomicIndex: 7.1 },
              { year: "2013/2014", grade: 7.4, socioEconomicIndex: 7.2 },
              { year: "2012/2013", grade: 7, socioEconomicIndex: 6.9 },
              { year: "2011/2012", grade: 6.5, socioEconomicIndex: 6.8 },
              { year: "2010/2011", grade: 7.2, socioEconomicIndex: 6.6 },
              { year: "2009/2010", grade: 6, socioEconomicIndex: 6.4 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 7.4, socioEconomicIndex: 6.3 },
              { year: "2010/2011", grade: 6.4, socioEconomicIndex: 5.8 },
              { year: "2009/2010", grade: 5.3, socioEconomicIndex: 5.7 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 9, socioEconomicIndex: 8.8 },
              { year: "2017/2018", grade: 8.5, socioEconomicIndex: 8.5 },
              { year: "2016/2017", grade: 8.6, socioEconomicIndex: 8.5 },
              { year: "2015/2016", grade: 8.3, socioEconomicIndex: 8.3 },
              { year: "2014/2015", grade: 9.1, socioEconomicIndex: 8.8 },
              { year: "2013/2014", grade: 8.3, socioEconomicIndex: 8.3 },
              { year: "2012/2013", grade: 7.9, socioEconomicIndex: 8 },
              { year: "2011/2012", grade: 8, socioEconomicIndex: 8 },
              { year: "2010/2011", grade: 7.3, socioEconomicIndex: 7.6 },
              { year: "2009/2010", grade: 7.1, socioEconomicIndex: 7.4 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 8, socioEconomicIndex: 8.1 },
              { year: "2017/2018", grade: 7.6, socioEconomicIndex: 7.7 },
              { year: "2016/2017", grade: 8.2, socioEconomicIndex: 8 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 7.7, socioEconomicIndex: 8 },
              { year: "2017/2018", grade: 6.7, socioEconomicIndex: 7.6 },
              { year: "2016/2017", grade: 8, socioEconomicIndex: 7.6 },
              { year: "2015/2016", grade: 7.6, socioEconomicIndex: 7.5 },
              { year: "2014/2015", grade: 8.8, socioEconomicIndex: 8 },
              { year: "2013/2014", grade: 7.3, socioEconomicIndex: 7.1 },
              { year: "2012/2013", grade: 7.4, socioEconomicIndex: 7.1 },
              { year: "2011/2012", grade: 7.4, socioEconomicIndex: 7.3 },
              { year: "2010/2011", grade: 7.6, socioEconomicIndex: 6.8 },
              { year: "2009/2010", grade: 6.2, socioEconomicIndex: 7.2 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 8.1, socioEconomicIndex: 7.9 },
              { year: "2017/2018", grade: 7.5, socioEconomicIndex: 7.6 },
              { year: "2016/2017", grade: 8, socioEconomicIndex: 7.8 },
              { year: "2015/2016", grade: 8, socioEconomicIndex: 7.8 },
              { year: "2014/2015", grade: 8.4, socioEconomicIndex: 8.1 },
              { year: "2013/2014", grade: 8.2, socioEconomicIndex: 7.8 },
              { year: "2012/2013", grade: 8.2, socioEconomicIndex: 8 },
              { year: "2011/2012", grade: 8.2, socioEconomicIndex: 8 },
              { year: "2010/2011", grade: 7.5, socioEconomicIndex: 7.4 },
              { year: "2009/2010", grade: 7.2, socioEconomicIndex: 7.9 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 6.8, socioEconomicIndex: 7.3 },
              { year: "2014/2015", grade: 8.4, socioEconomicIndex: 7.6 },
              { year: "2013/2014", grade: 6.4, socioEconomicIndex: 6.9 },
              { year: "2012/2013", grade: 7.1, socioEconomicIndex: 6.9 },
              { year: "2011/2012", grade: 7.5, socioEconomicIndex: 7 },
              { year: "2010/2011", grade: 6, socioEconomicIndex: 6.4 },
              { year: "2009/2010", grade: 6, socioEconomicIndex: 6.3 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Gl Hasseris Skole",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 6.4, socioEconomicIndex: 6.7 },
              { year: "2017/2018", grade: 7.1, socioEconomicIndex: 6.9 },
              { year: "2016/2017", grade: 6.7, socioEconomicIndex: 7.1 },
              { year: "2015/2016", grade: 7.5, socioEconomicIndex: 7.7 },
              { year: "2014/2015", grade: 7.3, socioEconomicIndex: 7.2 },
              { year: "2013/2014", grade: 6.4, socioEconomicIndex: 6.9 },
              { year: "2012/2013", grade: 7.4, socioEconomicIndex: 7.2 },
              { year: "2011/2012", grade: 7.3, socioEconomicIndex: 7.3 },
              { year: "2010/2011", grade: 6.7, socioEconomicIndex: 6.8 },
              { year: "2009/2010", grade: 6.8, socioEconomicIndex: 6.6 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.6, socioEconomicIndex: 8.7 },
              { year: "2017/2018", grade: 8.9, socioEconomicIndex: 8.7 },
              { year: "2016/2017", grade: 9.1, socioEconomicIndex: 8.8 },
              { year: "2015/2016", grade: 9.8, socioEconomicIndex: 9.1 },
              { year: "2014/2015", grade: 8.5, socioEconomicIndex: 8.5 },
              { year: "2013/2014", grade: 7.5, socioEconomicIndex: 8 },
              { year: "2012/2013", grade: 8.9, socioEconomicIndex: 8.3 },
              { year: "2011/2012", grade: 8.3, socioEconomicIndex: 8.2 },
              { year: "2010/2011", grade: 7, socioEconomicIndex: 7.8 },
              { year: "2009/2010", grade: 7, socioEconomicIndex: 7.4 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 7.8, socioEconomicIndex: 7.7 },
              { year: "2017/2018", grade: 7.5, socioEconomicIndex: 7.5 },
              { year: "2016/2017", grade: 8.1, socioEconomicIndex: 8 },
              { year: "2015/2016", grade: 8, socioEconomicIndex: 8 },
              { year: "2014/2015", grade: 7.2, socioEconomicIndex: 7.9 },
              { year: "2013/2014", grade: 6.3, socioEconomicIndex: 6.9 },
              { year: "2012/2013", grade: 7.5, socioEconomicIndex: 7.1 },
              { year: "2011/2012", grade: 7.4, socioEconomicIndex: 7.4 },
              { year: "2010/2011", grade: 7.9, socioEconomicIndex: 7.1 },
              { year: "2009/2010", grade: 7, socioEconomicIndex: 6.6 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 6.8, socioEconomicIndex: 7.1 },
              { year: "2017/2018", grade: 7.2, socioEconomicIndex: 6.9 },
              { year: "2016/2017", grade: 5.9, socioEconomicIndex: 7 },
              { year: "2015/2016", grade: 6.2, socioEconomicIndex: 7.1 },
              { year: "2014/2015", grade: 7.6, socioEconomicIndex: 7.3 },
              { year: "2013/2014", grade: 7.5, socioEconomicIndex: 7.2 },
              { year: "2012/2013", grade: 7.2, socioEconomicIndex: 6.9 },
              { year: "2011/2012", grade: 6.7, socioEconomicIndex: 6.9 },
              { year: "2010/2011", grade: 7, socioEconomicIndex: 7 },
              { year: "2009/2010", grade: 6.6, socioEconomicIndex: 6.5 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 6.9, socioEconomicIndex: 6.3 },
              { year: "2010/2011", grade: 5.8, socioEconomicIndex: 6 },
              { year: "2009/2010", grade: 5.5, socioEconomicIndex: 5.7 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 9.6, socioEconomicIndex: 9 },
              { year: "2017/2018", grade: 8.7, socioEconomicIndex: 8.7 },
              { year: "2016/2017", grade: 8.6, socioEconomicIndex: 8.7 },
              { year: "2015/2016", grade: 9.5, socioEconomicIndex: 8.9 },
              { year: "2014/2015", grade: 7.4, socioEconomicIndex: 8.1 },
              { year: "2013/2014", grade: 8.6, socioEconomicIndex: 8.6 },
              { year: "2012/2013", grade: 8.1, socioEconomicIndex: 8.1 },
              { year: "2011/2012", grade: 8.6, socioEconomicIndex: 8.3 },
              { year: "2010/2011", grade: 8.5, socioEconomicIndex: 8.2 },
              { year: "2009/2010", grade: 8.4, socioEconomicIndex: 7.9 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 7, socioEconomicIndex: 8.1 },
              { year: "2017/2018", grade: 8, socioEconomicIndex: 8.1 },
              { year: "2016/2017", grade: 9.3, socioEconomicIndex: 8.3 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 8.3, socioEconomicIndex: 7.9 },
              { year: "2017/2018", grade: 7.2, socioEconomicIndex: 7.9 },
              { year: "2016/2017", grade: 7.5, socioEconomicIndex: 7.7 },
              { year: "2015/2016", grade: 8.6, socioEconomicIndex: 7.9 },
              { year: "2014/2015", grade: 7.4, socioEconomicIndex: 7.6 },
              { year: "2013/2014", grade: 9, socioEconomicIndex: 7.7 },
              { year: "2012/2013", grade: 6.7, socioEconomicIndex: 6.8 },
              { year: "2011/2012", grade: 8.2, socioEconomicIndex: 7.4 },
              { year: "2010/2011", grade: 7.8, socioEconomicIndex: 7 },
              { year: "2009/2010", grade: 7, socioEconomicIndex: 7.4 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 8, socioEconomicIndex: 7.8 },
              { year: "2017/2018", grade: 7.7, socioEconomicIndex: 7.9 },
              { year: "2016/2017", grade: 8.2, socioEconomicIndex: 8 },
              { year: "2015/2016", grade: 8.6, socioEconomicIndex: 8.1 },
              { year: "2014/2015", grade: 8.1, socioEconomicIndex: 7.9 },
              { year: "2013/2014", grade: 7.9, socioEconomicIndex: 7.9 },
              { year: "2012/2013", grade: 8.4, socioEconomicIndex: 7.9 },
              { year: "2011/2012", grade: 8.7, socioEconomicIndex: 8.2 },
              { year: "2010/2011", grade: 8.2, socioEconomicIndex: 7.7 },
              { year: "2009/2010", grade: 8, socioEconomicIndex: 8.1 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 9.1, socioEconomicIndex: 7.9 },
              { year: "2014/2015", grade: 8.2, socioEconomicIndex: 7.5 },
              { year: "2013/2014", grade: 7.6, socioEconomicIndex: 7.4 },
              { year: "2012/2013", grade: 7.3, socioEconomicIndex: 7 },
              { year: "2011/2012", grade: 6.8, socioEconomicIndex: 7 },
              { year: "2010/2011", grade: 7.4, socioEconomicIndex: 7 },
              { year: "2009/2010", grade: 6.6, socioEconomicIndex: 6.5 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Gl Lindholm Skole",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 7.2, socioEconomicIndex: 6.3 },
              { year: "2017/2018", grade: 6.1, socioEconomicIndex: 5.4 },
              { year: "2016/2017", grade: 5.1, socioEconomicIndex: 5.6 },
              { year: "2015/2016", grade: 6.7, socioEconomicIndex: 6.4 },
              { year: "2014/2015", grade: 6.9, socioEconomicIndex: 6.4 },
              { year: "2013/2014", grade: 6.1, socioEconomicIndex: 5.8 },
              { year: "2012/2013", grade: 6.5, socioEconomicIndex: 6.2 },
              { year: "2011/2012", grade: 6.9, socioEconomicIndex: 6.1 },
              { year: "2010/2011", grade: 5.7, socioEconomicIndex: 5.4 },
              { year: "2009/2010", grade: 5.4, socioEconomicIndex: 5.5 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 9, socioEconomicIndex: 8.4 },
              { year: "2017/2018", grade: 7, socioEconomicIndex: 7.3 },
              { year: "2016/2017", grade: 6.9, socioEconomicIndex: 7.5 },
              { year: "2015/2016", grade: 7.6, socioEconomicIndex: 7.6 },
              { year: "2014/2015", grade: 7.1, socioEconomicIndex: 7.4 },
              { year: "2013/2014", grade: 7.1, socioEconomicIndex: 7.1 },
              { year: "2012/2013", grade: 6.7, socioEconomicIndex: 7 },
              { year: "2011/2012", grade: 6.7, socioEconomicIndex: 6.7 },
              { year: "2010/2011", grade: 5.9, socioEconomicIndex: 6.4 },
              { year: "2009/2010", grade: 8, socioEconomicIndex: 7.2 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 8.3, socioEconomicIndex: 6.9 },
              { year: "2017/2018", grade: 7.1, socioEconomicIndex: 6.2 },
              { year: "2016/2017", grade: 6.6, socioEconomicIndex: 6.7 },
              { year: "2015/2016", grade: 7.6, socioEconomicIndex: 6.9 },
              { year: "2014/2015", grade: 7.2, socioEconomicIndex: 7.2 },
              { year: "2013/2014", grade: 6.5, socioEconomicIndex: 5.5 },
              { year: "2012/2013", grade: 7.3, socioEconomicIndex: 6.3 },
              { year: "2011/2012", grade: 6.3, socioEconomicIndex: 5.7 },
              { year: "2010/2011", grade: 6.1, socioEconomicIndex: 5.7 },
              { year: "2009/2010", grade: 6, socioEconomicIndex: 5.8 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 8, socioEconomicIndex: 6.6 },
              { year: "2017/2018", grade: 7.7, socioEconomicIndex: 6 },
              { year: "2016/2017", grade: 7.1, socioEconomicIndex: 6.4 },
              { year: "2015/2016", grade: 6.6, socioEconomicIndex: 6.3 },
              { year: "2014/2015", grade: 7.5, socioEconomicIndex: 6.8 },
              { year: "2013/2014", grade: 6.3, socioEconomicIndex: 6 },
              { year: "2012/2013", grade: 7.5, socioEconomicIndex: 6.6 },
              { year: "2011/2012", grade: 7.1, socioEconomicIndex: 6 },
              { year: "2010/2011", grade: 7.6, socioEconomicIndex: 6.2 },
              { year: "2009/2010", grade: 7.5, socioEconomicIndex: 6.3 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 5, socioEconomicIndex: 5.1 },
              { year: "2010/2011", grade: 6.4, socioEconomicIndex: 5.5 },
              { year: "2009/2010", grade: 6.9, socioEconomicIndex: 5.4 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 7.8, socioEconomicIndex: 7.9 },
              { year: "2017/2018", grade: 7.7, socioEconomicIndex: 7.6 },
              { year: "2016/2017", grade: 7.4, socioEconomicIndex: 7.7 },
              { year: "2015/2016", grade: 6.7, socioEconomicIndex: 7.2 },
              { year: "2014/2015", grade: 8.8, socioEconomicIndex: 8.2 },
              { year: "2013/2014", grade: 7.3, socioEconomicIndex: 7.3 },
              { year: "2012/2013", grade: 8.5, socioEconomicIndex: 7.7 },
              { year: "2011/2012", grade: 8.3, socioEconomicIndex: 7.3 },
              { year: "2010/2011", grade: 7.2, socioEconomicIndex: 6.9 },
              { year: "2009/2010", grade: 7.4, socioEconomicIndex: 6.9 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 5.8, socioEconomicIndex: 6.9 },
              { year: "2017/2018", grade: 5.9, socioEconomicIndex: 6.6 },
              { year: "2016/2017", grade: 6.4, socioEconomicIndex: 7 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 7.5, socioEconomicIndex: 6.8 },
              { year: "2017/2018", grade: 7.1, socioEconomicIndex: 6.1 },
              { year: "2016/2017", grade: 7, socioEconomicIndex: 6.2 },
              { year: "2015/2016", grade: 6.5, socioEconomicIndex: 6.3 },
              { year: "2014/2015", grade: 6.8, socioEconomicIndex: 6.8 },
              { year: "2013/2014", grade: 6.1, socioEconomicIndex: 5.5 },
              { year: "2012/2013", grade: 7.6, socioEconomicIndex: 6.2 },
              { year: "2011/2012", grade: 6.3, socioEconomicIndex: 5.5 },
              { year: "2010/2011", grade: 5.2, socioEconomicIndex: 5.1 },
              { year: "2009/2010", grade: 6.8, socioEconomicIndex: 6.2 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 6.7, socioEconomicIndex: 6.5 },
              { year: "2017/2018", grade: 6.6, socioEconomicIndex: 6.2 },
              { year: "2016/2017", grade: 7, socioEconomicIndex: 6.8 },
              { year: "2015/2016", grade: 6.7, socioEconomicIndex: 6.7 },
              { year: "2014/2015", grade: 7, socioEconomicIndex: 7.1 },
              { year: "2013/2014", grade: 7.3, socioEconomicIndex: 6.4 },
              { year: "2012/2013", grade: 7.6, socioEconomicIndex: 6.9 },
              { year: "2011/2012", grade: 6, socioEconomicIndex: 6.1 },
              { year: "2010/2011", grade: 5.4, socioEconomicIndex: 5.7 },
              { year: "2009/2010", grade: 7.9, socioEconomicIndex: 7.3 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 5.2, socioEconomicIndex: 6.2 },
              { year: "2014/2015", grade: 6.5, socioEconomicIndex: 6.5 },
              { year: "2013/2014", grade: 6.6, socioEconomicIndex: 6.3 },
              { year: "2012/2013", grade: 6, socioEconomicIndex: 6.1 },
              { year: "2011/2012", grade: 5.5, socioEconomicIndex: 5.7 },
              { year: "2010/2011", grade: 5.3, socioEconomicIndex: 5.6 },
              { year: "2009/2010", grade: 4.8, socioEconomicIndex: 5.2 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Gudumholm Skole",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 6.6, socioEconomicIndex: 6.4 },
              { year: "2017/2018", grade: 6.1, socioEconomicIndex: 5.9 },
              { year: "2016/2017", grade: 5.4, socioEconomicIndex: 5.7 },
              { year: "2015/2016", grade: 6.4, socioEconomicIndex: 6.3 },
              { year: "2014/2015", grade: 6.7, socioEconomicIndex: 6.4 },
              { year: "2013/2014", grade: 6.5, socioEconomicIndex: 6.4 },
              { year: "2012/2013", grade: 5.6, socioEconomicIndex: 5.7 },
              { year: "2011/2012", grade: 4.3, socioEconomicIndex: 5.1 },
              { year: "2010/2011", grade: 6.6, socioEconomicIndex: 6.4 },
              { year: "2009/2010", grade: 5.3, socioEconomicIndex: 5.6 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 5.1, socioEconomicIndex: 6.5 },
              { year: "2017/2018", grade: 8.1, socioEconomicIndex: 7.7 },
              { year: "2016/2017", grade: 8.4, socioEconomicIndex: 8.2 },
              { year: "2015/2016", grade: 8.2, socioEconomicIndex: 7.8 },
              { year: "2014/2015", grade: 7.1, socioEconomicIndex: 7.1 },
              { year: "2013/2014", grade: 7.9, socioEconomicIndex: 7.7 },
              { year: "2012/2013", grade: 9.4, socioEconomicIndex: 7.9 },
              { year: "2011/2012", grade: 4.9, socioEconomicIndex: 5.6 },
              { year: "2010/2011", grade: 7.9, socioEconomicIndex: 7.6 },
              { year: "2009/2010", grade: 9, socioEconomicIndex: 8.1 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 7.9, socioEconomicIndex: 7.2 },
              { year: "2017/2018", grade: 7.8, socioEconomicIndex: 6.7 },
              { year: "2016/2017", grade: 6.1, socioEconomicIndex: 6.4 },
              { year: "2015/2016", grade: 7.2, socioEconomicIndex: 6.6 },
              { year: "2014/2015", grade: 8, socioEconomicIndex: 7.2 },
              { year: "2013/2014", grade: 6.6, socioEconomicIndex: 6.2 },
              { year: "2012/2013", grade: 5.8, socioEconomicIndex: 5.8 },
              { year: "2011/2012", grade: 4.3, socioEconomicIndex: 5.3 },
              { year: "2010/2011", grade: 6.4, socioEconomicIndex: 6.4 },
              { year: "2009/2010", grade: 6, socioEconomicIndex: 6.1 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 8, socioEconomicIndex: 6.9 },
              { year: "2017/2018", grade: 6, socioEconomicIndex: 5.9 },
              { year: "2016/2017", grade: 5.8, socioEconomicIndex: 6.1 },
              { year: "2015/2016", grade: 4.9, socioEconomicIndex: 5.6 },
              { year: "2014/2015", grade: 6.2, socioEconomicIndex: 6.1 },
              { year: "2013/2014", grade: 7.3, socioEconomicIndex: 6.8 },
              { year: "2012/2013", grade: 7.8, socioEconomicIndex: 6.4 },
              { year: "2011/2012", grade: 5.9, socioEconomicIndex: 6 },
              { year: "2010/2011", grade: 8.3, socioEconomicIndex: 7.1 },
              { year: "2009/2010", grade: 6.4, socioEconomicIndex: 6.2 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 5.1, socioEconomicIndex: 5.4 },
              { year: "2010/2011", grade: 7.6, socioEconomicIndex: 6.1 },
              { year: "2009/2010", grade: 4.8, socioEconomicIndex: 5.4 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 6.2, socioEconomicIndex: 6.8 },
              { year: "2017/2018", grade: 7.1, socioEconomicIndex: 7.2 },
              { year: "2016/2017", grade: 5.9, socioEconomicIndex: 6.5 },
              { year: "2015/2016", grade: 6.7, socioEconomicIndex: 6.9 },
              { year: "2014/2015", grade: 9, socioEconomicIndex: 8.5 },
              { year: "2013/2014", grade: 7, socioEconomicIndex: 7.2 },
              { year: "2012/2013", grade: 6.5, socioEconomicIndex: 6.7 },
              { year: "2011/2012", grade: 4.9, socioEconomicIndex: 5.4 },
              { year: "2010/2011", grade: 7, socioEconomicIndex: 7.1 },
              { year: "2009/2010", grade: 6.9, socioEconomicIndex: 6.9 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 7.3, socioEconomicIndex: 7.4 },
              { year: "2017/2018", grade: 9.2, socioEconomicIndex: 7.7 },
              { year: "2016/2017", grade: 7.6, socioEconomicIndex: 7.5 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 7.1, socioEconomicIndex: 7.1 },
              { year: "2017/2018", grade: 5.9, socioEconomicIndex: 6.4 },
              { year: "2016/2017", grade: 6.2, socioEconomicIndex: 6.1 },
              { year: "2015/2016", grade: 5.7, socioEconomicIndex: 6 },
              { year: "2014/2015", grade: 8.6, socioEconomicIndex: 7.5 },
              { year: "2013/2014", grade: 6.8, socioEconomicIndex: 6.6 },
              { year: "2012/2013", grade: 5.4, socioEconomicIndex: 5.5 },
              { year: "2011/2012", grade: 4.7, socioEconomicIndex: 5.1 },
              { year: "2010/2011", grade: 7.7, socioEconomicIndex: 6.5 },
              { year: "2009/2010", grade: 7.1, socioEconomicIndex: 6.8 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 7, socioEconomicIndex: 6.9 },
              { year: "2017/2018", grade: 7.1, socioEconomicIndex: 6.8 },
              { year: "2016/2017", grade: 6.9, socioEconomicIndex: 6.6 },
              { year: "2015/2016", grade: 6.7, socioEconomicIndex: 6.5 },
              { year: "2014/2015", grade: 9.7, socioEconomicIndex: 8.4 },
              { year: "2013/2014", grade: 7.6, socioEconomicIndex: 7.2 },
              { year: "2012/2013", grade: 7.1, socioEconomicIndex: 6.8 },
              { year: "2011/2012", grade: 6.3, socioEconomicIndex: 6.2 },
              { year: "2010/2011", grade: 8.3, socioEconomicIndex: 7.3 },
              { year: "2009/2010", grade: 8, socioEconomicIndex: 7.6 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 6.6, socioEconomicIndex: 6.4 },
              { year: "2014/2015", grade: 6, socioEconomicIndex: 6.1 },
              { year: "2013/2014", grade: 6, socioEconomicIndex: 6.3 },
              { year: "2012/2013", grade: 7.3, socioEconomicIndex: 6.4 },
              { year: "2011/2012", grade: 5.8, socioEconomicIndex: 5.8 },
              { year: "2010/2011", grade: 8.7, socioEconomicIndex: 7.2 },
              { year: "2009/2010", grade: 6.5, socioEconomicIndex: 6 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Gug Skole",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 7.5, socioEconomicIndex: 7 },
              { year: "2017/2018", grade: 6.2, socioEconomicIndex: 6.5 },
              { year: "2016/2017", grade: 7, socioEconomicIndex: 7 },
              { year: "2015/2016", grade: 7.7, socioEconomicIndex: 7.3 },
              { year: "2014/2015", grade: 6.5, socioEconomicIndex: 6.7 },
              { year: "2013/2014", grade: 7.5, socioEconomicIndex: 7.1 },
              { year: "2012/2013", grade: 7.5, socioEconomicIndex: 6.9 },
              { year: "2011/2012", grade: 7.6, socioEconomicIndex: 7 },
              { year: "2010/2011", grade: 7, socioEconomicIndex: 6.5 },
              { year: "2009/2010", grade: 6.4, socioEconomicIndex: 6.3 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.3, socioEconomicIndex: 8.5 },
              { year: "2017/2018", grade: 7.8, socioEconomicIndex: 8 },
              { year: "2016/2017", grade: 8, socioEconomicIndex: 8.3 },
              { year: "2015/2016", grade: 8.3, socioEconomicIndex: 8.1 },
              { year: "2014/2015", grade: 7.8, socioEconomicIndex: 8.2 },
              { year: "2013/2014", grade: 8, socioEconomicIndex: 8.1 },
              { year: "2012/2013", grade: 8.4, socioEconomicIndex: 8 },
              { year: "2011/2012", grade: 8.1, socioEconomicIndex: 7.6 },
              { year: "2010/2011", grade: 7.7, socioEconomicIndex: 7.6 },
              { year: "2009/2010", grade: 7.4, socioEconomicIndex: 7.4 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 7.4, socioEconomicIndex: 7.5 },
              { year: "2017/2018", grade: 7.3, socioEconomicIndex: 7.1 },
              { year: "2016/2017", grade: 7.8, socioEconomicIndex: 7.7 },
              { year: "2015/2016", grade: 8, socioEconomicIndex: 7.6 },
              { year: "2014/2015", grade: 7.7, socioEconomicIndex: 7.6 },
              { year: "2013/2014", grade: 8.1, socioEconomicIndex: 6.9 },
              { year: "2012/2013", grade: 7.3, socioEconomicIndex: 6.9 },
              { year: "2011/2012", grade: 7, socioEconomicIndex: 6.9 },
              { year: "2010/2011", grade: 7, socioEconomicIndex: 6.5 },
              { year: "2009/2010", grade: 6.1, socioEconomicIndex: 6.3 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 7.6, socioEconomicIndex: 7.1 },
              { year: "2017/2018", grade: 6.5, socioEconomicIndex: 6.5 },
              { year: "2016/2017", grade: 7.3, socioEconomicIndex: 7 },
              { year: "2015/2016", grade: 7.2, socioEconomicIndex: 6.8 },
              { year: "2014/2015", grade: 7, socioEconomicIndex: 7 },
              { year: "2013/2014", grade: 7.5, socioEconomicIndex: 7.1 },
              { year: "2012/2013", grade: 7.9, socioEconomicIndex: 6.8 },
              { year: "2011/2012", grade: 6.4, socioEconomicIndex: 6.4 },
              { year: "2010/2011", grade: 7.9, socioEconomicIndex: 6.7 },
              { year: "2009/2010", grade: 5.8, socioEconomicIndex: 6.2 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 6.1, socioEconomicIndex: 5.8 },
              { year: "2010/2011", grade: 7, socioEconomicIndex: 5.8 },
              { year: "2009/2010", grade: 5.4, socioEconomicIndex: 5.6 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.7, socioEconomicIndex: 8.7 },
              { year: "2017/2018", grade: 7.7, socioEconomicIndex: 8.2 },
              { year: "2016/2017", grade: 8.9, socioEconomicIndex: 8.5 },
              { year: "2015/2016", grade: 8.1, socioEconomicIndex: 8.2 },
              { year: "2014/2015", grade: 7.6, socioEconomicIndex: 7.9 },
              { year: "2013/2014", grade: 8.4, socioEconomicIndex: 8.3 },
              { year: "2012/2013", grade: 8, socioEconomicIndex: 7.8 },
              { year: "2011/2012", grade: 7.6, socioEconomicIndex: 7.5 },
              { year: "2010/2011", grade: 7.6, socioEconomicIndex: 7.5 },
              { year: "2009/2010", grade: 7.4, socioEconomicIndex: 7.3 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 7.3, socioEconomicIndex: 8 },
              { year: "2017/2018", grade: 6.8, socioEconomicIndex: 7.5 },
              { year: "2016/2017", grade: 7.7, socioEconomicIndex: 7.8 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 8.4, socioEconomicIndex: 8 },
              { year: "2017/2018", grade: 6.9, socioEconomicIndex: 7.5 },
              { year: "2016/2017", grade: 7.3, socioEconomicIndex: 7.4 },
              { year: "2015/2016", grade: 7, socioEconomicIndex: 7.4 },
              { year: "2014/2015", grade: 7.2, socioEconomicIndex: 7.6 },
              { year: "2013/2014", grade: 7.8, socioEconomicIndex: 7.1 },
              { year: "2012/2013", grade: 7.4, socioEconomicIndex: 6.8 },
              { year: "2011/2012", grade: 7.9, socioEconomicIndex: 6.9 },
              { year: "2010/2011", grade: 6.8, socioEconomicIndex: 6.5 },
              { year: "2009/2010", grade: 7.2, socioEconomicIndex: 7.1 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 7.8, socioEconomicIndex: 7.8 },
              { year: "2017/2018", grade: 7.7, socioEconomicIndex: 7.5 },
              { year: "2016/2017", grade: 8.4, socioEconomicIndex: 7.8 },
              { year: "2015/2016", grade: 8.3, socioEconomicIndex: 7.8 },
              { year: "2014/2015", grade: 8, socioEconomicIndex: 7.9 },
              { year: "2013/2014", grade: 8.2, socioEconomicIndex: 7.7 },
              { year: "2012/2013", grade: 8.6, socioEconomicIndex: 7.8 },
              { year: "2011/2012", grade: 7.9, socioEconomicIndex: 7.5 },
              { year: "2010/2011", grade: 7.6, socioEconomicIndex: 7.1 },
              { year: "2009/2010", grade: 8.5, socioEconomicIndex: 8 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 6.9, socioEconomicIndex: 7.1 },
              { year: "2014/2015", grade: 7.4, socioEconomicIndex: 7.1 },
              { year: "2013/2014", grade: 7.8, socioEconomicIndex: 7.1 },
              { year: "2012/2013", grade: 7.1, socioEconomicIndex: 6.7 },
              { year: "2011/2012", grade: 6.5, socioEconomicIndex: 6.5 },
              { year: "2010/2011", grade: 6.7, socioEconomicIndex: 6.4 },
              { year: "2009/2010", grade: 6.1, socioEconomicIndex: 6.1 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Hals Skole",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 5.8, socioEconomicIndex: 5.8 },
              { year: "2017/2018", grade: 5.8, socioEconomicIndex: 6.1 },
              { year: "2016/2017", grade: 5.4, socioEconomicIndex: 5.8 },
              { year: "2015/2016", grade: 6.4, socioEconomicIndex: 6.3 },
              { year: "2014/2015", grade: 5.9, socioEconomicIndex: 5.9 },
              { year: "2013/2014", grade: 6.5, socioEconomicIndex: 6.3 },
              { year: "2012/2013", grade: 5.3, socioEconomicIndex: 6 },
              { year: "2011/2012", grade: 5.4, socioEconomicIndex: 6.1 },
              { year: "2010/2011", grade: 4, socioEconomicIndex: 5.1 },
              { year: "2009/2010", grade: 5, socioEconomicIndex: 5.7 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 6.3, socioEconomicIndex: 6.9 },
              { year: "2017/2018", grade: 8.3, socioEconomicIndex: 8.1 },
              { year: "2016/2017", grade: 7.7, socioEconomicIndex: 7.5 },
              { year: "2015/2016", grade: 8.9, socioEconomicIndex: 8.1 },
              { year: "2014/2015", grade: 7.5, socioEconomicIndex: 7.4 },
              { year: "2013/2014", grade: 7, socioEconomicIndex: 7.4 },
              { year: "2012/2013", grade: 7.4, socioEconomicIndex: 7.4 },
              { year: "2011/2012", grade: 5.7, socioEconomicIndex: 6.8 },
              { year: "2010/2011", grade: 5.5, socioEconomicIndex: 6.3 },
              { year: "2009/2010", grade: 6.4, socioEconomicIndex: 6.9 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 6.1, socioEconomicIndex: 6.2 },
              { year: "2017/2018", grade: 6.9, socioEconomicIndex: 6.9 },
              { year: "2016/2017", grade: 7.4, socioEconomicIndex: 6.6 },
              { year: "2015/2016", grade: 7.3, socioEconomicIndex: 6.7 },
              { year: "2014/2015", grade: 6.8, socioEconomicIndex: 6.8 },
              { year: "2013/2014", grade: 7.2, socioEconomicIndex: 6.3 },
              { year: "2012/2013", grade: 5.2, socioEconomicIndex: 6.2 },
              { year: "2011/2012", grade: 5.7, socioEconomicIndex: 6.2 },
              { year: "2010/2011", grade: 5.9, socioEconomicIndex: 5.8 },
              { year: "2009/2010", grade: 5.2, socioEconomicIndex: 6 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 6, socioEconomicIndex: 6 },
              { year: "2017/2018", grade: 5.5, socioEconomicIndex: 6.3 },
              { year: "2016/2017", grade: 3.9, socioEconomicIndex: 5.6 },
              { year: "2015/2016", grade: 5.2, socioEconomicIndex: 6 },
              { year: "2014/2015", grade: 5.5, socioEconomicIndex: 5.9 },
              { year: "2013/2014", grade: 6.9, socioEconomicIndex: 6.6 },
              { year: "2012/2013", grade: 5, socioEconomicIndex: 5.9 },
              { year: "2011/2012", grade: 4.8, socioEconomicIndex: 5.8 },
              { year: "2010/2011", grade: 5.1, socioEconomicIndex: 5.7 },
              { year: "2009/2010", grade: 5.5, socioEconomicIndex: 6 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 5.4, socioEconomicIndex: 5.6 },
              { year: "2010/2011", grade: 5.8, socioEconomicIndex: 5.3 },
              { year: "2009/2010", grade: 4.9, socioEconomicIndex: 5.5 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8, socioEconomicIndex: 7.7 },
              { year: "2017/2018", grade: 6.5, socioEconomicIndex: 7.3 },
              { year: "2016/2017", grade: 6.7, socioEconomicIndex: 7.2 },
              { year: "2015/2016", grade: 8.1, socioEconomicIndex: 7.7 },
              { year: "2014/2015", grade: 9.9, socioEconomicIndex: 8.5 },
              { year: "2013/2014", grade: 7, socioEconomicIndex: 7.4 },
              { year: "2012/2013", grade: 7.3, socioEconomicIndex: 7.4 },
              { year: "2011/2012", grade: 5.6, socioEconomicIndex: 6.6 },
              { year: "2010/2011", grade: 5.9, socioEconomicIndex: 6.5 },
              { year: "2009/2010", grade: 6.5, socioEconomicIndex: 6.8 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 6.8, socioEconomicIndex: 6.9 },
              { year: "2017/2018", grade: 8.2, socioEconomicIndex: 7.6 },
              { year: "2016/2017", grade: 5, socioEconomicIndex: 6.3 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 6.6, socioEconomicIndex: 6.8 },
              { year: "2017/2018", grade: 8.1, socioEconomicIndex: 7.4 },
              { year: "2016/2017", grade: 7.2, socioEconomicIndex: 6.4 },
              { year: "2015/2016", grade: 7.6, socioEconomicIndex: 6.6 },
              { year: "2014/2015", grade: 6.9, socioEconomicIndex: 6.7 },
              { year: "2013/2014", grade: 6.9, socioEconomicIndex: 6.5 },
              { year: "2012/2013", grade: 5.8, socioEconomicIndex: 6.3 },
              { year: "2011/2012", grade: 7, socioEconomicIndex: 6.5 },
              { year: "2010/2011", grade: 5.9, socioEconomicIndex: 5.7 },
              { year: "2009/2010", grade: 7.2, socioEconomicIndex: 6.7 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 6.9, socioEconomicIndex: 6.8 },
              { year: "2017/2018", grade: 8.7, socioEconomicIndex: 7.6 },
              { year: "2016/2017", grade: 7.3, socioEconomicIndex: 6.8 },
              { year: "2015/2016", grade: 6.7, socioEconomicIndex: 6.6 },
              { year: "2014/2015", grade: 7.3, socioEconomicIndex: 7 },
              { year: "2013/2014", grade: 7.8, socioEconomicIndex: 7.1 },
              { year: "2012/2013", grade: 7, socioEconomicIndex: 7.2 },
              { year: "2011/2012", grade: 6.8, socioEconomicIndex: 7.1 },
              { year: "2010/2011", grade: 7, socioEconomicIndex: 6.7 },
              { year: "2009/2010", grade: 7.9, socioEconomicIndex: 7.5 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 7.5, socioEconomicIndex: 6.9 },
              { year: "2014/2015", grade: 5.8, socioEconomicIndex: 6.1 },
              { year: "2013/2014", grade: 4.4, socioEconomicIndex: 6.1 },
              { year: "2012/2013", grade: 6, socioEconomicIndex: 6.1 },
              { year: "2011/2012", grade: 4.8, socioEconomicIndex: 5.9 },
              { year: "2010/2011", grade: 4.6, socioEconomicIndex: 5.3 },
              { year: "2009/2010", grade: 6.1, socioEconomicIndex: 5.9 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Herningvej Skole",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 3.1, socioEconomicIndex: 3.7 },
              { year: "2017/2018", grade: 4.5, socioEconomicIndex: 4.8 },
              { year: "2016/2017", grade: 5.4, socioEconomicIndex: 5.8 },
              { year: "2015/2016", grade: 5.4, socioEconomicIndex: 5.7 },
              { year: "2014/2015", grade: 4.8, socioEconomicIndex: 5.1 },
              { year: "2013/2014", grade: 7.1, socioEconomicIndex: 6.5 },
              { year: "2012/2013", grade: 5.7, socioEconomicIndex: 5.8 },
              { year: "2011/2012", grade: 6.5, socioEconomicIndex: 6.3 },
              { year: "2010/2011", grade: 4, socioEconomicIndex: 5.1 },
              { year: "2009/2010", grade: 5.8, socioEconomicIndex: 5.4 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 5.9, socioEconomicIndex: 6.6 },
              { year: "2017/2018", grade: 5.4, socioEconomicIndex: 6.5 },
              { year: "2016/2017", grade: 6.3, socioEconomicIndex: 7.3 },
              { year: "2015/2016", grade: 6.1, socioEconomicIndex: 6.5 },
              { year: "2014/2015", grade: 4.4, socioEconomicIndex: 5.6 },
              { year: "2013/2014", grade: 7.6, socioEconomicIndex: 7.4 },
              { year: "2012/2013", grade: 8, socioEconomicIndex: 7.5 },
              { year: "2011/2012", grade: 7.7, socioEconomicIndex: 7.5 },
              { year: "2010/2011", grade: 7.7, socioEconomicIndex: 7.4 },
              { year: "2009/2010", grade: 6.8, socioEconomicIndex: 6.6 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 4.1, socioEconomicIndex: 4.9 },
              { year: "2017/2018", grade: 5.3, socioEconomicIndex: 5.7 },
              { year: "2016/2017", grade: 4.8, socioEconomicIndex: 6.2 },
              { year: "2015/2016", grade: 5.4, socioEconomicIndex: 5.9 },
              { year: "2014/2015", grade: 5.6, socioEconomicIndex: 5.9 },
              { year: "2013/2014", grade: 6.2, socioEconomicIndex: 5.8 },
              { year: "2012/2013", grade: 5.2, socioEconomicIndex: 5.6 },
              { year: "2011/2012", grade: 5.6, socioEconomicIndex: 5.8 },
              { year: "2010/2011", grade: 5.2, socioEconomicIndex: 5.9 },
              { year: "2009/2010", grade: 5.3, socioEconomicIndex: 5.4 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 4.9, socioEconomicIndex: 5.5 },
              { year: "2017/2018", grade: 4.1, socioEconomicIndex: 5.3 },
              { year: "2016/2017", grade: 7.2, socioEconomicIndex: 6.4 },
              { year: "2015/2016", grade: 5.8, socioEconomicIndex: 5.7 },
              { year: "2014/2015", grade: 4.7, socioEconomicIndex: 5.4 },
              { year: "2013/2014", grade: 5.8, socioEconomicIndex: 6 },
              { year: "2012/2013", grade: 8.1, socioEconomicIndex: 6.5 },
              { year: "2011/2012", grade: 6.8, socioEconomicIndex: 6.3 },
              { year: "2010/2011", grade: 6.5, socioEconomicIndex: 6.3 },
              { year: "2009/2010", grade: 6.5, socioEconomicIndex: 5.9 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 5.2, socioEconomicIndex: 5.3 },
              { year: "2010/2011", grade: 6.1, socioEconomicIndex: 5.6 },
              { year: "2009/2010", grade: 6.9, socioEconomicIndex: 5.5 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 7.4, socioEconomicIndex: 7.4 },
              { year: "2017/2018", grade: 8, socioEconomicIndex: 7.7 },
              { year: "2016/2017", grade: 7.3, socioEconomicIndex: 7.6 },
              { year: "2015/2016", grade: 8.5, socioEconomicIndex: 8 },
              { year: "2014/2015", grade: 6.3, socioEconomicIndex: 6.5 },
              { year: "2013/2014", grade: 7.8, socioEconomicIndex: 7.6 },
              { year: "2012/2013", grade: 7.3, socioEconomicIndex: 7.3 },
              { year: "2011/2012", grade: 8.5, socioEconomicIndex: 8.1 },
              { year: "2010/2011", grade: 8.5, socioEconomicIndex: 7.9 },
              { year: "2009/2010", grade: 7.2, socioEconomicIndex: 6.9 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 7, socioEconomicIndex: 6.8 },
              { year: "2017/2018", grade: 7.1, socioEconomicIndex: 6.8 },
              { year: "2016/2017", grade: 6.7, socioEconomicIndex: 7 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 3.5, socioEconomicIndex: 4.7 },
              { year: "2017/2018", grade: 5.3, socioEconomicIndex: 5.9 },
              { year: "2016/2017", grade: 4.8, socioEconomicIndex: 5.5 },
              { year: "2015/2016", grade: 5.9, socioEconomicIndex: 6 },
              { year: "2014/2015", grade: 4.7, socioEconomicIndex: 5.3 },
              { year: "2013/2014", grade: 5.4, socioEconomicIndex: 5.5 },
              { year: "2012/2013", grade: 5.9, socioEconomicIndex: 5.5 },
              { year: "2011/2012", grade: 7.3, socioEconomicIndex: 6.5 },
              { year: "2010/2011", grade: 4.6, socioEconomicIndex: 5.3 },
              { year: "2009/2010", grade: 4.9, socioEconomicIndex: 5.2 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 3.7, socioEconomicIndex: 4.4 },
              { year: "2017/2018", grade: 5, socioEconomicIndex: 5.8 },
              { year: "2016/2017", grade: 5.1, socioEconomicIndex: 5.9 },
              { year: "2015/2016", grade: 5.5, socioEconomicIndex: 6.1 },
              { year: "2014/2015", grade: 4.3, socioEconomicIndex: 5.4 },
              { year: "2013/2014", grade: 5.4, socioEconomicIndex: 5.9 },
              { year: "2012/2013", grade: 6.7, socioEconomicIndex: 6.4 },
              { year: "2011/2012", grade: 7.3, socioEconomicIndex: 7 },
              { year: "2010/2011", grade: 4.8, socioEconomicIndex: 5.8 },
              { year: "2009/2010", grade: 5.1, socioEconomicIndex: 5.9 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 5.3, socioEconomicIndex: 5.9 },
              { year: "2014/2015", grade: 5.4, socioEconomicIndex: 5.8 },
              { year: "2013/2014", grade: 6.7, socioEconomicIndex: 6.3 },
              { year: "2012/2013", grade: 7.3, socioEconomicIndex: 6.4 },
              { year: "2011/2012", grade: 7.7, socioEconomicIndex: 7 },
              { year: "2010/2011", grade: 5.1, socioEconomicIndex: 5.7 },
              { year: "2009/2010", grade: 4.4, socioEconomicIndex: 4.8 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Højvangskolen",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 6.8, socioEconomicIndex: 6.5 },
              { year: "2017/2018", grade: 6.9, socioEconomicIndex: 6.5 },
              { year: "2016/2017", grade: 6, socioEconomicIndex: 6.3 },
              { year: "2015/2016", grade: 7.3, socioEconomicIndex: 7.2 },
              { year: "2014/2015", grade: 6.9, socioEconomicIndex: 6.4 },
              { year: "2013/2014", grade: 5.9, socioEconomicIndex: 6.1 },
              { year: "2012/2013", grade: 7.1, socioEconomicIndex: 6.9 },
              { year: "2011/2012", grade: 6.9, socioEconomicIndex: 7.1 },
              { year: "2010/2011", grade: 5.8, socioEconomicIndex: 5.6 },
              { year: "2009/2010", grade: 6.7, socioEconomicIndex: 6.5 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.8, socioEconomicIndex: 8.4 },
              { year: "2017/2018", grade: 7.4, socioEconomicIndex: 7.7 },
              { year: "2016/2017", grade: 9.4, socioEconomicIndex: 8.7 },
              { year: "2015/2016", grade: 7.8, socioEconomicIndex: 7.9 },
              { year: "2014/2015", grade: 7.5, socioEconomicIndex: 7.4 },
              { year: "2013/2014", grade: 7.9, socioEconomicIndex: 7.8 },
              { year: "2012/2013", grade: 7.3, socioEconomicIndex: 7.5 },
              { year: "2011/2012", grade: 8.7, socioEconomicIndex: 8.4 },
              { year: "2010/2011", grade: 7.6, socioEconomicIndex: 7.1 },
              { year: "2009/2010", grade: 7.4, socioEconomicIndex: 7.4 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 7.9, socioEconomicIndex: 7.1 },
              { year: "2017/2018", grade: 7.1, socioEconomicIndex: 6.9 },
              { year: "2016/2017", grade: 6.9, socioEconomicIndex: 7.1 },
              { year: "2015/2016", grade: 7.2, socioEconomicIndex: 7.4 },
              { year: "2014/2015", grade: 7.8, socioEconomicIndex: 7.1 },
              { year: "2013/2014", grade: 5.5, socioEconomicIndex: 5.9 },
              { year: "2012/2013", grade: 6.3, socioEconomicIndex: 6.5 },
              { year: "2011/2012", grade: 6.7, socioEconomicIndex: 7.1 },
              { year: "2010/2011", grade: 5.6, socioEconomicIndex: 5.7 },
              { year: "2009/2010", grade: 6, socioEconomicIndex: 6.2 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 6.7, socioEconomicIndex: 6.6 },
              { year: "2017/2018", grade: 5.8, socioEconomicIndex: 6.3 },
              { year: "2016/2017", grade: 7.2, socioEconomicIndex: 6.8 },
              { year: "2015/2016", grade: 8.2, socioEconomicIndex: 7 },
              { year: "2014/2015", grade: 5.9, socioEconomicIndex: 6.1 },
              { year: "2013/2014", grade: 8, socioEconomicIndex: 7.1 },
              { year: "2012/2013", grade: 6.5, socioEconomicIndex: 6.5 },
              { year: "2011/2012", grade: 6.9, socioEconomicIndex: 7 },
              { year: "2010/2011", grade: 6.4, socioEconomicIndex: 6 },
              { year: "2009/2010", grade: 6.6, socioEconomicIndex: 6.3 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 8.4, socioEconomicIndex: 6.7 },
              { year: "2010/2011", grade: 6, socioEconomicIndex: 5.5 },
              { year: "2009/2010", grade: 5.9, socioEconomicIndex: 5.6 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 7.8, socioEconomicIndex: 7.9 },
              { year: "2017/2018", grade: 6.7, socioEconomicIndex: 7.3 },
              { year: "2016/2017", grade: 8, socioEconomicIndex: 8 },
              { year: "2015/2016", grade: 7.6, socioEconomicIndex: 7.9 },
              { year: "2014/2015", grade: 6.9, socioEconomicIndex: 7.2 },
              { year: "2013/2014", grade: 8.1, socioEconomicIndex: 7.9 },
              { year: "2012/2013", grade: 7.3, socioEconomicIndex: 7.5 },
              { year: "2011/2012", grade: 7.2, socioEconomicIndex: 7.6 },
              { year: "2010/2011", grade: 7.4, socioEconomicIndex: 7.1 },
              { year: "2009/2010", grade: 7, socioEconomicIndex: 7.1 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 7.3, socioEconomicIndex: 7.4 },
              { year: "2017/2018", grade: 8.7, socioEconomicIndex: 7.9 },
              { year: "2016/2017", grade: 8.9, socioEconomicIndex: 8 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 7.1, socioEconomicIndex: 7.1 },
              { year: "2017/2018", grade: 7.2, socioEconomicIndex: 7.2 },
              { year: "2016/2017", grade: 5.8, socioEconomicIndex: 6.4 },
              { year: "2015/2016", grade: 8.3, socioEconomicIndex: 7.7 },
              { year: "2014/2015", grade: 7.8, socioEconomicIndex: 7.1 },
              { year: "2013/2014", grade: 5.3, socioEconomicIndex: 5.9 },
              { year: "2012/2013", grade: 7.1, socioEconomicIndex: 6.6 },
              { year: "2011/2012", grade: 7.7, socioEconomicIndex: 7.3 },
              { year: "2010/2011", grade: 5.6, socioEconomicIndex: 5.3 },
              { year: "2009/2010", grade: 7.9, socioEconomicIndex: 7.4 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 7.1, socioEconomicIndex: 6.9 },
              { year: "2017/2018", grade: 7.3, socioEconomicIndex: 7.2 },
              { year: "2016/2017", grade: 6.9, socioEconomicIndex: 6.9 },
              { year: "2015/2016", grade: 8.1, socioEconomicIndex: 7.8 },
              { year: "2014/2015", grade: 6.8, socioEconomicIndex: 7.1 },
              { year: "2013/2014", grade: 5.2, socioEconomicIndex: 6 },
              { year: "2012/2013", grade: 7.6, socioEconomicIndex: 7.4 },
              { year: "2011/2012", grade: 7.5, socioEconomicIndex: 7.5 },
              { year: "2010/2011", grade: 5.4, socioEconomicIndex: 5.8 },
              { year: "2009/2010", grade: 8.6, socioEconomicIndex: 8.1 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 5.7, socioEconomicIndex: 6.6 },
              { year: "2014/2015", grade: 7.9, socioEconomicIndex: 6.9 },
              { year: "2013/2014", grade: 6.6, socioEconomicIndex: 6.6 },
              { year: "2012/2013", grade: 7.8, socioEconomicIndex: 6.9 },
              { year: "2011/2012", grade: 6.8, socioEconomicIndex: 6.7 },
              { year: "2010/2011", grade: 5.5, socioEconomicIndex: 5.5 },
              { year: "2009/2010", grade: 6.5, socioEconomicIndex: 6.3 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Klarup Skole",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 6.4, socioEconomicIndex: 6.5 },
              { year: "2017/2018", grade: 6.3, socioEconomicIndex: 6.3 },
              { year: "2016/2017", grade: 7.2, socioEconomicIndex: 7.2 },
              { year: "2015/2016", grade: 7.3, socioEconomicIndex: 7.3 },
              { year: "2014/2015", grade: 7.1, socioEconomicIndex: 6.8 },
              { year: "2013/2014", grade: 6, socioEconomicIndex: 6.4 },
              { year: "2012/2013", grade: 6.2, socioEconomicIndex: 6.3 },
              { year: "2011/2012", grade: 6.1, socioEconomicIndex: 6.2 },
              { year: "2010/2011", grade: 5.7, socioEconomicIndex: 6 },
              { year: "2009/2010", grade: 6, socioEconomicIndex: 6.1 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 7.6, socioEconomicIndex: 8 },
              { year: "2017/2018", grade: 8.9, socioEconomicIndex: 8.2 },
              { year: "2016/2017", grade: 8.4, socioEconomicIndex: 8.4 },
              { year: "2015/2016", grade: 9.2, socioEconomicIndex: 8.6 },
              { year: "2014/2015", grade: 8, socioEconomicIndex: 8.1 },
              { year: "2013/2014", grade: 6.8, socioEconomicIndex: 7.5 },
              { year: "2012/2013", grade: 6.3, socioEconomicIndex: 7 },
              { year: "2011/2012", grade: 6, socioEconomicIndex: 6.7 },
              { year: "2010/2011", grade: 6.1, socioEconomicIndex: 6.9 },
              { year: "2009/2010", grade: 6.4, socioEconomicIndex: 6.9 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 6.7, socioEconomicIndex: 7 },
              { year: "2017/2018", grade: 6.8, socioEconomicIndex: 6.9 },
              { year: "2016/2017", grade: 8.4, socioEconomicIndex: 7.9 },
              { year: "2015/2016", grade: 8, socioEconomicIndex: 7.8 },
              { year: "2014/2015", grade: 7.4, socioEconomicIndex: 7.5 },
              { year: "2013/2014", grade: 5.8, socioEconomicIndex: 6.2 },
              { year: "2012/2013", grade: 6.6, socioEconomicIndex: 6.5 },
              { year: "2011/2012", grade: 5.6, socioEconomicIndex: 6 },
              { year: "2010/2011", grade: 5.8, socioEconomicIndex: 6.1 },
              { year: "2009/2010", grade: 6.3, socioEconomicIndex: 6.3 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 6, socioEconomicIndex: 6.7 },
              { year: "2017/2018", grade: 8.1, socioEconomicIndex: 6.6 },
              { year: "2016/2017", grade: 7.8, socioEconomicIndex: 7.1 },
              { year: "2015/2016", grade: 6.4, socioEconomicIndex: 6.9 },
              { year: "2014/2015", grade: 6.2, socioEconomicIndex: 6.7 },
              { year: "2013/2014", grade: 6.4, socioEconomicIndex: 6.7 },
              { year: "2012/2013", grade: 6.6, socioEconomicIndex: 6.3 },
              { year: "2011/2012", grade: 4.9, socioEconomicIndex: 5.7 },
              { year: "2010/2011", grade: 6, socioEconomicIndex: 6.2 },
              { year: "2009/2010", grade: 6.1, socioEconomicIndex: 6.2 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 3.8, socioEconomicIndex: 5.3 },
              { year: "2010/2011", grade: 4.8, socioEconomicIndex: 5.5 },
              { year: "2009/2010", grade: 5.5, socioEconomicIndex: 5.6 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 7.5, socioEconomicIndex: 8.1 },
              { year: "2017/2018", grade: 8.9, socioEconomicIndex: 8.4 },
              { year: "2016/2017", grade: 8.9, socioEconomicIndex: 8.6 },
              { year: "2015/2016", grade: 9.6, socioEconomicIndex: 8.8 },
              { year: "2014/2015", grade: 7.6, socioEconomicIndex: 7.8 },
              { year: "2013/2014", grade: 8.1, socioEconomicIndex: 7.9 },
              { year: "2012/2013", grade: 7.6, socioEconomicIndex: 7.5 },
              { year: "2011/2012", grade: 8.1, socioEconomicIndex: 7.4 },
              { year: "2010/2011", grade: 6.4, socioEconomicIndex: 6.9 },
              { year: "2009/2010", grade: 6.6, socioEconomicIndex: 6.8 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.1, socioEconomicIndex: 7.8 },
              { year: "2017/2018", grade: 8.1, socioEconomicIndex: 7.5 },
              { year: "2016/2017", grade: 8.4, socioEconomicIndex: 8 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 7.3, socioEconomicIndex: 7.5 },
              { year: "2017/2018", grade: 8.8, socioEconomicIndex: 7.6 },
              { year: "2016/2017", grade: 8.5, socioEconomicIndex: 7.8 },
              { year: "2015/2016", grade: 7.5, socioEconomicIndex: 7.6 },
              { year: "2014/2015", grade: 7, socioEconomicIndex: 7.2 },
              { year: "2013/2014", grade: 7.3, socioEconomicIndex: 6.7 },
              { year: "2012/2013", grade: 5.8, socioEconomicIndex: 6 },
              { year: "2011/2012", grade: 5.8, socioEconomicIndex: 5.9 },
              { year: "2010/2011", grade: 6.7, socioEconomicIndex: 6.3 },
              { year: "2009/2010", grade: 6.4, socioEconomicIndex: 6.8 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 7.9, socioEconomicIndex: 7.5 },
              { year: "2017/2018", grade: 7.4, socioEconomicIndex: 7.2 },
              { year: "2016/2017", grade: 8.9, socioEconomicIndex: 8.3 },
              { year: "2015/2016", grade: 8.4, socioEconomicIndex: 7.9 },
              { year: "2014/2015", grade: 7.3, socioEconomicIndex: 7.4 },
              { year: "2013/2014", grade: 7.1, socioEconomicIndex: 7 },
              { year: "2012/2013", grade: 6.7, socioEconomicIndex: 7 },
              { year: "2011/2012", grade: 6.6, socioEconomicIndex: 6.7 },
              { year: "2010/2011", grade: 6.2, socioEconomicIndex: 6.7 },
              { year: "2009/2010", grade: 7.6, socioEconomicIndex: 7.6 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 7.4, socioEconomicIndex: 7.4 },
              { year: "2014/2015", grade: 6.9, socioEconomicIndex: 6.8 },
              { year: "2013/2014", grade: 6.4, socioEconomicIndex: 6.5 },
              { year: "2012/2013", grade: 5.8, socioEconomicIndex: 6.2 },
              { year: "2011/2012", grade: 4.4, socioEconomicIndex: 5.6 },
              { year: "2010/2011", grade: 5, socioEconomicIndex: 5.8 },
              { year: "2009/2010", grade: 4.7, socioEconomicIndex: 5.5 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Kongerslev Skole",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 5.9, socioEconomicIndex: 5.7 },
              { year: "2017/2018", grade: 5.3, socioEconomicIndex: 5.9 },
              { year: "2016/2017", grade: 7.1, socioEconomicIndex: 6.8 },
              { year: "2015/2016", grade: 7.2, socioEconomicIndex: 7.1 },
              { year: "2014/2015", grade: 5.6, socioEconomicIndex: 5.7 },
              { year: "2013/2014", grade: 6.7, socioEconomicIndex: 6.7 },
              { year: "2012/2013", grade: 6, socioEconomicIndex: 5.9 },
              { year: "2011/2012", grade: 6.7, socioEconomicIndex: 6.7 },
              { year: "2010/2011", grade: 4.1, socioEconomicIndex: 5 },
              { year: "2009/2010", grade: 4.9, socioEconomicIndex: 5.2 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 6.1, socioEconomicIndex: 6.9 },
              { year: "2017/2018", grade: 6.9, socioEconomicIndex: 7.5 },
              { year: "2016/2017", grade: 9.3, socioEconomicIndex: 8.6 },
              { year: "2015/2016", grade: 8.1, socioEconomicIndex: 8.2 },
              { year: "2014/2015", grade: 5.9, socioEconomicIndex: 6.5 },
              { year: "2013/2014", grade: 8, socioEconomicIndex: 8 },
              { year: "2012/2013", grade: 5.6, socioEconomicIndex: 5.9 },
              { year: "2011/2012", grade: 8.1, socioEconomicIndex: 7.9 },
              { year: "2010/2011", grade: 6.6, socioEconomicIndex: 6.8 },
              { year: "2009/2010", grade: 5.2, socioEconomicIndex: 5.6 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 6.7, socioEconomicIndex: 6.2 },
              { year: "2017/2018", grade: 5.6, socioEconomicIndex: 6.6 },
              { year: "2016/2017", grade: 7.9, socioEconomicIndex: 7.3 },
              { year: "2015/2016", grade: 8.6, socioEconomicIndex: 7.9 },
              { year: "2014/2015", grade: 7.6, socioEconomicIndex: 7 },
              { year: "2013/2014", grade: 6, socioEconomicIndex: 6.3 },
              { year: "2012/2013", grade: 5.3, socioEconomicIndex: 5.4 },
              { year: "2011/2012", grade: 6.4, socioEconomicIndex: 6.6 },
              { year: "2010/2011", grade: 6.5, socioEconomicIndex: 6.2 },
              { year: "2009/2010", grade: 4.8, socioEconomicIndex: 5.3 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 7.9, socioEconomicIndex: 6.6 },
              { year: "2017/2018", grade: 5.9, socioEconomicIndex: 6.4 },
              { year: "2016/2017", grade: 8, socioEconomicIndex: 6.9 },
              { year: "2015/2016", grade: 6.7, socioEconomicIndex: 6.9 },
              { year: "2014/2015", grade: 6.5, socioEconomicIndex: 6.4 },
              { year: "2013/2014", grade: 7.3, socioEconomicIndex: 7 },
              { year: "2012/2013", grade: 6, socioEconomicIndex: 5.8 },
              { year: "2011/2012", grade: 7, socioEconomicIndex: 6.8 },
              { year: "2010/2011", grade: 6.8, socioEconomicIndex: 6.4 },
              { year: "2009/2010", grade: 5.8, socioEconomicIndex: 5.7 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 5, socioEconomicIndex: 5.7 },
              { year: "2010/2011", grade: 4.2, socioEconomicIndex: 5.2 },
              { year: "2009/2010", grade: 6.3, socioEconomicIndex: 5.6 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 7.3, socioEconomicIndex: 7.3 },
              { year: "2017/2018", grade: 7.4, socioEconomicIndex: 7.8 },
              { year: "2016/2017", grade: 8.1, socioEconomicIndex: 7.9 },
              { year: "2015/2016", grade: 9, socioEconomicIndex: 8.7 },
              { year: "2014/2015", grade: 7.5, socioEconomicIndex: 7.4 },
              { year: "2013/2014", grade: 7.4, socioEconomicIndex: 7.5 },
              { year: "2012/2013", grade: 5.6, socioEconomicIndex: 5.8 },
              { year: "2011/2012", grade: 8.3, socioEconomicIndex: 8.1 },
              { year: "2010/2011", grade: 6.8, socioEconomicIndex: 6.9 },
              { year: "2009/2010", grade: 6.5, socioEconomicIndex: 6.5 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 7.5, socioEconomicIndex: 7.2 },
              { year: "2017/2018", grade: 8.2, socioEconomicIndex: 8 },
              { year: "2016/2017", grade: 8.5, socioEconomicIndex: 7.8 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 6.6, socioEconomicIndex: 6.4 },
              { year: "2017/2018", grade: 7.8, socioEconomicIndex: 7.7 },
              { year: "2016/2017", grade: 6.7, socioEconomicIndex: 6.7 },
              { year: "2015/2016", grade: 7.7, socioEconomicIndex: 7.3 },
              { year: "2014/2015", grade: 6.8, socioEconomicIndex: 6.5 },
              { year: "2013/2014", grade: 5.7, socioEconomicIndex: 6.2 },
              { year: "2012/2013", grade: 5.3, socioEconomicIndex: 5.4 },
              { year: "2011/2012", grade: 5.8, socioEconomicIndex: 6.2 },
              { year: "2010/2011", grade: 3.9, socioEconomicIndex: 5.1 },
              { year: "2009/2010", grade: 5.1, socioEconomicIndex: 5.9 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 6.2, socioEconomicIndex: 6.3 },
              { year: "2017/2018", grade: 7.7, socioEconomicIndex: 7.7 },
              { year: "2016/2017", grade: 6.4, socioEconomicIndex: 6.7 },
              { year: "2015/2016", grade: 7.5, socioEconomicIndex: 7.4 },
              { year: "2014/2015", grade: 6.6, socioEconomicIndex: 6.7 },
              { year: "2013/2014", grade: 6, socioEconomicIndex: 6.6 },
              { year: "2012/2013", grade: 5.3, socioEconomicIndex: 5.7 },
              { year: "2011/2012", grade: 7.1, socioEconomicIndex: 7.1 },
              { year: "2010/2011", grade: 4.6, socioEconomicIndex: 5.7 },
              { year: "2009/2010", grade: 6, socioEconomicIndex: 6.6 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 5.9, socioEconomicIndex: 6.6 },
              { year: "2014/2015", grade: 6.6, socioEconomicIndex: 6.5 },
              { year: "2013/2014", grade: 6.5, socioEconomicIndex: 6.6 },
              { year: "2012/2013", grade: 5.6, socioEconomicIndex: 5.6 },
              { year: "2011/2012", grade: 6, socioEconomicIndex: 6.2 },
              { year: "2010/2011", grade: 5, socioEconomicIndex: 5.4 },
              { year: "2009/2010", grade: 5.1, socioEconomicIndex: 5.3 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Løvvangskolen",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2016/2017", grade: 5.6, socioEconomicIndex: 5.4 },
              { year: "2015/2016", grade: 5.3, socioEconomicIndex: 5.4 },
              { year: "2014/2015", grade: 4, socioEconomicIndex: 4.5 },
              { year: "2013/2014", grade: 7.2, socioEconomicIndex: 6.1 },
              { year: "2012/2013", grade: 5.4, socioEconomicIndex: 5.4 },
              { year: "2011/2012", grade: 5.7, socioEconomicIndex: 5.4 },
              { year: "2010/2011", grade: 4.6, socioEconomicIndex: 4.7 },
              { year: "2009/2010", grade: 3.8, socioEconomicIndex: 4.1 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2016/2017", grade: 8.6, socioEconomicIndex: 7.9 },
              { year: "2015/2016", grade: 6.8, socioEconomicIndex: 6.9 },
              { year: "2014/2015", grade: 7.6, socioEconomicIndex: 7.3 },
              { year: "2013/2014", grade: 6.7, socioEconomicIndex: 6.5 },
              { year: "2012/2013", grade: 6.3, socioEconomicIndex: 6.5 },
              { year: "2011/2012", grade: 7.6, socioEconomicIndex: 7 },
              { year: "2010/2011", grade: 6.9, socioEconomicIndex: 6.8 },
              { year: "2009/2010", grade: 5.9, socioEconomicIndex: 6 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2016/2017", grade: 6.2, socioEconomicIndex: 5.9 },
              { year: "2015/2016", grade: 6.4, socioEconomicIndex: 6 },
              { year: "2014/2015", grade: 5.8, socioEconomicIndex: 5.9 },
              { year: "2013/2014", grade: 4.8, socioEconomicIndex: 4.5 },
              { year: "2012/2013", grade: 5.3, socioEconomicIndex: 5.3 },
              { year: "2011/2012", grade: 5, socioEconomicIndex: 5.1 },
              { year: "2010/2011", grade: 6.6, socioEconomicIndex: 5.8 },
              { year: "2009/2010", grade: 3.8, socioEconomicIndex: 4.6 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2016/2017", grade: 6.2, socioEconomicIndex: 5.7 },
              { year: "2015/2016", grade: 5.5, socioEconomicIndex: 5.5 },
              { year: "2014/2015", grade: 5.7, socioEconomicIndex: 5.6 },
              { year: "2013/2014", grade: 6.4, socioEconomicIndex: 5.6 },
              { year: "2012/2013", grade: 5.9, socioEconomicIndex: 5.7 },
              { year: "2011/2012", grade: 5.9, socioEconomicIndex: 5.5 },
              { year: "2010/2011", grade: 6, socioEconomicIndex: 5.6 },
              { year: "2009/2010", grade: 2.9, socioEconomicIndex: 4.3 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 4.1, socioEconomicIndex: 4.9 },
              { year: "2010/2011", grade: 6, socioEconomicIndex: 5.3 },
              { year: "2009/2010", grade: 5, socioEconomicIndex: 5 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2016/2017", grade: 8, socioEconomicIndex: 7.7 },
              { year: "2015/2016", grade: 6.6, socioEconomicIndex: 6.7 },
              { year: "2014/2015", grade: 7.6, socioEconomicIndex: 7.3 },
              { year: "2013/2014", grade: 7.4, socioEconomicIndex: 7 },
              { year: "2012/2013", grade: 6.7, socioEconomicIndex: 6.6 },
              { year: "2011/2012", grade: 6.1, socioEconomicIndex: 6.2 },
              { year: "2010/2011", grade: 6.6, socioEconomicIndex: 6.6 },
              { year: "2009/2010", grade: 4.6, socioEconomicIndex: 5 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [{ year: "2016/2017", grade: 7.6, socioEconomicIndex: 7 }],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2016/2017", grade: 5.8, socioEconomicIndex: 5 },
              { year: "2015/2016", grade: 5, socioEconomicIndex: 5.1 },
              { year: "2014/2015", grade: 7.4, socioEconomicIndex: 6.1 },
              { year: "2013/2014", grade: 4.7, socioEconomicIndex: 4.4 },
              { year: "2012/2013", grade: 4.7, socioEconomicIndex: 4.6 },
              { year: "2011/2012", grade: 4.9, socioEconomicIndex: 4.7 },
              { year: "2010/2011", grade: 4.5, socioEconomicIndex: 4.8 },
              { year: "2009/2010", grade: 3.7, socioEconomicIndex: 4.2 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2016/2017", grade: 5.8, socioEconomicIndex: 5.6 },
              { year: "2015/2016", grade: 6.3, socioEconomicIndex: 5.9 },
              { year: "2014/2015", grade: 5.9, socioEconomicIndex: 5.7 },
              { year: "2013/2014", grade: 6, socioEconomicIndex: 5.3 },
              { year: "2012/2013", grade: 6.2, socioEconomicIndex: 5.8 },
              { year: "2011/2012", grade: 5.6, socioEconomicIndex: 5.5 },
              { year: "2010/2011", grade: 6, socioEconomicIndex: 5.8 },
              { year: "2009/2010", grade: 5.5, socioEconomicIndex: 5.6 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 7, socioEconomicIndex: 6.3 },
              { year: "2014/2015", grade: 7.3, socioEconomicIndex: 6.4 },
              { year: "2013/2014", grade: 7.8, socioEconomicIndex: 6.2 },
              { year: "2012/2013", grade: 5.4, socioEconomicIndex: 5.4 },
              { year: "2011/2012", grade: 6.5, socioEconomicIndex: 5.7 },
              { year: "2010/2011", grade: 5.8, socioEconomicIndex: 5.7 },
              { year: "2009/2010", grade: 5.5, socioEconomicIndex: 5 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Mellervangskolen",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 4.3, socioEconomicIndex: 4.5 },
              { year: "2017/2018", grade: 4.9, socioEconomicIndex: 4.6 },
              { year: "2016/2017", grade: 6.4, socioEconomicIndex: 6 },
              { year: "2015/2016", grade: 6.1, socioEconomicIndex: 6 },
              { year: "2014/2015", grade: 4, socioEconomicIndex: 4.7 },
              { year: "2013/2014", grade: 5.1, socioEconomicIndex: 5.4 },
              { year: "2012/2013", grade: 5, socioEconomicIndex: 5.2 },
              { year: "2011/2012", grade: 4.9, socioEconomicIndex: 5.2 },
              { year: "2010/2011", grade: 4.2, socioEconomicIndex: 4.7 },
              { year: "2009/2010", grade: 5.1, socioEconomicIndex: 5.2 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 6.5, socioEconomicIndex: 6.7 },
              { year: "2017/2018", grade: 6.8, socioEconomicIndex: 6.9 },
              { year: "2016/2017", grade: 7.3, socioEconomicIndex: 7.6 },
              { year: "2015/2016", grade: 7, socioEconomicIndex: 7.3 },
              { year: "2014/2015", grade: 6.1, socioEconomicIndex: 6.7 },
              { year: "2013/2014", grade: 6.4, socioEconomicIndex: 6.8 },
              { year: "2012/2013", grade: 6.9, socioEconomicIndex: 6.6 },
              { year: "2011/2012", grade: 6.6, socioEconomicIndex: 6.5 },
              { year: "2010/2011", grade: 6.5, socioEconomicIndex: 6.5 },
              { year: "2009/2010", grade: 6.7, socioEconomicIndex: 6.7 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 4.4, socioEconomicIndex: 5.4 },
              { year: "2017/2018", grade: 5.6, socioEconomicIndex: 5.4 },
              { year: "2016/2017", grade: 5.7, socioEconomicIndex: 6.3 },
              { year: "2015/2016", grade: 5.3, socioEconomicIndex: 5.9 },
              { year: "2014/2015", grade: 5.6, socioEconomicIndex: 6.2 },
              { year: "2013/2014", grade: 3.6, socioEconomicIndex: 4.8 },
              { year: "2012/2013", grade: 5.6, socioEconomicIndex: 5.3 },
              { year: "2011/2012", grade: 5.1, socioEconomicIndex: 5.1 },
              { year: "2010/2011", grade: 4.4, socioEconomicIndex: 5 },
              { year: "2009/2010", grade: 5.2, socioEconomicIndex: 5.6 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 4.3, socioEconomicIndex: 5.2 },
              { year: "2017/2018", grade: 4.8, socioEconomicIndex: 5.2 },
              { year: "2016/2017", grade: 5.4, socioEconomicIndex: 6 },
              { year: "2015/2016", grade: 5.6, socioEconomicIndex: 5.8 },
              { year: "2014/2015", grade: 5.6, socioEconomicIndex: 5.8 },
              { year: "2013/2014", grade: 7.1, socioEconomicIndex: 6.2 },
              { year: "2012/2013", grade: 6.4, socioEconomicIndex: 5.8 },
              { year: "2011/2012", grade: 4.6, socioEconomicIndex: 4.9 },
              { year: "2010/2011", grade: 4.6, socioEconomicIndex: 5.2 },
              { year: "2009/2010", grade: 5, socioEconomicIndex: 5.5 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 5.9, socioEconomicIndex: 5 },
              { year: "2010/2011", grade: 5.8, socioEconomicIndex: 5.4 },
              { year: "2009/2010", grade: 5.6, socioEconomicIndex: 5.3 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 7.3, socioEconomicIndex: 7.3 },
              { year: "2017/2018", grade: 8.4, socioEconomicIndex: 7.9 },
              { year: "2016/2017", grade: 8.1, socioEconomicIndex: 7.9 },
              { year: "2015/2016", grade: 7.5, socioEconomicIndex: 7.5 },
              { year: "2014/2015", grade: 7.4, socioEconomicIndex: 7.4 },
              { year: "2013/2014", grade: 7.5, socioEconomicIndex: 7.3 },
              { year: "2012/2013", grade: 6.6, socioEconomicIndex: 6.6 },
              { year: "2011/2012", grade: 7.4, socioEconomicIndex: 7.1 },
              { year: "2010/2011", grade: 5.1, socioEconomicIndex: 5.7 },
              { year: "2009/2010", grade: 7, socioEconomicIndex: 6.9 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 5, socioEconomicIndex: 5.8 },
              { year: "2017/2018", grade: 7, socioEconomicIndex: 6.7 },
              { year: "2016/2017", grade: 6, socioEconomicIndex: 6.6 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 4.6, socioEconomicIndex: 5.6 },
              { year: "2017/2018", grade: 5.8, socioEconomicIndex: 5.5 },
              { year: "2016/2017", grade: 5.3, socioEconomicIndex: 5.3 },
              { year: "2015/2016", grade: 5.9, socioEconomicIndex: 5.8 },
              { year: "2014/2015", grade: 5.3, socioEconomicIndex: 5.6 },
              { year: "2013/2014", grade: 5.3, socioEconomicIndex: 5.2 },
              { year: "2012/2013", grade: 5.6, socioEconomicIndex: 5.1 },
              { year: "2011/2012", grade: 4.8, socioEconomicIndex: 5 },
              { year: "2010/2011", grade: 4.9, socioEconomicIndex: 5.1 },
              { year: "2009/2010", grade: 5.8, socioEconomicIndex: 5.7 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 4.7, socioEconomicIndex: 5.5 },
              { year: "2017/2018", grade: 5.7, socioEconomicIndex: 5.6 },
              { year: "2016/2017", grade: 5.4, socioEconomicIndex: 5.7 },
              { year: "2015/2016", grade: 6.3, socioEconomicIndex: 6.2 },
              { year: "2014/2015", grade: 4.5, socioEconomicIndex: 5.4 },
              { year: "2013/2014", grade: 5.2, socioEconomicIndex: 5.6 },
              { year: "2012/2013", grade: 5.6, socioEconomicIndex: 5.8 },
              { year: "2011/2012", grade: 5.8, socioEconomicIndex: 6 },
              { year: "2010/2011", grade: 5.3, socioEconomicIndex: 5.6 },
              { year: "2009/2010", grade: 6.7, socioEconomicIndex: 6.7 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 5.9, socioEconomicIndex: 6.2 },
              { year: "2014/2015", grade: 7.1, socioEconomicIndex: 6.5 },
              { year: "2013/2014", grade: 5.4, socioEconomicIndex: 5.7 },
              { year: "2012/2013", grade: 5.7, socioEconomicIndex: 5.6 },
              { year: "2011/2012", grade: 6.1, socioEconomicIndex: 5.8 },
              { year: "2010/2011", grade: 4.8, socioEconomicIndex: 5.2 },
              { year: "2009/2010", grade: 5.6, socioEconomicIndex: 5.5 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Mou Skole",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 5, socioEconomicIndex: 5.3 },
              { year: "2017/2018", grade: 3.4, socioEconomicIndex: 4.2 },
              { year: "2016/2017", grade: 5.4, socioEconomicIndex: 5.6 },
              { year: "2015/2016", grade: 6.2, socioEconomicIndex: 6.3 },
              { year: "2014/2015", grade: 5.6, socioEconomicIndex: 5.9 },
              { year: "2013/2014", grade: 5.9, socioEconomicIndex: 5.9 },
              { year: "2012/2013", grade: 5, socioEconomicIndex: 5.5 },
              { year: "2011/2012", grade: 5.8, socioEconomicIndex: 6 },
              { year: "2010/2011", grade: 5, socioEconomicIndex: 5.4 },
              { year: "2009/2010", grade: 4.7, socioEconomicIndex: 5.2 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 3.9, socioEconomicIndex: 5.7 },
              { year: "2017/2018", grade: 6.9, socioEconomicIndex: 7 },
              { year: "2016/2017", grade: 6.3, socioEconomicIndex: 6.7 },
              { year: "2015/2016", grade: 7.2, socioEconomicIndex: 7.3 },
              { year: "2014/2015", grade: 7.2, socioEconomicIndex: 7.4 },
              { year: "2013/2014", grade: 8, socioEconomicIndex: 7.7 },
              { year: "2012/2013", grade: 8.6, socioEconomicIndex: 7.8 },
              { year: "2011/2012", grade: 7.4, socioEconomicIndex: 7.2 },
              { year: "2010/2011", grade: 8.3, socioEconomicIndex: 7.8 },
              { year: "2009/2010", grade: 4.6, socioEconomicIndex: 5.4 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 5.4, socioEconomicIndex: 6 },
              { year: "2017/2018", grade: 4.8, socioEconomicIndex: 5.4 },
              { year: "2016/2017", grade: 5.8, socioEconomicIndex: 6 },
              { year: "2015/2016", grade: 7, socioEconomicIndex: 6.6 },
              { year: "2014/2015", grade: 7.9, socioEconomicIndex: 7.3 },
              { year: "2013/2014", grade: 5.2, socioEconomicIndex: 5.5 },
              { year: "2012/2013", grade: 5.7, socioEconomicIndex: 5.9 },
              { year: "2011/2012", grade: 4.6, socioEconomicIndex: 5.7 },
              { year: "2010/2011", grade: 5.3, socioEconomicIndex: 5.7 },
              { year: "2009/2010", grade: 4.5, socioEconomicIndex: 5.4 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 4.8, socioEconomicIndex: 5.7 },
              { year: "2017/2018", grade: 4.4, socioEconomicIndex: 5.3 },
              { year: "2016/2017", grade: 6.1, socioEconomicIndex: 5.8 },
              { year: "2015/2016", grade: 7, socioEconomicIndex: 6.3 },
              { year: "2014/2015", grade: 7.1, socioEconomicIndex: 6.8 },
              { year: "2013/2014", grade: 6.5, socioEconomicIndex: 6.4 },
              { year: "2012/2013", grade: 6.1, socioEconomicIndex: 6 },
              { year: "2011/2012", grade: 5.9, socioEconomicIndex: 6.1 },
              { year: "2010/2011", grade: 5.8, socioEconomicIndex: 6 },
              { year: "2009/2010", grade: 4.2, socioEconomicIndex: 5 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 5.1, socioEconomicIndex: 5.5 },
              { year: "2010/2011", grade: 6.8, socioEconomicIndex: 5.8 },
              { year: "2009/2010", grade: 4.7, socioEconomicIndex: 5.2 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.1, socioEconomicIndex: 7.8 },
              { year: "2017/2018", grade: 6.9, socioEconomicIndex: 7 },
              { year: "2016/2017", grade: 7.9, socioEconomicIndex: 7.6 },
              { year: "2015/2016", grade: 7.2, socioEconomicIndex: 7.3 },
              { year: "2014/2015", grade: 7.4, socioEconomicIndex: 7.4 },
              { year: "2013/2014", grade: 9, socioEconomicIndex: 8.3 },
              { year: "2012/2013", grade: 7.2, socioEconomicIndex: 7.1 },
              { year: "2011/2012", grade: 7.3, socioEconomicIndex: 7.1 },
              { year: "2010/2011", grade: 7, socioEconomicIndex: 6.8 },
              { year: "2009/2010", grade: 4.6, socioEconomicIndex: 5.3 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 6.5, socioEconomicIndex: 6.8 },
              { year: "2017/2018", grade: 4.8, socioEconomicIndex: 5.9 },
              { year: "2016/2017", grade: 6.9, socioEconomicIndex: 6.8 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 6.9, socioEconomicIndex: 6.9 },
              { year: "2017/2018", grade: 5.8, socioEconomicIndex: 5.9 },
              { year: "2016/2017", grade: 5.9, socioEconomicIndex: 5.7 },
              { year: "2015/2016", grade: 5.9, socioEconomicIndex: 6.2 },
              { year: "2014/2015", grade: 6.5, socioEconomicIndex: 6.4 },
              { year: "2013/2014", grade: 6.7, socioEconomicIndex: 6.1 },
              { year: "2012/2013", grade: 6, socioEconomicIndex: 5.8 },
              { year: "2011/2012", grade: 5, socioEconomicIndex: 5.6 },
              { year: "2010/2011", grade: 5.2, socioEconomicIndex: 5.3 },
              { year: "2009/2010", grade: 4.8, socioEconomicIndex: 5.9 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 6.4, socioEconomicIndex: 6.6 },
              { year: "2017/2018", grade: 5.7, socioEconomicIndex: 5.9 },
              { year: "2016/2017", grade: 6.1, socioEconomicIndex: 6.1 },
              { year: "2015/2016", grade: 6.6, socioEconomicIndex: 6.6 },
              { year: "2014/2015", grade: 6, socioEconomicIndex: 6.3 },
              { year: "2013/2014", grade: 6.4, socioEconomicIndex: 6.3 },
              { year: "2012/2013", grade: 6.9, socioEconomicIndex: 6.7 },
              { year: "2011/2012", grade: 5.7, socioEconomicIndex: 6.2 },
              { year: "2010/2011", grade: 6, socioEconomicIndex: 6 },
              { year: "2009/2010", grade: 5.3, socioEconomicIndex: 6.5 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 6.3, socioEconomicIndex: 6.4 },
              { year: "2014/2015", grade: 8.5, socioEconomicIndex: 7.6 },
              { year: "2013/2014", grade: 6.5, socioEconomicIndex: 6.4 },
              { year: "2012/2013", grade: 6.3, socioEconomicIndex: 6.1 },
              { year: "2011/2012", grade: 6.4, socioEconomicIndex: 6.1 },
              { year: "2010/2011", grade: 4.8, socioEconomicIndex: 5.2 },
              { year: "2009/2010", grade: 4.4, socioEconomicIndex: 5 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Nibe Skole",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 7, socioEconomicIndex: 6.7 },
              { year: "2017/2018", grade: 7.5, socioEconomicIndex: 6.7 },
              { year: "2016/2017", grade: 7.2, socioEconomicIndex: 6.8 },
              { year: "2015/2016", grade: 7.7, socioEconomicIndex: 7.1 },
              { year: "2014/2015", grade: 6.9, socioEconomicIndex: 6.7 },
              { year: "2013/2014", grade: 7.3, socioEconomicIndex: 6.8 },
              { year: "2012/2013", grade: 7, socioEconomicIndex: 7.1 },
              { year: "2011/2012", grade: 7.1, socioEconomicIndex: 6.8 },
              { year: "2010/2011", grade: 6.6, socioEconomicIndex: 6.4 },
              { year: "2009/2010", grade: 7, socioEconomicIndex: 6.4 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 7.7, socioEconomicIndex: 8.2 },
              { year: "2017/2018", grade: 8.6, socioEconomicIndex: 8.3 },
              { year: "2016/2017", grade: 8.2, socioEconomicIndex: 8.1 },
              { year: "2015/2016", grade: 8.7, socioEconomicIndex: 8.4 },
              { year: "2014/2015", grade: 8.9, socioEconomicIndex: 8.3 },
              { year: "2013/2014", grade: 8.3, socioEconomicIndex: 8.1 },
              { year: "2012/2013", grade: 7.2, socioEconomicIndex: 7.8 },
              { year: "2011/2012", grade: 7.4, socioEconomicIndex: 7.4 },
              { year: "2010/2011", grade: 6.5, socioEconomicIndex: 7.3 },
              { year: "2009/2010", grade: 7.6, socioEconomicIndex: 7.4 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 7.8, socioEconomicIndex: 7.3 },
              { year: "2017/2018", grade: 7.6, socioEconomicIndex: 7.1 },
              { year: "2016/2017", grade: 7.8, socioEconomicIndex: 7.3 },
              { year: "2015/2016", grade: 8.5, socioEconomicIndex: 7.5 },
              { year: "2014/2015", grade: 8.4, socioEconomicIndex: 7.6 },
              { year: "2013/2014", grade: 6.6, socioEconomicIndex: 6.5 },
              { year: "2012/2013", grade: 7.8, socioEconomicIndex: 7.2 },
              { year: "2011/2012", grade: 6.6, socioEconomicIndex: 6.6 },
              { year: "2010/2011", grade: 7.1, socioEconomicIndex: 6.6 },
              { year: "2009/2010", grade: 7.1, socioEconomicIndex: 6.3 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 7.1, socioEconomicIndex: 6.9 },
              { year: "2017/2018", grade: 7.7, socioEconomicIndex: 6.7 },
              { year: "2016/2017", grade: 7.5, socioEconomicIndex: 6.7 },
              { year: "2015/2016", grade: 6.5, socioEconomicIndex: 6.7 },
              { year: "2014/2015", grade: 7.9, socioEconomicIndex: 7 },
              { year: "2013/2014", grade: 6, socioEconomicIndex: 6.6 },
              { year: "2012/2013", grade: 8.6, socioEconomicIndex: 7.3 },
              { year: "2011/2012", grade: 6.5, socioEconomicIndex: 6.4 },
              { year: "2010/2011", grade: 5.7, socioEconomicIndex: 6.4 },
              { year: "2009/2010", grade: 6.7, socioEconomicIndex: 6.3 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 6.6, socioEconomicIndex: 5.7 },
              { year: "2010/2011", grade: 6.8, socioEconomicIndex: 5.9 },
              { year: "2009/2010", grade: 7.6, socioEconomicIndex: 5.6 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 7.3, socioEconomicIndex: 8 },
              { year: "2017/2018", grade: 7.9, socioEconomicIndex: 8.1 },
              { year: "2016/2017", grade: 8.5, socioEconomicIndex: 8.1 },
              { year: "2015/2016", grade: 7.7, socioEconomicIndex: 7.9 },
              { year: "2014/2015", grade: 8.1, socioEconomicIndex: 7.9 },
              { year: "2013/2014", grade: 7.4, socioEconomicIndex: 7.7 },
              { year: "2012/2013", grade: 6.4, socioEconomicIndex: 7.5 },
              { year: "2011/2012", grade: 7.3, socioEconomicIndex: 7.4 },
              { year: "2010/2011", grade: 6.7, socioEconomicIndex: 7.2 },
              { year: "2009/2010", grade: 7.1, socioEconomicIndex: 7.1 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 7.8, socioEconomicIndex: 7.8 },
              { year: "2017/2018", grade: 7.6, socioEconomicIndex: 7.7 },
              { year: "2016/2017", grade: 8.5, socioEconomicIndex: 7.7 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 7.2, socioEconomicIndex: 7.6 },
              { year: "2017/2018", grade: 7.7, socioEconomicIndex: 7.5 },
              { year: "2016/2017", grade: 7.1, socioEconomicIndex: 7.1 },
              { year: "2015/2016", grade: 7.1, socioEconomicIndex: 7 },
              { year: "2014/2015", grade: 7.3, socioEconomicIndex: 7.3 },
              { year: "2013/2014", grade: 7.2, socioEconomicIndex: 6.7 },
              { year: "2012/2013", grade: 7.6, socioEconomicIndex: 7 },
              { year: "2011/2012", grade: 6.3, socioEconomicIndex: 6.6 },
              { year: "2010/2011", grade: 6.2, socioEconomicIndex: 6.4 },
              { year: "2009/2010", grade: 8.1, socioEconomicIndex: 7 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 7.5, socioEconomicIndex: 7.5 },
              { year: "2017/2018", grade: 7.5, socioEconomicIndex: 7.4 },
              { year: "2016/2017", grade: 8.7, socioEconomicIndex: 7.7 },
              { year: "2015/2016", grade: 7.8, socioEconomicIndex: 7.4 },
              { year: "2014/2015", grade: 8.2, socioEconomicIndex: 7.6 },
              { year: "2013/2014", grade: 8.1, socioEconomicIndex: 7.3 },
              { year: "2012/2013", grade: 8.7, socioEconomicIndex: 8 },
              { year: "2011/2012", grade: 7.2, socioEconomicIndex: 7.4 },
              { year: "2010/2011", grade: 7.6, socioEconomicIndex: 7.2 },
              { year: "2009/2010", grade: 8.6, socioEconomicIndex: 7.8 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 8.5, socioEconomicIndex: 7.5 },
              { year: "2014/2015", grade: 7.8, socioEconomicIndex: 7 },
              { year: "2013/2014", grade: 5.5, socioEconomicIndex: 6.5 },
              { year: "2012/2013", grade: 6.5, socioEconomicIndex: 6.7 },
              { year: "2011/2012", grade: 6.6, socioEconomicIndex: 6.5 },
              { year: "2010/2011", grade: 6.1, socioEconomicIndex: 6.3 },
              { year: "2009/2010", grade: 6.3, socioEconomicIndex: 6.1 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Nr Uttrup Skole",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 5.6, socioEconomicIndex: 5.3 },
              { year: "2017/2018", grade: 6.6, socioEconomicIndex: 5.8 },
              { year: "2016/2017", grade: 8.4, socioEconomicIndex: 7.4 },
              { year: "2015/2016", grade: 6.6, socioEconomicIndex: 6.4 },
              { year: "2014/2015", grade: 8.9, socioEconomicIndex: 6.8 },
              { year: "2013/2014", grade: 6.2, socioEconomicIndex: 6.4 },
              { year: "2012/2013", grade: 5.7, socioEconomicIndex: 5.7 },
              { year: "2011/2012", grade: 6.8, socioEconomicIndex: 6.5 },
              { year: "2010/2011", grade: 6.4, socioEconomicIndex: 5.9 },
              { year: "2009/2010", grade: 5.3, socioEconomicIndex: 5.4 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 6.5, socioEconomicIndex: 6.9 },
              { year: "2017/2018", grade: 9.2, socioEconomicIndex: 8.4 },
              { year: "2016/2017", grade: 9.2, socioEconomicIndex: 8.6 },
              { year: "2015/2016", grade: 8.1, socioEconomicIndex: 7.8 },
              { year: "2014/2015", grade: 8.5, socioEconomicIndex: 7.7 },
              { year: "2013/2014", grade: 8.5, socioEconomicIndex: 8.1 },
              { year: "2012/2013", grade: 5.4, socioEconomicIndex: 6.2 },
              { year: "2011/2012", grade: 7.7, socioEconomicIndex: 7.6 },
              { year: "2010/2011", grade: 7.6, socioEconomicIndex: 7.2 },
              { year: "2009/2010", grade: 6.8, socioEconomicIndex: 6.7 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 7.4, socioEconomicIndex: 6.4 },
              { year: "2017/2018", grade: 8.1, socioEconomicIndex: 6.8 },
              { year: "2016/2017", grade: 8.5, socioEconomicIndex: 7.5 },
              { year: "2015/2016", grade: 7.1, socioEconomicIndex: 6.7 },
              { year: "2014/2015", grade: 8.4, socioEconomicIndex: 6.9 },
              { year: "2013/2014", grade: 5.7, socioEconomicIndex: 6.2 },
              { year: "2012/2013", grade: 5.4, socioEconomicIndex: 5.7 },
              { year: "2011/2012", grade: 6.6, socioEconomicIndex: 6.4 },
              { year: "2010/2011", grade: 6.3, socioEconomicIndex: 6 },
              { year: "2009/2010", grade: 5.7, socioEconomicIndex: 5.6 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 5.6, socioEconomicIndex: 5.6 },
              { year: "2017/2018", grade: 6.7, socioEconomicIndex: 6.1 },
              { year: "2016/2017", grade: 8.5, socioEconomicIndex: 6.9 },
              { year: "2015/2016", grade: 4.8, socioEconomicIndex: 5.9 },
              { year: "2014/2015", grade: 7.1, socioEconomicIndex: 6.2 },
              { year: "2013/2014", grade: 6.8, socioEconomicIndex: 6.8 },
              { year: "2012/2013", grade: 6.1, socioEconomicIndex: 6 },
              { year: "2011/2012", grade: 6.8, socioEconomicIndex: 6.4 },
              { year: "2010/2011", grade: 7.1, socioEconomicIndex: 6.3 },
              { year: "2009/2010", grade: 6.1, socioEconomicIndex: 5.8 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 6.6, socioEconomicIndex: 5.9 },
              { year: "2010/2011", grade: 7.5, socioEconomicIndex: 5.7 },
              { year: "2009/2010", grade: 5.8, socioEconomicIndex: 5.4 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 7.3, socioEconomicIndex: 7.5 },
              { year: "2017/2018", grade: 7.2, socioEconomicIndex: 7.4 },
              { year: "2016/2017", grade: 9.1, socioEconomicIndex: 8.5 },
              { year: "2015/2016", grade: 7.5, socioEconomicIndex: 7.6 },
              { year: "2014/2015", grade: 8.1, socioEconomicIndex: 7.6 },
              { year: "2013/2014", grade: 6.5, socioEconomicIndex: 7.2 },
              { year: "2012/2013", grade: 6.3, socioEconomicIndex: 6.6 },
              { year: "2011/2012", grade: 7.8, socioEconomicIndex: 7.4 },
              { year: "2010/2011", grade: 7.6, socioEconomicIndex: 7.2 },
              { year: "2009/2010", grade: 6.7, socioEconomicIndex: 6.6 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.9, socioEconomicIndex: 7.3 },
              { year: "2017/2018", grade: 9.7, socioEconomicIndex: 7.9 },
              { year: "2016/2017", grade: 9.5, socioEconomicIndex: 8.1 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 6.6, socioEconomicIndex: 6.4 },
              { year: "2017/2018", grade: 7.6, socioEconomicIndex: 6.6 },
              { year: "2016/2017", grade: 7, socioEconomicIndex: 6.5 },
              { year: "2015/2016", grade: 6.2, socioEconomicIndex: 6.1 },
              { year: "2014/2015", grade: 8.4, socioEconomicIndex: 6.9 },
              { year: "2013/2014", grade: 6.1, socioEconomicIndex: 6.4 },
              { year: "2012/2013", grade: 5.5, socioEconomicIndex: 5.5 },
              { year: "2011/2012", grade: 6.7, socioEconomicIndex: 6.3 },
              { year: "2010/2011", grade: 6.5, socioEconomicIndex: 5.7 },
              { year: "2009/2010", grade: 5.2, socioEconomicIndex: 6 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 5.8, socioEconomicIndex: 6.1 },
              { year: "2017/2018", grade: 7.3, socioEconomicIndex: 6.6 },
              { year: "2016/2017", grade: 7.5, socioEconomicIndex: 7 },
              { year: "2015/2016", grade: 7.4, socioEconomicIndex: 6.7 },
              { year: "2014/2015", grade: 8.9, socioEconomicIndex: 7.3 },
              { year: "2013/2014", grade: 7.5, socioEconomicIndex: 7.3 },
              { year: "2012/2013", grade: 6.8, socioEconomicIndex: 6.6 },
              { year: "2011/2012", grade: 7.8, socioEconomicIndex: 7.1 },
              { year: "2010/2011", grade: 6.7, socioEconomicIndex: 6.4 },
              { year: "2009/2010", grade: 7.2, socioEconomicIndex: 7.2 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 7.6, socioEconomicIndex: 6.8 },
              { year: "2014/2015", grade: 7.5, socioEconomicIndex: 6.7 },
              { year: "2013/2014", grade: 7.8, socioEconomicIndex: 7.1 },
              { year: "2012/2013", grade: 6.7, socioEconomicIndex: 6.2 },
              { year: "2011/2012", grade: 6.2, socioEconomicIndex: 6.1 },
              { year: "2010/2011", grade: 6.7, socioEconomicIndex: 6 },
              { year: "2009/2010", grade: 5.5, socioEconomicIndex: 5.6 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Seminarieskolen",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 6.5, socioEconomicIndex: 5.9 },
              { year: "2017/2018", grade: 6.1, socioEconomicIndex: 5.6 },
              { year: "2016/2017", grade: 5.1, socioEconomicIndex: 5.2 },
              { year: "2015/2016", grade: 6.3, socioEconomicIndex: 6.4 },
              { year: "2014/2015", grade: 5.6, socioEconomicIndex: 5.4 },
              { year: "2013/2014", grade: 4.6, socioEconomicIndex: 5.4 },
              { year: "2012/2013", grade: 4.1, socioEconomicIndex: 5 },
              { year: "2011/2012", grade: 5.2, socioEconomicIndex: 5.8 },
              { year: "2010/2011", grade: 4.7, socioEconomicIndex: 4.9 },
              { year: "2009/2010", grade: 5.2, socioEconomicIndex: 5.5 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 6.2, socioEconomicIndex: 6.8 },
              { year: "2017/2018", grade: 7.6, socioEconomicIndex: 7.7 },
              { year: "2016/2017", grade: 5.6, socioEconomicIndex: 6.3 },
              { year: "2015/2016", grade: 6.7, socioEconomicIndex: 7.3 },
              { year: "2014/2015", grade: 7.4, socioEconomicIndex: 7.2 },
              { year: "2013/2014", grade: 7, socioEconomicIndex: 7 },
              { year: "2012/2013", grade: 6.4, socioEconomicIndex: 6.7 },
              { year: "2011/2012", grade: 7.1, socioEconomicIndex: 7.3 },
              { year: "2010/2011", grade: 5.2, socioEconomicIndex: 5.9 },
              { year: "2009/2010", grade: 6, socioEconomicIndex: 6.6 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 6.4, socioEconomicIndex: 6.2 },
              { year: "2017/2018", grade: 6.9, socioEconomicIndex: 6.3 },
              { year: "2016/2017", grade: 5.6, socioEconomicIndex: 5.8 },
              { year: "2015/2016", grade: 6.2, socioEconomicIndex: 6.7 },
              { year: "2014/2015", grade: 5.9, socioEconomicIndex: 6.1 },
              { year: "2013/2014", grade: 4, socioEconomicIndex: 5.4 },
              { year: "2012/2013", grade: 3.2, socioEconomicIndex: 4.9 },
              { year: "2011/2012", grade: 4.8, socioEconomicIndex: 5.8 },
              { year: "2010/2011", grade: 4.8, socioEconomicIndex: 5.1 },
              { year: "2009/2010", grade: 6, socioEconomicIndex: 5.8 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 6.8, socioEconomicIndex: 6.2 },
              { year: "2017/2018", grade: 4.2, socioEconomicIndex: 5.5 },
              { year: "2016/2017", grade: 5.5, socioEconomicIndex: 5.4 },
              { year: "2015/2016", grade: 6.7, socioEconomicIndex: 6.4 },
              { year: "2014/2015", grade: 6.8, socioEconomicIndex: 6.1 },
              { year: "2013/2014", grade: 3.5, socioEconomicIndex: 5.5 },
              { year: "2012/2013", grade: 3.8, socioEconomicIndex: 5.2 },
              { year: "2011/2012", grade: 5.9, socioEconomicIndex: 6.1 },
              { year: "2010/2011", grade: 3.8, socioEconomicIndex: 4.9 },
              { year: "2009/2010", grade: 5, socioEconomicIndex: 5.6 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 5.5, socioEconomicIndex: 5.3 },
              { year: "2010/2011", grade: 4.9, socioEconomicIndex: 5.1 },
              { year: "2009/2010", grade: 4.2, socioEconomicIndex: 5.3 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 6.1, socioEconomicIndex: 6.7 },
              { year: "2017/2018", grade: 6.6, socioEconomicIndex: 7 },
              { year: "2016/2017", grade: 5.9, socioEconomicIndex: 6.4 },
              { year: "2015/2016", grade: 7.4, socioEconomicIndex: 7.7 },
              { year: "2014/2015", grade: 7.5, socioEconomicIndex: 7.2 },
              { year: "2013/2014", grade: 7.2, socioEconomicIndex: 7.3 },
              { year: "2012/2013", grade: 6.7, socioEconomicIndex: 6.8 },
              { year: "2011/2012", grade: 7.6, socioEconomicIndex: 7.5 },
              { year: "2010/2011", grade: 5.5, socioEconomicIndex: 6 },
              { year: "2009/2010", grade: 7.3, socioEconomicIndex: 7 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 6.3, socioEconomicIndex: 6.5 },
              { year: "2017/2018", grade: 5.2, socioEconomicIndex: 6.3 },
              { year: "2016/2017", grade: 4.5, socioEconomicIndex: 5.8 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 4.6, socioEconomicIndex: 5.3 },
              { year: "2017/2018", grade: 4.6, socioEconomicIndex: 5.4 },
              { year: "2016/2017", grade: 3.8, socioEconomicIndex: 4.6 },
              { year: "2015/2016", grade: 5.9, socioEconomicIndex: 6.2 },
              { year: "2014/2015", grade: 5.6, socioEconomicIndex: 5.7 },
              { year: "2013/2014", grade: 5.3, socioEconomicIndex: 5.5 },
              { year: "2012/2013", grade: 3.6, socioEconomicIndex: 4.6 },
              { year: "2011/2012", grade: 4.6, socioEconomicIndex: 5.3 },
              { year: "2010/2011", grade: 4.8, socioEconomicIndex: 4.9 },
              { year: "2009/2010", grade: 6, socioEconomicIndex: 6.1 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 5.9, socioEconomicIndex: 5.6 },
              { year: "2017/2018", grade: 5.2, socioEconomicIndex: 5.5 },
              { year: "2016/2017", grade: 5.3, socioEconomicIndex: 5.5 },
              { year: "2015/2016", grade: 6.8, socioEconomicIndex: 6.8 },
              { year: "2014/2015", grade: 6, socioEconomicIndex: 6 },
              { year: "2013/2014", grade: 6.3, socioEconomicIndex: 6.3 },
              { year: "2012/2013", grade: 4.4, socioEconomicIndex: 5.4 },
              { year: "2011/2012", grade: 5.7, socioEconomicIndex: 6.1 },
              { year: "2010/2011", grade: 5.2, socioEconomicIndex: 5.6 },
              { year: "2009/2010", grade: 7.1, socioEconomicIndex: 7 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 6.2, socioEconomicIndex: 6.6 },
              { year: "2014/2015", grade: 6, socioEconomicIndex: 6.1 },
              { year: "2013/2014", grade: 6.5, socioEconomicIndex: 6.2 },
              { year: "2012/2013", grade: 5, socioEconomicIndex: 5.5 },
              { year: "2011/2012", grade: 4.8, socioEconomicIndex: 5.6 },
              { year: "2010/2011", grade: 4.8, socioEconomicIndex: 5.2 },
              { year: "2009/2010", grade: 4.2, socioEconomicIndex: 5.1 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Skansevejens Skole",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 6.6, socioEconomicIndex: 6.5 },
              { year: "2017/2018", grade: 6.5, socioEconomicIndex: 6.4 },
              { year: "2016/2017", grade: 6.1, socioEconomicIndex: 6.7 },
              { year: "2015/2016", grade: 7.7, socioEconomicIndex: 7 },
              { year: "2014/2015", grade: 5.8, socioEconomicIndex: 6.4 },
              { year: "2013/2014", grade: 6.6, socioEconomicIndex: 6 },
              { year: "2012/2013", grade: 6.4, socioEconomicIndex: 6.6 },
              { year: "2011/2012", grade: 6.6, socioEconomicIndex: 6.5 },
              { year: "2010/2011", grade: 5.5, socioEconomicIndex: 5.7 },
              { year: "2009/2010", grade: 5.8, socioEconomicIndex: 6 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.2, socioEconomicIndex: 8.2 },
              { year: "2017/2018", grade: 6.6, socioEconomicIndex: 7.5 },
              { year: "2016/2017", grade: 8.8, socioEconomicIndex: 8.6 },
              { year: "2015/2016", grade: 7.7, socioEconomicIndex: 7.9 },
              { year: "2014/2015", grade: 7.5, socioEconomicIndex: 7.8 },
              { year: "2013/2014", grade: 7.8, socioEconomicIndex: 7.5 },
              { year: "2012/2013", grade: 7.7, socioEconomicIndex: 7.7 },
              { year: "2011/2012", grade: 6.9, socioEconomicIndex: 7.1 },
              { year: "2010/2011", grade: 6, socioEconomicIndex: 6.6 },
              { year: "2009/2010", grade: 6.5, socioEconomicIndex: 6.8 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 7.6, socioEconomicIndex: 7.3 },
              { year: "2017/2018", grade: 7.4, socioEconomicIndex: 7.2 },
              { year: "2016/2017", grade: 6.8, socioEconomicIndex: 7.4 },
              { year: "2015/2016", grade: 7.4, socioEconomicIndex: 7.1 },
              { year: "2014/2015", grade: 6.4, socioEconomicIndex: 7.3 },
              { year: "2013/2014", grade: 5, socioEconomicIndex: 5.2 },
              { year: "2012/2013", grade: 6.7, socioEconomicIndex: 6.7 },
              { year: "2011/2012", grade: 6.3, socioEconomicIndex: 6.3 },
              { year: "2010/2011", grade: 5.9, socioEconomicIndex: 5.9 },
              { year: "2009/2010", grade: 6.2, socioEconomicIndex: 6.1 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 7, socioEconomicIndex: 6.8 },
              { year: "2017/2018", grade: 6.9, socioEconomicIndex: 6.5 },
              { year: "2016/2017", grade: 8.3, socioEconomicIndex: 7.3 },
              { year: "2015/2016", grade: 6.8, socioEconomicIndex: 6.6 },
              { year: "2014/2015", grade: 6.6, socioEconomicIndex: 6.7 },
              { year: "2013/2014", grade: 6.6, socioEconomicIndex: 6.2 },
              { year: "2012/2013", grade: 6.1, socioEconomicIndex: 6.5 },
              { year: "2011/2012", grade: 7.2, socioEconomicIndex: 6.5 },
              { year: "2010/2011", grade: 6.8, socioEconomicIndex: 6.2 },
              { year: "2009/2010", grade: 6.4, socioEconomicIndex: 6.1 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 5.8, socioEconomicIndex: 5.8 },
              { year: "2010/2011", grade: 5.8, socioEconomicIndex: 5.5 },
              { year: "2009/2010", grade: 5.6, socioEconomicIndex: 5.4 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.7, socioEconomicIndex: 8.6 },
              { year: "2017/2018", grade: 8.8, socioEconomicIndex: 8.6 },
              { year: "2016/2017", grade: 7.3, socioEconomicIndex: 7.8 },
              { year: "2015/2016", grade: 7.4, socioEconomicIndex: 7.6 },
              { year: "2014/2015", grade: 7.6, socioEconomicIndex: 8 },
              { year: "2013/2014", grade: 7.2, socioEconomicIndex: 7.1 },
              { year: "2012/2013", grade: 7.7, socioEconomicIndex: 7.7 },
              { year: "2011/2012", grade: 7, socioEconomicIndex: 7.1 },
              { year: "2010/2011", grade: 6.1, socioEconomicIndex: 6.6 },
              { year: "2009/2010", grade: 6.5, socioEconomicIndex: 6.7 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.2, socioEconomicIndex: 7.9 },
              { year: "2017/2018", grade: 7.1, socioEconomicIndex: 7.5 },
              { year: "2016/2017", grade: 8.1, socioEconomicIndex: 7.9 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 8.1, socioEconomicIndex: 7.4 },
              { year: "2017/2018", grade: 7.3, socioEconomicIndex: 7.3 },
              { year: "2016/2017", grade: 7.5, socioEconomicIndex: 7.2 },
              { year: "2015/2016", grade: 7.8, socioEconomicIndex: 6.6 },
              { year: "2014/2015", grade: 6.3, socioEconomicIndex: 7.2 },
              { year: "2013/2014", grade: 5.9, socioEconomicIndex: 5.5 },
              { year: "2012/2013", grade: 5.8, socioEconomicIndex: 6.3 },
              { year: "2011/2012", grade: 6.7, socioEconomicIndex: 6.1 },
              { year: "2010/2011", grade: 4.4, socioEconomicIndex: 5.4 },
              { year: "2009/2010", grade: 6.8, socioEconomicIndex: 6.6 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 7.2, socioEconomicIndex: 7.2 },
              { year: "2017/2018", grade: 7.1, socioEconomicIndex: 7.1 },
              { year: "2016/2017", grade: 7.3, socioEconomicIndex: 7.3 },
              { year: "2015/2016", grade: 7.7, socioEconomicIndex: 7 },
              { year: "2014/2015", grade: 7.3, socioEconomicIndex: 7.6 },
              { year: "2013/2014", grade: 5.4, socioEconomicIndex: 5.7 },
              { year: "2012/2013", grade: 6.4, socioEconomicIndex: 7 },
              { year: "2011/2012", grade: 7.2, socioEconomicIndex: 6.8 },
              { year: "2010/2011", grade: 5.9, socioEconomicIndex: 6.4 },
              { year: "2009/2010", grade: 7.6, socioEconomicIndex: 7.5 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 7.6, socioEconomicIndex: 6.9 },
              { year: "2014/2015", grade: 7.2, socioEconomicIndex: 7.1 },
              { year: "2013/2014", grade: 6.4, socioEconomicIndex: 6.2 },
              { year: "2012/2013", grade: 7.4, socioEconomicIndex: 6.8 },
              { year: "2011/2012", grade: 6, socioEconomicIndex: 5.9 },
              { year: "2010/2011", grade: 5.7, socioEconomicIndex: 5.8 },
              { year: "2009/2010", grade: 5.1, socioEconomicIndex: 5.6 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Sofiendalskolen",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 6, socioEconomicIndex: 6 },
              { year: "2017/2018", grade: 5.3, socioEconomicIndex: 5.8 },
              { year: "2016/2017", grade: 5.7, socioEconomicIndex: 5.9 },
              { year: "2015/2016", grade: 6.3, socioEconomicIndex: 6.5 },
              { year: "2014/2015", grade: 5.8, socioEconomicIndex: 5.8 },
              { year: "2013/2014", grade: 5.6, socioEconomicIndex: 6.1 },
              { year: "2012/2013", grade: 6, socioEconomicIndex: 6 },
              { year: "2011/2012", grade: 5.9, socioEconomicIndex: 6.3 },
              { year: "2010/2011", grade: 6.1, socioEconomicIndex: 5.7 },
              { year: "2009/2010", grade: 5.4, socioEconomicIndex: 5.5 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8, socioEconomicIndex: 8.1 },
              { year: "2017/2018", grade: 7.6, socioEconomicIndex: 7.7 },
              { year: "2016/2017", grade: 8.3, socioEconomicIndex: 7.7 },
              { year: "2015/2016", grade: 7.7, socioEconomicIndex: 7.7 },
              { year: "2014/2015", grade: 7.4, socioEconomicIndex: 7.4 },
              { year: "2013/2014", grade: 7.7, socioEconomicIndex: 7.6 },
              { year: "2012/2013", grade: 6.6, socioEconomicIndex: 7 },
              { year: "2011/2012", grade: 6.1, socioEconomicIndex: 6.9 },
              { year: "2010/2011", grade: 6.2, socioEconomicIndex: 6.8 },
              { year: "2009/2010", grade: 5.9, socioEconomicIndex: 6.5 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 7.1, socioEconomicIndex: 7.1 },
              { year: "2017/2018", grade: 6.9, socioEconomicIndex: 6.8 },
              { year: "2016/2017", grade: 6.6, socioEconomicIndex: 6.6 },
              { year: "2015/2016", grade: 6.9, socioEconomicIndex: 6.8 },
              { year: "2014/2015", grade: 7.6, socioEconomicIndex: 6.9 },
              { year: "2013/2014", grade: 5.7, socioEconomicIndex: 6 },
              { year: "2012/2013", grade: 5.8, socioEconomicIndex: 5.9 },
              { year: "2011/2012", grade: 5.9, socioEconomicIndex: 6.4 },
              { year: "2010/2011", grade: 6.2, socioEconomicIndex: 5.8 },
              { year: "2009/2010", grade: 6.8, socioEconomicIndex: 6 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 7, socioEconomicIndex: 6.6 },
              { year: "2017/2018", grade: 8.9, socioEconomicIndex: 6.8 },
              { year: "2016/2017", grade: 5.8, socioEconomicIndex: 5.9 },
              { year: "2015/2016", grade: 6, socioEconomicIndex: 6.2 },
              { year: "2014/2015", grade: 5.9, socioEconomicIndex: 6.1 },
              { year: "2013/2014", grade: 6.8, socioEconomicIndex: 6.6 },
              { year: "2012/2013", grade: 6.1, socioEconomicIndex: 6.1 },
              { year: "2011/2012", grade: 6.6, socioEconomicIndex: 6.2 },
              { year: "2010/2011", grade: 5.2, socioEconomicIndex: 5.8 },
              { year: "2009/2010", grade: 6.6, socioEconomicIndex: 6 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 3.9, socioEconomicIndex: 5.5 },
              { year: "2010/2011", grade: 4.2, socioEconomicIndex: 5.4 },
              { year: "2009/2010", grade: 6.7, socioEconomicIndex: 5.5 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.8, socioEconomicIndex: 8.4 },
              { year: "2017/2018", grade: 7.6, socioEconomicIndex: 7.8 },
              { year: "2016/2017", grade: 8.9, socioEconomicIndex: 8.1 },
              { year: "2015/2016", grade: 7.1, socioEconomicIndex: 7.6 },
              { year: "2014/2015", grade: 7.6, socioEconomicIndex: 7.5 },
              { year: "2013/2014", grade: 7.7, socioEconomicIndex: 7.7 },
              { year: "2012/2013", grade: 6.7, socioEconomicIndex: 6.9 },
              { year: "2011/2012", grade: 7.3, socioEconomicIndex: 7.3 },
              { year: "2010/2011", grade: 5.9, socioEconomicIndex: 6.7 },
              { year: "2009/2010", grade: 6.6, socioEconomicIndex: 6.7 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 6.6, socioEconomicIndex: 7.3 },
              { year: "2017/2018", grade: 7.6, socioEconomicIndex: 7.4 },
              { year: "2016/2017", grade: 8.1, socioEconomicIndex: 7.1 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 5.6, socioEconomicIndex: 6.9 },
              { year: "2017/2018", grade: 5.9, socioEconomicIndex: 6.8 },
              { year: "2016/2017", grade: 5, socioEconomicIndex: 5.8 },
              { year: "2015/2016", grade: 5.1, socioEconomicIndex: 6.2 },
              { year: "2014/2015", grade: 6, socioEconomicIndex: 6.4 },
              { year: "2013/2014", grade: 5.7, socioEconomicIndex: 6 },
              { year: "2012/2013", grade: 5.2, socioEconomicIndex: 5.4 },
              { year: "2011/2012", grade: 4.8, socioEconomicIndex: 6.1 },
              { year: "2010/2011", grade: 6.6, socioEconomicIndex: 5.6 },
              { year: "2009/2010", grade: 6.5, socioEconomicIndex: 6.4 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 6.5, socioEconomicIndex: 7 },
              { year: "2017/2018", grade: 5.8, socioEconomicIndex: 6.6 },
              { year: "2016/2017", grade: 5.9, socioEconomicIndex: 6.4 },
              { year: "2015/2016", grade: 5.9, socioEconomicIndex: 6.6 },
              { year: "2014/2015", grade: 6.8, socioEconomicIndex: 6.8 },
              { year: "2013/2014", grade: 6, socioEconomicIndex: 6.5 },
              { year: "2012/2013", grade: 5.8, socioEconomicIndex: 6.3 },
              { year: "2011/2012", grade: 5.6, socioEconomicIndex: 6.8 },
              { year: "2010/2011", grade: 6.5, socioEconomicIndex: 6.3 },
              { year: "2009/2010", grade: 7, socioEconomicIndex: 7.2 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 5.5, socioEconomicIndex: 6.3 },
              { year: "2014/2015", grade: 6.6, socioEconomicIndex: 6.5 },
              { year: "2013/2014", grade: 6.9, socioEconomicIndex: 6.4 },
              { year: "2012/2013", grade: 5.1, socioEconomicIndex: 5.8 },
              { year: "2011/2012", grade: 5.6, socioEconomicIndex: 6.1 },
              { year: "2010/2011", grade: 6.3, socioEconomicIndex: 6 },
              { year: "2009/2010", grade: 4.9, socioEconomicIndex: 5.5 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Stolpedalsskolen",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2017/2018", grade: 7.3, socioEconomicIndex: 7 },
              { year: "2016/2017", grade: 7.8, socioEconomicIndex: 7.4 },
              { year: "2015/2016", grade: 7.7, socioEconomicIndex: 7.8 },
              { year: "2014/2015", grade: 7.3, socioEconomicIndex: 7.4 },
              { year: "2013/2014", grade: 7.3, socioEconomicIndex: 7.4 },
              { year: "2012/2013", grade: 7.7, socioEconomicIndex: 7.6 },
              { year: "2011/2012", grade: 8.1, socioEconomicIndex: 7.7 },
              { year: "2010/2011", grade: 6.9, socioEconomicIndex: 6.7 },
              { year: "2009/2010", grade: 7.6, socioEconomicIndex: 7 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2017/2018", grade: 8.9, socioEconomicIndex: 8.7 },
              { year: "2016/2017", grade: 9.4, socioEconomicIndex: 8.8 },
              { year: "2015/2016", grade: 8.2, socioEconomicIndex: 8.7 },
              { year: "2014/2015", grade: 10.4, socioEconomicIndex: 9.3 },
              { year: "2013/2014", grade: 9.2, socioEconomicIndex: 8.9 },
              { year: "2012/2013", grade: 8.9, socioEconomicIndex: 8.6 },
              { year: "2011/2012", grade: 9.7, socioEconomicIndex: 8.8 },
              { year: "2010/2011", grade: 8.5, socioEconomicIndex: 8.2 },
              { year: "2009/2010", grade: 7.9, socioEconomicIndex: 8.2 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2017/2018", grade: 8.1, socioEconomicIndex: 7.7 },
              { year: "2016/2017", grade: 9.3, socioEconomicIndex: 8.2 },
              { year: "2015/2016", grade: 8.3, socioEconomicIndex: 8.2 },
              { year: "2014/2015", grade: 9.6, socioEconomicIndex: 8.5 },
              { year: "2013/2014", grade: 8.5, socioEconomicIndex: 7.5 },
              { year: "2012/2013", grade: 8.6, socioEconomicIndex: 7.6 },
              { year: "2011/2012", grade: 8.8, socioEconomicIndex: 7.8 },
              { year: "2010/2011", grade: 7.4, socioEconomicIndex: 6.9 },
              { year: "2009/2010", grade: 8.4, socioEconomicIndex: 7.3 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2017/2018", grade: 7.3, socioEconomicIndex: 7 },
              { year: "2016/2017", grade: 7, socioEconomicIndex: 7 },
              { year: "2015/2016", grade: 8, socioEconomicIndex: 7.4 },
              { year: "2014/2015", grade: 8.4, socioEconomicIndex: 7.6 },
              { year: "2013/2014", grade: 7.6, socioEconomicIndex: 7.5 },
              { year: "2012/2013", grade: 7.5, socioEconomicIndex: 7.3 },
              { year: "2011/2012", grade: 8.4, socioEconomicIndex: 7.5 },
              { year: "2010/2011", grade: 6.6, socioEconomicIndex: 6.7 },
              { year: "2009/2010", grade: 7.8, socioEconomicIndex: 7.2 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 9.1, socioEconomicIndex: 6.8 },
              { year: "2010/2011", grade: 5.4, socioEconomicIndex: 5.8 },
              { year: "2009/2010", grade: 7, socioEconomicIndex: 6 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2017/2018", grade: 9.1, socioEconomicIndex: 9 },
              { year: "2016/2017", grade: 9.2, socioEconomicIndex: 8.9 },
              { year: "2015/2016", grade: 8.9, socioEconomicIndex: 8.9 },
              { year: "2014/2015", grade: 9.8, socioEconomicIndex: 9.4 },
              { year: "2013/2014", grade: 9, socioEconomicIndex: 9 },
              { year: "2012/2013", grade: 9, socioEconomicIndex: 8.6 },
              { year: "2011/2012", grade: 8.8, socioEconomicIndex: 8.7 },
              { year: "2010/2011", grade: 8, socioEconomicIndex: 8.2 },
              { year: "2009/2010", grade: 8.9, socioEconomicIndex: 8.4 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2017/2018", grade: 7.6, socioEconomicIndex: 8.1 },
              { year: "2016/2017", grade: 7.8, socioEconomicIndex: 8.2 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2017/2018", grade: 8.7, socioEconomicIndex: 8.1 },
              { year: "2016/2017", grade: 8, socioEconomicIndex: 7.8 },
              { year: "2015/2016", grade: 7.3, socioEconomicIndex: 7.7 },
              { year: "2014/2015", grade: 9, socioEconomicIndex: 8.5 },
              { year: "2013/2014", grade: 7.5, socioEconomicIndex: 7.4 },
              { year: "2012/2013", grade: 6.4, socioEconomicIndex: 7 },
              { year: "2011/2012", grade: 8.5, socioEconomicIndex: 7.8 },
              { year: "2010/2011", grade: 7.8, socioEconomicIndex: 7 },
              { year: "2009/2010", grade: 9.1, socioEconomicIndex: 7.8 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2017/2018", grade: 8.1, socioEconomicIndex: 8.2 },
              { year: "2016/2017", grade: 8.2, socioEconomicIndex: 8.2 },
              { year: "2015/2016", grade: 8.1, socioEconomicIndex: 8.1 },
              { year: "2014/2015", grade: 8.8, socioEconomicIndex: 8.7 },
              { year: "2013/2014", grade: 8.3, socioEconomicIndex: 8.1 },
              { year: "2012/2013", grade: 8.5, socioEconomicIndex: 8.2 },
              { year: "2011/2012", grade: 8.9, socioEconomicIndex: 8.3 },
              { year: "2010/2011", grade: 8.6, socioEconomicIndex: 7.7 },
              { year: "2009/2010", grade: 9.2, socioEconomicIndex: 8.4 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 6.6, socioEconomicIndex: 7.4 },
              { year: "2014/2015", grade: 7.9, socioEconomicIndex: 7.9 },
              { year: "2013/2014", grade: 7.8, socioEconomicIndex: 7.6 },
              { year: "2012/2013", grade: 7.8, socioEconomicIndex: 7.3 },
              { year: "2011/2012", grade: 7.5, socioEconomicIndex: 7.3 },
              { year: "2010/2011", grade: 5.3, socioEconomicIndex: 6.5 },
              { year: "2009/2010", grade: 6.2, socioEconomicIndex: 6.8 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Sulsted Skole",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 5.6, socioEconomicIndex: 5.9 },
              { year: "2017/2018", grade: 4.7, socioEconomicIndex: 5.3 },
              { year: "2016/2017", grade: 5.9, socioEconomicIndex: 6 },
              { year: "2015/2016", grade: 6.8, socioEconomicIndex: 6.6 },
              { year: "2014/2015", grade: 5.7, socioEconomicIndex: 5.9 },
              { year: "2013/2014", grade: 6.2, socioEconomicIndex: 6.1 },
              { year: "2012/2013", grade: 5.8, socioEconomicIndex: 5.8 },
              { year: "2011/2012", grade: 5.8, socioEconomicIndex: 6 },
              { year: "2010/2011", grade: 5.2, socioEconomicIndex: 5.8 },
              { year: "2009/2010", grade: 6.4, socioEconomicIndex: 6.1 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.6, socioEconomicIndex: 8.1 },
              { year: "2017/2018", grade: 7.9, socioEconomicIndex: 7.8 },
              { year: "2016/2017", grade: 7.7, socioEconomicIndex: 7.5 },
              { year: "2015/2016", grade: 7.7, socioEconomicIndex: 7.6 },
              { year: "2014/2015", grade: 7.7, socioEconomicIndex: 7.6 },
              { year: "2013/2014", grade: 7.8, socioEconomicIndex: 7.6 },
              { year: "2012/2013", grade: 6.8, socioEconomicIndex: 6.9 },
              { year: "2011/2012", grade: 9.1, socioEconomicIndex: 7.9 },
              { year: "2010/2011", grade: 7.7, socioEconomicIndex: 7.4 },
              { year: "2009/2010", grade: 6.6, socioEconomicIndex: 6.7 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 6, socioEconomicIndex: 6.5 },
              { year: "2017/2018", grade: 5.8, socioEconomicIndex: 6.2 },
              { year: "2016/2017", grade: 6.8, socioEconomicIndex: 6.7 },
              { year: "2015/2016", grade: 6.1, socioEconomicIndex: 6.6 },
              { year: "2014/2015", grade: 6.3, socioEconomicIndex: 6.6 },
              { year: "2013/2014", grade: 6.6, socioEconomicIndex: 6 },
              { year: "2012/2013", grade: 6.4, socioEconomicIndex: 5.9 },
              { year: "2011/2012", grade: 6.4, socioEconomicIndex: 6.1 },
              { year: "2010/2011", grade: 5.7, socioEconomicIndex: 6.1 },
              { year: "2009/2010", grade: 6.4, socioEconomicIndex: 6.1 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 5.8, socioEconomicIndex: 6.2 },
              { year: "2017/2018", grade: 5.2, socioEconomicIndex: 6 },
              { year: "2016/2017", grade: 7, socioEconomicIndex: 6.2 },
              { year: "2015/2016", grade: 4, socioEconomicIndex: 5.8 },
              { year: "2014/2015", grade: 6.9, socioEconomicIndex: 6.5 },
              { year: "2013/2014", grade: 6.5, socioEconomicIndex: 6.4 },
              { year: "2012/2013", grade: 6, socioEconomicIndex: 5.9 },
              { year: "2011/2012", grade: 6.2, socioEconomicIndex: 6 },
              { year: "2010/2011", grade: 5.1, socioEconomicIndex: 6 },
              { year: "2009/2010", grade: 5.9, socioEconomicIndex: 5.9 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 6.3, socioEconomicIndex: 5.5 },
              { year: "2010/2011", grade: 4.8, socioEconomicIndex: 5.5 },
              { year: "2009/2010", grade: 6.9, socioEconomicIndex: 5.5 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 7.7, socioEconomicIndex: 7.8 },
              { year: "2017/2018", grade: 5.3, socioEconomicIndex: 6.4 },
              { year: "2016/2017", grade: 6.5, socioEconomicIndex: 7.1 },
              { year: "2015/2016", grade: 6.2, socioEconomicIndex: 7 },
              { year: "2014/2015", grade: 7.9, socioEconomicIndex: 7.7 },
              { year: "2013/2014", grade: 6, socioEconomicIndex: 6.8 },
              { year: "2012/2013", grade: 6.9, socioEconomicIndex: 7 },
              { year: "2011/2012", grade: 7, socioEconomicIndex: 6.9 },
              { year: "2010/2011", grade: 5.7, socioEconomicIndex: 6.6 },
              { year: "2009/2010", grade: 6.6, socioEconomicIndex: 6.6 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 6.3, socioEconomicIndex: 7 },
              { year: "2017/2018", grade: 7.2, socioEconomicIndex: 7.1 },
              { year: "2016/2017", grade: 5.9, socioEconomicIndex: 6.7 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 8.1, socioEconomicIndex: 7.2 },
              { year: "2017/2018", grade: 7.4, socioEconomicIndex: 6.7 },
              { year: "2016/2017", grade: 7.1, socioEconomicIndex: 6.5 },
              { year: "2015/2016", grade: 5.6, socioEconomicIndex: 6.4 },
              { year: "2014/2015", grade: 7.9, socioEconomicIndex: 7.1 },
              { year: "2013/2014", grade: 5.7, socioEconomicIndex: 5.9 },
              { year: "2012/2013", grade: 5.2, socioEconomicIndex: 5.5 },
              { year: "2011/2012", grade: 6.5, socioEconomicIndex: 6 },
              { year: "2010/2011", grade: 6, socioEconomicIndex: 6.1 },
              { year: "2009/2010", grade: 5.8, socioEconomicIndex: 6.2 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 6.8, socioEconomicIndex: 6.9 },
              { year: "2017/2018", grade: 7, socioEconomicIndex: 6.6 },
              { year: "2016/2017", grade: 7, socioEconomicIndex: 6.8 },
              { year: "2015/2016", grade: 6.6, socioEconomicIndex: 6.8 },
              { year: "2014/2015", grade: 8.4, socioEconomicIndex: 7.5 },
              { year: "2013/2014", grade: 6.8, socioEconomicIndex: 6.5 },
              { year: "2012/2013", grade: 6.4, socioEconomicIndex: 6.5 },
              { year: "2011/2012", grade: 7.7, socioEconomicIndex: 7 },
              { year: "2010/2011", grade: 5.6, socioEconomicIndex: 6.6 },
              { year: "2009/2010", grade: 6.5, socioEconomicIndex: 6.9 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 7.2, socioEconomicIndex: 6.8 },
              { year: "2014/2015", grade: 6.7, socioEconomicIndex: 6.7 },
              { year: "2013/2014", grade: 6.4, socioEconomicIndex: 6.4 },
              { year: "2012/2013", grade: 6.6, socioEconomicIndex: 6.1 },
              { year: "2011/2012", grade: 5.9, socioEconomicIndex: 6 },
              { year: "2010/2011", grade: 5.1, socioEconomicIndex: 5.8 },
              { year: "2009/2010", grade: 4.6, socioEconomicIndex: 5.1 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Svenstrup Skole",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 6.6, socioEconomicIndex: 6.7 },
              { year: "2017/2018", grade: 7.7, socioEconomicIndex: 7.1 },
              { year: "2016/2017", grade: 6.3, socioEconomicIndex: 6.4 },
              { year: "2015/2016", grade: 7.5, socioEconomicIndex: 7 },
              { year: "2014/2015", grade: 6.3, socioEconomicIndex: 6.2 },
              { year: "2013/2014", grade: 6.7, socioEconomicIndex: 6.3 },
              { year: "2012/2013", grade: 6.8, socioEconomicIndex: 6.8 },
              { year: "2011/2012", grade: 6.8, socioEconomicIndex: 6.4 },
              { year: "2010/2011", grade: 6.8, socioEconomicIndex: 6.3 },
              { year: "2009/2010", grade: 6.8, socioEconomicIndex: 6.3 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.5, socioEconomicIndex: 8.5 },
              { year: "2017/2018", grade: 9.1, socioEconomicIndex: 8.7 },
              { year: "2016/2017", grade: 8.7, socioEconomicIndex: 8 },
              { year: "2015/2016", grade: 7.8, socioEconomicIndex: 7.9 },
              { year: "2014/2015", grade: 8.3, socioEconomicIndex: 7.9 },
              { year: "2013/2014", grade: 8.3, socioEconomicIndex: 7.9 },
              { year: "2012/2013", grade: 7.8, socioEconomicIndex: 7.8 },
              { year: "2011/2012", grade: 7.8, socioEconomicIndex: 7.3 },
              { year: "2010/2011", grade: 7.8, socioEconomicIndex: 7.3 },
              { year: "2009/2010", grade: 8, socioEconomicIndex: 7.4 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 7.4, socioEconomicIndex: 7.3 },
              { year: "2017/2018", grade: 8.1, socioEconomicIndex: 7.5 },
              { year: "2016/2017", grade: 7.4, socioEconomicIndex: 6.9 },
              { year: "2015/2016", grade: 8.8, socioEconomicIndex: 7.3 },
              { year: "2014/2015", grade: 7.1, socioEconomicIndex: 7 },
              { year: "2013/2014", grade: 8, socioEconomicIndex: 6.4 },
              { year: "2012/2013", grade: 7.6, socioEconomicIndex: 6.9 },
              { year: "2011/2012", grade: 7.1, socioEconomicIndex: 6.4 },
              { year: "2010/2011", grade: 6.8, socioEconomicIndex: 6.3 },
              { year: "2009/2010", grade: 7.8, socioEconomicIndex: 6.5 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 6.8, socioEconomicIndex: 6.9 },
              { year: "2017/2018", grade: 8.8, socioEconomicIndex: 7 },
              { year: "2016/2017", grade: 5.6, socioEconomicIndex: 6.1 },
              { year: "2015/2016", grade: 8.7, socioEconomicIndex: 6.9 },
              { year: "2014/2015", grade: 6.5, socioEconomicIndex: 6.3 },
              { year: "2013/2014", grade: 7.6, socioEconomicIndex: 6.8 },
              { year: "2012/2013", grade: 6.6, socioEconomicIndex: 6.5 },
              { year: "2011/2012", grade: 7.4, socioEconomicIndex: 6.4 },
              { year: "2010/2011", grade: 5.1, socioEconomicIndex: 5.9 },
              { year: "2009/2010", grade: 6.9, socioEconomicIndex: 6.1 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 7.7, socioEconomicIndex: 5.7 },
              { year: "2010/2011", grade: 4.9, socioEconomicIndex: 5.4 },
              { year: "2009/2010", grade: 7.5, socioEconomicIndex: 5.6 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.4, socioEconomicIndex: 8.4 },
              { year: "2017/2018", grade: 9.4, socioEconomicIndex: 8.9 },
              { year: "2016/2017", grade: 8.2, socioEconomicIndex: 8 },
              { year: "2015/2016", grade: 8.5, socioEconomicIndex: 8.1 },
              { year: "2014/2015", grade: 8.1, socioEconomicIndex: 7.7 },
              { year: "2013/2014", grade: 8.4, socioEconomicIndex: 7.9 },
              { year: "2012/2013", grade: 8.4, socioEconomicIndex: 8 },
              { year: "2011/2012", grade: 7.2, socioEconomicIndex: 7.1 },
              { year: "2010/2011", grade: 7.5, socioEconomicIndex: 7.3 },
              { year: "2009/2010", grade: 7.5, socioEconomicIndex: 7.2 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 9.1, socioEconomicIndex: 8.2 },
              { year: "2017/2018", grade: 7.6, socioEconomicIndex: 7.9 },
              { year: "2016/2017", grade: 8.2, socioEconomicIndex: 7.5 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 8.5, socioEconomicIndex: 7.8 },
              { year: "2017/2018", grade: 8.3, socioEconomicIndex: 7.8 },
              { year: "2016/2017", grade: 7.8, socioEconomicIndex: 7 },
              { year: "2015/2016", grade: 7.8, socioEconomicIndex: 7 },
              { year: "2014/2015", grade: 6.5, socioEconomicIndex: 6.8 },
              { year: "2013/2014", grade: 7.3, socioEconomicIndex: 6.5 },
              { year: "2012/2013", grade: 6.8, socioEconomicIndex: 6.7 },
              { year: "2011/2012", grade: 7.1, socioEconomicIndex: 6.4 },
              { year: "2010/2011", grade: 6.4, socioEconomicIndex: 6.2 },
              { year: "2009/2010", grade: 7.7, socioEconomicIndex: 6.9 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 7.5, socioEconomicIndex: 7.5 },
              { year: "2017/2018", grade: 8.4, socioEconomicIndex: 7.8 },
              { year: "2016/2017", grade: 8.2, socioEconomicIndex: 7.5 },
              { year: "2015/2016", grade: 7.5, socioEconomicIndex: 7.1 },
              { year: "2014/2015", grade: 7.5, socioEconomicIndex: 7.1 },
              { year: "2013/2014", grade: 7.6, socioEconomicIndex: 6.9 },
              { year: "2012/2013", grade: 8.3, socioEconomicIndex: 7.8 },
              { year: "2011/2012", grade: 7.5, socioEconomicIndex: 7.2 },
              { year: "2010/2011", grade: 7.4, socioEconomicIndex: 7.1 },
              { year: "2009/2010", grade: 9.3, socioEconomicIndex: 8.2 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 6.1, socioEconomicIndex: 6.5 },
              { year: "2014/2015", grade: 7.4, socioEconomicIndex: 6.8 },
              { year: "2013/2014", grade: 6.4, socioEconomicIndex: 6.4 },
              { year: "2012/2013", grade: 7, socioEconomicIndex: 6.7 },
              { year: "2011/2012", grade: 5.8, socioEconomicIndex: 6 },
              { year: "2010/2011", grade: 6.4, socioEconomicIndex: 6.1 },
              { year: "2009/2010", grade: 5.7, socioEconomicIndex: 5.8 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Sønderbroskolen",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 6.1, socioEconomicIndex: 6.2 },
              { year: "2017/2018", grade: 6, socioEconomicIndex: 6 },
              { year: "2016/2017", grade: 6, socioEconomicIndex: 6.6 },
              { year: "2015/2016", grade: 7.2, socioEconomicIndex: 6.7 },
              { year: "2014/2015", grade: 6.5, socioEconomicIndex: 6.2 },
              { year: "2013/2014", grade: 7.2, socioEconomicIndex: 6.5 },
              { year: "2012/2013", grade: 6.6, socioEconomicIndex: 6.4 },
              { year: "2011/2012", grade: 6.5, socioEconomicIndex: 6.6 },
              { year: "2010/2011", grade: 5.9, socioEconomicIndex: 6 },
              { year: "2009/2010", grade: 5.9, socioEconomicIndex: 5.8 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8, socioEconomicIndex: 8 },
              { year: "2017/2018", grade: 7.6, socioEconomicIndex: 7.8 },
              { year: "2016/2017", grade: 8.6, socioEconomicIndex: 8.2 },
              { year: "2015/2016", grade: 7.7, socioEconomicIndex: 7.7 },
              { year: "2014/2015", grade: 8, socioEconomicIndex: 7.8 },
              { year: "2013/2014", grade: 8.2, socioEconomicIndex: 7.5 },
              { year: "2012/2013", grade: 7.4, socioEconomicIndex: 7.3 },
              { year: "2011/2012", grade: 7.3, socioEconomicIndex: 7.4 },
              { year: "2010/2011", grade: 7.7, socioEconomicIndex: 7.4 },
              { year: "2009/2010", grade: 7.9, socioEconomicIndex: 7.3 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 7.3, socioEconomicIndex: 7.2 },
              { year: "2017/2018", grade: 7.6, socioEconomicIndex: 7 },
              { year: "2016/2017", grade: 7.3, socioEconomicIndex: 7.6 },
              { year: "2015/2016", grade: 7.2, socioEconomicIndex: 6.9 },
              { year: "2014/2015", grade: 7.5, socioEconomicIndex: 7.2 },
              { year: "2013/2014", grade: 7, socioEconomicIndex: 6.1 },
              { year: "2012/2013", grade: 5.4, socioEconomicIndex: 6.2 },
              { year: "2011/2012", grade: 6.4, socioEconomicIndex: 6.6 },
              { year: "2010/2011", grade: 6.6, socioEconomicIndex: 6.2 },
              { year: "2009/2010", grade: 5.9, socioEconomicIndex: 5.9 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 6.1, socioEconomicIndex: 6.5 },
              { year: "2017/2018", grade: 7.9, socioEconomicIndex: 6.6 },
              { year: "2016/2017", grade: 6.7, socioEconomicIndex: 6.7 },
              { year: "2015/2016", grade: 7.3, socioEconomicIndex: 6.4 },
              { year: "2014/2015", grade: 6.8, socioEconomicIndex: 6.5 },
              { year: "2013/2014", grade: 7.2, socioEconomicIndex: 6.5 },
              { year: "2012/2013", grade: 5.7, socioEconomicIndex: 6 },
              { year: "2011/2012", grade: 6.7, socioEconomicIndex: 6.4 },
              { year: "2010/2011", grade: 5.3, socioEconomicIndex: 6.2 },
              { year: "2009/2010", grade: 4.8, socioEconomicIndex: 5.5 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 5.8, socioEconomicIndex: 5.8 },
              { year: "2010/2011", grade: 4.6, socioEconomicIndex: 5.6 },
              { year: "2009/2010", grade: 3.1, socioEconomicIndex: 5.1 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.8, socioEconomicIndex: 8.5 },
              { year: "2017/2018", grade: 7.5, socioEconomicIndex: 7.9 },
              { year: "2016/2017", grade: 7.8, socioEconomicIndex: 8.1 },
              { year: "2015/2016", grade: 7.4, socioEconomicIndex: 7.8 },
              { year: "2014/2015", grade: 7.1, socioEconomicIndex: 7.6 },
              { year: "2013/2014", grade: 7.7, socioEconomicIndex: 7.6 },
              { year: "2012/2013", grade: 8.1, socioEconomicIndex: 7.6 },
              { year: "2011/2012", grade: 6.8, socioEconomicIndex: 7.3 },
              { year: "2010/2011", grade: 7.4, socioEconomicIndex: 7.4 },
              { year: "2009/2010", grade: 6.6, socioEconomicIndex: 6.8 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 4.6, socioEconomicIndex: 7 },
              { year: "2017/2018", grade: 6.7, socioEconomicIndex: 7.3 },
              { year: "2016/2017", grade: 6.7, socioEconomicIndex: 7.5 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 7.4, socioEconomicIndex: 7.4 },
              { year: "2017/2018", grade: 7.3, socioEconomicIndex: 7.1 },
              { year: "2016/2017", grade: 5, socioEconomicIndex: 7 },
              { year: "2015/2016", grade: 6.7, socioEconomicIndex: 6.8 },
              { year: "2014/2015", grade: 7.2, socioEconomicIndex: 7.2 },
              { year: "2013/2014", grade: 6.7, socioEconomicIndex: 6.3 },
              { year: "2012/2013", grade: 6.2, socioEconomicIndex: 6.1 },
              { year: "2011/2012", grade: 7.8, socioEconomicIndex: 6.6 },
              { year: "2010/2011", grade: 5.9, socioEconomicIndex: 5.9 },
              { year: "2009/2010", grade: 6.7, socioEconomicIndex: 6.5 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 7.1, socioEconomicIndex: 7.4 },
              { year: "2017/2018", grade: 7.3, socioEconomicIndex: 7.2 },
              { year: "2016/2017", grade: 7, socioEconomicIndex: 7.6 },
              { year: "2015/2016", grade: 7.2, socioEconomicIndex: 7.2 },
              { year: "2014/2015", grade: 7.4, socioEconomicIndex: 7.5 },
              { year: "2013/2014", grade: 7.1, socioEconomicIndex: 7 },
              { year: "2012/2013", grade: 7.3, socioEconomicIndex: 7 },
              { year: "2011/2012", grade: 8, socioEconomicIndex: 7.2 },
              { year: "2010/2011", grade: 6.4, socioEconomicIndex: 6.6 },
              { year: "2009/2010", grade: 7.9, socioEconomicIndex: 7.6 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 5.7, socioEconomicIndex: 6.7 },
              { year: "2014/2015", grade: 6, socioEconomicIndex: 6.6 },
              { year: "2013/2014", grade: 8.1, socioEconomicIndex: 6.7 },
              { year: "2012/2013", grade: 6, socioEconomicIndex: 6.2 },
              { year: "2011/2012", grade: 6, socioEconomicIndex: 6.2 },
              { year: "2010/2011", grade: 6.6, socioEconomicIndex: 6.3 },
              { year: "2009/2010", grade: 5.9, socioEconomicIndex: 5.8 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Sønderholm Skole",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 7.5, socioEconomicIndex: 7.3 },
              { year: "2017/2018", grade: 5.9, socioEconomicIndex: 5.8 },
              { year: "2016/2017", grade: 7.4, socioEconomicIndex: 7.2 },
              { year: "2014/2015", grade: 6.1, socioEconomicIndex: 6.1 },
              { year: "2013/2014", grade: 5.2, socioEconomicIndex: 5.6 },
              { year: "2012/2013", grade: 5.2, socioEconomicIndex: 5.7 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.9, socioEconomicIndex: 8.8 },
              { year: "2017/2018", grade: 8.8, socioEconomicIndex: 8.3 },
              { year: "2016/2017", grade: 9.5, socioEconomicIndex: 9 },
              { year: "2014/2015", grade: 7.9, socioEconomicIndex: 7.8 },
              { year: "2013/2014", grade: 7.2, socioEconomicIndex: 7.4 },
              { year: "2012/2013", grade: 7.2, socioEconomicIndex: 7.3 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 6.8, socioEconomicIndex: 7.2 },
              { year: "2017/2018", grade: 6.9, socioEconomicIndex: 6.5 },
              { year: "2016/2017", grade: 7.6, socioEconomicIndex: 7.6 },
              { year: "2014/2015", grade: 6.7, socioEconomicIndex: 6.7 },
              { year: "2013/2014", grade: 5.5, socioEconomicIndex: 5.8 },
              { year: "2012/2013", grade: 5.1, socioEconomicIndex: 5.9 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 8.1, socioEconomicIndex: 7.5 },
              { year: "2017/2018", grade: 5.9, socioEconomicIndex: 5.9 },
              { year: "2016/2017", grade: 7.1, socioEconomicIndex: 7 },
              { year: "2014/2015", grade: 6.4, socioEconomicIndex: 6.4 },
              { year: "2013/2014", grade: 6.9, socioEconomicIndex: 6.7 },
              { year: "2012/2013", grade: 5.6, socioEconomicIndex: 6 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 9.4, socioEconomicIndex: 9 },
              { year: "2017/2018", grade: 9.1, socioEconomicIndex: 8.6 },
              { year: "2016/2017", grade: 9.6, socioEconomicIndex: 9 },
              { year: "2014/2015", grade: 8.6, socioEconomicIndex: 8.1 },
              { year: "2013/2014", grade: 8.9, socioEconomicIndex: 8.3 },
              { year: "2012/2013", grade: 7.5, socioEconomicIndex: 7.5 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.6, socioEconomicIndex: 8.3 },
              { year: "2017/2018", grade: 6.9, socioEconomicIndex: 6.9 },
              { year: "2016/2017", grade: 8.9, socioEconomicIndex: 8.3 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 9.4, socioEconomicIndex: 8.4 },
              { year: "2017/2018", grade: 7.7, socioEconomicIndex: 7.2 },
              { year: "2016/2017", grade: 7.9, socioEconomicIndex: 7.5 },
              { year: "2014/2015", grade: 8.1, socioEconomicIndex: 7.2 },
              { year: "2013/2014", grade: 5.8, socioEconomicIndex: 5.9 },
              { year: "2012/2013", grade: 3.2, socioEconomicIndex: 4.8 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 9.3, socioEconomicIndex: 8.4 },
              { year: "2017/2018", grade: 7.9, socioEconomicIndex: 7.5 },
              { year: "2016/2017", grade: 7.9, socioEconomicIndex: 7.7 },
              { year: "2014/2015", grade: 6.7, socioEconomicIndex: 6.7 },
              { year: "2013/2014", grade: 4.8, socioEconomicIndex: 5.6 },
              { year: "2012/2013", grade: 4.8, socioEconomicIndex: 5.8 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2014/2015", grade: 4.9, socioEconomicIndex: 5.7 },
              { year: "2013/2014", grade: 5.1, socioEconomicIndex: 5.8 },
              { year: "2012/2013", grade: 5.4, socioEconomicIndex: 5.9 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Tofthøjskolen",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 5.9, socioEconomicIndex: 5.9 },
              { year: "2017/2018", grade: 5.6, socioEconomicIndex: 5.9 },
              { year: "2016/2017", grade: 7.5, socioEconomicIndex: 6.9 },
              { year: "2015/2016", grade: 5.9, socioEconomicIndex: 6.3 },
              { year: "2014/2015", grade: 6, socioEconomicIndex: 6.1 },
              { year: "2013/2014", grade: 5.9, socioEconomicIndex: 6.2 },
              { year: "2012/2013", grade: 6.9, socioEconomicIndex: 6.7 },
              { year: "2011/2012", grade: 5.4, socioEconomicIndex: 5.8 },
              { year: "2010/2011", grade: 6, socioEconomicIndex: 6 },
              { year: "2009/2010", grade: 5.6, socioEconomicIndex: 6 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.7, socioEconomicIndex: 7.9 },
              { year: "2017/2018", grade: 8.8, socioEconomicIndex: 8.3 },
              { year: "2016/2017", grade: 8.7, socioEconomicIndex: 8.2 },
              { year: "2015/2016", grade: 7.7, socioEconomicIndex: 7.6 },
              { year: "2014/2015", grade: 9.1, socioEconomicIndex: 8.3 },
              { year: "2013/2014", grade: 7.6, socioEconomicIndex: 7.6 },
              { year: "2012/2013", grade: 7.6, socioEconomicIndex: 7.6 },
              { year: "2011/2012", grade: 6.7, socioEconomicIndex: 6.8 },
              { year: "2010/2011", grade: 7.3, socioEconomicIndex: 7.1 },
              { year: "2009/2010", grade: 7.2, socioEconomicIndex: 7.2 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 6.1, socioEconomicIndex: 6.4 },
              { year: "2017/2018", grade: 6.7, socioEconomicIndex: 6.7 },
              { year: "2016/2017", grade: 8.4, socioEconomicIndex: 7.4 },
              { year: "2015/2016", grade: 7.4, socioEconomicIndex: 6.9 },
              { year: "2014/2015", grade: 7.3, socioEconomicIndex: 7 },
              { year: "2013/2014", grade: 5.3, socioEconomicIndex: 6 },
              { year: "2012/2013", grade: 6, socioEconomicIndex: 6.5 },
              { year: "2011/2012", grade: 5.6, socioEconomicIndex: 5.9 },
              { year: "2010/2011", grade: 6, socioEconomicIndex: 6 },
              { year: "2009/2010", grade: 5.7, socioEconomicIndex: 6.1 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 6.6, socioEconomicIndex: 6.3 },
              { year: "2017/2018", grade: 6.1, socioEconomicIndex: 6.3 },
              { year: "2016/2017", grade: 7.5, socioEconomicIndex: 6.6 },
              { year: "2015/2016", grade: 6.6, socioEconomicIndex: 6.3 },
              { year: "2014/2015", grade: 6.9, socioEconomicIndex: 6.5 },
              { year: "2013/2014", grade: 6.3, socioEconomicIndex: 6.4 },
              { year: "2012/2013", grade: 6.3, socioEconomicIndex: 6.2 },
              { year: "2011/2012", grade: 6.1, socioEconomicIndex: 6 },
              { year: "2010/2011", grade: 6.6, socioEconomicIndex: 6.2 },
              { year: "2009/2010", grade: 6, socioEconomicIndex: 6.2 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 6.7, socioEconomicIndex: 5.9 },
              { year: "2010/2011", grade: 6, socioEconomicIndex: 5.5 },
              { year: "2009/2010", grade: 5.5, socioEconomicIndex: 5.6 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 7.7, socioEconomicIndex: 7.8 },
              { year: "2017/2018", grade: 6.4, socioEconomicIndex: 7.2 },
              { year: "2016/2017", grade: 7.9, socioEconomicIndex: 7.9 },
              { year: "2015/2016", grade: 7.1, socioEconomicIndex: 7.3 },
              { year: "2014/2015", grade: 7.9, socioEconomicIndex: 7.7 },
              { year: "2013/2014", grade: 7.7, socioEconomicIndex: 7.7 },
              { year: "2012/2013", grade: 8.8, socioEconomicIndex: 8.2 },
              { year: "2011/2012", grade: 5.6, socioEconomicIndex: 6.1 },
              { year: "2010/2011", grade: 7.3, socioEconomicIndex: 7.2 },
              { year: "2009/2010", grade: 6.1, socioEconomicIndex: 6.5 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 6.3, socioEconomicIndex: 7.1 },
              { year: "2017/2018", grade: 6.2, socioEconomicIndex: 7.1 },
              { year: "2016/2017", grade: 7.1, socioEconomicIndex: 7.3 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 7, socioEconomicIndex: 7.2 },
              { year: "2017/2018", grade: 7.1, socioEconomicIndex: 7.1 },
              { year: "2016/2017", grade: 8.1, socioEconomicIndex: 7.2 },
              { year: "2015/2016", grade: 6.9, socioEconomicIndex: 6.7 },
              { year: "2014/2015", grade: 6.9, socioEconomicIndex: 6.9 },
              { year: "2013/2014", grade: 6.2, socioEconomicIndex: 6.2 },
              { year: "2012/2013", grade: 6.3, socioEconomicIndex: 6.5 },
              { year: "2011/2012", grade: 5.4, socioEconomicIndex: 6 },
              { year: "2010/2011", grade: 6, socioEconomicIndex: 6 },
              { year: "2009/2010", grade: 6.3, socioEconomicIndex: 6.6 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 7.3, socioEconomicIndex: 7.3 },
              { year: "2017/2018", grade: 6.4, socioEconomicIndex: 6.8 },
              { year: "2016/2017", grade: 7.4, socioEconomicIndex: 7.2 },
              { year: "2015/2016", grade: 6.4, socioEconomicIndex: 6.8 },
              { year: "2014/2015", grade: 6.5, socioEconomicIndex: 6.8 },
              { year: "2013/2014", grade: 6.8, socioEconomicIndex: 6.8 },
              { year: "2012/2013", grade: 7.7, socioEconomicIndex: 7.7 },
              { year: "2011/2012", grade: 7.3, socioEconomicIndex: 7.3 },
              { year: "2010/2011", grade: 6.5, socioEconomicIndex: 6.7 },
              { year: "2009/2010", grade: 6.3, socioEconomicIndex: 7 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 4.7, socioEconomicIndex: 6 },
              { year: "2014/2015", grade: 5.6, socioEconomicIndex: 6.3 },
              { year: "2013/2014", grade: 5.2, socioEconomicIndex: 6.2 },
              { year: "2012/2013", grade: 4.3, socioEconomicIndex: 5.7 },
              { year: "2011/2012", grade: 4.6, socioEconomicIndex: 5.3 },
              { year: "2010/2011", grade: 4.7, socioEconomicIndex: 5.5 },
              { year: "2009/2010", grade: 4.6, socioEconomicIndex: 5.3 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Tornhøjskolen",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 4.4, socioEconomicIndex: 4.2 },
              { year: "2017/2018", grade: 2.3, socioEconomicIndex: 2.9 },
              { year: "2016/2017", grade: 4.3, socioEconomicIndex: 4.6 },
              { year: "2015/2016", grade: 5.6, socioEconomicIndex: 5.3 },
              { year: "2014/2015", grade: 4.3, socioEconomicIndex: 4.7 },
              { year: "2013/2014", grade: 4.3, socioEconomicIndex: 4.7 },
              { year: "2012/2013", grade: 5.4, socioEconomicIndex: 5.2 },
              { year: "2011/2012", grade: 5.9, socioEconomicIndex: 5.6 },
              { year: "2010/2011", grade: 3.2, socioEconomicIndex: 4.1 },
              { year: "2009/2010", grade: 5.3, socioEconomicIndex: 5.2 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 7.4, socioEconomicIndex: 7 },
              { year: "2017/2018", grade: 6.1, socioEconomicIndex: 6.2 },
              { year: "2016/2017", grade: 6, socioEconomicIndex: 6.5 },
              { year: "2015/2016", grade: 6.9, socioEconomicIndex: 6.9 },
              { year: "2014/2015", grade: 6, socioEconomicIndex: 6.4 },
              { year: "2013/2014", grade: 6.9, socioEconomicIndex: 6.7 },
              { year: "2012/2013", grade: 5.9, socioEconomicIndex: 6.1 },
              { year: "2011/2012", grade: 6.5, socioEconomicIndex: 6.4 },
              { year: "2010/2011", grade: 6.6, socioEconomicIndex: 6.5 },
              { year: "2009/2010", grade: 5.7, socioEconomicIndex: 6.1 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 5.2, socioEconomicIndex: 5 },
              { year: "2017/2018", grade: 3.3, socioEconomicIndex: 4.2 },
              { year: "2016/2017", grade: 5.2, socioEconomicIndex: 5.6 },
              { year: "2015/2016", grade: 6.5, socioEconomicIndex: 5.9 },
              { year: "2014/2015", grade: 5.4, socioEconomicIndex: 5.7 },
              { year: "2013/2014", grade: 4.1, socioEconomicIndex: 4.4 },
              { year: "2012/2013", grade: 3.7, socioEconomicIndex: 4.4 },
              { year: "2011/2012", grade: 5, socioEconomicIndex: 5.3 },
              { year: "2010/2011", grade: 4.1, socioEconomicIndex: 4.8 },
              { year: "2009/2010", grade: 5.5, socioEconomicIndex: 5.4 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 5.6, socioEconomicIndex: 5.2 },
              { year: "2017/2018", grade: 3.5, socioEconomicIndex: 4.4 },
              { year: "2016/2017", grade: 4.1, socioEconomicIndex: 5.1 },
              { year: "2015/2016", grade: 5.5, socioEconomicIndex: 5.4 },
              { year: "2014/2015", grade: 3.2, socioEconomicIndex: 4.6 },
              { year: "2013/2014", grade: 4, socioEconomicIndex: 4.7 },
              { year: "2012/2013", grade: 4.9, socioEconomicIndex: 4.9 },
              { year: "2011/2012", grade: 7.1, socioEconomicIndex: 6.1 },
              { year: "2010/2011", grade: 4.5, socioEconomicIndex: 5.1 },
              { year: "2009/2010", grade: 5.1, socioEconomicIndex: 5.2 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 6.3, socioEconomicIndex: 5.1 },
              { year: "2010/2011", grade: 3.8, socioEconomicIndex: 5.1 },
              { year: "2009/2010", grade: 5.7, socioEconomicIndex: 5.2 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8, socioEconomicIndex: 7.6 },
              { year: "2017/2018", grade: 5.9, socioEconomicIndex: 6.3 },
              { year: "2016/2017", grade: 5.3, socioEconomicIndex: 6.1 },
              { year: "2015/2016", grade: 6.7, socioEconomicIndex: 6.7 },
              { year: "2014/2015", grade: 6.1, socioEconomicIndex: 6.4 },
              { year: "2013/2014", grade: 5.6, socioEconomicIndex: 6 },
              { year: "2012/2013", grade: 6.3, socioEconomicIndex: 6.4 },
              { year: "2011/2012", grade: 5.3, socioEconomicIndex: 5.8 },
              { year: "2010/2011", grade: 5.3, socioEconomicIndex: 5.9 },
              { year: "2009/2010", grade: 5.3, socioEconomicIndex: 5.8 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 6.3, socioEconomicIndex: 6 },
              { year: "2017/2018", grade: 4.5, socioEconomicIndex: 5.3 },
              { year: "2016/2017", grade: 5.6, socioEconomicIndex: 6 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 6.4, socioEconomicIndex: 5.5 },
              { year: "2017/2018", grade: 4.4, socioEconomicIndex: 4.8 },
              { year: "2016/2017", grade: 3.7, socioEconomicIndex: 4.2 },
              { year: "2015/2016", grade: 5.5, socioEconomicIndex: 5.2 },
              { year: "2014/2015", grade: 5.7, socioEconomicIndex: 5.5 },
              { year: "2013/2014", grade: 3.2, socioEconomicIndex: 4.2 },
              { year: "2012/2013", grade: 4.7, socioEconomicIndex: 4.4 },
              { year: "2011/2012", grade: 5.5, socioEconomicIndex: 4.8 },
              { year: "2010/2011", grade: 3.6, socioEconomicIndex: 4.2 },
              { year: "2009/2010", grade: 5.2, socioEconomicIndex: 5.4 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 6.7, socioEconomicIndex: 5.9 },
              { year: "2017/2018", grade: 4.5, socioEconomicIndex: 4.9 },
              { year: "2016/2017", grade: 4.4, socioEconomicIndex: 4.8 },
              { year: "2015/2016", grade: 5.4, socioEconomicIndex: 5.4 },
              { year: "2014/2015", grade: 5.4, socioEconomicIndex: 5.7 },
              { year: "2013/2014", grade: 4.5, socioEconomicIndex: 5.1 },
              { year: "2012/2013", grade: 4.8, socioEconomicIndex: 5.1 },
              { year: "2011/2012", grade: 5.4, socioEconomicIndex: 5.4 },
              { year: "2010/2011", grade: 3.7, socioEconomicIndex: 4.8 },
              { year: "2009/2010", grade: 5.5, socioEconomicIndex: 6.2 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 4.4, socioEconomicIndex: 5.1 },
              { year: "2014/2015", grade: 6.4, socioEconomicIndex: 6.1 },
              { year: "2013/2014", grade: 8, socioEconomicIndex: 6.7 },
              { year: "2012/2013", grade: 4.7, socioEconomicIndex: 4.9 },
              { year: "2011/2012", grade: 5.4, socioEconomicIndex: 5.3 },
              { year: "2010/2011", grade: 4.7, socioEconomicIndex: 5.1 },
              { year: "2009/2010", grade: 5.6, socioEconomicIndex: 5.4 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Vadum Skole",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 6.7, socioEconomicIndex: 6.3 },
              { year: "2017/2018", grade: 6.8, socioEconomicIndex: 6 },
              { year: "2016/2017", grade: 7.4, socioEconomicIndex: 7.1 },
              { year: "2015/2016", grade: 6.9, socioEconomicIndex: 6.6 },
              { year: "2014/2015", grade: 6.8, socioEconomicIndex: 6.6 },
              { year: "2013/2014", grade: 6.2, socioEconomicIndex: 6.3 },
              { year: "2012/2013", grade: 7.2, socioEconomicIndex: 6.9 },
              { year: "2011/2012", grade: 7.2, socioEconomicIndex: 6.6 },
              { year: "2010/2011", grade: 5.3, socioEconomicIndex: 5.8 },
              { year: "2009/2010", grade: 7, socioEconomicIndex: 6.4 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.3, socioEconomicIndex: 8.1 },
              { year: "2017/2018", grade: 7.4, socioEconomicIndex: 7.4 },
              { year: "2016/2017", grade: 7.3, socioEconomicIndex: 7.9 },
              { year: "2015/2016", grade: 6.9, socioEconomicIndex: 7.2 },
              { year: "2014/2015", grade: 7.6, socioEconomicIndex: 7.7 },
              { year: "2013/2014", grade: 8.3, socioEconomicIndex: 8 },
              { year: "2012/2013", grade: 8.8, socioEconomicIndex: 8.2 },
              { year: "2011/2012", grade: 7.3, socioEconomicIndex: 7.1 },
              { year: "2010/2011", grade: 5.7, socioEconomicIndex: 6.5 },
              { year: "2009/2010", grade: 6.1, socioEconomicIndex: 6.5 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 8, socioEconomicIndex: 6.9 },
              { year: "2017/2018", grade: 7.8, socioEconomicIndex: 6.5 },
              { year: "2016/2017", grade: 8.7, socioEconomicIndex: 7.8 },
              { year: "2015/2016", grade: 7.7, socioEconomicIndex: 6.9 },
              { year: "2014/2015", grade: 8.9, socioEconomicIndex: 7.7 },
              { year: "2013/2014", grade: 6.9, socioEconomicIndex: 6.5 },
              { year: "2012/2013", grade: 8.2, socioEconomicIndex: 7.1 },
              { year: "2011/2012", grade: 6.9, socioEconomicIndex: 6.2 },
              { year: "2010/2011", grade: 5.9, socioEconomicIndex: 6 },
              { year: "2009/2010", grade: 7.2, socioEconomicIndex: 6.3 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 6.9, socioEconomicIndex: 6.5 },
              { year: "2017/2018", grade: 7.1, socioEconomicIndex: 6.1 },
              { year: "2016/2017", grade: 7.6, socioEconomicIndex: 7.1 },
              { year: "2015/2016", grade: 7, socioEconomicIndex: 6.3 },
              { year: "2014/2015", grade: 8.3, socioEconomicIndex: 7.3 },
              { year: "2013/2014", grade: 6, socioEconomicIndex: 6.5 },
              { year: "2012/2013", grade: 6.8, socioEconomicIndex: 6.6 },
              { year: "2011/2012", grade: 5, socioEconomicIndex: 5.6 },
              { year: "2010/2011", grade: 5, socioEconomicIndex: 5.8 },
              { year: "2009/2010", grade: 6.7, socioEconomicIndex: 6.1 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 4.5, socioEconomicIndex: 5.3 },
              { year: "2010/2011", grade: 5.2, socioEconomicIndex: 5.5 },
              { year: "2009/2010", grade: 5.7, socioEconomicIndex: 5.4 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8, socioEconomicIndex: 7.9 },
              { year: "2017/2018", grade: 8.5, socioEconomicIndex: 7.9 },
              { year: "2016/2017", grade: 7.9, socioEconomicIndex: 8 },
              { year: "2015/2016", grade: 7.8, socioEconomicIndex: 7.6 },
              { year: "2014/2015", grade: 7.4, socioEconomicIndex: 7.4 },
              { year: "2013/2014", grade: 7.2, socioEconomicIndex: 7.4 },
              { year: "2012/2013", grade: 7.9, socioEconomicIndex: 7.7 },
              { year: "2011/2012", grade: 7.2, socioEconomicIndex: 6.9 },
              { year: "2010/2011", grade: 7.2, socioEconomicIndex: 7.2 },
              { year: "2009/2010", grade: 6.3, socioEconomicIndex: 6.5 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 7.6, socioEconomicIndex: 7.4 },
              { year: "2017/2018", grade: 6.3, socioEconomicIndex: 6.6 },
              { year: "2016/2017", grade: 7.6, socioEconomicIndex: 7.7 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 8.4, socioEconomicIndex: 7.5 },
              { year: "2017/2018", grade: 6.8, socioEconomicIndex: 6.5 },
              { year: "2016/2017", grade: 8.1, socioEconomicIndex: 7.4 },
              { year: "2015/2016", grade: 6.4, socioEconomicIndex: 6.5 },
              { year: "2014/2015", grade: 7.2, socioEconomicIndex: 7 },
              { year: "2013/2014", grade: 5.2, socioEconomicIndex: 5.8 },
              { year: "2012/2013", grade: 6.8, socioEconomicIndex: 6.6 },
              { year: "2011/2012", grade: 9.1, socioEconomicIndex: 6.8 },
              { year: "2010/2011", grade: 4.7, socioEconomicIndex: 5.8 },
              { year: "2009/2010", grade: 6.4, socioEconomicIndex: 6.7 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 7.4, socioEconomicIndex: 7.2 },
              { year: "2017/2018", grade: 6.5, socioEconomicIndex: 6.5 },
              { year: "2016/2017", grade: 7.8, socioEconomicIndex: 7.4 },
              { year: "2015/2016", grade: 6.3, socioEconomicIndex: 6.6 },
              { year: "2014/2015", grade: 6.6, socioEconomicIndex: 6.8 },
              { year: "2013/2014", grade: 5.1, socioEconomicIndex: 6 },
              { year: "2012/2013", grade: 6.4, socioEconomicIndex: 7 },
              { year: "2011/2012", grade: 9, socioEconomicIndex: 7.4 },
              { year: "2010/2011", grade: 6.1, socioEconomicIndex: 6.7 },
              { year: "2009/2010", grade: 7.2, socioEconomicIndex: 7.4 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2014/2015", grade: 6.2, socioEconomicIndex: 6.5 },
              { year: "2013/2014", grade: 6, socioEconomicIndex: 6.4 },
              { year: "2012/2013", grade: 6, socioEconomicIndex: 6.3 },
              { year: "2011/2012", grade: 6.4, socioEconomicIndex: 6 },
              { year: "2010/2011", grade: 6, socioEconomicIndex: 6.1 },
              { year: "2009/2010", grade: 6.6, socioEconomicIndex: 6.1 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Vejgaard Østre Skole",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 6.2, socioEconomicIndex: 6.4 },
              { year: "2017/2018", grade: 5.6, socioEconomicIndex: 5.6 },
              { year: "2016/2017", grade: 7.9, socioEconomicIndex: 7.3 },
              { year: "2015/2016", grade: 7.5, socioEconomicIndex: 7.3 },
              { year: "2014/2015", grade: 7.6, socioEconomicIndex: 7 },
              { year: "2013/2014", grade: 6.6, socioEconomicIndex: 6.9 },
              { year: "2012/2013", grade: 6.4, socioEconomicIndex: 6.9 },
              { year: "2011/2012", grade: 7.4, socioEconomicIndex: 7 },
              { year: "2010/2011", grade: 5.2, socioEconomicIndex: 5.8 },
              { year: "2009/2010", grade: 5.7, socioEconomicIndex: 6 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 9.6, socioEconomicIndex: 8.6 },
              { year: "2017/2018", grade: 7.4, socioEconomicIndex: 7.7 },
              { year: "2016/2017", grade: 8.3, socioEconomicIndex: 8.3 },
              { year: "2015/2016", grade: 9.8, socioEconomicIndex: 8.6 },
              { year: "2014/2015", grade: 8.3, socioEconomicIndex: 8.4 },
              { year: "2013/2014", grade: 8.1, socioEconomicIndex: 8 },
              { year: "2012/2013", grade: 6.9, socioEconomicIndex: 7.7 },
              { year: "2011/2012", grade: 7.5, socioEconomicIndex: 7.6 },
              { year: "2010/2011", grade: 6.7, socioEconomicIndex: 7.1 },
              { year: "2009/2010", grade: 6.8, socioEconomicIndex: 7.1 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 8.2, socioEconomicIndex: 7.4 },
              { year: "2017/2018", grade: 6.4, socioEconomicIndex: 6.6 },
              { year: "2016/2017", grade: 8.7, socioEconomicIndex: 7.9 },
              { year: "2015/2016", grade: 7.6, socioEconomicIndex: 7.6 },
              { year: "2014/2015", grade: 8.5, socioEconomicIndex: 7.8 },
              { year: "2013/2014", grade: 4.9, socioEconomicIndex: 6.4 },
              { year: "2012/2013", grade: 5.9, socioEconomicIndex: 6.8 },
              { year: "2011/2012", grade: 8.4, socioEconomicIndex: 7.1 },
              { year: "2010/2011", grade: 6.2, socioEconomicIndex: 6.1 },
              { year: "2009/2010", grade: 6.1, socioEconomicIndex: 6.2 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 9.1, socioEconomicIndex: 7 },
              { year: "2017/2018", grade: 6.4, socioEconomicIndex: 6.1 },
              { year: "2016/2017", grade: 8.7, socioEconomicIndex: 7.1 },
              { year: "2015/2016", grade: 6.9, socioEconomicIndex: 6.7 },
              { year: "2014/2015", grade: 8.4, socioEconomicIndex: 7.6 },
              { year: "2013/2014", grade: 5.8, socioEconomicIndex: 6.8 },
              { year: "2012/2013", grade: 6.6, socioEconomicIndex: 6.8 },
              { year: "2011/2012", grade: 7.7, socioEconomicIndex: 6.8 },
              { year: "2010/2011", grade: 6.1, socioEconomicIndex: 6.2 },
              { year: "2009/2010", grade: 5.4, socioEconomicIndex: 6.1 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 7, socioEconomicIndex: 6.1 },
              { year: "2010/2011", grade: 5.1, socioEconomicIndex: 5.5 },
              { year: "2009/2010", grade: 5.2, socioEconomicIndex: 5.6 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 9.1, socioEconomicIndex: 8.7 },
              { year: "2017/2018", grade: 7.9, socioEconomicIndex: 8 },
              { year: "2016/2017", grade: 8.5, socioEconomicIndex: 8.5 },
              { year: "2015/2016", grade: 8.3, socioEconomicIndex: 8.3 },
              { year: "2014/2015", grade: 9.3, socioEconomicIndex: 8.7 },
              { year: "2013/2014", grade: 7.6, socioEconomicIndex: 7.8 },
              { year: "2012/2013", grade: 8, socioEconomicIndex: 8.1 },
              { year: "2011/2012", grade: 8, socioEconomicIndex: 7.7 },
              { year: "2010/2011", grade: 7.3, socioEconomicIndex: 7.4 },
              { year: "2009/2010", grade: 6.2, socioEconomicIndex: 6.8 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.1, socioEconomicIndex: 7.8 },
              { year: "2017/2018", grade: 7, socioEconomicIndex: 7.3 },
              { year: "2016/2017", grade: 8.1, socioEconomicIndex: 7.9 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 8.7, socioEconomicIndex: 7.5 },
              { year: "2017/2018", grade: 6.4, socioEconomicIndex: 6.7 },
              { year: "2016/2017", grade: 7.7, socioEconomicIndex: 7.4 },
              { year: "2015/2016", grade: 7.7, socioEconomicIndex: 7.2 },
              { year: "2014/2015", grade: 7.8, socioEconomicIndex: 7.2 },
              { year: "2013/2014", grade: 5.7, socioEconomicIndex: 6.5 },
              { year: "2012/2013", grade: 5.6, socioEconomicIndex: 6.5 },
              { year: "2011/2012", grade: 6.9, socioEconomicIndex: 6.7 },
              { year: "2010/2011", grade: 5.8, socioEconomicIndex: 6 },
              { year: "2009/2010", grade: 6.5, socioEconomicIndex: 6.7 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 8.3, socioEconomicIndex: 7.5 },
              { year: "2017/2018", grade: 5.8, socioEconomicIndex: 6.6 },
              { year: "2016/2017", grade: 8.1, socioEconomicIndex: 7.8 },
              { year: "2015/2016", grade: 8.5, socioEconomicIndex: 7.7 },
              { year: "2014/2015", grade: 7.3, socioEconomicIndex: 7.3 },
              { year: "2013/2014", grade: 7, socioEconomicIndex: 7.2 },
              { year: "2012/2013", grade: 7.1, socioEconomicIndex: 7.4 },
              { year: "2011/2012", grade: 7.8, socioEconomicIndex: 7.4 },
              { year: "2010/2011", grade: 6.3, socioEconomicIndex: 6.6 },
              { year: "2009/2010", grade: 7.7, socioEconomicIndex: 7.5 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 7.9, socioEconomicIndex: 7.2 },
              { year: "2014/2015", grade: 7.1, socioEconomicIndex: 6.9 },
              { year: "2013/2014", grade: 7, socioEconomicIndex: 6.7 },
              { year: "2012/2013", grade: 6.7, socioEconomicIndex: 6.8 },
              { year: "2011/2012", grade: 7.4, socioEconomicIndex: 6.7 },
              { year: "2010/2011", grade: 4.7, socioEconomicIndex: 5.7 },
              { year: "2009/2010", grade: 5.5, socioEconomicIndex: 5.8 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Vestbjerg Skole",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 6.8, socioEconomicIndex: 6.6 },
              { year: "2017/2018", grade: 6.4, socioEconomicIndex: 6.5 },
              { year: "2016/2017", grade: 8, socioEconomicIndex: 7.4 },
              { year: "2015/2016", grade: 7.4, socioEconomicIndex: 7 },
              { year: "2014/2015", grade: 6.9, socioEconomicIndex: 7 },
              { year: "2013/2014", grade: 7.2, socioEconomicIndex: 6.7 },
              { year: "2012/2013", grade: 7.8, socioEconomicIndex: 7.1 },
              { year: "2011/2012", grade: 5.8, socioEconomicIndex: 6.1 },
              { year: "2010/2011", grade: 7.1, socioEconomicIndex: 6.6 },
              { year: "2009/2010", grade: 6.1, socioEconomicIndex: 6.1 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.8, socioEconomicIndex: 8.3 },
              { year: "2017/2018", grade: 8.2, socioEconomicIndex: 8.4 },
              { year: "2016/2017", grade: 9.7, socioEconomicIndex: 8.8 },
              { year: "2015/2016", grade: 9.4, socioEconomicIndex: 8.4 },
              { year: "2014/2015", grade: 9.8, socioEconomicIndex: 9 },
              { year: "2013/2014", grade: 10.3, socioEconomicIndex: 8.8 },
              { year: "2012/2013", grade: 8.1, socioEconomicIndex: 7.8 },
              { year: "2011/2012", grade: 8.2, socioEconomicIndex: 7.7 },
              { year: "2010/2011", grade: 10, socioEconomicIndex: 8.5 },
              { year: "2009/2010", grade: 8.1, socioEconomicIndex: 7.6 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 7.1, socioEconomicIndex: 7 },
              { year: "2017/2018", grade: 7.8, socioEconomicIndex: 7.4 },
              { year: "2016/2017", grade: 8.8, socioEconomicIndex: 7.9 },
              { year: "2015/2016", grade: 6.7, socioEconomicIndex: 6.8 },
              { year: "2014/2015", grade: 8.3, socioEconomicIndex: 8.1 },
              { year: "2013/2014", grade: 6.5, socioEconomicIndex: 6.2 },
              { year: "2012/2013", grade: 7, socioEconomicIndex: 6.9 },
              { year: "2011/2012", grade: 5.4, socioEconomicIndex: 6.1 },
              { year: "2010/2011", grade: 7.2, socioEconomicIndex: 6.6 },
              { year: "2009/2010", grade: 6.5, socioEconomicIndex: 6.2 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 6.4, socioEconomicIndex: 6.5 },
              { year: "2017/2018", grade: 6.7, socioEconomicIndex: 6.8 },
              { year: "2016/2017", grade: 8.3, socioEconomicIndex: 7 },
              { year: "2015/2016", grade: 7.3, socioEconomicIndex: 6.5 },
              { year: "2014/2015", grade: 7.4, socioEconomicIndex: 7.2 },
              { year: "2013/2014", grade: 7.2, socioEconomicIndex: 6.8 },
              { year: "2012/2013", grade: 5.9, socioEconomicIndex: 6.4 },
              { year: "2011/2012", grade: 5.6, socioEconomicIndex: 6 },
              { year: "2010/2011", grade: 7.4, socioEconomicIndex: 6.7 },
              { year: "2009/2010", grade: 5.4, socioEconomicIndex: 5.7 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 6.7, socioEconomicIndex: 5.9 },
              { year: "2010/2011", grade: 7.3, socioEconomicIndex: 6 },
              { year: "2009/2010", grade: 5.4, socioEconomicIndex: 5.4 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 11.1, socioEconomicIndex: 9.4 },
              { year: "2017/2018", grade: 11, socioEconomicIndex: 9.6 },
              { year: "2016/2017", grade: 11.5, socioEconomicIndex: 9.5 },
              { year: "2015/2016", grade: 10.9, socioEconomicIndex: 9.5 },
              { year: "2014/2015", grade: 10, socioEconomicIndex: 9.2 },
              { year: "2013/2014", grade: 10.8, socioEconomicIndex: 9.1 },
              { year: "2012/2013", grade: 9.9, socioEconomicIndex: 8.6 },
              { year: "2011/2012", grade: 7.1, socioEconomicIndex: 7.1 },
              { year: "2010/2011", grade: 7.5, socioEconomicIndex: 7.4 },
              { year: "2009/2010", grade: 7.6, socioEconomicIndex: 7.3 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.3, socioEconomicIndex: 7.7 },
              { year: "2017/2018", grade: 8.5, socioEconomicIndex: 8.1 },
              { year: "2016/2017", grade: 9.9, socioEconomicIndex: 8.3 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 8.2, socioEconomicIndex: 7.7 },
              { year: "2017/2018", grade: 7.6, socioEconomicIndex: 7.6 },
              { year: "2016/2017", grade: 8.3, socioEconomicIndex: 7.6 },
              { year: "2015/2016", grade: 7.9, socioEconomicIndex: 7.3 },
              { year: "2014/2015", grade: 8.5, socioEconomicIndex: 7.9 },
              { year: "2013/2014", grade: 6.3, socioEconomicIndex: 6.3 },
              { year: "2012/2013", grade: 6.5, socioEconomicIndex: 6.5 },
              { year: "2011/2012", grade: 5.5, socioEconomicIndex: 5.9 },
              { year: "2010/2011", grade: 7.5, socioEconomicIndex: 6.5 },
              { year: "2009/2010", grade: 7.1, socioEconomicIndex: 6.9 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 7.5, socioEconomicIndex: 7.5 },
              { year: "2017/2018", grade: 7.7, socioEconomicIndex: 7.5 },
              { year: "2016/2017", grade: 8.4, socioEconomicIndex: 7.9 },
              { year: "2015/2016", grade: 7.8, socioEconomicIndex: 7.5 },
              { year: "2014/2015", grade: 8.6, socioEconomicIndex: 8.1 },
              { year: "2013/2014", grade: 7, socioEconomicIndex: 6.9 },
              { year: "2012/2013", grade: 6.9, socioEconomicIndex: 7.3 },
              { year: "2011/2012", grade: 6.3, socioEconomicIndex: 6.6 },
              { year: "2010/2011", grade: 7.2, socioEconomicIndex: 6.9 },
              { year: "2009/2010", grade: 8.8, socioEconomicIndex: 8 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 8, socioEconomicIndex: 7.2 },
              { year: "2014/2015", grade: 8.3, socioEconomicIndex: 7.6 },
              { year: "2013/2014", grade: 8.9, socioEconomicIndex: 7.3 },
              { year: "2012/2013", grade: 6.8, socioEconomicIndex: 6.6 },
              { year: "2011/2012", grade: 6.5, socioEconomicIndex: 6.2 },
              { year: "2010/2011", grade: 7.2, socioEconomicIndex: 6.6 },
              { year: "2009/2010", grade: 7.8, socioEconomicIndex: 6.6 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Vester Hassing Skole",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 7.2, socioEconomicIndex: 6.6 },
              { year: "2017/2018", grade: 6.8, socioEconomicIndex: 6.3 },
              { year: "2016/2017", grade: 7.1, socioEconomicIndex: 6.9 },
              { year: "2015/2016", grade: 7.3, socioEconomicIndex: 7.1 },
              { year: "2014/2015", grade: 6.1, socioEconomicIndex: 6.4 },
              { year: "2013/2014", grade: 5.5, socioEconomicIndex: 6.1 },
              { year: "2012/2013", grade: 6.8, socioEconomicIndex: 6.7 },
              { year: "2011/2012", grade: 6.7, socioEconomicIndex: 6.6 },
              { year: "2010/2011", grade: 6.4, socioEconomicIndex: 6.3 },
              { year: "2009/2010", grade: 6.9, socioEconomicIndex: 6.5 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 7.8, socioEconomicIndex: 8 },
              { year: "2017/2018", grade: 7.8, socioEconomicIndex: 7.7 },
              { year: "2016/2017", grade: 9.3, socioEconomicIndex: 8.5 },
              { year: "2015/2016", grade: 8, socioEconomicIndex: 8.1 },
              { year: "2014/2015", grade: 8, socioEconomicIndex: 8 },
              { year: "2013/2014", grade: 8.2, socioEconomicIndex: 7.8 },
              { year: "2012/2013", grade: 7.3, socioEconomicIndex: 7.6 },
              { year: "2011/2012", grade: 7.4, socioEconomicIndex: 7.4 },
              { year: "2010/2011", grade: 6.8, socioEconomicIndex: 7.2 },
              { year: "2009/2010", grade: 7.3, socioEconomicIndex: 7.4 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 7.4, socioEconomicIndex: 6.8 },
              { year: "2017/2018", grade: 8.5, socioEconomicIndex: 7.2 },
              { year: "2016/2017", grade: 8.2, socioEconomicIndex: 7.6 },
              { year: "2015/2016", grade: 7.1, socioEconomicIndex: 7.2 },
              { year: "2014/2015", grade: 7.4, socioEconomicIndex: 7.4 },
              { year: "2013/2014", grade: 5.5, socioEconomicIndex: 6.1 },
              { year: "2012/2013", grade: 5.8, socioEconomicIndex: 6.5 },
              { year: "2011/2012", grade: 8.2, socioEconomicIndex: 6.9 },
              { year: "2010/2011", grade: 6.8, socioEconomicIndex: 6.5 },
              { year: "2009/2010", grade: 6.5, socioEconomicIndex: 6.4 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 7.8, socioEconomicIndex: 6.8 },
              { year: "2017/2018", grade: 8.3, socioEconomicIndex: 6.7 },
              { year: "2016/2017", grade: 6.9, socioEconomicIndex: 6.6 },
              { year: "2015/2016", grade: 5.8, socioEconomicIndex: 6.5 },
              { year: "2014/2015", grade: 6.7, socioEconomicIndex: 6.7 },
              { year: "2013/2014", grade: 5.1, socioEconomicIndex: 6.2 },
              { year: "2012/2013", grade: 5, socioEconomicIndex: 6.1 },
              { year: "2011/2012", grade: 6.8, socioEconomicIndex: 6.5 },
              { year: "2010/2011", grade: 6.9, socioEconomicIndex: 6.6 },
              { year: "2009/2010", grade: 5.5, socioEconomicIndex: 6.2 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 6.6, socioEconomicIndex: 5.9 },
              { year: "2010/2011", grade: 6.3, socioEconomicIndex: 5.8 },
              { year: "2009/2010", grade: 4.6, socioEconomicIndex: 5.6 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 6.9, socioEconomicIndex: 7.5 },
              { year: "2017/2018", grade: 8.4, socioEconomicIndex: 8.1 },
              { year: "2016/2017", grade: 8.5, socioEconomicIndex: 8.2 },
              { year: "2015/2016", grade: 7.8, socioEconomicIndex: 7.9 },
              { year: "2014/2015", grade: 8.3, socioEconomicIndex: 8 },
              { year: "2013/2014", grade: 7.3, socioEconomicIndex: 7.5 },
              { year: "2012/2013", grade: 6.6, socioEconomicIndex: 7.2 },
              { year: "2011/2012", grade: 7.4, socioEconomicIndex: 7.4 },
              { year: "2010/2011", grade: 7.2, socioEconomicIndex: 7.3 },
              { year: "2009/2010", grade: 6.7, socioEconomicIndex: 6.9 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 7.2, socioEconomicIndex: 7.4 },
              { year: "2017/2018", grade: 7.2, socioEconomicIndex: 7.2 },
              { year: "2016/2017", grade: 8.2, socioEconomicIndex: 7.7 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 7.2, socioEconomicIndex: 7.3 },
              { year: "2017/2018", grade: 9.4, socioEconomicIndex: 7.8 },
              { year: "2016/2017", grade: 7.4, socioEconomicIndex: 7.1 },
              { year: "2015/2016", grade: 7.2, socioEconomicIndex: 7.2 },
              { year: "2014/2015", grade: 7.9, socioEconomicIndex: 7.4 },
              { year: "2013/2014", grade: 6.8, socioEconomicIndex: 6.4 },
              { year: "2012/2013", grade: 6.9, socioEconomicIndex: 6.4 },
              { year: "2011/2012", grade: 8.3, socioEconomicIndex: 7 },
              { year: "2010/2011", grade: 7.1, socioEconomicIndex: 6.5 },
              { year: "2009/2010", grade: 7.2, socioEconomicIndex: 6.9 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 7.8, socioEconomicIndex: 7.3 },
              { year: "2017/2018", grade: 9.3, socioEconomicIndex: 8 },
              { year: "2016/2017", grade: 8.5, socioEconomicIndex: 7.8 },
              { year: "2015/2016", grade: 7.2, socioEconomicIndex: 7.5 },
              { year: "2014/2015", grade: 7.9, socioEconomicIndex: 7.5 },
              { year: "2013/2014", grade: 7.6, socioEconomicIndex: 7.1 },
              { year: "2012/2013", grade: 8, socioEconomicIndex: 7.3 },
              { year: "2011/2012", grade: 8.5, socioEconomicIndex: 7.7 },
              { year: "2010/2011", grade: 7.3, socioEconomicIndex: 7.1 },
              { year: "2009/2010", grade: 8, socioEconomicIndex: 7.6 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 7.4, socioEconomicIndex: 7.3 },
              { year: "2014/2015", grade: 7.8, socioEconomicIndex: 7.1 },
              { year: "2013/2014", grade: 7.5, socioEconomicIndex: 6.7 },
              { year: "2012/2013", grade: 6.8, socioEconomicIndex: 6.6 },
              { year: "2011/2012", grade: 8.8, socioEconomicIndex: 7.2 },
              { year: "2010/2011", grade: 7, socioEconomicIndex: 6.4 },
              { year: "2009/2010", grade: 5.5, socioEconomicIndex: 5.8 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Vester Mariendal Skole",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 6.5, socioEconomicIndex: 6.5 },
              { year: "2017/2018", grade: 6.4, socioEconomicIndex: 6.4 },
              { year: "2016/2017", grade: 6.8, socioEconomicIndex: 7.1 },
              { year: "2015/2016", grade: 7.7, socioEconomicIndex: 7.4 },
              { year: "2014/2015", grade: 6.6, socioEconomicIndex: 6.8 },
              { year: "2013/2014", grade: 6.7, socioEconomicIndex: 7.1 },
              { year: "2012/2013", grade: 6.6, socioEconomicIndex: 7 },
              { year: "2011/2012", grade: 6.4, socioEconomicIndex: 6.8 },
              { year: "2010/2011", grade: 6.2, socioEconomicIndex: 6.2 },
              { year: "2009/2010", grade: 6.8, socioEconomicIndex: 6.5 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.6, socioEconomicIndex: 8.6 },
              { year: "2017/2018", grade: 8.2, socioEconomicIndex: 8.1 },
              { year: "2016/2017", grade: 8, socioEconomicIndex: 8.4 },
              { year: "2015/2016", grade: 8.6, socioEconomicIndex: 8.4 },
              { year: "2014/2015", grade: 7.6, socioEconomicIndex: 8 },
              { year: "2013/2014", grade: 9.5, socioEconomicIndex: 8.8 },
              { year: "2012/2013", grade: 7.2, socioEconomicIndex: 7.7 },
              { year: "2011/2012", grade: 7.5, socioEconomicIndex: 7.6 },
              { year: "2010/2011", grade: 8.2, socioEconomicIndex: 7.6 },
              { year: "2009/2010", grade: 7, socioEconomicIndex: 7.3 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 7.7, socioEconomicIndex: 7.4 },
              { year: "2017/2018", grade: 7.4, socioEconomicIndex: 7.2 },
              { year: "2016/2017", grade: 8.3, socioEconomicIndex: 8 },
              { year: "2015/2016", grade: 7.6, socioEconomicIndex: 7.7 },
              { year: "2014/2015", grade: 8.3, socioEconomicIndex: 7.7 },
              { year: "2013/2014", grade: 5.8, socioEconomicIndex: 6.9 },
              { year: "2012/2013", grade: 6.5, socioEconomicIndex: 6.9 },
              { year: "2011/2012", grade: 6.5, socioEconomicIndex: 6.9 },
              { year: "2010/2011", grade: 6.9, socioEconomicIndex: 6.4 },
              { year: "2009/2010", grade: 7.7, socioEconomicIndex: 6.7 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 5.9, socioEconomicIndex: 6.8 },
              { year: "2017/2018", grade: 7.8, socioEconomicIndex: 6.7 },
              { year: "2016/2017", grade: 8.2, socioEconomicIndex: 7.2 },
              { year: "2015/2016", grade: 7.9, socioEconomicIndex: 7 },
              { year: "2014/2015", grade: 6.4, socioEconomicIndex: 6.8 },
              { year: "2013/2014", grade: 7.1, socioEconomicIndex: 7.3 },
              { year: "2012/2013", grade: 7.2, socioEconomicIndex: 6.9 },
              { year: "2011/2012", grade: 6.4, socioEconomicIndex: 6.6 },
              { year: "2010/2011", grade: 7.2, socioEconomicIndex: 6.5 },
              { year: "2009/2010", grade: 5.6, socioEconomicIndex: 6.3 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 7.3, socioEconomicIndex: 6.3 },
              { year: "2010/2011", grade: 6.3, socioEconomicIndex: 5.7 },
              { year: "2009/2010", grade: 5.5, socioEconomicIndex: 5.6 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.9, socioEconomicIndex: 8.7 },
              { year: "2017/2018", grade: 8.4, socioEconomicIndex: 8.5 },
              { year: "2016/2017", grade: 9, socioEconomicIndex: 8.9 },
              { year: "2015/2016", grade: 9.3, socioEconomicIndex: 8.7 },
              { year: "2014/2015", grade: 8.7, socioEconomicIndex: 8.4 },
              { year: "2013/2014", grade: 6.9, socioEconomicIndex: 7.9 },
              { year: "2012/2013", grade: 8, socioEconomicIndex: 8.1 },
              { year: "2011/2012", grade: 7.8, socioEconomicIndex: 7.8 },
              { year: "2010/2011", grade: 7.4, socioEconomicIndex: 7.4 },
              { year: "2009/2010", grade: 7.5, socioEconomicIndex: 7.4 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 8, socioEconomicIndex: 8 },
              { year: "2017/2018", grade: 6.9, socioEconomicIndex: 7.5 },
              { year: "2016/2017", grade: 7.4, socioEconomicIndex: 8 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 9.5, socioEconomicIndex: 7.8 },
              { year: "2017/2018", grade: 7.4, socioEconomicIndex: 7.5 },
              { year: "2016/2017", grade: 5.5, socioEconomicIndex: 7.3 },
              { year: "2015/2016", grade: 7.7, socioEconomicIndex: 7.3 },
              { year: "2014/2015", grade: 6.9, socioEconomicIndex: 7.4 },
              { year: "2013/2014", grade: 5.6, socioEconomicIndex: 6.7 },
              { year: "2012/2013", grade: 6.6, socioEconomicIndex: 6.7 },
              { year: "2011/2012", grade: 5.9, socioEconomicIndex: 6.6 },
              { year: "2010/2011", grade: 5.5, socioEconomicIndex: 6.1 },
              { year: "2009/2010", grade: 7.3, socioEconomicIndex: 7.1 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 7.6, socioEconomicIndex: 7.4 },
              { year: "2017/2018", grade: 7.3, socioEconomicIndex: 7.4 },
              { year: "2016/2017", grade: 7.2, socioEconomicIndex: 7.8 },
              { year: "2015/2016", grade: 7.4, socioEconomicIndex: 7.6 },
              { year: "2014/2015", grade: 8, socioEconomicIndex: 7.8 },
              { year: "2013/2014", grade: 6.7, socioEconomicIndex: 7.4 },
              { year: "2012/2013", grade: 7.8, socioEconomicIndex: 7.7 },
              { year: "2011/2012", grade: 6.9, socioEconomicIndex: 7.3 },
              { year: "2010/2011", grade: 6.2, socioEconomicIndex: 6.8 },
              { year: "2009/2010", grade: 7.8, socioEconomicIndex: 7.9 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 5.9, socioEconomicIndex: 6.8 },
              { year: "2014/2015", grade: 7.1, socioEconomicIndex: 7 },
              { year: "2013/2014", grade: 6.9, socioEconomicIndex: 7.1 },
              { year: "2012/2013", grade: 7.5, socioEconomicIndex: 6.8 },
              { year: "2011/2012", grade: 5.8, socioEconomicIndex: 6.2 },
              { year: "2010/2011", grade: 6.4, socioEconomicIndex: 6.2 },
              { year: "2009/2010", grade: 6.2, socioEconomicIndex: 6.2 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Vesterkærets Skole",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 4.7, socioEconomicIndex: 5 },
              { year: "2017/2018", grade: 7, socioEconomicIndex: 6.6 },
              { year: "2016/2017", grade: 6.2, socioEconomicIndex: 6.1 },
              { year: "2015/2016", grade: 6, socioEconomicIndex: 6.1 },
              { year: "2014/2015", grade: 6.1, socioEconomicIndex: 6.2 },
              { year: "2013/2014", grade: 7.2, socioEconomicIndex: 6.5 },
              { year: "2012/2013", grade: 6.2, socioEconomicIndex: 6.2 },
              { year: "2011/2012", grade: 7.1, socioEconomicIndex: 6.7 },
              { year: "2009/2010", grade: 5, socioEconomicIndex: 5.3 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 6.7, socioEconomicIndex: 6.9 },
              { year: "2017/2018", grade: 8.6, socioEconomicIndex: 8.5 },
              { year: "2016/2017", grade: 7.3, socioEconomicIndex: 7.3 },
              { year: "2015/2016", grade: 7.6, socioEconomicIndex: 7.4 },
              { year: "2014/2015", grade: 6.7, socioEconomicIndex: 7.2 },
              { year: "2013/2014", grade: 7.1, socioEconomicIndex: 7.2 },
              { year: "2012/2013", grade: 7.2, socioEconomicIndex: 7.1 },
              { year: "2011/2012", grade: 7, socioEconomicIndex: 7.3 },
              { year: "2009/2010", grade: 4.6, socioEconomicIndex: 5.4 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 4.1, socioEconomicIndex: 5.3 },
              { year: "2017/2018", grade: 7.4, socioEconomicIndex: 7.1 },
              { year: "2016/2017", grade: 5.9, socioEconomicIndex: 6.3 },
              { year: "2015/2016", grade: 6.2, socioEconomicIndex: 6.3 },
              { year: "2014/2015", grade: 7, socioEconomicIndex: 7.2 },
              { year: "2013/2014", grade: 6.5, socioEconomicIndex: 5.9 },
              { year: "2012/2013", grade: 6.1, socioEconomicIndex: 6.1 },
              { year: "2011/2012", grade: 6.1, socioEconomicIndex: 6.3 },
              { year: "2009/2010", grade: 4.3, socioEconomicIndex: 5.2 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 5.9, socioEconomicIndex: 5.8 },
              { year: "2017/2018", grade: 7.1, socioEconomicIndex: 6.7 },
              { year: "2016/2017", grade: 8.7, socioEconomicIndex: 6.6 },
              { year: "2015/2016", grade: 6.9, socioEconomicIndex: 6.2 },
              { year: "2014/2015", grade: 6.3, socioEconomicIndex: 6.5 },
              { year: "2013/2014", grade: 6.5, socioEconomicIndex: 6.3 },
              { year: "2012/2013", grade: 6.6, socioEconomicIndex: 6.2 },
              { year: "2011/2012", grade: 6, socioEconomicIndex: 6.3 },
              { year: "2009/2010", grade: 5, socioEconomicIndex: 5.4 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 6.1, socioEconomicIndex: 5.9 },
              { year: "2009/2010", grade: 5.3, socioEconomicIndex: 5.2 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 4.5, socioEconomicIndex: 5.7 },
              { year: "2017/2018", grade: 9.1, socioEconomicIndex: 8.7 },
              { year: "2016/2017", grade: 6.9, socioEconomicIndex: 7.2 },
              { year: "2015/2016", grade: 9.1, socioEconomicIndex: 8.4 },
              { year: "2014/2015", grade: 8.5, socioEconomicIndex: 8.2 },
              { year: "2013/2014", grade: 7.9, socioEconomicIndex: 7.6 },
              { year: "2012/2013", grade: 6.9, socioEconomicIndex: 7.1 },
              { year: "2011/2012", grade: 8.2, socioEconomicIndex: 7.8 },
              { year: "2009/2010", grade: 5.1, socioEconomicIndex: 5.6 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 5.4, socioEconomicIndex: 6.2 },
              { year: "2017/2018", grade: 8.9, socioEconomicIndex: 8.2 },
              { year: "2016/2017", grade: 5.4, socioEconomicIndex: 6.3 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 3.1, socioEconomicIndex: 5.3 },
              { year: "2017/2018", grade: 8.5, socioEconomicIndex: 7.6 },
              { year: "2016/2017", grade: 5.6, socioEconomicIndex: 6 },
              { year: "2015/2016", grade: 5.8, socioEconomicIndex: 5.9 },
              { year: "2014/2015", grade: 7.1, socioEconomicIndex: 6.8 },
              { year: "2013/2014", grade: 6.4, socioEconomicIndex: 5.9 },
              { year: "2012/2013", grade: 5.5, socioEconomicIndex: 5.8 },
              { year: "2011/2012", grade: 5.7, socioEconomicIndex: 5.9 },
              { year: "2009/2010", grade: 5.6, socioEconomicIndex: 5.8 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 3.8, socioEconomicIndex: 5.3 },
              { year: "2017/2018", grade: 7.6, socioEconomicIndex: 7.3 },
              { year: "2016/2017", grade: 6.5, socioEconomicIndex: 6.4 },
              { year: "2015/2016", grade: 5.9, socioEconomicIndex: 6.2 },
              { year: "2014/2015", grade: 7.9, socioEconomicIndex: 7.2 },
              { year: "2013/2014", grade: 7.4, socioEconomicIndex: 6.7 },
              { year: "2012/2013", grade: 6.8, socioEconomicIndex: 6.9 },
              { year: "2011/2012", grade: 6, socioEconomicIndex: 6.4 },
              { year: "2009/2010", grade: 6.5, socioEconomicIndex: 6.8 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 4.6, socioEconomicIndex: 5.5 },
              { year: "2014/2015", grade: 5.9, socioEconomicIndex: 6.2 },
              { year: "2013/2014", grade: 6.4, socioEconomicIndex: 6.3 },
              { year: "2012/2013", grade: 7, socioEconomicIndex: 6.4 },
              { year: "2011/2012", grade: 4.5, socioEconomicIndex: 5.5 },
              { year: "2009/2010", grade: 4.2, socioEconomicIndex: 4.8 },
            ],
          },
        ],
      },
    ],
  },
  {
    name: " Vodskov Skole",
    municipality: "Aalborg",
    type: "Folkeskoler",
    courses: [
      {
        name: "Dansk",
        disciplines: [
          {
            name: "Læsning",
            grades: [
              { year: "2018/2019", grade: 6.1, socioEconomicIndex: 6.3 },
              { year: "2017/2018", grade: 6, socioEconomicIndex: 5.9 },
              { year: "2016/2017", grade: 5.9, socioEconomicIndex: 6.4 },
              { year: "2015/2016", grade: 6.9, socioEconomicIndex: 6.7 },
              { year: "2014/2015", grade: 6.8, socioEconomicIndex: 6.7 },
              { year: "2013/2014", grade: 6.8, socioEconomicIndex: 6.7 },
              { year: "2012/2013", grade: 6.5, socioEconomicIndex: 6.9 },
              { year: "2011/2012", grade: 7.5, socioEconomicIndex: 6.6 },
              { year: "2010/2011", grade: 7.2, socioEconomicIndex: 6.6 },
              { year: "2009/2010", grade: 6.9, socioEconomicIndex: 6.4 },
            ],
          },
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.1, socioEconomicIndex: 8 },
              { year: "2017/2018", grade: 8.1, socioEconomicIndex: 7.9 },
              { year: "2016/2017", grade: 7.4, socioEconomicIndex: 7.8 },
              { year: "2015/2016", grade: 7.1, socioEconomicIndex: 7.6 },
              { year: "2014/2015", grade: 7.9, socioEconomicIndex: 8 },
              { year: "2013/2014", grade: 8.2, socioEconomicIndex: 8 },
              { year: "2012/2013", grade: 7.3, socioEconomicIndex: 7.7 },
              { year: "2011/2012", grade: 7.2, socioEconomicIndex: 7.2 },
              { year: "2010/2011", grade: 7.4, socioEconomicIndex: 7.4 },
              { year: "2009/2010", grade: 7.4, socioEconomicIndex: 7.3 },
            ],
          },
          {
            name: "Retskrivning",
            grades: [
              { year: "2018/2019", grade: 6.8, socioEconomicIndex: 7 },
              { year: "2017/2018", grade: 6.9, socioEconomicIndex: 6.6 },
              { year: "2016/2017", grade: 6.1, socioEconomicIndex: 7 },
              { year: "2015/2016", grade: 7.5, socioEconomicIndex: 7 },
              { year: "2014/2015", grade: 7.2, socioEconomicIndex: 7.5 },
              { year: "2013/2014", grade: 7, socioEconomicIndex: 6.6 },
              { year: "2012/2013", grade: 6.8, socioEconomicIndex: 6.9 },
              { year: "2011/2012", grade: 6.9, socioEconomicIndex: 6.3 },
              { year: "2010/2011", grade: 6.4, socioEconomicIndex: 6.4 },
              { year: "2009/2010", grade: 6.3, socioEconomicIndex: 6.3 },
            ],
          },
          {
            name: "Skriftlig",
            grades: [
              { year: "2018/2019", grade: 5.5, socioEconomicIndex: 6.4 },
              { year: "2017/2018", grade: 7.1, socioEconomicIndex: 6.4 },
              { year: "2016/2017", grade: 6.7, socioEconomicIndex: 6.5 },
              { year: "2015/2016", grade: 7.2, socioEconomicIndex: 6.5 },
              { year: "2014/2015", grade: 6, socioEconomicIndex: 6.6 },
              { year: "2013/2014", grade: 6.2, socioEconomicIndex: 6.7 },
              { year: "2012/2013", grade: 6.2, socioEconomicIndex: 6.6 },
              { year: "2011/2012", grade: 7.2, socioEconomicIndex: 6.3 },
              { year: "2010/2011", grade: 5.8, socioEconomicIndex: 6.2 },
              { year: "2009/2010", grade: 5.9, socioEconomicIndex: 6.1 },
            ],
          },
          {
            name: "Orden",
            grades: [
              { year: "2011/2012", grade: 7.6, socioEconomicIndex: 5.7 },
              { year: "2010/2011", grade: 5.5, socioEconomicIndex: 5.5 },
              { year: "2009/2010", grade: 5.6, socioEconomicIndex: 5.5 },
            ],
          },
        ],
      },
      {
        name: "Engelsk",
        disciplines: [
          {
            name: "Mundtlig",
            grades: [
              { year: "2018/2019", grade: 8.5, socioEconomicIndex: 8.4 },
              { year: "2017/2018", grade: 7.1, socioEconomicIndex: 7.4 },
              { year: "2016/2017", grade: 8.1, socioEconomicIndex: 8 },
              { year: "2015/2016", grade: 8, socioEconomicIndex: 7.9 },
              { year: "2014/2015", grade: 8.2, socioEconomicIndex: 8.1 },
              { year: "2013/2014", grade: 8.2, socioEconomicIndex: 8 },
              { year: "2012/2013", grade: 8.1, socioEconomicIndex: 8.1 },
              { year: "2011/2012", grade: 5.3, socioEconomicIndex: 6.7 },
              { year: "2010/2011", grade: 6.2, socioEconomicIndex: 7.1 },
              { year: "2009/2010", grade: 7.3, socioEconomicIndex: 7.2 },
            ],
          },
        ],
      },
      {
        name: "Fællesprøve i fysik/kemi, biologi og geografi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2018/2019", grade: 6.9, socioEconomicIndex: 7.3 },
              { year: "2017/2018", grade: 5.9, socioEconomicIndex: 7.1 },
              { year: "2016/2017", grade: 6.4, socioEconomicIndex: 7.3 },
            ],
          },
        ],
      },
      {
        name: "Matematik",
        disciplines: [
          {
            name: "Matematik med hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 8.2, socioEconomicIndex: 7.5 },
              { year: "2017/2018", grade: 6, socioEconomicIndex: 6.8 },
              { year: "2016/2017", grade: 5.8, socioEconomicIndex: 6.8 },
              { year: "2015/2016", grade: 6.9, socioEconomicIndex: 6.9 },
              { year: "2014/2015", grade: 6.4, socioEconomicIndex: 7.2 },
              { year: "2013/2014", grade: 6.7, socioEconomicIndex: 6.7 },
              { year: "2012/2013", grade: 6.3, socioEconomicIndex: 6.8 },
              { year: "2011/2012", grade: 6.8, socioEconomicIndex: 6.5 },
              { year: "2010/2011", grade: 5.9, socioEconomicIndex: 6.2 },
              { year: "2009/2010", grade: 7.1, socioEconomicIndex: 6.9 },
            ],
          },
          {
            name: "Matematik uden hjælpemidler",
            grades: [
              { year: "2018/2019", grade: 7.3, socioEconomicIndex: 7.3 },
              { year: "2017/2018", grade: 6.6, socioEconomicIndex: 6.8 },
              { year: "2016/2017", grade: 6, socioEconomicIndex: 7 },
              { year: "2015/2016", grade: 7, socioEconomicIndex: 7.1 },
              { year: "2014/2015", grade: 6.1, socioEconomicIndex: 7.3 },
              { year: "2013/2014", grade: 6.6, socioEconomicIndex: 7 },
              { year: "2012/2013", grade: 6.8, socioEconomicIndex: 7.6 },
              { year: "2011/2012", grade: 7.4, socioEconomicIndex: 7.2 },
              { year: "2010/2011", grade: 6.9, socioEconomicIndex: 6.9 },
              { year: "2009/2010", grade: 7.9, socioEconomicIndex: 7.7 },
            ],
          },
        ],
      },
      {
        name: "Fysik/kemi",
        disciplines: [
          {
            name: "Praktisk/mundtlig",
            grades: [
              { year: "2015/2016", grade: 5.9, socioEconomicIndex: 6.8 },
              { year: "2014/2015", grade: 5.7, socioEconomicIndex: 6.7 },
              { year: "2013/2014", grade: 6.7, socioEconomicIndex: 6.8 },
              { year: "2012/2013", grade: 6, socioEconomicIndex: 6.7 },
              { year: "2011/2012", grade: 5.2, socioEconomicIndex: 6.1 },
              { year: "2010/2011", grade: 4.5, socioEconomicIndex: 5.9 },
              { year: "2009/2010", grade: 5.8, socioEconomicIndex: 5.8 },
            ],
          },
        ],
      },
    ],
  },
] as ISchool[];
